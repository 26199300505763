import React, { Component } from "react";
import { Col, Container, Button, Card, CardBody } from "reactstrap";
import api from "../../../Services/api";
import apiCalls from '../../../config/apiCalls';
import { Constantes } from '../../../Services/Constantes';

import {
  Header,
  Base,
  Nome,
  Footer,
  UserGreeting,
  UserFoto,
  User,
  BaseConclusao,
  Logo
} from "../../Assets/Assets";

const apiProjects = apiCalls.projects;
let logoutTimer;

class Fimprova extends Component {
  state = {
    textoFinal: '<p></p>'
  }
  componentDidMount = () => {
    document.body.style.background = '#f2c800';

    if (!sessionStorage.getItem("token")) this.props.history.push("/");
    else logoutTimer = setInterval(this.logout, 20000);
  };

  componentWillUnmount() {
    document.body.style.background = '#fff';
  }

  start = () => {
    this.props.history.push("/iniciar");
  };
  logout = () => {
    api
      .post("api/auth/logout", { userId: sessionStorage.getItem("user") })
      .then(() => {
        clearInterval(logoutTimer);
        let projetoId = sessionStorage.getItem("projeto");
        sessionStorage.clear();
        this.props.history.push(`/projeto/${projetoId}`);
      })
      .catch(err => {
        if (err.response) console.log(err.response.data);
        this.logout();
      });
  };
  render() {
    return (
      <div className="text-left">
        <BaseConclusao>
          <Logo
            className="logo"
            alt="logo"
            src={require("../../Assets/_assets/logo.png")}
          />
          <div>
            <h2>Fim do Teste</h2>
            <br />
            <div style={{ marginTop: 10 }} className="font-size16"
              dangerouslySetInnerHTML={{
                __html: `${this.state.textoFinal}`,
              }}
            />
            <br />
            <br />
            <Button onClick={this.logout}>Voltar para página Inicial</Button>
            <Footer />
            <br />
            <br />
            <br />
            <br />
          </div>
        </BaseConclusao>
      </div>
    );
  }
}

export default Fimprova;
