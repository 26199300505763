import React, { Component } from "react";
import * as draftToHtml from "draftjs-to-html";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container
} from "reactstrap";
import styled from "styled-components";
import PropTypes from 'prop-types'

import {
  Base,
  Icon,
  Inst,
  Instrucoes,
  Contador,
  Button,
  Footer,
  Mesa
} from "../../Assets/Assets";
import Loading from "../../../Services/Loading";

import api from "../../../Services/api";
import Timer from "../../../Services/Timer";
import { Constantes } from "../../../Services/Constantes";
import { millisToMinutesAndSeconds } from "../../Instruments/Utils/Calculos";
import { srvTime } from '../Utils';
import fetchInstrumentoCase from "./utils/fetch-instrumento-case";
import { notifyError } from "../../../Services/Notificacoes";
import Criterio from "../../../models/Criterio";
import apiCalls from "../../../config/apiCalls";
import fetchEscolhasParticipante from "./utils/fetch-escolhas-participante";
import fetchProgressoGrupo from "./utils/fetch-progresso-grupo";
import { withRouter } from 'react-router'
import ErrorComponent from '../../../Services/ErrorComponent';

import capitalizeFirstLetterEachWord from '../../../utils/capitalizeFirstLetterEachWord';

import './styles.css'

const ObjectID = require("bson-objectid");

// Imagens
const spinner = Constantes.imagemSpinner;

// Estilos
const Criterios = styled.div`
  padding: 10px;
  background: #ffd800;
  color: #fff;
  font-size: 14px;
  margin: 10px;
  border-radius: 20px;
`;

const P = styled.p`
  font-size: 13px;
`;

const verificarRespostas = () => null;

class CaseGrupo extends Component {
  state = {
    criterios: [],
    textoGrupo: "",
    template: {},
    SelectId: [],

    /**
     * @type(bool) indica se o User é o secretário
     */
    secretario: false,
    criteriosGrupo: [],
    criteriosIndividual: [],
    escolhaIndividual: [],
    qtdCriterio:0,
    escolhaGrupo: [],
    modalErroCriterio: false,
    modalErroEscolha: false,
    modalConfirmacao: false,
    modalInstrucoes: false,
    loading: true,
    tempos: {},
    pararTimer: false,
    loadingSpinner: false,
    modalMesa: false,
    loadingEscolha: false,
    intervalResetRequest: false,
    haveError: false,
    errorCount: 0,
    errorRetry: 10
  };

  // Variaveis
  nomeSecretario = "";
  opcoesResposta = [];
  onLogout = false;
  onProximo = false;
  // Tempos
  tempoOnMount = srvTime();
  tempoTotal = 0;

  static propTypes = {
    idInstrumento: PropTypes.string.isRequired,
    tableName: PropTypes.string.isRequired,
    geral: PropTypes.object.isRequired,
    idProjeto: PropTypes.string.isRequired,
    rodada: PropTypes.number.isRequired,
  }

  static defaultProps = {
    /**
     * @type(Obj) todos os grupos da rodada
     */
    geral:
    {
      mesa: 'mesa 1'
    }
  }

  constructor(props) {
    super(props);
    const userId = sessionStorage.getItem("user") || this.props.idUser;
    const idInstrumento = props.idInstrumento;
    const tableName = props.tableName;
    this.inicializarCriterios(
      userId,
      idInstrumento,
      tableName
    );
  }

  inicializarEscolhas() {
    const qtdeEscolhas = this.state.template.tipo.quantidadeEscolhas
    let escolhaGrupo = new Array(qtdeEscolhas).fill(0);
    let escolhaIndividual = new Array(qtdeEscolhas).fill(0);
    for ( let i = 0; i < qtdeEscolhas; i++) {
      escolhaGrupo[i] = {
        id: null,
        value: ""
      };
      escolhaIndividual[i] = {
        id: null,
        value: ""
      };
    }
    this.state.escolhaGrupo = escolhaGrupo;
    this.state.escolhaIndividual = escolhaIndividual;
  }

  /**
   * 
   * @param {string} loadingVar 
   * @param {*} fn será executada entre um loading
   * @param {string} errorMsg 
   */
  async whileLoading (loadingVar, fn, errorMsg = "Não foi possível realizar a operação. Por favor, tente novamente") {
    try {
      this.setState({ [loadingVar]: true });
      await fn(); 
    } catch (error) {
      console.error(error);
      notifyError(errorMsg);
    } finally {
      this.setState({ [loadingVar]: false });
    }
  }

  async salvarResposta(fn, errorMsg) {
    await this.whileLoading("loadingEscolha", fn, errorMsg);
  }

  inicializarCriterios(userId, idInstrumento, tableName) {
    let criteriosGrupo = new Array(5).fill(0);
    let criteriosIndividual = new Array(5).fill(0);
    let criterios = new Array(9).fill(0);
    const qtdeCriterios = 5;
    for (let i = 0; i < qtdeCriterios; i++) {
      criteriosGrupo[i] =
        new Criterio(null, userId, idInstrumento, tableName);
      criteriosIndividual[i] =
        new Criterio(null, userId, idInstrumento, tableName);
      criterios[i] =
        new Criterio(null, userId, idInstrumento, tableName);
      criterios[i].value = "Critério disponível";
    }

    this.state.criteriosGrupo = criteriosGrupo;
    this.state.criteriosIndividual = criteriosIndividual;
    this.state.criterios = criterios;
  
  }

  hasRespostaSalva(escolha) {
    if (escolha == null || escolha && escolha.length <= 0) return false;
    else return true;
  }

  getNomeSecretarioFromProps(
    props
  ) {
    try {
      const meuGrupo = props.geral;
      const pessoasGrupo = meuGrupo.pessoas;
      const dadosSecretario = pessoasGrupo.find(
        (g) => g.id === props.secretario);
      return dadosSecretario.nome;
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * indica se o este user é o secretário.
   * retorna um booleano.
   */
  isSecretario(
    idUser,
    idSecretario
  ) {
    return (idSecretario === idUser);
  }

  /**
   * busca e carrega as escolhas do user no state, num formato especifico.
   */
  async configEscolhasIndividual() {
    try {

      const escolhas = await fetchEscolhasParticipante(
        this.idUser,
        this.idInstrumento,
        this.props.tableName
      );
        if(escolhas.opcoesEscolhidas.length === 0){
          //this.inicializarEscolhas()
          
        }else{
          this.setState({
            escolhaIndividual : escolhas.opcoesEscolhidas
          })
        }
        
      const hasRespostaSalva = this.hasRespostaSalva(escolhas.criteriosSelecionados);
      if (hasRespostaSalva == true) {
        const escolhasAnteriores = escolhas.criteriosSelecionados;
        this.setState(prev => {
          escolhasAnteriores.map((escolha, indexEscolha) => {
            if(prev["criteriosIndividual"][indexEscolha]){
              prev["criteriosIndividual"][indexEscolha]._id =
                escolha._id ? escolha._id.toString() : null;
              prev["criteriosIndividual"][indexEscolha].value = escolha.valor;
            }
          });
          return {
            criteriosIndividual: prev["criteriosIndividual"]
          }
        });
      } else {
        this.setState({ modalMesa: true });
      }
    } catch (error) {
      console.error(error);
      notifyError("Não foi possível carregar as escolhas. Por favor, recarregue a página");
    }
  }

  mountFunction = async() => {
    if (this.state.errorCount >= this.state.errorRetry) {
      return this.setState({ haveError: true, loading: false });
    }
    try {
      this.whileLoading("loadingEscolha", async () => {
        this.idUser = sessionStorage.getItem("user") || this.props.idUser;;
        this.idProjeto = sessionStorage.getItem("projeto") || this.props.idProjeto;
        this.idDinamica = sessionStorage.getItem("atividade") || this.props.idAtividade;
        this.idInstrumento = this.props.idInstrumento;;
        this.tableName = this.props.tableName;
        const criterios = await this.fetchCriteriosDisponiveis();
        if (criterios.length < 5) {
          this.state.criteriosGrupo.splice(0, 5 - criterios.length)
          this.state.criteriosIndividual.splice(0, 5 - criterios.length)
        }
  
        var i = 1
        for (let criterio of criterios) {
          if (criterio.value.length > 0) {
            this.state.qtdCriterio = i++
          }
        }
        if (this.state.qtdCriterio > 5) {
          this.state.qtdCriterio = 5
        }
  
        this.nomeSecretario = this.getNomeSecretarioFromProps(
          this.props
        );
        await this.configurarTemplate();
        const isSecretario = this.isSecretario(
          this.idUser,
          this.props.secretario // id do secretário.
        );
        await this.configCriteriosGrupo();
        await this.configEscolhasIndividual();
        this.subscribePush();
        this.setState({
          criterios,
          secretario: isSecretario,
          errorCount: 0
        });
      });
    } catch(err) {
      console.error(err);
      this.setState({ errorCount: this.state.errorCount + 1 });
      setTimeout(() => this.mountFunction(), 1000);
    }
    
  }

  componentDidMount = async () => {
    await this.mountFunction();

    api
    .get(
      `api/projects/rodadas/atualizar/${sessionStorage.getItem(
        "projeto"
      )}/${localStorage.getItem("dinamica")}/get-tempos`,
      {
        params: {
          userId: sessionStorage.getItem('user'),
          rodadaAtual: this.props.rodada,
          instrumento: Constantes.instrumentoCaseIndividual,
        }
      }
    )
  };

  /**
   * busca e carrega as escolhas do grupo no state, num formato especifico.
   */
  async configCriteriosGrupo() {
    try {
      const progressoGrupo = await fetchProgressoGrupo(
        this.tableName,
        this.idInstrumento,
        this.idProjeto
      );
      if (!progressoGrupo) return; // secretary answer not found.

      // if(this.props.secretario === this.idUser){
        if(progressoGrupo.opcoesEscolhidas.length === 0){
          if(this.props.secretario === this.idUser){
            this.inicializarEscolhas();
          }
        }else{
          this.setState({
            escolhaGrupo : progressoGrupo.opcoesEscolhidas
          })
        }

      if (progressoGrupo) {
        const hasRespostaGrupoSalva = this.hasRespostaSalva(
          progressoGrupo.criteriosSelecionados
        );
        if (hasRespostaGrupoSalva === true) {
          const escolhasAnteriores = progressoGrupo.criteriosSelecionados;
          this.setState(prev => {
            escolhasAnteriores.map((escolha, indexEscolha) => {
              prev["criteriosGrupo"][indexEscolha]._id =
                escolha._id ? escolha._id.toString() : null;
              prev["criteriosGrupo"][indexEscolha].value = escolha.valor;
              prev["criteriosGrupo"][indexEscolha].authorName = escolha.authorName;
            });
            return {
              criteriosGrupo: prev["criteriosGrupo"]
              
            }
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadEscolhasUSer(escolhasAnteriores) {
    try {
      this.setState(prev => {
        escolhasAnteriores.map((escolha, indexEscolha) => {
          prev["criteriosIndividual"][indexEscolha]._id =
            escolha._id ? escolha._id.toString() : null;
          prev["criteriosIndividual"][indexEscolha].value = escolha.valor;
        });
        return {
          criteriosIndividual: prev["criteriosIndividual"]
        }
      });
    } catch (error) {
      console.error(error);
      notifyError("Não foi possível carregar as escolhas. Por favor, recarregue a página");
    }
  }

  async saveEmptyEscolhas() {
    // TODO
  }

  componentWillUnmount = () => {
    Constantes.io.off("Projeto:Stop");
    Constantes.io.off("Projeto:RespostaGrupo");
    Constantes.io.off("Projeto:NovosTempos");
    Constantes.io.off("Auth:LogoutInstrumento");
  };

  fetchCriteriosDisponiveis = async () => {
    // Recuperando lista de criterios
    const { idInstrumento, tableName, indexGrupo } = this.props;
    const group = tableName;
    const res = await apiCalls.case.getCriteriaByUserGroupAndInstrument(
      idInstrumento,
      group,
      this.idDinamica,
      this.idProjeto,
      indexGrupo,
    );
    const valoresCriterios = res.data.criterios;
    let criterios = [];
    valoresCriterios.forEach((criterioSalvo, i) => {
      let criterioObj = new Criterio(
        criterioSalvo._id,
        this.idUser,
        this.idInstrumento,
        this.tableName,
        criterioSalvo.authorName);
      criterioObj.value = criterioSalvo.value;
      criterios[i] = criterioObj;
    });

    return criterios;
  }

  async configurarTemplate() {
    let instrumento = await fetchInstrumentoCase(this.props);
    const caseTemplateConfigId = instrumento.info;
    const chosenTemplateId = instrumento.template;
    const allTemplatesResponse = await apiCalls.case.getCaseTemplates();
    const caseConfigResponse = await apiCalls.case.getCaseConfig(caseTemplateConfigId);

    const allTemplates = allTemplatesResponse.data;
    const chosenTemplate = await allTemplates.find((t) => t._id === chosenTemplateId);
    const infoCase = caseConfigResponse.data;
    const { textos, opcoes } = infoCase;
    const template = chosenTemplate;

    let textoGrupo = textos[0].grupo
    this.caseEscolhido = template;

    for(let part of textos){
      for(let par of part.participante){
        if(par.participante === sessionStorage.getItem("user")){
          if(typeof (textos[par.texto].grupo) === 'string'){
            textoGrupo = textos[par.texto].grupo;
          }else{
            textoGrupo = draftToHtml(textos[par.texto].grupo);
          }
        }
      }        
    } 
    
    this.opcoesResposta = opcoes.map((o, index) => ({
      id: (index + 1),
      value: o,
      selected: false
    }));
    
    if(template.tipo.participantes === true){
      let index = (this.opcoesResposta.length) || 1;

      let grupoPessoas = this.props.geral;
      for (let pessoa of grupoPessoas.pessoas){
        index++;
        this.opcoesResposta.push({
          id: index,
          value: pessoa.nome,
          selected: false
        });
      }
    }
     let tempos = instrumento.tempos.grupo || {
       tempoFim: 0,
       tempoInicio: 0,
       tempoRestante: 0
     };
     this.tempoTotal = tempos.tempoFim - tempos.tempoInicio;
     tempos.tempoRestante = tempos.tempoFim - this.tempoOnMount;
     if (tempos.tempoRestante < 0) tempos.tempoRestante = 0;

    this.setState({
      template,
      textoGrupo,
      loading: false,
      tempos,
      opcoesResposta: this.opcoesResposta,
      choicesToMake: template.tipo.quantidadeEscolhas
    });
    this.inicializarEscolhas()

  }

  /**
   * cadastra o front as mensagens de socket do backend
   */
  subscribePush = () => {
    Constantes.io.on("Project:SyncConsultantTime", data => {
      if (Object.keys(data.tempos).length && data.instrumento === Constantes.instrumentoCaseIndividual && data.userId === sessionStorage.getItem('user')) {

        let tempos = data.tempos.grupo
        //tempos.tempoRestante = tempos.tempoFim - srvTime()
        this.tempoTotal = tempos.tempoFim - tempos.tempoInicio;
        this.setState({
          tempos,
          intervalResetRequest: true
        });
        this.setState({ tempos })
      }
    })

    if (this.isSecretario(
      this.idUser,
      this.props.secretario
    ) == false) {
      Constantes.io.on("dinamica:casegrupo:sync-secretario-answer", async () => {
        await this.configCriteriosGrupo();
      });
    }
    Constantes.io.on("Projeto:Stop", data => {
      if (
        data.instrumento === Constantes.instrumentoCaseIndividual &&
        data.start.grupo === 0 &&
        data.rodada === this.props.rodada
      ) {
        this.props.history.go();
      }
    });
    Constantes.io.on("Projeto:NovosTempos", data => {
      if (data.instrumento === Constantes.instrumentoCaseIndividual) {
        let tempos = data.tempos.grupo;
        //tempos.tempoRestante = tempos.tempoFim - srvTime();
        this.tempoTotal = tempos.tempoFim - tempos.tempoInicio;
        this.setState({
          tempos,
          intervalResetRequest: true
        });
      } 
    });

    Constantes.io.on("Auth:LogoutInstrumento", userId => {
      if (sessionStorage.getItem("user") === userId.toString()) {
        this.onLogout = true;
        if (this.state.tempos.tempoRestante > 0)
          this.setState({ pararTimer: true });
        else this.fimAtividade();
      }
    });
  };

  /**
   * exibe o modal modalErroCriterio caso encontre erros de validação
   */
  verifica = () => {
    let verificacao = true;
    let countCriterioGrupo = 0;
    let countCriterioIndividual = 0;
  
    if (this.state.secretario) {
      if (this.state.qtdCriterio < this.state.criteriosGrupo.length) {
        this.state.criteriosGrupo.forEach(criterio => {
          if (criterio._id) countCriterioGrupo++;
        });

        if (countCriterioGrupo !== this.state.qtdCriterio) verificacao = false;
      } else {
        this.state.criteriosGrupo.forEach(criterio => {
          if (!criterio._id)
            verificacao = false;
        });
      }
    }

    // Checando se mandou todos os individuais
    if (this.state.qtdCriterio < this.state.criteriosIndividual.length) {
      this.state.criteriosIndividual.forEach(criterio => {
        if (criterio._id) countCriterioIndividual++;
      });

      if (countCriterioIndividual !== this.state.qtdCriterio) verificacao = false;
    } else {
      this.state.criteriosIndividual.forEach(criterio => {
        if (!criterio._id)
          verificacao = false;
      });
    }


    if (!verificacao) this.setState({ modalErroCriterio: true });
    else {
      let verificarEscolha = true;

      if (this.state.secretario) {
        this.state.escolhaGrupo.forEach(escolha => {

          if (
            escolha.value === Constantes.mensagemAguardandoSecretario ||
            escolha.value === Constantes.mensagemSelecionar ||
            escolha.value === ''
          )
            verificarEscolha = false;
        });
      }

      this.state.escolhaIndividual.forEach(escolha => {
        if (escolha.value === "" || escolha.value === Constantes.mensagemSelecionar)
          verificarEscolha = false;
      });

      if (!verificarEscolha) this.setState({ modalErroEscolha: true });
      else this.setState({ modalConfirmacao: true });
    }


  };

  gravaRespostas = async (retry = false) => {
    if (this.state.secretario) {
      let resp = {
        instrumento: Constantes.instrumentoCaseIndividual,
        resposta: {
          criterios: this.state.criteriosGrupo,
          escolha: this.state.escolhaGrupo
        },
        grupoIndex: this.props.grupo,
        rodada: this.props.rodada
      };
      const dinamicaId = sessionStorage.getItem("atividade");
      const projetoId = sessionStorage.getItem("projeto");

        await this.saveOpcaoGrupo();
        await this.saveOpcaoUser();


      // // debugger
      // await api
      //   .put(
      //     `api/projects/editRespostasSecretario/${projetoId}/${dinamicaId}`,
      //     { ...resp }
      //   )
      //   .catch(error => {
      //     if (error.response) {
      //       // The request was made and the server responded with a status code
      //       // that falls out of the range of 2xx
      //     } else if (error.request) {
      //       // The request was made but no response was received
      //       // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      //       // http.ClientRequest in node.js
      //     } else {
      //       // Something happened in setting up the request that triggered an Error
      //     }

      //     // this.gravaRespostas();
      //   });
    }
    // Atualizando tempos:
    let tempos = {
      tempoAtual: this.state.tempos.tempoRestante
    };
    if (retry) var atualizadoEm = retry;
    else var atualizadoEm = srvTime();

    let resp = {
      instrumentId: this.props.idInstrumento,
      instrumento: Constantes.instrumentoCaseIndividual2,
      resposta: {
        criterios: this.state.criteriosIndividual,
        escolha: this.state.escolhaIndividual
      },
      atualizadoEm,
      rodada: this.props.rodada,
      tempos
    };
    const dinamicaId = sessionStorage.getItem("atividade");
    await this.saveOpcaoUser();
    // debugger
    await api
      .put(`api/user/respostas/modulo/${Constantes.moduloDinamicas}`, {
        resp,
        atividadeId: dinamicaId
      })
      .then((res) => {
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error(error.response.data);
          console.error(error.response.status);
          console.error(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.error(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error', error.message);
        }
        console.error(error.config);

        // this.gravaRespostas(atualizadoEm);
      });
  };


    /**
   * salva as escolhas individuais do participante
   */
  async saveOpcaoGrupo() {
    try {
      const opcoesEscolhidas = this.state.escolhaGrupo
      await apiCalls.case.saveOpcaoGrupo({
        queryParams: {
          idUser: this.idUser,
          group: this.props.tableName,
          idInstrumento: this.idInstrumento
        },
        data: {
          opcoesEscolhidas
        }
      });
    } catch (error) {
      console.error(error);
      notifyError("Não foi possível salvar a resposta. Por favor, tente novamente");
    }
  }


  async saveOpcaoUser() {
    try {
      const opcoesEscolhidas = this.state.escolhaIndividual
      await apiCalls.case.saveOpcaoUser({
        queryParams: {
          idUser: this.idUser,
          idInstrumento: this.idInstrumento
        },
        data: {
          opcoesEscolhidas
        }
      });
    } catch (error) {
      console.error(error);
      notifyError("Não foi possível salvar a resposta. Por favor, tente novamente");
    }
  }

  /**
   * acionado no clique do botão Finalizar.
   * deve encerrar esta fase da atividade.
   */
  proximaAtividade = async () => {
    if (this.state.errorCount >= this.state.errorRetry) {
      return this.setState({ haveError: true, loading: false, modalConfirmacao: false });
    }
    try {
      const indexRodadaAtual = this.props.rodada;

      await apiCalls.case.finalizarCaseGrupo(
        {
          queryParams: {
            idUser: this.idUser,
            idInstrumento: this.idInstrumento,
            idDinamica: this.idDinamica,
            idProjeto: this.props.idProjeto,
            indexRodadaAtual
          }
        }
      );
      this.props.action();
    } catch (err) {
      console.error(err);
      this.setState({ errorCount: this.state.errorCount + 1 });
      setTimeout(() => this.proximaAtividade(), 1000);
    }
  };

  tempoAtualizado = tempo => {
    this.setState(previousState => ({
      tempos: {
        ...previousState.tempos,
        tempoRestante: tempo
      }
    }));
  };

  getCriterioById(
    id
  ) {
    return this.state.criterios.find(
      (c) => c._id.toString() === id);
  }

  /**
   * envia para o backend os critérios selecionados pelo grupo,
   * em um formato especifico.
   */
  async saveEscolhasGrupo() {
    const { criteriosGrupo } = this.state;
    const criteriosSelecionados = criteriosGrupo.map((criterio) => ({
      _id: criterio._id,
      valor: criterio.value
    }));
    await apiCalls.case.saveEscolhasGrupo({
      queryParams: {
        group: this.props.tableName,
        idInstrumento: this.idInstrumento,
        idDinamica: this.idDinamica
      },
      data: {
        criteriosSelecionados
      }
    });
  }

  /**
   * callback chamado ao selecionar um criterio no select
   * de escolhas do grupo
   */
  atualizarListaGrupo = async (e, indice) => {
    this.salvarResposta(async () => {
      let criteriosGrupo = this.state.criteriosGrupo;
      const idCriterioSelecionado = e.target.value;
      const criterioSelecionado = this.getCriterioById(
        idCriterioSelecionado
      );
      criteriosGrupo[indice] = criterioSelecionado;
      await this.saveEscolhasGrupo();
    }, "Não foi possível salvar sua resposta. Por favor, tente novamente")
  };

  /**
   * salva as escolhas individuais do participante
   */
  async saveEscolhasCriterios() {
    const criteriosSelecionados = this.state.criteriosIndividual.map((criterio) => {
      return {
        _id: criterio._id,
        valor: criterio.value
      }
    });
    let progressoSalvo = await apiCalls.case.saveEscolhasUser({
      queryParams: {
        idUser: this.idUser,
        idInstrumento: this.idInstrumento,
        group: this.props.tableName
      },
      data: {
        criteriosSelecionados
      }
    });
  }

  /**
   * salva os critérios selecionados na coluna individual
   */
  atualizarListaIndividual = async (e, indice) => {
    this.salvarResposta(async () => {
      let criteriosIndividual = this.state.criteriosIndividual;
      const idCriterioSelecionado = e.target.value;
      const criterioSelecionado = this.getCriterioById(
        idCriterioSelecionado
      );
      criteriosIndividual[indice] = criterioSelecionado;
      await this.saveEscolhasCriterios();
    }, "Não foi possível salvar sua resposta. Por favor, tente novamente");
};

  checarCriteriosDisponiveis = (criterio, select, grupo = false) => {
    let check = true;
    if (grupo) {
      this.state.criteriosGrupo.forEach((cri, index) => {
        if (cri === criterio && select !== index) {
          check = false;
        }
      });
    } else {
      this.state.criteriosIndividual.forEach((cri, index) => {
        if (cri === criterio && select !== index) {
          check = false;
        }
      });
    }
    return check;
  };

  cancelarMesa = () => {
    this.setState({ modalMesa: false });
    this.setState({ modalInstrucoes: true });
  };
  cancelar = () => {
    this.setState({
      modalErroCriterio: false,
      modalConfirmacao: false,
      modalErroEscolha: false
    });
  };

  onChangeEscolha = (e, choiceIndex) => {
    this.salvarResposta(async () => {
      let escolha = this.state[e.target.name];
      let { opcoesResposta } = this.state;
      
      const choiceId = e.target.value;
      if (!choiceId) return;
      
      let choice = opcoesResposta.find((o) => Number(o.id) === Number(choiceId));

      escolha[choiceIndex] = {
        id: choiceId,
        value: choice.value
      };
      await this.gravaRespostas();
    }, "Não foi possível salvar sua resposta. Por favor, tente novamente")
  };

  fimAtividade = () => {
    if (this.onLogout) this.props.logout();
    else {
      if (!this.onProximo) {
        this.onProximo = true;
        if (!this.state.loadingSpinner) this.setState({ loadingSpinner: true });
        this.proximaAtividade();
      }
    }
  };

  toggleInstrucoes = () => {
    this.setState(previousState => ({
      modalInstrucoes: !previousState.modalInstrucoes
    }));
  };

  toggleErroCriterio = () => {
    this.setState(previousState => ({
      modalErroCriterio: !previousState.modalErroCriterio
    }));
  };

  toggleErroEscolha = () => {
    this.setState(previousState => ({
      modalErroEscolha: !previousState.modalErroEscolha
    }));
  };

  toggleConfirmacao = () => {
    if (!this.state.loadingSpinner) {
      this.setState(previousState => ({
        modalConfirmacao: !previousState.modalConfirmacao
      }));
    }
  };

  render() {
    const {
      choicesToMake,
      loadingEscolha,
      secretario,
      escolhaGrupo,
      escolhaIndividual,
      opcoesResposta
    } = this.state;
    if (this.state.haveError) return <ErrorComponent />;
    if (this.state.loading) {
      if (this.state.proximaAtividade) this.proximaAtividade();
      return <Loading />;
    }
    return (
      <div className="text-left back" tabIndex="0">
        <Base>
          {/*--------------------------------- MODALS ------------------------------*/}
          {/*Modal instruções*/}
          <Modal
            isOpen={this.state.modalMesa}
            className={this.props.className}
          >
            <ModalBody>
              <Instrucoes>
                <Mesa mesa={this.props.tableName} />
              </Instrucoes>
            </ModalBody>
            <ModalFooter>
              <Container className="text-center">
                <Button color="success" onClick={this.cancelarMesa}>
                  Já estou na Mesa {this.props.tableName}
                </Button>{" "}
              </Container>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={this.state.modalInstrucoes}
            className={this.props.className}
          >
            <ModalBody>
              <Instrucoes>
                <div>
                  <b>INSTRUÇÕES</b>
                  <br />
                  {this.state.secretario
                    ? "Você foi nomeado para secretário do seu grupo! Aguarde a chegada de todos do Grupo e decidam quais são os melhores Critérios. Após todos estarem de acordo, submeta novamente o case."
                    : ""}
                  <br /> <br />
                  <b>Enunciado</b>
                  <P>Vocês terão um total de {`${millisToMinutesAndSeconds(this.tempoTotal)}`} minutos para discussão em grupo, escolha de 5 critérios dentre os definidos pelos participantes na etapa individual e indicação da(s) decisão(ões) por consenso do Grupo.</P>
                  <P>Lembre-se que você também poderá escolher individualmente os 5 critérios e indicar a decisão que lhe parecer mais pertinente.</P> 
                  <P>Nessa atividade o grupo discute a mesma situação, mas com informações atinentes a cada realidade. Além das informações apresentadas no texto, você pode também considerar suas experiências sua e visão sobre necessidades da Organização, assim como quaisquer outras informações sobre mercado, concorrência, futuro etc, que sejam de seu domínio. Contudo, compartilhar as informações que você conhece é uma decisão pessoal.</P>

                  <b>Como escolher os critérios e a decisão</b>
                  <P>Ao clicar no campo "select", será aberto a lista com todos os critérios/decisão disponíveis para a escolha. Selecione a opção que desejar. Para trocar a opção, clique novamente no campo e escolha outra opção. Você precisará fazer isso em todos os campos.</P>
                  <strong>Regras</strong>
                  <P>Só é permitido escolher um critério em cada campo</P>
                  <P>O critério escolhido em um campo não poderá mais ser selecionado em outro campo</P>
                  <P>Não é permitido edições dos critérios já criados</P>
                  <P>Ao lado de cada critério, você poderá visualizar o nome de quem o criou</P>

                  <b>Secretário</b>
                  <P>O secretário, além de enviar a SUA própria definição de critério e decisão, também será o responsável por enviar a atividade em equipe</P>
                  <b>Entregas individuais e em grupo</b>
                    <P>Todos os participantes terão duas colunas:</P> 
                  <P><strong>Direita</strong>: Representa a atividade individual. Só você mesmo pode enxergar e mudar a escolha dos critérios/decisão</P>
                  <P><strong>Esquerda</strong>: Representa a atividade em grupo. Só o secretário pode fazer alterações. Todas as alterações feitas por ele, são visualizadas por todos nessa coluna.</P>
                  <P>Quando tiver concluído a escolha dos critérios e a decisão (individual e em grupo), você deverá clicar em “enviar” e confirmar o envio da atividade. </P>
                  <P>IMPORTANTE: O <strong>secretário</strong> é o responsável pelo envio da atividade em <strong>grupo</strong>, desta forma, só deverá enviar quando tiver finalizado a atividade em grupo e a própria individual (colunas da esquerda e da direita).</P>
                  <P>Qualquer participante que não seja o secretário, poderá enviar a atividade própria a qualquer momento, contudo, caso seja enviado antes do secretário finalizar a parte em grupo, o participante não poderá mais visualizar as alterações feitas na coluna do grupo (esquerda)</P>
                  <P>Após enviar a atividade você não poderá voltar para ajustar, nem visualizar alterações do grupo.</P>
                </div>
              </Instrucoes>
            </ModalBody>
            <ModalFooter>
              <Container className="text-center">
                <Button color="success" onClick={this.toggleInstrucoes}>
                  ok
                </Button>{" "}
              </Container>
            </ModalFooter>
          </Modal>
          {/* Modal de falta de criterio */}
          <Modal
            isOpen={this.state.modalErroCriterio}
            toggle={this.toggleErroCriterio}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleErroCriterio}>Case</ModalHeader>
            <ModalBody>Escolha todos os Critérios</ModalBody>
            <ModalFooter>
              <Button color="success" onClick={this.toggleErroCriterio}>
                OK
              </Button>{" "}
            </ModalFooter>
          </Modal>
          {/* Modal de falta de escolha */}
          <Modal
            isOpen={this.state.modalErroEscolha}
            toggle={this.toggleErroEscolha}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleErroEscolha}>Case</ModalHeader>
            <ModalBody>Escolha a resposta</ModalBody>
            <ModalFooter>
              <Button color="success" onClick={this.toggleErroEscolha}>
                OK
              </Button>{" "}
            </ModalFooter>
          </Modal>
          {/* Modal de confirmacao */}
          <Modal
            isOpen={this.state.modalConfirmacao}
            toggle={this.toggleConfirmacao}
            className={this.props.className}
            onClosed={() => (this.onProximo = false)}
          >
            <ModalHeader toggle={this.toggleConfirmacao}>Case</ModalHeader>
            <ModalBody>
              Se você estiver satisfeito com suas escolhas clique em prosseguir,
              se não clique em cancelar
            </ModalBody>
            <ModalFooter>
              {!this.state.loadingSpinner ? (
                <div>
                  <Button
                    color="success"
                    onClick={() => {
                      this.state.tempos.tempoRestante > 0
                        ? this.setState({
                            loadingSpinner: true,
                            pararTimer: true
                          })
                        : this.fimAtividade();
                    }}
                    disabled={this.state.loadingSpinner}
                  >
                    Prosseguir
                  </Button>{" "}
                  <Button
                    color="danger"
                    onClick={this.toggleConfirmacao}
                    disabled={this.state.loadingSpinner}
                  >
                    Cancelar
                  </Button>
                </div>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>Enviando. Por favor não recarregue a tela. <img style={{ width: 20 }} src={require(`../../Assets/_assets/${spinner}`)} /></span>
                  {
                    this.state.errorCount > 0 ?
                      <><span style={{ fontSize: 12 }}>Tentativas {this.state.errorCount} / {this.state.errorRetry}.</span></>
                    : null
                  }
                </div>
              )}
            </ModalFooter>
          </Modal>
          {/*--------------------------------- MODALS ------------------------------*/}
          <Container>
            <br />
            <br />
            <br />
            <Row>
              <Col>
                <h1>Case</h1>
              </Col>
              <Col className="text-right">
                <Icon
                  src={require("../../Assets/_assets/doubt.png")}
                  alt="Instrucoes"
                />
                <Inst onClick={this.toggleInstrucoes}>Instruções</Inst>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col sm="6">
                <Contador
                  counter={
                    <Timer
                      intervalResetRequest={this.state.intervalResetRequest}
                      intervalResetFeedback={() => this.setState({ intervalResetRequest: false })}
                      crescente={false}
                      tempo={this.state.tempos.tempoRestante}
                      resetAutomatico={false}
                      tempoAtualizado={this.tempoAtualizado}
                      pararTimer={this.state.pararTimer}
                      resetar={false}
                      tempoParado={this.fimAtividade}
                      id="tempo"
                    />
                  }
                  tempo={
                    100 * (this.state.tempos.tempoRestante / this.tempoTotal)
                  }
                />
              </Col>
            </Row>
            <br />
            <br />
            <h1>{this.state.template.nomeCase}</h1>

            <div
              className='responsive-image'
              dangerouslySetInnerHTML={{
                __html: (this.state.textoGrupo)
              }}
            />

            {/* <div
              dangerouslySetInnerHTML={{
                __html: (this.state.textoGrupo)
              }}
            /> */}

            <br />
            <br />
            <h2>Mesa: {this.props.tableName}</h2>
            <br />
            <h2>
              Secretário: {this.state.secretario ? "Você" : capitalizeFirstLetterEachWord(this.nomeSecretario.toLowerCase())}
            </h2>
            <br />
            <br />
            <Row>
              <Col sm="6">
                {" "}
                {/* Secretário */}
                {this.state.secretario && (
                  <div>
                    <h4>
                      Defina os {this.state.criterios ? this.state.qtdCriterio : Constantes.quantidadeCriteriosGrupo } Critérios
                      para o Grupo
                    </h4>
                    {this.state.criteriosGrupo.map((criterio, index) => (
                      <div key={index}>
                        <select
                          className="form-control"
                          onChange={e => this.atualizarListaGrupo(e, index)}
                          value={criterio._id ? criterio._id : ""}
                          disabled={this.state.loadingEscolha}
                          id={"select-sec-"+index}
                        >
                          <option
                            key={Constantes.mensagemSelecionar}
                            value=""
                            disabled
                          >
                            {" "}
                            {Constantes.mensagemSelecionar}{" "}
                          </option>
                          {
                        this.state.criterios.map((criterioDisponivel, indice) => {
                          if(criterioDisponivel.value !== ""){
                            if(this.state.criteriosGrupo.some((criterioGrupo) => {
                              return criterioDisponivel._id == criterioGrupo._id
                            })) {
                              return <option title={`${criterioDisponivel.value} - ${criterioDisponivel.authorName && capitalizeFirstLetterEachWord(criterioDisponivel.authorName.toLowerCase())}`} key={indice} disabled value={criterioDisponivel._id}>
                              {criterioDisponivel.value} - {criterioDisponivel.authorName && capitalizeFirstLetterEachWord(criterioDisponivel.authorName.toLowerCase())}
                              </option>
                            }
                            return <option title={`${criterioDisponivel.value} - ${criterioDisponivel.authorName && capitalizeFirstLetterEachWord(criterioDisponivel.authorName.toLowerCase())}`} key={indice} value={criterioDisponivel._id}>
                              {criterioDisponivel.value} - {criterioDisponivel.authorName && capitalizeFirstLetterEachWord(criterioDisponivel.authorName.toLowerCase())}
                            </option>
                          }
                        })
                      }
                        </select>
                        <br />
                        <br />
                      </div>
                    ))}
                  </div>
                )}
                {/* Participante */}
                {!this.state.secretario && (
                  <div>
                    <h4>Critérios do Grupo</h4>
                    {this.state.criteriosGrupo.map((criterio, indice) => (
                      <div key={indice}>
                        <select className="form-control" disabled id={"select-sec-"+indice}>
                          <option title={`${criterio.value} - ${criterio.authorName && capitalizeFirstLetterEachWord(criterio.authorName.toLowerCase())}`}>{criterio.value} - {criterio.authorName && capitalizeFirstLetterEachWord(criterio.authorName.toLowerCase())} </option>
                        </select>
                        <br />
                        <br />
                      </div>
                    ))}
                  </div>
                )}
              </Col>
              <Col sm="6">
                {" "}
                <h4>Defina os Critérios Individuais:</h4>
                {this.state.criteriosIndividual.map((criterio, index) => (
                  <div key={index}>
                    <select
                      key={index}
                      className="form-control"
                      onChange={e => {
                        this.atualizarListaIndividual(e, index)
                      }
                    }
                      disabled={this.state.loadingEscolha}
                      value={criterio._id ? criterio._id : ""}
                      id={"select-par-"+index}
                    >
                      <option
                        key={Constantes.mensagemSelecionar}
                        value=""
                        disabled
                      >
                        {" "}
                        {Constantes.mensagemSelecionar}{" "}
                      </option>
                      {
                        this.state.criterios.map((criterioDisponivel, indice) => {
                          if(criterioDisponivel.value !== ""){
                            if(this.state.criteriosIndividual.some((criterioIndividual) => {
                              return criterioDisponivel._id == criterioIndividual._id
                            })) {
                              return <option title={`${criterioDisponivel.value} - ${criterioDisponivel.authorName && capitalizeFirstLetterEachWord(criterioDisponivel.authorName.toLowerCase())}`} key={indice} disabled value={criterioDisponivel._id}>
                              {criterioDisponivel.value} - {criterioDisponivel.authorName && capitalizeFirstLetterEachWord(criterioDisponivel.authorName.toLowerCase())}
                              </option>
                            }
                            return <option title={`${criterioDisponivel.value} - ${criterioDisponivel.authorName && capitalizeFirstLetterEachWord(criterioDisponivel.authorName.toLowerCase())}`} key={indice} value={criterioDisponivel._id}>
                              {criterioDisponivel.value} - {criterioDisponivel.authorName && capitalizeFirstLetterEachWord(criterioDisponivel.authorName.toLowerCase())}
                            </option>
                          }
                        })
                      }
                    </select>
                    <br />
                    <br />
                  </div>
                ))}
                <br />
                <br />
              </Col>
              <Col sm="6" className="text-center">
                <h4>Escolha em grupo</h4>
                {secretario
                  ? new Array(choicesToMake).fill(0).map((_, selectIndex) => {
                    const related = escolhaGrupo[selectIndex] || {};

                    return (
                      <div key={selectIndex}>
                        <select
                          className="form-control"
                          name="escolhaGrupo"
                          key={selectIndex}
                          onChange={e => this.onChangeEscolha(e, selectIndex)}
                          value={related.id || ""}
                          disabled={loadingEscolha}
                          id={"select-grupo-" + selectIndex}
                        >
                          <option
                            key={Constantes.mensagemSelecionar}
                            value=""
                            disabled
                          >
                            {" "}
                            {Constantes.mensagemSelecionar}{" "}
                          </option>

                          {opcoesResposta.map((choice, choiceIndex) => {

                            const alreadySelected = escolhaGrupo
                              .find((a) => Number(a.id) === Number(choice.id));

                            return (
                              <option title={choice.value} disabled={alreadySelected}
                                key={choiceIndex}
                                value={choice.id}>

                                {choice.value && capitalizeFirstLetterEachWord(choice.value.toLowerCase())}
                              </option>
                            )
                          })
                          }
                        </select>
                        <br />
                        <br />
                      </div>
                    )
                  })
                  : this.state.escolhaGrupo.map((escolhasGrupo, index) => (
                      <div key={index}>
                        <select
                          className="form-control"
                          name="escolhaGrupo"
                          key={index}
                          onChange={e => this.onChangeEscolha(e, index)}
                          value={this.state.escolhaGrupo[index]}
                          disabled={!this.state.secretario || this.state.loadingEscolha}
                          id={"select-grupo-"+index}
                        >
                          <option title={escolhasGrupo.value && capitalizeFirstLetterEachWord(escolhasGrupo.value.toLowerCase())}> {escolhasGrupo.value && capitalizeFirstLetterEachWord(escolhasGrupo.value.toLowerCase())} </option>
                        </select>
                        <br />
                        <br />
                      </div>
                    ))}
              </Col>
              <br />
              <br />
              <Col sm="6" className="text-center">
                <h4>Escolha individual</h4>
                {

                new Array(choicesToMake).fill(0).map((_, choiceIndex) => {
                  const related = escolhaIndividual[choiceIndex] || {};

                  return (
                    <div key={choiceIndex}>
                      <select
                        className="form-control"
                        key={choiceIndex}
                        name="escolhaIndividual"
                        value={related.id || ""}
                        onChange={e => this.onChangeEscolha(e, choiceIndex)}
                        disabled={this.state.loadingEscolha}
                        id={"select-individual-" + choiceIndex}
                      >
                        <option
                          key={Constantes.mensagemSelecionar}
                          value=""
                          disabled
                        >
                          {" "}
                          {Constantes.mensagemSelecionar}{" "}
                        </option>
                        {
                          opcoesResposta.map((choice, indice) => {
                            const alreadySelected = escolhaIndividual
                              .find((a) => Number(a.id) === Number(choice.id));

                            return (
                              <option title={choice.value && capitalizeFirstLetterEachWord(choice.value.toLowerCase())} disabled={alreadySelected}
                                key={indice}
                                value={choice.id}>

                                {choice.value && capitalizeFirstLetterEachWord(choice.value.toLowerCase())}
                              </option>
                            )
                          })
                        }
                      </select>
                      <br />
                      <br />
                    </div>
                  )
                })}
              </Col>
              <br />
              <br />
              <Col sm="12" className="text-center">
                <Button onClick={this.verifica} color="success" disabled={this.state.loadingEscolha}>
                  Enviar
                </Button>
                <Footer />
              </Col>
            </Row>
          </Container>
        </Base>
      </div>
    );
  }
}

export default withRouter(CaseGrupo);
