import React, { Component } from "react";
import { Col, Container, Button, Card, CardBody } from "reactstrap";
import api from "../../../Services/api";
import apiCalls from '../../../config/apiCalls';
import { Constantes } from '../../../Services/Constantes';

import {
  Header,
  Base,
  Nome,
  Footer,
  UserGreeting,
  UserFoto,
  User,
  BaseConclusao,
  Logo
} from "../../Assets/Assets";

const apiProjects = apiCalls.projects;
let logoutTimer;

class Fimprova extends Component {
  state = {
    textoFinal: ''
  }
  componentDidMount = () => {
    document.body.style.background = '#f2c800';

    const projectId = sessionStorage.getItem('projeto');
    const atividadeId = sessionStorage.getItem('atividade');
    const modulo = sessionStorage.getItem('modulo');
    const userId = sessionStorage.getItem('user');

    // Textos da atividade
    apiProjects.getProvasText(projectId, modulo, atividadeId)
      .then((res) => {
        let { textos, textosTemplate } = res.data;

        if (modulo === Constantes.moduloProvas) {
          textos.final = textosTemplate.final + `<p>${textos.final}</p>`
        }

        this.setState({
          textoFinal: textos.final
        });
      })
      .catch(err => {
        console.error(err);
      });
    if (!sessionStorage.getItem("token")) this.props.history.push("/");
    else logoutTimer = setInterval(this.logout, 20000);
  };

  componentWillUnmount() {
    document.body.style.background = '#fff';
  }

  start = () => {
    this.props.history.push("/iniciar");
  };
  logout = () => {
    api
      .post("api/auth/logout", { userId: sessionStorage.getItem("user") })
      .then(() => {
        clearInterval(logoutTimer);
        let projetoId = sessionStorage.getItem("projeto");
        sessionStorage.clear();
        this.props.history.push(`/projeto/${projetoId}`);
      })
      .catch(err => {
        if (err.response) console.log(err.response.data);
        this.logout();
      });
  };
  render() {
    return (
      <div className="text-left">
        <BaseConclusao>
          <Logo
            className="logo"
            alt="logo"
            src={require("../../Assets/_assets/logo.png")}
          />
          <div>
            <h2>Fim da Prova</h2>
            <br />
            Aguarde, em silêncio, instruções do instrutor
            <div style={{ marginTop: 10 }} className="font-size16"
              dangerouslySetInnerHTML={{
                __html: `${this.state.textoFinal}`,
              }}
            />
            <br />
            <br />
            <Button onClick={this.logout}>Voltar para página Inicial</Button>
            <Footer />
            <br />
            <br />
            <br />
            <br />
          </div>
        </BaseConclusao>
      </div>
    );
  }
}

export default Fimprova;
