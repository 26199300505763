import { Constantes } from "../../Services/Constantes";

function restartPing(tipo) {

    clearInterval(Constantes.pingServerInterval)
    Constantes.pingServerInterval = setInterval(() => {
        // console.log("pingando server... as: ", tipo)

        Constantes.io.emit("pingg", { tipo })
    }, 5000)
}

export function startPing(tipo) {

    let interval = Constantes.pingServerInterval
    // console.log('status ping: ', Constantes.pingServerInterval)

    if (!!interval == false) {
        Constantes.pingServerInterval = setInterval(() => {
            // console.log("pingando server... as: ", tipo)
    
            Constantes.io.emit("pingg", { tipo })
        }, 5000)
    } else {
        restartPing(tipo)
    }
}