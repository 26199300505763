import React, { Component } from 'react';
import JoyrideContext from '../../contexts/joyride';
import styled from 'styled-components';
import Aguarde from '../Instruments/Aguarde';
import Header from '../Assets/header';
import api from '../../Services/api';
import Storytelling from '../Instruments/Storytelling/Storytelling';
import StorytellingGrupo from '../Instruments/Storytelling/StorytellingGrupo';
import Lideranca from '../Instruments/EstilosDeLideranca/EstilosDeLideranca';
import Sociometria from '../Instruments/Sociometria/Sociometria';
import Case from '../Instruments/Case/Case';
import CaseGrupo from '../Instruments/Case/CaseGrupo';
import AvaliacaoBinaria from '../Instruments/Case/AvaliacaoBinaria';
import TestAvaliacaoBinaria from '../TestsInstruments/Case/AvaliacaoBinaria';
import TestCaseGrupo from '../TestsInstruments/Case/CaseGrupo';
import TestCase from '../TestsInstruments/Case/Case';
import TestStorytelling from '../TestsInstruments/Storytelling/Storytelling';
import TestStorytellingGrupo from '../TestsInstruments/Storytelling/StorytellingGrupo';
import TestProva from '../TestsInstruments/Prova/Prova';
import TestEstilos from '../TestsInstruments/EstilosDeLideranca/EstilosDeLideranca';
import TestSociometria from '../TestsInstruments/Sociometria/Sociometria';
import { Constantes } from '../../Services/Constantes';
import Prova from '../Instruments/Prova/Prova';
import Loading from '../../Services/Loading';
import ErrorComponent from '../../Services/ErrorComponent';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { testarServidor } from '../../Services/GerenciarConexao';
import { startPing } from './ping';
import ErrorBoundary from '../../components/error-boundary';

const Main = styled.div`
  min-height: 100%;
  display: flex;
  align-items: center;
`;

class Iniciar extends Component {
  static contextType = JoyrideContext;
  state = {
    instrumentoAtual: <Loading />,
    haveError: false,
    errorCount: 0,
    errorRetry: 10,
    testInstruments: null
  };

  instrumentoAtual = <Loading />;
  finalizados = [];

  /**
   * indica se a tela a ser renderizada é a de "Aguarde".
   */
  loading = true;
  conexaoBanco;
  timer = null

  testsInstrumentsObj = {
    Case: <TestCase action={this.testAction} />,
    CaseGrupo: <TestCaseGrupo action={this.testAction} />,
    Binario: <TestAvaliacaoBinaria action={this.testAction} />,
    Storytelling: <TestStorytelling action={this.testAction} />,
    StorytellingGrupo: <TestStorytellingGrupo action={this.testAction} />,
    Prova: <TestProva action={this.testAction} />
  }

  componentDidMount = async () => {
    // this.conexaoBanco = setInterval(testarServidor, 10000);
    testarServidor();
    await this.recebendoInfo();

    // this.meuGrupo();
    /// this.maturidade()
  };

  sendUserInfo = (instrumentId, userId, userName) => {
    Constantes.io.emit('dinamica:atividade-id', instrumentId);
    Constantes.io.emit('atividade:send-mongo-id', userId);
    Constantes.io.emit('atividade:send-username', userName);
  }
  
  componentWillUnmount = () => {
    clearInterval(this.conexaoBanco);
    clearInterval(this.timer);
    Constantes.io.off('Projeto:Play');
    this.sendUserInfo(null, null, null);
  };

  getTestInstrument = (instrumentName) => {
    if (instrumentName === 'Case') return <TestCase action={this.testAction} />
    if (instrumentName === 'CaseGrupo') return <TestCaseGrupo action={this.testAction} />
    if (instrumentName === 'Binario') return <TestAvaliacaoBinaria action={this.testAction} />
    if (instrumentName === 'Storytelling') return <TestStorytelling action={this.testAction} />
    if (instrumentName === 'StorytellingGrupo') return <TestStorytellingGrupo action={this.testAction} />
    if (instrumentName === 'Prova') return <TestProva action={this.testAction} />
    if (instrumentName.includes('Estilos')) return <TestEstilos action={this.testAction} />
    if(instrumentName === 'Sociometria') return <TestSociometria action={this.testAction} />
  }

  sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  testAction = async () => {
    this.context.setRunTour(false);

    this.instrumentoAtual = <Loading />
    this.setState({ instrumentoAtual: <Loading /> });
    
    await this.sleep(1500);

    if (this.state.testInstruments[this.state.currentInstrumentIndex + 1]) {

      const atividadeId = sessionStorage.getItem('atividade');
      const userId = sessionStorage.getItem('user');

      const authRes = await api.get(`/v3/amb-testes/${atividadeId}/verify-participant/${userId}`);
      const { authorized } = authRes.data;
      if (!authorized) return this.logout();

      const nextInst = this.getTestInstrument(this.state.testInstruments[this.state.currentInstrumentIndex + 1].instrument)
      this.instrumentoAtual = nextInst;

      sessionStorage.setItem('currentTestIndex', this.state.currentInstrumentIndex + 1);
      this.setState({ instrumentoAtual: nextInst, currentInstrumentIndex: this.state.currentInstrumentIndex + 1 })
    } else {
      this.props.history.push('/fimteste');
    }
  }

  recebendoInfo = async () => {
    if (this.state.errorCount >= this.state.errorRetry) {
      this.loading = false;
      return this.setState({ haveError: true, instrumentoAtual: <ErrorComponent /> });
    }
    try {
    const atividadeId = sessionStorage.getItem('atividade');
    const modulo = sessionStorage.getItem('modulo');
    const projetoId = sessionStorage.getItem('projeto');
    const userId = sessionStorage.getItem('user');
    this.idUser = userId;
    this.idProjeto = projetoId;
    this.idAtividade = atividadeId;
    this.nomeUser = sessionStorage.getItem("username");

    if (modulo.toLowerCase().includes('test')) {
      this.context.setRunTour(false);
      const authRes = await api.get(`/v3/amb-testes/${atividadeId}/verify-participant/${userId}`);
      const { authorized } = authRes.data;
      if (!authorized) return this.logout();

      const testRes = await api.get(`/v3/amb-testes/${atividadeId}/participant`);

      const { instrumentsObj } = testRes.data;

      if (!instrumentsObj || !instrumentsObj.length) return this.props.history.push('/fimteste')

      const instrumentIndex = sessionStorage.getItem('currentTestIndex') ? Number(sessionStorage.getItem('currentTestIndex')) : 0;

      const inst = this.getTestInstrument(instrumentsObj[instrumentIndex].instrument);

      this.instrumentoAtual = inst;
      sessionStorage.setItem('currentTestIndex', instrumentIndex);
      this.setState({ instrumentoAtual: inst, testInstruments: instrumentsObj, currentInstrumentIndex: instrumentIndex });

      return;
    }

    // Pegando infos do projeto
    await api
      .get(
        `api/projects/${
        projetoId
        }/participante/${
        modulo
        }/${
        atividadeId
        }?idUser=${
        userId
        }`
      )
      .then(async (res) => {
        let { emAndamento, total } = res.data;
        this.setState({ 
          errorCount: 0,
          haveError: false
        })
        // Verifica se dinâmica
        if (modulo === Constantes.moduloDinamicas) {
          let { meuGrupo, secretarios, instrumentos, grupo, answeredAll, isVinculado } = res.data;
          // Verifica instrumento atual
          this.verificaPlay(
            emAndamento,
            meuGrupo,
            grupo,
            secretarios,
            total,
            instrumentos,
            answeredAll,
            isVinculado
          );
        }

        // Verifica se prova
        else if (modulo === Constantes.moduloProvas) {
          await api
            .get(
              `api/user/usuario/${userId}/finalizados/${projetoId}/${modulo}/${atividadeId}`
            )
            .then(async res => {
              this.finalizados = res.data;
            })
            .catch((err) => {
              console.error(err);
            });

          // Verifica se já foi feito
          if (this.finalizados.length) {
            this.props.history.push('/fimdeprova');
          } else {
            if (emAndamento.length) {
              this.instrumentoAtual = (
                <Prova action={this.aguarde} logout={this.logoutProcess} />
              );
            } else {
              this.instrumentoAtual = this.getAguardeComponent();
            }
          }
        }
        this.setState({
          instrumentoAtual: this.instrumentoAtual
        });
      })

      this.subscribePush();

      // .catch((err) => {
      //   console.error(err);
      // });
    } catch (err) {
      console.error(err);
      this.setState({ errorCount: this.state.errorCount + 1 });
      setTimeout(() => this.recebendoInfo(), 1000);
    }
    
  };

  subscribePush = () => {
    const io = Constantes.io;
    // Configura ping
    startPing('ATIVIDADE');
    // Constantes.io.on('disconnect', this.logoutProcess);
    // envia o id solicitado pelo socket
    io.on('id', () => {
      let id = sessionStorage.getItem('user');

      io.emit('id', { id });
    });
    io.on("reconnect", () => {
      this.sendUserInfo(this.idAtividade, this.idUser, this.nomeUser);
    });
    io.on('dinamica:logout', async () => {
      this.logout();
    });

    this.sendUserInfo(this.idAtividade, this.idUser, this.nomeUser);
  };

  updateTimer = (instrumento, rodada) => {
    Constantes.io.emit('sync-dynamic-timers', { instrumento, rodadaAtual: rodada, userId: sessionStorage.getItem('user') });
  }

  //// FUNCAO QUE VERIFICA QUAL INSTRUMENTO ESTA EM PLAY
  verificaPlay = (emAndamento, meuGrupo, grupo, secretarios, total, instruments, answeredAll, isVinculado = true) => {
    if (!isVinculado) return this.logout();

    this.instrumentoAtual = this.getAguardeComponent();

    function allActivitiesEnded() {
      try {
        let stopped = 0;
        instruments.map(i => {
          if (i.start.individual === 0) stopped++;

          if (i.content.includes("storytelling") || i.content.includes("case")) {
            if (i.start.grupo === 0) stopped++;
          }

          if (i.content.includes("case") && i.start.binario === 0) {
            stopped++;
          }
        });

        return stopped === total;
      } catch (error) {
        console.error(error);
        return false;
      }
    }

    // Verifica se ainda está em andamento
    if (answeredAll || allActivitiesEnded()) {
      this.props.history.push('/concluido');
    } else if (!emAndamento || !emAndamento.length) {
      return;
    } else {
        const { rodada, start, instrumento, idInstrumento } = emAndamento[0];

        const minhaMesa = grupo.mesa;

        const secretarioGrupoDB = grupo && grupo.secretario;

        this.timer = setInterval(() => this.updateTimer(instrumento, rodada), 60000)
        
        if (start.individual === 1) {
          if (
            instrumento === Constantes.instrumentoStoryTellingIndividual
          ) {
            this.instrumentoAtual = (
              <Storytelling
                rodada={rodada}
                action={this.aguarde}
                logout={this.logoutProcess}
                idInstrumento={idInstrumento}
              />
            );
            this.loading = false;
          } else if (
            instrumento === Constantes.instrumentoEstilos
          ) {
            this.instrumentoAtual = (
              <Lideranca
                rodada={rodada}
                action={this.aguarde}
                idInstrumento={idInstrumento}
                grupo={meuGrupo[rodada]}
                logout={this.logoutProcess}
              />
            );
            this.loading = false;
          } else if (
            instrumento === Constantes.instrumentoSociometria
          ) {
            this.instrumentoAtual = (
              <Sociometria
                rodada={rodada}
                action={this.aguarde}
                grupo={meuGrupo[rodada]}
                idInstrumento={idInstrumento}
                logout={this.logoutProcess}
              />
            );
            this.loading = false;
          } else if (
            instrumento === Constantes.instrumentoCaseIndividual
          ) {
            this.instrumentoAtual = (
              <Case
                rodada={rodada}
                action={this.aguarde}
                grupo={meuGrupo[rodada]}
                logout={this.logoutProcess}
                idInstrumento={idInstrumento}
                tableName={minhaMesa}
              />
            );
            this.loading = false;
          }
        }

        ///// VAMOS TOCAR OS SECUNDARIOS
        // VERIFICAR SECRETARIO
        if (start.grupo === 1) {
          if (
            instrumento === Constantes.instrumentoStoryTellingIndividual
          ) {
            this.instrumentoAtual = (
              <StorytellingGrupo
                action={this.aguarde}
                grupo={meuGrupo[rodada]}
                geral={grupo}
                rodada={rodada}
                idInstrumento={idInstrumento}
                secretario={ secretarioGrupoDB || secretarios[rodada] }
                logout={this.logoutProcess}
              />
            );
            this.loading = false;
          }
          if (instrumento === Constantes.instrumentoCaseIndividual) {
            this.instrumentoAtual = (
              <CaseGrupo
                grupo={meuGrupo[rodada]}
                action={this.aguarde}
                geral={grupo}
                rodada={rodada}
                secretario={ secretarioGrupoDB || secretarios[rodada] }
                logout={this.logoutProcess}
                idProjeto={this.idProjeto}
                idInstrumento={idInstrumento}
                tableName={minhaMesa}
              />
            );
            this.loading = false;
          }
        }
        
        if (start.binario === 1) {
          if (
            instrumento === Constantes.instrumentoCaseIndividual
          ) {
            this.instrumentoAtual = (
              <AvaliacaoBinaria
                grupo={meuGrupo[rodada]}
                action={this.aguarde}
                rodada={rodada}
                logout={this.logoutProcess}
                idProjeto={this.idProjeto}
                idInstrumento={idInstrumento}
                idDinamica={this.idAtividade}
                tableName={minhaMesa}
                idUser = {this.idUser}
              />
            );
            this.loading = false;
          }
        }
    }
  };

  /// FUNCAO QUE CHAMA O AGUARDE
  aguarde = async status => {
    try {
      const atividadeId = sessionStorage.getItem("atividade");
      const modulo = sessionStorage.getItem("modulo");
      const projetoId = sessionStorage.getItem("projeto");
      const userId = sessionStorage.getItem("user");
      // Atualizando informações
      this.loading = true;
      
      const resProjeto = await api.get(
        `api/projects/${projetoId}/participante/${modulo}/${atividadeId}`
      );
      let { emAndamento, total } = resProjeto.data;
      // Verifica se dinâmica
      if (modulo === Constantes.moduloDinamicas) {

        let { meuGrupo, grupo, secretarios, instrumentos, answeredAll, isVinculado } = resProjeto.data;
        // Verifica instrumento atual
        this.verificaPlay(
          emAndamento, 
          meuGrupo, 
          grupo, 
          secretarios, 
          total,
          instrumentos,
          answeredAll,
          isVinculado
        );
      }
      // Verifica se prova
      else if (modulo === Constantes.moduloProvas) {
        const resUser = await api.get(
          `api/user/usuario/${userId}/finalizados/${projetoId}/${modulo}/${atividadeId}`
        );
        this.finalizados = resUser.data;

        if (emAndamento.length) {
          if (!this.finalizados.length) {
            this.loading = false;
            this.instrumentoAtual = (
              <Prova action={this.aguarde} logout={this.logoutProcess} />
            );
          }
        } else {
          this.instrumentoAtual = this.getAguardeComponent();
        }
      }
      if (this.loading === true) {
        if (status === "fimDeProva") {
          this.props.history.push("/fimdeprova");
        } else {
          // Instrumento da dinâmica finalizado
          this.setState({
            instrumentoAtual: this.getAguardeComponent(),
            loading: true
          });
        }
      } else {
        this.setState({
          instrumentoAtual: this.instrumentoAtual
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  logout = async () => {
    api
      .post('api/auth/logout', { userId: sessionStorage.getItem('user') })
      .then(() => {
        this.logoutProcess();
      })
      .catch((err) => {
        if (err.response) console.error(err.response.data);
      });
  };

  logoutProcess = async () => {
    let projetoId = sessionStorage.getItem('projeto');
    sessionStorage.clear();
    this.props.history.push(`/projeto/${projetoId}`);
  };

  /**
   * renderiza a tela de Aguarde
   */
  getAguardeComponent() {
    return (
      <Aguarde
        info="aguarde"
        logout={this.logoutProcess}
        idUser={this.idUser}
        renderProvaComponent={
          this.renderProvaComponent
        } />
    );
  }

  renderProvaComponent = (
    data
  ) => {
    if (this.loading === true) {
      this.instrumentoAtual = (
        <Prova
          action={this.aguarde}
          logout={this.logoutProcess}
        />
      );
    }
    this.setState({
      instrumentoAtual: this.instrumentoAtual
    });
  }

  render() {
    return (
      <div>
        <Header onLogout={this.logout} />
        <ToastContainer />
        <Main>
          <ErrorBoundary errorComponent={() => <h2>Não foi possível carregar a atividade. Por favor, recarregue a página</h2>}>
            {this.state.instrumentoAtual}
          </ErrorBoundary>
        </Main>
      </div>
    );
  }
}
export default Iniciar;
