import React, { Component } from "react";
import Joyride from 'react-joyride';
import JoyrideContext from "../../../contexts/joyride";

import * as draftToHtml from "draftjs-to-html";
import {
  Col,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Container
} from "reactstrap";
import {
  Base,
  Icon,
  Inst,
  Instrucoes2,
  Contador,
  Textarea,
  Button,
  Footer
} from "../../Assets/Assets";
import { Constantes } from "../../../Services/Constantes";
import Timer from "../../../Services/Timer";
import Loading from "../../../Services/Loading";
import { millisToMinutesAndSeconds } from "../Utils/Calculos";
import { srvTime } from '../Utils';
import { notifyError } from "../../../Services/Notificacoes";
import ErrorComponent from '../../../Services/ErrorComponent';
import PropTypes from 'prop-types';

import styled from "styled-components";

import fetchInstrumentoCase from './utils/fetch-instrumento-case';
import fetchRespostaPrevia from './utils/fetch-resposta-previa';
import Criterio from "../../../models/Criterio";
import { withRouter } from 'react-router';
import { TourP } from "../../../utils/TourUtils";

import './styles.css';

const ObjectID = require("bson-objectid");

// Imagens
const spinner = Constantes.imagemSpinner;

const P = styled.p`
  font-size: 13px;
`;

String.prototype.cleanup = function() {
  return this.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");
};

class Case extends Component {
  static contextType = JoyrideContext;

  state = {
    template: {
      __v:0,
      _id:"627013994cb0bd3f26061952",
      created_at:"2022-05-02T17:23:37.905Z",
      dinamicaId:"627012c54cb0bd3f26061905",
      instrumentId:"627013824cb0bd3f26061949",
      nomeCase:"Treinamento",
      opcoes:["Burger King", "Mc Donald's", "Bob's"],
      projectId:"607496a37ef518352e709009",
      roundId:"6270137f4cb0bd3f26061945",
      textos:[{
        active: true,
        grupo: "<p>Selecione os 5 principais critérios para a escolha da melhor hamburgueria fast food e eleja dentre as opções a vencedora:</p>",
        individual: "<p>Defina os 3 principais critérios para a escolha da melhor hamburgueria fast food:</p>",
        participante: [{ grupo: 0, participante: sessionStorage.getItem('user'), texto: 0 }]
      }],
      updated_at:"2022-05-02T17:23:37.905Z",
    },
    textoIndividual: "<p>Defina os 3 principais critérios para a escolha da melhor hamburgueria fast food:</p>",
    criterios: [],
    modalConfirmacao: false,
    msgErro: "",
    modalErro: false,
    modalInstrucoes: true,
    loading: false,
    tempos: {},
    pararTimer: false,
    loadingSpinner: false,
    intervalResetRequest: false,
    haveError: false,
    errorCount: 0,
    errorRetry: 10,
    steps: []
  };

  onLogout = false;
  onProximo = false;
  // Tempos
  tempoOnMount = srvTime();
  tempoTotal = 0;

  mountCriterias = async () => {
    return new Promise((resolve, reject) => {
      const userId = sessionStorage.getItem("user");
      const idInstrumento = this.props.idInstrumento;
      const tableName = 'Teste';
      let arrayCriterios = new Array(3).fill(0);
      arrayCriterios.map((_c, i) => {
        arrayCriterios[i] = new Criterio(ObjectID(), userId, idInstrumento, tableName);
      });

      resolve(this.setState({
        criterios: arrayCriterios
      }));
    });
  }

  componentDidMount = async () => {
    await this.mountCriterias();

    const { setHaveTour, setRunTour } = this.context;

      const steps= [
        {
          target: '.case-step-1',
          content: <TourP>Aqui você acompanha o tempo restante para a conclusão desta atividade.</TourP>,
          disableBeacon: true
        },
        {
          target: '.case-step-2',
          content: <TourP>Nesta área você deve escrever um critério em cada campo com no máximo 100 caracteres.
          Procure estabelecer critérios relevantes de acordo com o tema que foi proposto.</TourP>,
          disableBeacon: true
        },
        {
          target: '.case-step-3',
          content: <TourP>Após incluir os três critérios, envie a sua atividade clicando neste botão. Ao clicar no botão, uma
          janela irá se abrir. Caso não tenha dúvidas quanto as suas escolhas, confirme o envio clicando no
          botão ‘Prosseguir’</TourP>,
          disableBeacon: true
        },
      ];
      setHaveTour(true);
      setRunTour(false);

      this.setState({ steps });

    //this.subscribePush();
    //this.recebendoInfo();
  };

  // componentWillUnmount = () => {
  //   Constantes.io.off("Projeto:Stop");
  //   Constantes.io.off("Projeto:NovosTempos");
  //   Constantes.io.off("Auth:LogoutInstrumento");
  // };

  /**
   * Salva critérios em branco na base.
   * Usada no primeiro acesso a atividade.
   */
  saveEmptyCriterios = async () => {
    try {
      const criteriosArray = [...this.state.criterios];
      // await apiCalls.binary.postCriteriaV2(criteriosArray);

      this.setState({
        criterios: criteriosArray
      })
    } catch (error) {
      console.error(error);
      throw new Error("Erro: não foi possível salvar critérios em branco!");
    }
  }

  updateSavedCriterios = () => {
    const criterios = this.state.criterios;
    criterios.map((criterio) => {
      // apiCalls.binary.updateCriteria(criterio)
      // .then((_) => {
      //   // criterios atualizados
      // })
      // .catch((err) => {
      //   console.error(err);
      // })
    });
  }

  recebendoInfo = async () => {
    if (this.state.errorCount >= this.state.errorRetry) {
      return this.setState({ haveError: true, loading: false });
    }
    try {
      this.setState({ loading: true });
 
      this.setState({
        modalInstrucoes: true
      });
      await this.saveEmptyCriterios();

    } catch (err) {
      console.error(err);
      this.setState({ errorCount: this.state.errorCount + 1 });
      setTimeout(() => this.recebendoInfo(), 1000);
    } finally {
      this.setState({
        loading: false
      })
    }
  };

  subscribePush = () => {
    Constantes.io.on("Projeto:Stop", data => {
      if (
        data.instrumento === Constantes.instrumentoCaseIndividual &&
        data.start.individual === 0 &&
        data.rodada === this.props.rodada
      ) {
        this.props.history.go();
      }
    });
    Constantes.io.on("Projeto:NovosTempos", data => {
      if (
        data.instrumento === Constantes.instrumentoCaseIndividual
      ) {
        let tempos = data.tempos.individual;
        //tempos.tempoRestante = tempos.tempoFim - srvTime();
        this.tempoTotal = tempos.tempoFim - tempos.tempoInicio;
        this.setState({
          tempos,
          intervalResetRequest: true
        });
      }
    });
    Constantes.io.on("Auth:LogoutInstrumento", userId => {
      if (sessionStorage.getItem("user") === userId.toString()) {
        this.onLogout = true;
        if (this.state.tempos.tempoRestante > 0)
          this.setState({ pararTimer: true });
        else this.fimAtividade();
      }
    });
  };

  proximaAtividade = async () => {
    if (this.state.errorCount >= this.state.errorRetry) {
      return this.setState({ haveError: true, loading: false, modalConfirmacao: false });
    }
    try {
      const idProjeto = sessionStorage.getItem("projeto") || this.props.idProjeto;
      const idDinamica = sessionStorage.getItem("atividade") || this.props.idDinamica;
      const idUser = sessionStorage.getItem("user");
      const idInstrumento = this.props.idInstrumento;

      // await apiCalls.case.finalizarCaseIndividual({
      //   queryParams: {
      //     idProjeto,
      //     idDinamica,
      //     idUser,
      //     idInstrumento,
      //     indexRodadaAtual: this.indexRodadaAtual,
      //     group: this.props.tableName
      //   }
      // });
      this.props.action();
    } catch (err) {
      console.error(err);
      this.setState({ errorCount: this.state.errorCount + 1 });
      setTimeout(() => this.proximaAtividade(), 1000);
    }
  };

  verificarRespostasCase = () => {
    this.context.setRunTour(false);
    let verificar = true;
    loop1: for (let i = 0; i < Constantes.caseIndividualQuantidade; i++) {
      if (this.state.criterios[i].value.trim() == "") {
        this.setState({
          modalErro: true,
          msgErro: "Preencha todos os critérios"
        });
        verificar = false;
        break;
      }
      for (let j = i + 1; j < Constantes.caseIndividualQuantidade; j++) {
        if (
          this.state.criterios[i].value.cleanup() ===
          this.state.criterios[j].value.cleanup()
        ) {
          verificar = false;
          this.setState({
            modalErro: true,
            msgErro: "Os critérios precisam ser diferentes"
          });
          break loop1;
        }
      }
    }
    if (verificar) this.setState({ modalConfirmacao: true });
  };

  tempoAtualizado = tempo => {
    this.setState(previousState => ({
      tempos: {
        ...previousState.tempos,
        tempoRestante: tempo
      }
    }));
  };

  handleCriterioChange = e => {
    if (e.target.value.length <= Constantes.criteriosTamanho) {
      let criterios = this.state.criterios;
      criterios[
        parseInt(e.target.name, 10) - 1
      ].value = e.target.value.toLowerCase();
      this.setState({ criterios });
    }
  };

  fimAtividade = () => {
    if (this.onLogout) this.props.logout();
    else {
      if (!this.onProximo) {
        this.onProximo = true;
        if (!this.state.loadingSpinner) this.setState({ loadingSpinner: true });
        this.proximaAtividade();
      }
    }
  };

  toggleConfirmacao = () => {
    if (!this.state.loadingSpinner) {
      this.setState(previousState => ({
        modalConfirmacao: !previousState.modalConfirmacao
      }));
    }
  };

  toggleErro = () => {
    this.setState(previousState => ({
      modalErro: !previousState.modalErro
    }));
  };

  toggleInstrucoes = () => {
    if (this.state.modalInstrucoes && !this.state.alreadyOpened) {
      this.context.setRunTour(true);
      this.setState({ alreadyOpened: true });
    } 
    this.setState(previousState => ({
      modalInstrucoes: !previousState.modalInstrucoes,
    }));
  };

  callback = (data) => {
    if (data.status === 'finished' || data.action === 'close') {
        // This explicitly stops the tour (otherwise it displays a "beacon" to resume the tour)
        this.context.setRunTour(false);
    }
  }

  render() {
    if (this.state.haveError) return <ErrorComponent />;
    if (this.state.loading) {
      if (this.state.proximaAtividade) this.proximaAtividade();

      return <Loading />;
    }
    const criterios = this.state.criterios;
    return (
      <div className="text-left back" tabIndex="0">
        <Joyride
          showProgress={true}
          steps={this.state.steps}
          run={this.context.runTour}
          continuous={true}
          callback={this.callback}
          disableOverlayClose={true}
          spotlightClicks={true}
          disableBeacon={true}
          locale={{
            back: 'Voltar',
            close: 'Fechar',
            last: 'Fim',
            next: 'Próximo',
            skip: 'Pular'
          }}
          styles={{
            options: {
              primaryColor: '#012a4a'
            },
            tooltipContainer: {
              textAlign: 'left',
            },
          }}
        />
        <Base>
          <Modal
            isOpen={this.state.modalInstrucoes}
            className={this.props.className}
          >
            <ModalBody>
              <Instrucoes2>
                <div>
                <P>Car@ participante, a seguir algumas instruções para que você possa realizar a entrega da atividade case individual. Lembrando que essas atividades são ilustrativas e não terão seus resultados considerados ou salvos no IAPP.</P>
                <P>As instruções a seguir são relacionadas exclusivamente ao teste atual. Na aplicação oficial, as instruções poderão ser diferentes.</P>
                <P><strong>Enunciado</strong></P>
                <P>Nessa atividade simularemos uma situa&ccedil;&atilde;o de trabalho em time. Em duas etapas: (i)Uma entrega individual e (ii)depois uma entrega com seus colegas de grupo. Vamos iniciar com a etapa (i).</P>
                <P>Lembrando que essa atividade &eacute; ilustrativa e n&atilde;o ter&atilde;o seus resultados considerados ou salvos no IAPP.</P>
                <P><strong>Como criar os crit&eacute;rios</strong></P>
                <P>Ap&oacute;s a leitura da situa&ccedil;&atilde;o apresentada a seguir, haver&aacute; 3 campos para voc&ecirc; registrar cada um dos crit&eacute;rios que propor. Clique no campo e insira o texto.</P>
                <P><strong>Regras</strong></P>
                <P>O campo aceita, no m&aacute;ximo, 100 caracteres</P>
                <P>Atente-se para que o texto de cada campo represente apenas UM crit&eacute;rio. N&atilde;o dever&aacute; haver dois ou mais crit&eacute;rios em um &uacute;nico campo</P>
                <P>N&atilde;o &eacute; poss&iacute;vel enviar a atividade sem ter criado os 3 crit&eacute;rios</P>
                <P>Quando tiver conclu&iacute;do a cria&ccedil;&atilde;o dos 3 crit&eacute;rios, voc&ecirc; dever&aacute; clicar em ENVIAR para a atividade.</P>
                <P>IMPORTANTE: Ap&oacute;s enviar a atividade voc&ecirc; n&atilde;o poder&aacute; voltar para ajustar</P>
                <P>Lembre-se, o Crit&eacute;rio &eacute; um argumento que deve ser entendido pelo seu executivo, sem que haja necessidade de voc&ecirc; estar presente para explicar.</P>
                <P><strong>Exemplos:</strong></P>
                <P>Certo: "O custo, sendo uma vari&aacute;vel estrat&eacute;gica, dever&aacute; ser analisada em rela&ccedil;&atilde;o ao retorno do investimento."</P>
                <P>Errado: "Custo versus benef&iacute;cio."</P>
                </div>
              </Instrucoes2>
            </ModalBody>
            <ModalFooter>
              <Container className="text-center">
                <Button color="success" onClick={this.toggleInstrucoes}>
                  ok
                </Button>{" "}
              </Container>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={this.state.modalConfirmacao}
            toggle={this.toggleConfirmacao}
            className={this.props.className}
            onClosed={() => (this.onProximo = false)}
          >
            <ModalHeader toggle={this.toggleConfirmacao}>Case</ModalHeader>
            <ModalBody>
              Se você estiver satisfeito com suas escolhas clique em prosseguir,
              se não clique em cancelar
            </ModalBody>
            <ModalFooter>
              {!this.state.loadingSpinner ? (
                <div>
                  <Button
                    color="success"
                    onClick={() => {
                      this.state.tempos.tempoRestante > 0
                        ? this.setState({
                            loadingSpinner: true,
                            pararTimer: true
                          })
                        : this.fimAtividade();
                    }}
                    disabled={this.state.loadingSpinner}
                  >
                    Prosseguir
                  </Button>{" "}
                  <Button
                    color="danger"
                    onClick={this.toggleConfirmacao}
                    disabled={this.state.loadingSpinner}
                  >
                    Cancelar
                  </Button>
                </div>
              ) : (
                <img src={require(`../../Assets/_assets/${spinner}`)} />
              )}
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={this.state.modalErro}
            toggle={this.toggleErro}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleErro}>Case</ModalHeader>
            <ModalBody>{this.state.msgErro}</ModalBody>
            <ModalFooter>
              <Button color="success" onClick={this.toggleErro}>
                OK
              </Button>{" "}
            </ModalFooter>
          </Modal>
          <Container>
            <br />
            <br />
            <br />
            <Row>
              <Col>
                <h1>Case</h1>
              </Col>
              <Col className="text-right">
                <Icon
                  src={require("../../Assets/_assets/doubt.png")}
                  alt="Instrucoes"
                />
                <Inst onClick={this.toggleInstrucoes}>Instruções</Inst>
              </Col>
            </Row>
            <br />
            <br />
             <Row>
              <Col className="case-step-1" sm="6">
                <Contador
                  counter={
                    <Timer
                      intervalResetRequest={this.state.intervalResetRequest}
                      intervalResetFeedback={() => this.setState({ intervalResetRequest: false })}
                      crescente={false}
                      tempo={901200}
                      resetAutomatico={false}
                      tempoAtualizado={this.tempoAtualizado}
                      pararTimer={this.state.pararTimer}
                      resetar={false}
                      tempoParado={this.fimAtividade}
                      id="tempo"
                    />
                  }
                  tempo={
                    901200
                  }
                />
              </Col>
            </Row>
            <br />
            <br /> 
            <h1>{this.state.template.nomeCase}</h1>
            {/*<div
              dangerouslySetInnerHTML={{
                __html: this.state.template.template.texto
              }}
            />*/}

            <div
              className='responsive-image'
              dangerouslySetInnerHTML={{
                __html: (this.state.textoIndividual)
              }}
            />

            <Col sm="12" className="text-left case-step-2">
              {" "}
              <br />
              <br />
              <h2>Defina os Critérios Abaixo:</h2>
              {this.state.criterios.map((criterio, index) => (
                <div key={index}>
                  {`${this.state.criterios[index].value.length}/${
                    Constantes.criteriosTamanho
                  }`}
                  <br />
                  <Textarea
                    placeholder="Insira um critério de decisão"
                    name={index + 1}
                    key={index}
                    onChange={this.handleCriterioChange}
                    onBlur={this.updateSavedCriterios}
                    onFocus={this.updateSavedCriterios}
                    value={criterio.value}
                  />
                  <br />
                </div>
              ))}
            </Col>
            <Container className="text-center">
              <Button className="case-step-3" color="success" onClick={this.verificarRespostasCase}>
                {" "}
                Enviar{" "}
              </Button>
            </Container>
            <Footer />
            <br />
            <br />
            <br />
            <br />
            <br />
          </Container>
        </Base>
      </div>
    );
  }
}

export default withRouter(Case);
