const segundo = 1000;

export function atualizarTempo(tempo) {
  if (tempo > 0) return tempo - segundo;
  else return 0;
}

// Pad to 2 or 3 digits, default is 2
function pad(n, z) {
  z = z || 2;
  return ("00" + n).slice(-z);
}

export function formatarTempo(tempo) {
  var ms = tempo % 1000;
  tempo = (tempo - ms) / 1000;
  var secs = tempo % 60;
  tempo = (tempo - secs) / 60;
  var mins = tempo % 60;
  var hrs = (tempo - mins) / 60;

  return pad(mins) + ":" + pad(secs);
}
