// Base url
let { REACT_APP_BASE_URL } = process.env;

if (!REACT_APP_BASE_URL) {
    // REACT_APP_BASE_URL = 'http://localhost:3000';
    REACT_APP_BASE_URL = 'http://3.12.105.236:3000';
    
}

export const baseURL = REACT_APP_BASE_URL;
