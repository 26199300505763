import api from "../../../Services/api";

export function trazFotoUser (userId, projectId, cb) {

    api.get(`api/user/usuario/${userId}/foto/${projectId}`)
    .then(res => {
        let foto = res.data
        if (foto) cb(foto)
        else cb(null)
    })
    .catch(err => {
        console.error(err)
        cb(err)
    })
}