import axios from "../Services/api";
import api from "../Services/api";

const apiVersion = "v3";

export default {
    binary: {
        getNovoDuelo: async ({ queryParams }) => {
            const res = await axios.get(
                `/binary/get-new-clash?group=${
                queryParams.group
                }&idUser=${
                queryParams.idUser
                }&instrumentId=${
                queryParams.idInstrumento
                }&projectId=${
                    queryParams.projectId
                }&dynamicId=${
                    queryParams.dynamicId
                }&roundIndex=${
                    queryParams.roundIndex
                }`);
            return res.data;
        },
        postCriteria: async (criterio) => await axios.post("/binary/receive-criteria", criterio),
        postCriteriaV2: async (criteriaArray) => await axios.post("/binary/receive-criteria", { criteriaArray }, {
            params: {
                v2: true
            }
        }),
        updateCriteria: (criterio) => axios.put("/binary/patch-criteria", criterio),
        getCriteriaByInstrumentAndUser: (instrumentId, userId) =>
            axios.get(`/binary/get-criteria-by-instrument-and-user/${instrumentId}/${userId}`),
        getProgressoBinario: (userId) =>
            axios.get(`/case/get-progresso-by-user-id/${userId}`),
        saveUserResponse: async ({ reqBody, queryParams }) =>
            await axios.patch(
                `/binary/save-user-response?idUser=${
                queryParams.idUser
                }&idProjeto=${
                queryParams.idProjeto
                }&idDinamica=${
                queryParams.idDinamica
                }&idInstrumento=${
                queryParams.idInstrumento
                }`,
                reqBody
            ),
    },
    case: {
        finalizarCaseIndividual: async ({ queryParams }) => {
            const res = await axios.get(
                "/case/actions/finalizar-case-individual",
                {
                    params: queryParams
                }
            );
            return res.data;
        },
        finalizarCaseGrupo: async ({ queryParams }) => {
            await axios.get("/case/actions/finalizar-case-grupo", {
                params: queryParams
            });
        },
        finalizarBinario: async ({ queryParams }) => {
            return await api.get(
                "/case/actions/finalizar-binario",
                {
                    params: queryParams
                }
            );
        },

        calculaBinario: async (idInstrumento) => {
            return await api.get(
                "/binary/get-standardized-note-generation/?instrumentId="+idInstrumento,                
            );
        },


        getCriteriaByUserGroupAndInstrument: async (
            idInstrumento,
            group,
            idDinamica,
            idProjeto,
            grupoIndex,
        ) => {
            const res = await axios.get(
                `/case/get-criteria-by-instrument-and-group`,
                {
                    params: {
                        idDinamica,
                        idProjeto,
                        grupoIndex,
                        idInstrumento,
                        group
                    }
                })
            return res;
        },
        getEscolhasUser: async (
            { queryParams }
        ) => {
            const res = await axios.get("/case/get-progresso-by-user-id", {
                params: queryParams
            });
            return res.data;
        },
        getEscolhasGrupo: async (
            idInstrumento,
            { queryParams },
            idProjeto
        ) => {
            const res = await axios.get(`/case/get-case-grupo-progresso/${
                idInstrumento
            }`, {
                params: queryParams
            });
            return res.data;
        },
        saveEscolhasUser: async (
            { queryParams, data }
        ) => {
            return await axios.post("/case/salvar-escolhas-user", data, {
                params: queryParams
            });
        },
        saveEscolhasGrupo: async (
            { queryParams, data }
        ) => {
            return await axios.post("/case/salvar-escolhas-grupo", data, {
                params: queryParams
            });
        },
        saveOpcaoUser: async (
            { queryParams, data }
        ) => {
            await axios.post("/case/salvar-opcoes-user", data, {
                params: queryParams
            });
        },


        saveOpcaoGrupo: async (
            { queryParams, data }
        ) => {
            await axios.post("/case/salvar-opcoes-grupo", data, {
                params: queryParams
            });
        },
        updateEscolhasGrupo: async (
            { queryParams }
        ) => {
            // TODO
        },

        async getCaseTemplates() {
            return api
              .get(`/${apiVersion}/case-templates`);
          },
          
        async getCaseConfig(idCaseConfig) {
            return api
                .get(`/${apiVersion}/case-config/${idCaseConfig}`);
        },
    },

    dinamica: {
        getRankingDinamica: async (params) => {
            return await api.get(
                `/${apiVersion}/projetos/${params.idProjeto}/dinamicas/${params.idDinamica}/results/ranking-dinamica`
            );
        },

        getPesosRodada: async (params, queryParams) => {
            return await api.get(
                `/${apiVersion}/projetos/${params.idProjeto}/dinamicas/${params.idDinamica}/results/pesos-rodada`,
                {
                    params: queryParams
                }
            );
        },

        async saveInstrumentAnswer(instrumentName, instrumentId, moduleName, moduleId, roundIndex, projectId) {
            return await api
                .put(`api/user/respostas/finalizar`, {
                    instrumento: instrumentName,
                    instrumentId,
                    atividade: moduleName,
                    atividadeId: moduleId,
                    rodada: roundIndex,
                    projectId
                });
        }
    },
    projects: {
      getProvasText: async (projectId, modulo, atividadeId) => 
        api.get(
          `api/projects/${projectId}/modulos/${modulo}/${atividadeId}/textos`
        )
    },

    provas: {

        calcProofResults({ provaId, idProjeto }) {
            return api.post(`/${apiVersion}/provas/${provaId}/results`, {
                idProjeto
            });
        },

        getProvas(query) {
            return api.get(`${apiVersion}/provas`, {
                params: {
                    provasIds: query.provasIds
                }
            });
        }
    },

    templateProva: {
        getTemplateById(templateId) {
            return api.get('/api/provas/templates/' + templateId)
        }
    }
};
