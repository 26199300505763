import React, { useState } from 'react';
import styled from 'styled-components';

import CookieConsentModal from '../CookieConsentModal';

const StyledSpan = styled.span`
  cursor: pointer;
  text-decoration: underline;
  font-size: 15px;
`

function Politics(props) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    
    function toggleModal() {
        setIsModalOpen(!isModalOpen);
    }

    return (
        <>
            <CookieConsentModal isOpen={isModalOpen} toggle={toggleModal} />
            <StyledSpan onClick={toggleModal}>Política de Privacidade</StyledSpan>
        </>
    );
}

export default Politics;