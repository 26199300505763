export default function capitalizeFirstLetterEachWord(phrase='') {
  let splited = phrase.trim().split(' ');

  const mask = /\b(das|dos|de|da|do|d)\b/i;

  let text = '';

  splited.map(s => {
    if (!mask.test(s)) {
      const upper = s.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
      text += ` ${upper}`;
    } else {
      text += ` ${s}`;
    }
  })

  return text;
}