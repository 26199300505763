import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import api from "../../../Services/api";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Container
} from "reactstrap";
import { Constantes } from "../../../Services/Constantes";
import { reenviarResposta } from "../../../Services/GerenciarConexao";
import Timer from "../../../Services/Timer";
import { verificarRespostas } from "../Utils/verificarRespostas";
import Loading from "../../../Services/Loading";
import ErrorComponent from '../../../Services/ErrorComponent';

import capitalizeFirstLetterEachWord from '../../../utils/capitalizeFirstLetterEachWord';

import {
  Base,
  Inst,
  Instrucoes,
  Button,
  Contador,
  Icon,
  Story,
  Footer,
  Mesa
} from "../../Assets/Assets";
import styled from "styled-components";
import { withRouter } from 'react-router';

import {
  notifyError
} from "../../../Services/Notificacoes";
import { millisToMinutesAndSeconds } from "../Utils/Calculos";
import { srvTime } from "../Utils";

import apiCalls from "../../../config/apiCalls";

// Imagens
const spinner = Constantes.imagemSpinner;

const Item = styled.div`
  margin: 14px;

  padding: 13px;
`;

const P = styled.p`
  font-size: 13px;
`;

const grid = 1;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  padding: grid,
  width: "100%"
});

const droppableIdGrupo = "droppableGrupo";
const droppableIdIndividual = "droppableIndividual";

class StorytellingGrupo extends Component {
  resultadoListaIndividual = []
  resultadoListaGrupo = []
  state = {
    frasesIndividual: [],
    frasesGrupo: [],
    dados: {},
    respostas: [],
    somaGrupo: 0,
    somaIndividual: 0,
    enunciado: "",
    secretario: false,
    modalConfirmacao: false,
    modalInstrucoes: false,
    loading: true,
    tempos: {},
    pararTimer: false,
    loadingSpinner: false,
    modalMesa: false,
    loadingSpinner: false,
    intervalResetRequest: false,
    haveError: false,
    errorCount: 0,
    errorRetry: 10
  }

  // Variaveis
  usuarioAtualId
  secretarioNome = ""
  onLogout = false
  onProximo = false
  // Tempos
  tempoOnMount = srvTime()
  tempoTotal = 0

  componentDidMount = async () => {
    this.subscribePush()
    await this.recebendoInfo()

    api
    .get(
      `api/projects/rodadas/atualizar/${sessionStorage.getItem(
        "projeto"
      )}/${localStorage.getItem("dinamica")}/get-tempos`,
      {
        params: {
          userId: sessionStorage.getItem('user'),
          rodadaAtual: this.props.rodada,
          instrumento: Constantes.instrumentoStoryTellingIndividual,
        }
      }
    )
  }

  componentWillUnmount = () => {
    Constantes.io.off("Projeto:Stop")
    Constantes.io.off("Projeto:RespostaGrupo")
    Constantes.io.off("Projeto:NovosTempos")
    Constantes.io.off("Project:SyncConsultantTime")
    Constantes.io.off("Auth:LogoutInstrumento")
  }

  /**
   * @todo mover requisições para config/apiCalls
   */
  recebendoInfo = async () => {
    if (this.state.errorCount >= this.state.errorRetry) {
      return this.setState({ haveError: true, loading: false });
    }
    try {
      // Instruções de grupo
    await api
    .get(
      `api/projects/dinamicas/projeto/${sessionStorage.getItem("atividade")}/instrumento/${
        Constantes.instrumentoStoryTellingGrupo
      }`,
      {
        params: {
          roundIndex: this.props.rodada,
          instrumentId: this.props.idInstrumento
        }
      }
    )
    .then(async res => {

      const projetoDinamica = res.data
      const { template } = res.data;
      const dinamicaId = sessionStorage.getItem("atividade")
      // Verificando respostas prévias
      await verificarRespostas(
        sessionStorage.getItem("user"),
        Constantes.instrumentoStoryTellingIndividual2,
        Constantes.moduloDinamicas,
        dinamicaId,
        this.props.rodada, // rodada
        -1, // grupoIndex
        projetoDinamica,
        this.props.idInstrumento
      )
        .then(async res => {
          let respostaPreviaIndividual2 = res
          await verificarRespostas(
            sessionStorage.getItem("user"),
            Constantes.instrumentoStoryTellingIndividual,
            Constantes.moduloDinamicas,
            dinamicaId,
            this.props.rodada,
            this.props.grupo,
            projetoDinamica,
            this.props.idInstrumento
          )
            .then(async res => {
              let respostaPreviaGrupo = res;

              let dinamica = projetoDinamica.projeto.modulos.dinamicas[0]
              // let dinamica = projetoDinamica.data.modulos.dinamicas.find(
              // let dinamica = projetoDinamica.projeto.modulos.dinamicas.find(
              //   dinamica =>
              //     dinamica._id.toString() ===
              //     sessionStorage.getItem("atividade")
              // );
              let instrumento = dinamica.rodadas[this.props.rodada].instrumentos.find(
                intrumento => intrumento._id === this.props.idInstrumento
              )
              
              // Inicializando template
              const dados = template;
              const enunciado = dados.instrucoesGrupo

              // Inicializando tempos
              let tempos = instrumento.tempos && instrumento.tempos.grupo || {
                tempoFim: 0,
                tempoInicio: 0,
                tempoRestante: 0,
              };

              this.tempoTotal = (tempos.tempoFim - tempos.tempoInicio) || 0;
              tempos.tempoRestante = (tempos.tempoFim - this.tempoOnMount) || 0;
              if (tempos.tempoRestante < 0) tempos.tempoRestante = 0;

              // Inicializando e variaveis
              let frasesGrupo = []
              let frasesIndividual = []
              let iniciandoAtividade = false

              // Recuperando userId
              this.usuarioAtualId = sessionStorage.getItem("user")

              // Checa se secretario e define nome
              let secretario = false

              if (this.props.secretario === this.usuarioAtualId) {
                secretario = true
              }

              this.secretarioNome = this.props.geral.pessoas.find(
                p => p.id === this.props.secretario
              ).nome
              // ].pessoas.find(g => g.id === this.props.secretario).nome;

              // Checando respostas individuais
              let modalMesa = false
              if (respostaPreviaIndividual2) {
                if (respostaPreviaIndividual2.resposta.length) {
                  frasesIndividual = respostaPreviaIndividual2.resposta
                } else {
                  // Atualizando respostas e tempos
                  dados.sentencas.map((dado, indice) => {
                    frasesIndividual.push({
                      id: `${indice}`,
                      sequencia: dado.sequencia,
                      content:
                        String.fromCharCode(97 + indice).toUpperCase() + ". " + dado.enunciado
                    })
                  })
                }
              } else {
                var respostaPreviaIndividual = await verificarRespostas(
                  sessionStorage.getItem("user"),
                  Constantes.instrumentoStoryTellingIndividual,
                  Constantes.moduloDinamicas,
                  dinamicaId,
                  this.props.rodada,
                  -1,
                  projetoDinamica,
                  this.props.idInstrumento
                )

                // Inicializando respostas
                // Checando por não stop no storytelling individual
                if (respostaPreviaIndividual.resposta.length) {
                  frasesIndividual = respostaPreviaIndividual.resposta
                } else {
                  // Atualizando respostas e tempos
                  dados.sentencas.map((dado, indice) => {
                    frasesIndividual.push({
                      id: `${indice}`,
                      sequencia: dado.sequencia,
                      content:
                        String.fromCharCode(97 + indice).toUpperCase() + ". " + dado.enunciado
                    })
                  })
                }

                // Flagando incio de atividade
                iniciandoAtividade = true

                // Habilitando modals
                modalMesa = true
              }

              // Checando respostas em grupo
              if (respostaPreviaGrupo)
                // Atualizando respostas
                frasesGrupo = respostaPreviaGrupo.resposta
              else {
                // Atualizando respostas e tempos
                dados.sentencas.map((dado, indice) => {
                  frasesGrupo.push({
                    id: `${indice}`,
                    sequencia: dado.sequencia,
                    content:
                      String.fromCharCode(97 + indice).toUpperCase() + ". " + dado.enunciado
                  })
                })
              }

              // Inicializando variaveis de controle
              this.resultadoListaIndividual = frasesIndividual
              this.resultadoListaGrupo = frasesGrupo

              // Iniciando atividade
              if (iniciandoAtividade) this.gravaRespostas(respostaPreviaIndividual.pontuacao, 0, secretario)

              this.setState({
                dados,
                frasesGrupo,
                frasesIndividual,
                enunciado,
                secretario,
                loading: false,
                tempos,
                modalMesa,
                errorCount: 0
              })

              //this.subscribeToEvents();
            })
            // .catch(err => {
            //   if (err.response) console.error(err.response.data)
            //   // this.recebendoInfo();
            //   console.error(err)
            // })
        })
        // .catch(err => {
        //   if (err.response) console.error(err.response.data)
        //   // this.recebendoInfo();
        //   console.error(err)
        // })
    })
    // .catch(err => {
    //   if (err.response) console.error(err.response.data)
    //   // this.recebendoInfo();
    //   console.error(err)
    // })
    } catch (err) {
      console.error(err);
      this.setState({ errorCount: this.state.errorCount + 1 });
      setTimeout(() => this.recebendoInfo(), 1000);
    }
    
  }

  reorderIndividual = (list, startIndex, endIndex) => {
    this.resultadoListaIndividual = list
    const [removed] = this.resultadoListaIndividual.splice(startIndex, 1)
    this.resultadoListaIndividual.splice(endIndex, 0, removed)
    return this.resultadoListaIndividual
  }

  reorderGrupo = (list, startIndex, endIndex) => {
    this.resultadoListaGrupo = list
    const [removed] = this.resultadoListaGrupo.splice(startIndex, 1)
    this.resultadoListaGrupo.splice(endIndex, 0, removed)
    return this.resultadoListaGrupo
  }

  subscribePush = () => {
    if (this.props.secretario !== sessionStorage.getItem("user")) {
      Constantes.io.on("Projeto:RespostaGrupo", data => {
        if (data.grupoIndex === this.props.grupo)
          this.setState({
            frasesGrupo: data.resposta
          })
      })
    }
    Constantes.io.on("Projeto:Stop", data => {
      if (
        data.instrumento === Constantes.instrumentoStoryTellingIndividual &&
        data.start.grupo === 0 &&
        data.rodada === this.props.rodada
      ) {
        this.props.history.go(); // refresh the page
      }
    })
    Constantes.io.on("Projeto:NovosTempos", data => {
      if (data.instrumento === Constantes.instrumentoStoryTellingIndividual) {
        let tempos = data.tempos.grupo
        //tempos.tempoRestante = tempos.tempoFim - srvTime()
        this.tempoTotal = tempos.tempoFim - tempos.tempoInicio;
        this.setState({
          tempos,
          intervalResetRequest: true
        });
        this.setState({ tempos })
      }
    })
    Constantes.io.on("Project:SyncConsultantTime", data => {
      if (Object.keys(data.tempos).length && data.instrumento === Constantes.instrumentoStoryTellingIndividual && data.userId === sessionStorage.getItem('user')) {
        let tempos = data.tempos.grupo
        //tempos.tempoRestante = tempos.tempoFim - srvTime()
        this.tempoTotal = tempos.tempoFim - tempos.tempoInicio;
        this.setState({
          tempos,
          intervalResetRequest: true
        });
        this.setState({ tempos })
      }
    })
    Constantes.io.on("Auth:LogoutInstrumento", userId => {
      if (sessionStorage.getItem("user") === userId.toString()) {
        this.onLogout = true
        if (this.state.tempos.tempoRestante > 0) this.setState({ pararTimer: true })
        else this.fimAtividade()
      }
    })
  }

  openInst = () => {
    this.setState({ modalInstrucoes: true })
  }

  cancelarMesa = () => {
    this.setState({ modalMesa: false })
    this.setState({ modalInstrucoes: true })
  }

  cancelarInst = () => {
    this.setState({ modalInstrucoes: false })
  }

  enunciado = () => {
    const texto = this.state.enunciado
    var res = texto.split("~")
    if (this.state.secretario) {
      return (
        <div>
          Você foi nomeado para secretário do seu grupo!
          <br />
          <br />
          Aguarde a chegada de todos do Grupo e decidam qual a melhor ordem para essa históra. Após
          todos estarem de acordo, submeta novamente o storytelling.
          <br />
          <br />
          {res[0]}
          <br />
          <br />
          {res[1]}
          <br />
          <br />
          {res[2]}
          <br />
          <br />
        </div>
      )
    } else {
      return (
        <div>
          <br />
          <br />
          {res[0]}
          <br />
          <br />
          {res[1]}
          <br />
          <br />
          {res[2]}
          <br />
          <br />
        </div>
      )
    }
  }
  onDragEnd = async result => {
    if (!result.destination) {
      return
    }

    // Checando se resposta do grupo
    if (result.source.droppableId === droppableIdGrupo) {
      const frasesGrupo = this.reorderGrupo(
        this.state.frasesGrupo,
        result.source.index,
        result.destination.index
      )
      this.verificaResultado()
      this.setState({
        frasesGrupo
      })
    } else {
      const frasesIndividual = this.reorderIndividual(
        this.state.frasesIndividual,
        result.source.index,
        result.destination.index
      )
      this.verificaResultado()

      this.setState({
        frasesIndividual
      })
    }
  }

  verificaResultado = async () => {
    let somaIndividual = 0
    let somaGrupo = 0
    // Verificando por secretário
    if (this.state.secretario) {
      // Avaliando itens sem ser o último
      for (let i = 1; i < this.resultadoListaGrupo.length; i++) {
        if (
          parseInt(this.resultadoListaGrupo[i - 1].sequencia, 10) + 1 ===
          parseInt(this.resultadoListaGrupo[i].sequencia, 10)
        ) {
          somaGrupo++
        }
      }
      // Avaliando o primeiro
      if (parseInt(this.resultadoListaGrupo[0].sequencia, 10) === 1) somaGrupo++
    }

    // Calculando resultado individual

    // Avaliando itens sem ser o último
    for (let i = 1; i < this.resultadoListaIndividual.length; i++) {
      if (
        parseInt(this.resultadoListaIndividual[i - 1].sequencia, 10) + 1 ===
        parseInt(this.resultadoListaIndividual[i].sequencia, 10)
      ) {
        somaIndividual++
      }
    }
    // Avaliando o primeiro
    if (parseInt(this.resultadoListaIndividual[0].sequencia, 10) === 1) somaIndividual++

    // Gravando respostas
    this.gravaRespostas(somaIndividual, somaGrupo)
  }

  /**
   * Salva as respostas do secretário.
   */
  gravaRespostasSecretario = async resp => {
    // debugger
    let dinamicaId = sessionStorage.getItem("atividade")

    if (resp) {
      api
        .put(
          `api/projects/editRespostasSecretario/${sessionStorage.getItem("projeto")}/${dinamicaId}`,
          { ...resp }
        )
        .then(res => {
        })
        .catch(async err => {
          if (err) {
            console.error(err)
          }

          let info = {
            status: 4,
            ...resp,
            atividadeId: dinamicaId,
            projetoId: sessionStorage.getItem("projeto")
          }
          // reenviarResposta(info);
        })
    } else {
    }
  }

  gravaRespostas = async (somaIndividual, somaGrupo, secretario = false) => {
    // Atualizando tempos:
    let tempos = {
      tempoAtual: this.state.tempos.tempoRestante
    }
    const dinamicaId = sessionStorage.getItem("atividade")
    const atualizadoEm = srvTime()

    let respSecretario = {
      instrumentId: this.props.idInstrumento,
      instrumento: Constantes.instrumentoStoryTellingIndividual,
      pontuacao: somaGrupo,
      resposta: this.resultadoListaGrupo,
      grupoIndex: this.props.grupo,
      rodada: this.props.rodada
    }

    // salvando resposta do secretário, caso o participante seja o secretario.
    if (this.state.secretario || secretario) this.gravaRespostasSecretario(respSecretario)

    // salvando resposta individual.
    let resp = {
      instrumentId: this.props.idInstrumento,
      resposta: this.resultadoListaIndividual,
      instrumento: Constantes.instrumentoStoryTellingIndividual2,
      rodada: this.props.rodada,
      tempos,
      atualizadoEm,
      pontuacao: somaIndividual,
      finalizado: false
    }

    api
      .put(`api/user/respostas/modulo/${Constantes.moduloDinamicas}`, {
        resp,
        atividadeId: dinamicaId
      })
      .then(resPut => {
      })
      .catch(async err => {
        if (err) {
          console.error(err)
        }

        let info = {
          status: 1,
          resp,
          atividadeId: dinamicaId,
          modulo: Constantes.moduloDinamicas
        }
        reenviarResposta(info)
      })

    this.setState({ somaIndividual, somaGrupo })
  }

  proximaAtividade = async () => {
    if (this.state.errorCount >= this.state.errorRetry) {
      return this.setState({ haveError: true, loading: false, modalConfirmacao: false });
    }
    try {
      const dinamicaId = sessionStorage.getItem("atividade")
      await apiCalls.dinamica.saveInstrumentAnswer(
        Constantes.instrumentoStoryTellingIndividual2,
        this.props.idInstrumento,
        Constantes.moduloDinamicas,
        dinamicaId,
        this.props.rodada,
        sessionStorage.getItem("projeto")
      );
      this.props.action();
    } catch (err) {
      console.error(err);
      this.setState({ errorCount: this.state.errorCount + 1 });
      setTimeout(() => this.proximaAtividade(), 1000);
    }
  }

  tempoAtualizado = tempo => {
    this.setState(previousState => ({
      tempos: {
        ...previousState.tempos,
        tempoRestante: tempo
      }
    }))
  }

  toggleConfirmacao = () => {
    if (!this.state.loadingSpinner) {
      this.setState(previousState => ({
        modalConfirmacao: !previousState.modalConfirmacao
      }))
    }
  }

  fimAtividade = () => {
    if (this.onLogout) this.props.logout()
    else {
      if (!this.onProximo) {
        this.onProximo = true
        if (!this.state.loadingSpinner) this.setState({ loadingSpinner: true })
        this.proximaAtividade()
      }
    }
  }

  render() {
    if (this.state.haveError) return <ErrorComponent />;
    if (this.state.loading) {
      return <Loading />
    }
    return (
      <div className="text-left back" id="logo" tabIndex="0">
        <Modal
          isOpen={this.state.modalMesa}
          className={this.props.className}>
          <ModalBody>
            <Instrucoes>
              <Mesa mesa={this.props.geral.mesa} />
            </Instrucoes>
          </ModalBody>
          <ModalFooter>
            <Container className="text-center">
              <Button color="success" onClick={this.cancelarMesa}>
                Já estou na Mesa {this.props.geral.mesa}
              </Button>{" "}
            </Container>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modalInstrucoes}
          toggle={this.toggleInstrucoes}
          className={this.props.className}>
          <ModalBody>
            <Instrucoes>
              <div>
                <br />
                <b>INSTRUÇÕES</b>
                <br />
                <b>Enunciado</b>
                <P>Vocês terão um total de { `${millisToMinutesAndSeconds(this.tempoTotal)}` } minutos para analisar e decidir sobre a sequência que melhor define a história apresentada.
                Trabalhem em conjunto para entrar em consenso quanto a melhor organização possível. As alterações discutidas em grupo são registradas pelo participante definido como secretário da mesa na coluna ao lado esquerdo da tela. Esta coluna apenas o secretário consegue alterar, contudo, todos os participantes acompanharão as atualizações feitas em tempo real nas suas próprias telas. 
                Na coluna à direita da tela, aparece a história na sequência definida por você na etapa individual. A organização dessa coluna só você vê. Ficará a seu critério alterar sua sequência individual da maneira que considerar mais adequada: (a) igual àquela que vocês definiram em equipe; (b) a mesma de que você definiu originalmente; ou, ainda, (c) outra ordenação que preferir.
                </P>

                <b>Como enviar a história</b><br />
                <strong>Entrega do grupo</strong><br />
                <P>O secretário será o responsável por registrar e enviar tanto a atividade do grupo quanto sua própria atividade. Ao longo da discussão, o secretário pode alterar ambas as colunas – da esquerda (em consenso com o grupo) e da direita (escolhas individuais) (observar a manutenção da denominação) 
                Concluída a discussão em grupo, é importante que, ANTES DE ENVIAR, (i) o secretário consulte todo o grupo assegurando que a discussão se esgotou e o consenso foi atingido; e (ii) confira também se sua coluna individual contempla suas escolhas finais. 
                Ao enviar a atividade, não poderão ser feitas novas alterações.</P>
                <strong>Entrega individual</strong><br />
                <P>Ao longo da discussão do grupo, você pode também fazer as alterações que considerar pertinentes na coluna à direita. Quando tiver concluído a sequência da história e a discussão na equipe, você deverá clicar em “enviar” e confirmar o envio da atividade. 
                Qualquer participante que não seja o secretário, poderá enviar sua atividade a qualquer momento. Contudo, caso seja enviada antes do secretário finalizar o consenso da equipe, o participante não poderá mais visualizar as alterações feitas na coluna da equipe (esquerda)
                Após enviar a atividade você não poderá voltar para ajustar, nem visualizar alterações da equipe.</P>
                <b>Como é definido meu resultado nesta etapa</b>
                <P>Seu resultado é avaliado pela coerência da sequência final proposta tanto pela equipe quanto individualmente. Portanto, assegure que a entrega de ambas as histórias finais – da equipe e a individual – represente uma sequência que você acredita ser a ideal, não necessariamente iguais.</P>
              </div>
            </Instrucoes>
          </ModalBody>
          <ModalFooter>
            <Container className="text-center">
              <Button color="success" onClick={this.cancelarInst}>
                ok
              </Button>{" "}
            </Container>
          </ModalFooter>
        </Modal>
        <Base>
          <Container>
            <br />
            <br />
            <br />
            <Row>
              <Col>
                <h1>Storytelling</h1>
              </Col>
              <Col className="text-right">
                <Icon src={require("../../Assets/_assets/doubt.png")} alt="Instrucoes" />
                <Inst onClick={this.openInst}>Instruções</Inst>
              </Col>
            </Row>
            <br />
            <br />
            <Modal
              isOpen={this.state.modalConfirmacao}
              toggle={this.toggleConfirmacao}
              className={this.props.className}
              onClosed={() => (this.onProximo = false)}>
              <ModalHeader toggle={this.toggleConfirmacao}>Storytelling</ModalHeader>
              <ModalBody>
                Se você estiver satisfeito com suas escolhas clique em prosseguir, se não clique em
                cancelar
              </ModalBody>
              <ModalFooter>
                {!this.state.loadingSpinner ? (
                  <div>
                    <Button
                      color="success"
                      onClick={() => {
                        this.state.tempos.tempoRestante > 0
                          ? this.setState({
                              loadingSpinner: true,
                              pararTimer: true
                            })
                          : this.fimAtividade()
                      }}
                      disabled={this.state.loadingSpinner}>
                      Prosseguir
                    </Button>{" "}
                    <Button
                      color="danger"
                      onClick={this.toggleConfirmacao}
                      disabled={this.state.loadingSpinner}>
                      Cancelar
                    </Button>
                  </div>
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>Enviando. Por favor não recarregue a tela. <img style={{ width: 20 }} src={require(`../../Assets/_assets/${spinner}`)} /></span>
                    {
                      this.state.errorCount > 0 ?
                        <><span style={{ fontSize: 12 }}>Tentativas {this.state.errorCount} / {this.state.errorRetry}.</span></>
                      : null
                    }
                  </div>
                )}
              </ModalFooter>
            </Modal>

            <Row>
              <Col sm="12">
                <Contador
                  counter={
                    <Timer
                      intervalResetRequest={this.state.intervalResetRequest}
                      intervalResetFeedback={() => this.setState({ intervalResetRequest: false })}
                      crescente={false}
                      tempo={this.state.tempos.tempoRestante}
                      resetAutomatico={false}
                      tempoAtualizado={this.tempoAtualizado}
                      pararTimer={this.state.pararTimer}
                      resetar={false}
                      tempoParado={this.fimAtividade}
                      id="tempo"
                    />
                  }
                  tempo={100 * (this.state.tempos.tempoRestante / this.tempoTotal)}
                />
              </Col>
            </Row>
            <br />
            <br />
            <p>Reorganizar ou manter após discussão do Grupo</p>
            <br />
            <br />
            <h2>Mesa: {this.props.geral.mesa}</h2>
            <br />
            <h2>Secretário: {this.state.secretario ? "Você" : capitalizeFirstLetterEachWord(this.secretarioNome.toLowerCase())} </h2>
            <Row>
              <Col sm="6">
                {/* Secretário */}
                {this.state.secretario && (
                  <div>
                    <h3>
                      <b>Grupo</b>
                    </h3>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <Droppable droppableId={droppableIdGrupo}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            style={(getListStyle(snapshot.isDraggingOver), { scroll: "auto" })}>
                            {this.state.frasesGrupo.map((item, index) => (
                              <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}>
                                    <Story story={item.content} />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                      <br />
                    </DragDropContext>
                  </div>
                )}

                {/* Participante */}
                {!this.state.secretario && (
                  <div>
                    <h3>
                      <b>Grupo</b>
                    </h3>
                    {this.state.frasesGrupo.map((item, index) => (
                      <div key={index}>
                        <Story tipo="null" story={item.content} />
                      </div>
                    ))}
                  </div>
                )}
              </Col>
              <Col sm="6">
                <h3>
                  <b>Individual</b>
                </h3>
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId={droppableIdIndividual}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                        {this.state.frasesIndividual.map((item, index) => (
                          <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}>
                                <Story story={item.content} />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  <br />
                </DragDropContext>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col className="text-center">
                <Button onClick={this.toggleConfirmacao}>ENVIAR</Button>
                <Footer />
              </Col>
            </Row>
          </Container>
        </Base>
      </div>
    )
  }
}
export default withRouter(StorytellingGrupo);
