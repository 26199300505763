import React from 'react';
import { Button } from "../Pages/Assets/Assets";

function ErrorComponent() {
    return(
        <div style={{ margin: 'auto' }}>
            <h2>Não foi possível carregar a atividade</h2><br />
            <Button
            color="success"
            onClick={() => {
                window.location.reload();
            }}
            >
                Recarregar
            </Button>
        </div>
    )
}

export default ErrorComponent;