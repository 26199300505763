import { Constantes } from "../../../Services/Constantes";
import apiCalls from "../../../config/apiCalls";

export function quantidadeEscolhas(numeroPessoas) {
  let liderEscolha, timeEscolha, naoEscolha;
  if (numeroPessoas <= 5) {
    liderEscolha = 1;
    timeEscolha = 1;
    naoEscolha = 1;
  } else if (numeroPessoas == 6) {
    liderEscolha = 1;
    timeEscolha = 2;
    naoEscolha = 1;
  } else if (numeroPessoas == 7) {
    liderEscolha = 1;
    timeEscolha = 2;
    naoEscolha = 2;
  } else if (numeroPessoas == 8) {
    liderEscolha = 2;
    timeEscolha = 2;
    naoEscolha = 2;
  } else if (numeroPessoas == 9) {
    liderEscolha = 2;
    timeEscolha = 3;
    naoEscolha = 2;
  } else if (numeroPessoas == 10 || numeroPessoas == 11) {
    liderEscolha = 2;
    timeEscolha = 3;
    naoEscolha = 3;
  } else if (numeroPessoas >= 12) {
    liderEscolha = 2;
    timeEscolha = 4;
    naoEscolha = 3;
  }

  return [liderEscolha, timeEscolha, naoEscolha];
}

/**
 * index -> a etapa atual
 * escolhasQuantidade -> tipo: []
 */
export function definirEnunciados(index, escolhasQuantidade) {
  let enunciado = "";

  if (index === 0) {
    enunciado =
      "Escolha " +
      escolhasQuantidade[0] +
      (escolhasQuantidade[0] === 1 ? " participante " : " participantes ") +
      "que considera apto(s) a liderar a sua equipe";
  }
  if (index === 1) {
    enunciado =
      "Indique " +
      escolhasQuantidade[1] +
      (escolhasQuantidade[1] === 1 ? " participante " : " participantes ") +
      "com quem gostaria de ter em sua equipe de trabalho";
  }

  if (index === 2) {
    enunciado =
      "Indique " +
      escolhasQuantidade[2] +
      (escolhasQuantidade[2] === 1 ? " participante " : " participantes ") +
      "que não gostaria ou consideraria de ter em sua nova equipe de trabalho";
  }

  return enunciado;
}

export function converterMsParaTempo(tempo) {
  // Pad to 2 or 3 digits, default is 2
  function pad(n, z) {
    z = z || 2;
    return ("00" + n).slice(-z);
  }

  var ms = tempo % 1000;
  tempo = (tempo - ms) / 1000;
  var secs = tempo % 60;
  tempo = (tempo - secs) / 60;

  var mins = tempo % 60;
  var hrs = (tempo - mins) / 60;

  return pad(mins) + ":" + pad(secs);
}

export function millisToMinutesAndSeconds(millis) {
  let minutes = Math.floor(millis / 60000);
  let seconds = ((millis % 60000) / 1000).toFixed(0);
  return (minutes < 10 ? '0' : '') + minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
}
