import React, { Component } from "react";
import Joyride from 'react-joyride';
import JoyrideContext from "../../../contexts/joyride";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Container,
  Row,
  Col
} from "reactstrap";
import { Constantes } from "../../../Services/Constantes";
import ErrorComponent from '../../../Services/ErrorComponent';
import Timer from "../../../Services/Timer";
import Loading from "../../../Services/Loading";
import {
  Base,
  Inst,
  Instrucoes2,
  Button,
  Icon,
  Contador,
  Story,
  Footer
} from "../../Assets/Assets";
import { withRouter } from 'react-router';

import styled from "styled-components";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAlt } from '@fortawesome/free-solid-svg-icons'
import { millisToMinutesAndSeconds } from "../Utils/Calculos";
import { srvTime } from '../Utils';
import { TourP } from "../../../utils/TourUtils";

const P = styled.p`
  font-size: 13px;
`;

// Imagens
const spinner = Constantes.imagemSpinner;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  padding: grid,
  width: "100%"
});

const grid = 1;

class Storytelling extends Component {
  static contextType = JoyrideContext;

  state = {
    frases: [
      {content:"A. O bosque é perigoso, por isso evitamos ir pelo caminho mais curto, disse Maria.",
      id:"0",
      sequencia:"10",},

      {content:"B. Caminhos mais fáceis não são os mais seguros, evite a trilha! Lembre-se do que lhe disse!",
      id:"1",
      sequencia:"6",},

      {content:"C. Chapeuzinho sempre ouvia sua mãe, mas hoje estava atrasada.",
      id:"2",
      sequencia:"14",},

      {content:"D. Esse caminho é mais rápido, preciso chegar rápido, para voltar antes do anoitecer.   ",
      id:"3",
      sequencia:"1",},

      {content:"E. Esse caminho é diferente, muitas árvores, flores e frutos diferentes.",
      id:"4",
      sequencia:"13",},

      {content:"F. Acho que não é tão inseguro, e economiza muito tempo vindo pela trilha.",
      id:"5",
      sequencia:"5",},

      {content:"G. Olha a casa da vovó. A chaminé já anuncia um belo bolo no forno.     ",
      id:"6",
      sequencia:"4",},

      {content:"H. Toc. Toc. Vovó, Chapeuzinho chegou!",
      id:"7",
      sequencia:"2",},

      {content:"I. Que saudades, parece que não me visita a anos! Um sorriso carinhoso irradiou suas feições.   ",
      id:"8",
      sequencia:"15",},

      {content:"J. Meus ouvidos escutam você chegando ainda ao longe.",
      id:"9",
      sequencia:"7",},

      {content:"K. Por onde você veio! Não a vi pela estrada!",
      id:"10",
      sequencia:"12",},

      {content:"L. Vovó, você está tão diferente!",
      id:"11",
      sequencia:"8",},

      {content:"M. Meus olhos crescem ao vê-la.",
      id:"12",
      sequencia:"3",},

      {content:"N. Minha boca se abre de alegria.",
      id:"13",
      sequencia:"9",},

      {content:"O. Chapeuzinho corre sempre que percebe o perigo.",
      id:"14",
      sequencia:"11",},
    ],
    dados: {
      peso: "50",
      "instrucoesGrupo": "Vocês terão 40 minutos para decidir-se sobre a sequência que melhor define a História apresentada.~Ao final, na coluna esquerda, Minhas respostas, você deverá indicar qual a sequência que você considera adequada, podendo ser aquela que a equipe definiu, a mesma de que definiu originalmente ou uma terceira~Seu resultado é avaliado pela coerência e lógica da sequência final individual e/ou da Equipe.",
      "instrucoesIndividual": "Você terá 15 minutos para analisar e ordenar os fragmentos da História indicando as possíveis sequências da mesma e, a seguir, preparar-se para discutir com a Equipe que lhe será indicada. Para tanto, reflita e defina o que considerou como base para a sequência que propôs.~Registre sua opção de sequência e aguarde a instrução quanto a Equipe que lhe será indicada. Seu resultado dar-se-á pela coerência da sequência proposta.",
      "_id": "5f2c1156d638d16e5ea23d3d",
      "nome": "Storytelling-Teste",
      "sentencas": [
        {enunciado:"O bosque é perigoso, por isso evitamos ir pelo caminho mais curto, disse Maria.",
      sequencia:"10",},

      {enunciado:"Caminhos mais fáceis não são os mais seguros, evite a trilha! Lembre-se do que lhe disse!",
      sequencia:"6",},

      {enunciado:"Chapeuzinho sempre ouvia sua mãe, mas hoje estava atrasada.",
      sequencia:"14",},

      {enunciado:"Esse caminho é mais rápido, preciso chegar rápido, para voltar antes do anoitecer.   ",
      sequencia:"1",},

      {enunciado:"Esse caminho é diferente, muitas árvores, flores e frutos diferentes.",
      sequencia:"13",},

      {enunciado:"Acho que não é tão inseguro, e economiza muito tempo vindo pela trilha.",
      sequencia:"5",},

      {enunciado:"Olha a casa da vovó. A chaminé já anuncia um belo bolo no forno.     ",
      sequencia:"4",},

      {enunciado:"Toc. Toc. Vovó, Chapeuzinho chegou!",
      sequencia:"2",},

      {enunciado:"Que saudades, parece que não me visita a anos! Um sorriso carinhoso irradiou suas feições.   ",
      sequencia:"15",},

      {enunciado:"Meus ouvidos escutam você chegando ainda ao longe.",
      sequencia:"7",},

      {enunciado:"Por onde você veio! Não a vi pela estrada!",
      sequencia:"12",},

      {enunciado:"Vovó, você está tão diferente!",
      sequencia:"8",},

      {enunciado:"Meus olhos crescem ao vê-la.",
      sequencia:"3",},

      {enunciado:"Minha boca se abre de alegria.",
      sequencia:"9",},

      {enunciado:"Chapeuzinho corre sempre que percebe o perigo.",
      sequencia:"11",},
      ],
      "created_at": "2022-04-27T20:24:35.803Z",
      "updated_at": "2022-04-27T20:24:35.803Z"
    },
    respostas: [],
    soma: 0,
    enunciado: "Você terá 15 minutos para analisar e ordenar os fragmentos da História indicando as possíveis sequências da mesma e, a seguir, preparar-se para discutir com a Equipe que lhe será indicada. Para tanto, reflita e defina o que considerou como base para a sequência que propôs.~Registre sua opção de sequência e aguarde a instrução quanto a Equipe que lhe será indicada. Seu resultado dar-se-á pela coerência da sequência proposta.",
    "modalConfirmacao": false,
    "modalInstrucoes": true,
    "loading": false,
    "tempos": {
      "tempoInicio": 1651091075000,
      "tempoFim": 1651091975000,
      "tempoRestante": 884000,
      "finalizado": false
    },
    "pararTimer": false,
    "loadingSpinner": false,
    "intervalResetRequest": false,
    "haveError": false,
    "errorCount": 0,
    "errorRetry": 10,
    steps: [],
  }

  resultadoLista = this.state.frases; // armazena as respostas do usuário, em forma de frases ordenadas.
  onLogout = false;
  onProximo = false;
  // Tempos
  tempoOnMount = srvTime();
  tempoTotal = 0;

  componentDidMount() {
    const { setRunTour, setHaveTour } = this.context;

      const steps= [
        {
          target: '.storytelling-step-1',
          content: <TourP>Aqui você acompanha o tempo restante para a conclusão desta atividade</TourP>,
          disableBeacon: true
        },
        {
          target: '.storytelling-step-2',
          content: <TourP>Nesta área você deve clicar e arrastar os cartões para cima e/ou para baixo e depois soltar na área
          desejada, de forma a ordenar a história</TourP>,
          placement: 'auto',
          disableBeacon: true
        },
        {
          target: '.storytelling-step-3',
          content: <TourP>Quando finalizar a ordenação, clique neste botão para enviar sua atividade. Ao clicar neste botão,
          uma janela irá se abrir. Caso não tenha dúvidas quanto as suas escolhas, confirme o envio clicando
          no botão ‘Prosseguir’</TourP>,
          disableBeacon: true
        },
      ];

      setHaveTour(true);
      setRunTour(false);

      this.setState({ steps });
  }

  recebendoInfo = async () => {
    if (this.state.errorCount >= this.state.errorRetry) {
      return this.setState({ haveError: true, loading: false });
    }
    try {
      // Recuperando o template
    const dinamicaId = sessionStorage.getItem("atividade");

    // await api
    //   .get(`api/projects/dinamicas/projeto/${sessionStorage.getItem("atividade")}/instrumento/${Constantes.instrumentoStoryTellingIndividual}`, {
    //     params: {
    //       roundIndex: this.props.rodada,
    //       instrumentId: this.props.idInstrumento
    //     }
    //   })
    //   .then(async res => {
    //     const projetoDinamica = res.data;
    //     const { template } = res.data;
    //     // const response = res;
    //     // Verificando respostas prévias
    //     await verificarRespostas(
    //       sessionStorage.getItem("user"),
    //       Constantes.instrumentoStoryTellingIndividual,
    //       Constantes.moduloDinamicas,
    //       dinamicaId,
    //       this.props.rodada,
    //       -1,
    //       projetoDinamica,
    //       this.props.idInstrumento
    //     )
    //       .then(async res => {
    //         let respostaPrevia = res;
    //         let dinamica = projetoDinamica.projeto.modulos.dinamicas[0]
    //         // let dinamica = projetoDinamica.projeto.modulos.dinamicas.find(
    //         //   dinamica =>
    //         //     dinamica._id.toString() === sessionStorage.getItem("atividade")
    //         // );

    //         // BUSCANDO INFO DO INSTRUMENTO

    //         let instrumento = dinamica.rodadas[
    //           this.props.rodada
    //         ].instrumentos.find(
    //           intrumento =>
    //             intrumento._id ===
    //             this.props.idInstrumento
    //         );

    //         // Inicializando template
    //         const dados = template;
    //         const enunciado = dados.instrucoesIndividual;
    //         let frases = [];

    //         // Inicializando tempos
    //         let tempos = instrumento.tempos && instrumento.tempos.individual || {
    //           tempoFim: 0,
    //           tempoInicio: 0,
    //           tempoRestante: 0,
    //         };

    //         this.tempoTotal = (tempos.tempoFim - tempos.tempoInicio) || 0;
    //         tempos.tempoRestante = (tempos.tempoFim - this.tempoOnMount) || 0;
    //         if (tempos.tempoRestante < 0) tempos.tempoRestante = 0;

    //         if (respostaPrevia) {
    //           // Atualizando respostas e tempos
    //           var modalInstrucoes = false;
    //           frases = respostaPrevia.resposta;
    //         } else {
    //           // não foi encontrada resposta prévia.

    //           var modalInstrucoes = true;

    //           // inicia as frases
    //           dados.sentencas.map((dado, indice) => {
    //             frases.push({
    //               id: `${indice}`,
    //               sequencia: dado.sequencia,
    //               content:
    //                 String.fromCharCode(97 + indice).toUpperCase() +
    //                 ". " +
    //                 dado.enunciado
    //             });
    //           });

    //           // Iniciando atividade
    //           this.resultadoLista = frases;
    //           this.gravaRespostas(0); // grava a resposta inicial do usuário (ainda inalterada), com soma 0.
    //         }

    //         this.setState({
    //           dados,
    //           frases,
    //           enunciado,
    //           loading: false,
    //           tempos,
    //           modalInstrucoes,
    //           errorCount: 0
    //         });
    //       })
    //       // .catch(err => {
    //       //   if (err.response) console.error(err.response.data);
    //       //   console.error(err)
    //       // });
    //   })
      // .catch(err => {
      //   if (err.response) console.error(err.response.data);
      //   console.error(err)
      // });
    } catch (err) {
      console.error(err);
      this.setState({ errorCount: this.state.errorCount + 1 });
      setTimeout(() => this.recebendoInfo(), 1000);
    }
    
  };

  reorder = (list, startIndex, endIndex) => {
    this.resultadoLista = list;
    const [removed] = this.resultadoLista.splice(startIndex, 1);
    this.resultadoLista.splice(endIndex, 0, removed);
    return this.resultadoLista;
  };

  subscribePush = () => {
    Constantes.io.on("Projeto:Stop", data => {
      if (
        data.instrumento === Constantes.instrumentoStoryTellingIndividual &&
        data.start.individual === 0 &&
        data.rodada === this.props.rodada
      ) {
        this.props.history.go()
      }
    });
    Constantes.io.on("Projeto:NovosTempos", data => {
      if (
        data.instrumento === Constantes.instrumentoStoryTellingIndividual
      ) {
        let tempos = data.tempos.individual;
        //tempos.tempoRestante = tempos.tempoFim - srvTime();
        this.tempoTotal = tempos.tempoFim - tempos.tempoInicio;
        this.setState({
          tempos,
          intervalResetRequest: true
        });
    }

    });
    Constantes.io.on("Auth:LogoutInstrumento", userId => {
      if (sessionStorage.getItem("user") === userId.toString()) {
        this.onLogout = true;
        if (this.state.tempos.tempoRestante > 0)
          this.setState({ pararTimer: true });
        else this.fimAtividade();
      }
    });
  };

  openInst = () => {
    this.setState({ modalInstrucoes: true });
  };

  cancelarInst = () => {
    this.setState({ modalInstrucoes: false });
    if (!this.state.alreadyOpen) this.context.setRunTour(true);
    this.setState({ alreadyOpen: true })
  };

  enunciado = () => {
    const texto = this.state.enunciado;
    var res = texto.split("~");

    return (
      <div>
        <br />
        <br />
        {res[0]}
        <br />
        <br />
        {res[1]}
        <br />
        <br />
        {res[2]}
        <br />
        <br />
      </div>
    );
  };
  onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const frases = this.reorder(
      this.state.frases,
      result.source.index,
      result.destination.index
    );

    this.verificaResultado();

    this.setState({
      frases
    });
  };

  verificaResultado = async () => {
    let soma = 0;
    // Avaliando o primeiro
    if (parseInt(this.resultadoLista[0].sequencia, 10) === 1) soma++;

    // Avaliando itens sem ser o último
    for (let i = 1; i < this.resultadoLista.length; i++) {
      if (
        parseInt(this.resultadoLista[i - 1].sequencia, 10) + 1 ===
        parseInt(this.resultadoLista[i].sequencia, 10)
      ) {
        soma++;
      }
    }

    // Gravando respostas
    this.gravaRespostas(soma);
  };

  gravaRespostas = async soma => {
    const dinamicaId = sessionStorage.getItem("atividade");
    const atualizadoEm = srvTime();

    // Atualizando tempos:
    let tempos = {
      tempoAtual: this.state.tempos.tempoRestante
    };
    
    let resp = {
      instrumentId: this.props.idInstrumento,
      resposta: this.resultadoLista,
      instrumento: Constantes.instrumentoStoryTellingIndividual,
      rodada: this.props.rodada,
      tempos,
      atualizadoEm,
      pontuacao: soma
    };
    
    // await api
    //   .put(`api/user/respostas/modulo/${Constantes.moduloDinamicas}`, {
    //     resp,
    //     atividadeId: dinamicaId,
    //   })
    //   .then(resPut => {
    //     // atualizado
    //   })
    //   .catch(async err => {
    //     console.error(err);
    //     let info = {
    //       status: 1,
    //       resp,
    //       atividadeId: dinamicaId,
    //       modulo: Constantes.moduloDinamicas
    //     };
    //   });

    this.setState({ soma });
  };

  proximaAtividade = async () => {
    if (this.state.errorCount >= this.state.errorRetry) {
      return this.setState({ haveError: true, loading: false, modalConfirmacao: false });
    }
    try {
      this.setState({ loadingSpinner: true });
      await this.sleep(1500);
      const dinamicaId = sessionStorage.getItem("atividade");
      // await apiCalls.dinamica.saveInstrumentAnswer(
      //   Constantes.instrumentoStoryTellingIndividual,
      //   this.props.idInstrumento,
      //   Constantes.moduloDinamicas,
      //   dinamicaId,
      //   this.props.rodada,
      //   sessionStorage.getItem("projeto")
      // );
      this.props.action();
    } catch (err) {
      console.error(err);
      this.setState({ errorCount: this.state.errorCount + 1 });
      setTimeout(() => this.proximaAtividade(), 1000);
    }
  };

  toggleConfirmacao = () => {
    this.context.setRunTour(false);
    if (!this.state.loadingSpinner) {
      this.setState(previousState => ({
        modalConfirmacao: !previousState.modalConfirmacao
      }));
    }
  };

  toggleInstrucoes = () => {
    if (this.state.modalInstrucoes === true) this.context.setRunTour(true);
    this.setState(previousState => ({
      modalInstrucoes: !previousState.modalInstrucoes
    }));
  };

  tempoAtualizado = tempo => {
    this.setState(previousState => ({
      tempos: {
        ...previousState.tempos,
        tempoRestante: tempo
      }
    }));
  };

  fimAtividade = () => {
    if (this.onLogout) this.props.logout();
    else {
      if (!this.onProximo) {
        this.onProximo = true;
        if (!this.state.loadingSpinner) this.setState({ loadingSpinner: true });
        this.proximaAtividade();
      }
    }
  };

  sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  callback = (data) => {
    if (data.status === 'finished' || data.action === 'close') {
        // This explicitly stops the tour (otherwise it displays a "beacon" to resume the tour)
        this.context.setRunTour(false);
    }
  }

  render() {
    if (this.state.haveError) return <ErrorComponent />;
    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <div className="text-left back" id="logo" tabIndex="0">
        <Joyride
          steps={this.state.steps}
          showProgress={true}
          run={this.context.runTour}
          continuous={true}
          callback={this.callback}
          disableOverlayClose={true}
          spotlightClicks={true}
          disableBeacon={true}
          locale={{
            back: 'Voltar',
            close: 'Fechar',
            last: 'Fim',
            next: 'Próximo',
            skip: 'Pular'
          }}
          styles={{
            options: {
              primaryColor: '#012a4a'
            },
            tooltipContainer: {
              textAlign: 'left',
            },
          }}
        />
        <Modal
          isOpen={this.state.modalInstrucoes}
          className={this.props.className}
        >
          <ModalBody>
            <Instrucoes2>
              <div>
                <P>Car@ participante, a seguir algumas instruções para que você possa realizar a entrega da atividade storytelling individual. Lembrando que essas atividades são ilustrativas e não terão seus resultados considerados ou salvos no IAPP.</P>
                <P>As instruções a seguir são relacionadas exclusivamente ao teste atual. Na aplicação oficial, as instruções poderão ser diferentes.</P>
                <P><strong>Enunciado</strong></P>
                <P>Nessa atividade, será apresentado a você trechos que compõem uma história. Contudo, você perceberá que os trechos se apresentam fora de ordem. Você terá um total de 15:00 minutos para analisar e ordenar os fragmentos da história indicando as possíveis sequências da mesma. Após os 15:00 minutos, aparecerá para você a indicação do grupo com o qual irá discutir sobre a organização ideal da história. Para tanto, é importante que você tenha clareza e saiba explicar aos demais qual foi a lógica que considerou para a sequência que propôs.</P>
                <P><strong>Como ordenar a hist&oacute;ria</strong></P>
                <P>Se voc&ecirc; est&aacute; utilizando um mouse f&iacute;sico, leve o mouse at&eacute; o card (fragmentos de frase) que quer movimentar, clique e segure sobre o s&iacute;mbolo <FontAwesomeIcon icon={faArrowsAlt} />. Mantendo este comando, arraste o card at&eacute; a posi&ccedil;&atilde;o escolhida e solte o bot&atilde;o do mouse.</P>
                <P>Se voc&ecirc; estiver utilizando um dispositivo touch, encoste o dedo na tela do dispositivo sobre o card (fragmentos de frase) que quer movimentar, arraste para a posi&ccedil;&atilde;o escolhida e e tire o dedo da tela.</P>
                <P>Ao concluir a ordena&ccedil;&atilde;o dos trechos, clique em <strong>ENVIAR</strong> e aguarde as instru&ccedil;&otilde;es da pr&oacute;xima etapa desta atividade.</P>
                <P><strong>IMPORTANTE:</strong></P>
                <P>Ap&oacute;s clicar no bot&atilde;o ENVIAR voc&ecirc; n&atilde;o poder&aacute; mais fazer modifica&ccedil;&otilde;es na sequ&ecirc;ncia de sua hist&oacute;ria nesta etapa individual.</P>
              </div>
            </Instrucoes2>
          </ModalBody>
          <ModalFooter>
            <Container className="text-center">
              <Button color="success" onClick={this.cancelarInst}>
                ok
              </Button>{" "}
            </Container>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalConfirmacao}
          toggle={this.toggleConfirmacao}
          className={this.props.className}
          onClosed={() => (this.onProximo = false)}
        >
          <ModalHeader toggle={this.toggleConfirmacao}>
            Storytelling
          </ModalHeader>
          <ModalBody>
            Se você estiver satisfeito com suas escolhas clique em prosseguir,
            se não clique em cancelar
          </ModalBody>
          <ModalFooter>
            {!this.state.loadingSpinner ? (
              <div>
                <Button
                  color="success"
                  onClick={() => {
                    this.state.tempos.tempoRestante > 0
                      ? this.setState({
                          loadingSpinner: true,
                          pararTimer: true
                        })
                      : this.fimAtividade();
                  }}
                  disabled={this.state.loadingSpinner}
                >
                  Prosseguir
                </Button>{" "}
                <Button
                  color="danger"
                  onClick={this.toggleConfirmacao}
                  disabled={this.state.loadingSpinner}
                >
                  Cancelar
                </Button>
              </div>
            ) : (
              <img src={require(`../../Assets/_assets/${spinner}`)} />
            )}
          </ModalFooter>
        </Modal>
        <Base>
          <Container>
            <br />
            <br />
            <br />
            <Row>
              <Col>
                <h1>Storytelling</h1>
              </Col>
              <Col className="text-right">
                <Icon
                  src={require("../../Assets/_assets/doubt.png")}
                  alt="Instrucoes"
                />
                <Inst onClick={this.openInst}>Instruções</Inst>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col className="storytelling-step-1" sm="6">
                <Contador
                  counter={
                    <Timer
                      intervalResetRequest={this.state.intervalResetRequest}
                      intervalResetFeedback={() => this.setState({ intervalResetRequest: false })}
                      crescente={false}
                      tempo={901200}
                      resetAutomatico={false}
                      tempoAtualizado={this.tempoAtualizado}
                      pararTimer={this.state.pararTimer}
                      resetar={false}
                      tempoParado={this.fimAtividade}
                      id="tempo"
                    />
                  }
                  tempo={
                    901200
                  }
                />
              </Col>
            </Row>
            <br />
            <br />

            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    className="storytelling-step-2"
                    ref={provided.innerRef}
                    style={(getListStyle(snapshot.isDraggingOver), {})}
                  >
                    {this.state.frases.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <Story story={item.content} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>

              <br />
              <Row>
                <Col className="text-center">
                <Button className="storytelling-step-3" onClick={this.toggleConfirmacao}>ENVIAR</Button>
                </Col>
              </Row>
              <Footer />
            </DragDropContext>
          </Container>
        </Base>
      </div>
    );
  }
}
export default withRouter(Storytelling);
