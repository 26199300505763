import React, { Component } from 'react';
import api from '../../../Services/api';
import { Constantes, startedProofStatusCode } from '../../../Services/Constantes';
import ErrorComponent from '../../../Services/ErrorComponent';
import * as workerTimers from 'worker-timers';

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Container,
  Progress,
  Col
} from 'reactstrap';
import Loading from '../../../Services/Loading';
// ------- DRIX --------
import {
  Base,
  HeaderLogged,
  Button,
  Titulo,
  Icon,
  ContadorProva,
  Qst,
  Options,
  ContadorQst,
  Fonts,
  Footer,
  Clear
} from '../../Assets/Assets';
import { Inst, Instrucoes, Enunciado } from './Assets';
// ------- DRIX --------
import { verificarRespostas } from '../Utils/verificarRespostas';
import { montaPaginas } from '../Utils';
import { atualizarTempo, formatarTempo } from './Timer';
import { notifyError } from '../../..//Services/Notificacoes';
import apiCalls from '../../../config/apiCalls';
import './css/Prova.css';
import { srvTime } from '../Utils/index';

let opts = React.createRef();
// Imagens
const spinner = Constantes.imagemSpinner;

class Prova extends Component {
  constructor(props) {
    super(props);
    this.logo = React.createRef();
  }

  // Constantes
  quantidadeQuestoes = 0;
  tempoProvaQuestao = 0;
  tempoProvaProva = 0;
  tempoOnMount = srvTime();

  tempoInicial = {
    tempoQuestaoRestante: 0,
    tempoProvaRestante: 0,
    tempoPauseAcumuladoProva: 0,
    tempoPauseAcumuladoQuestao: 0
  };

  // Variaveis Globais
  checkFimTimer = 0;
  onLogout = false;
  timer;

  // Variaveis de resposta
  respostas = {
    acertos: 0,
    errors: 0,
    escolhas: [],
    nivel: 0,
    totalQuestoes: 0,
    questaoAtual: 0,
    perguntasRestantes: [],
    tempos: {
      tempoInicioProva: this.tempoOnMount,
      tempoAtualProva: this.tempoOnMount,
      tempoFimProva: this.tempoOnMount + this.tempoProvaProva,
      tempoAdicional: []
    }
  };
  pontuacao = 0;

  //Estado
  state = {
    haveError: false,
    errorCount: 0,
    errorRetry: 10,
    questao: {
      enunciado: '',
      alternativas: []
    },
    ...this.tempoInicial,
    resetar: false,
    loading: true,
    modalEnvioResposta: false,
    modalInstrucoes: false,
    font: 14,
    respSelecao: '-2',
    progressoEnvio: 0,
    modalSelecioneAlterativa: false,
    loadingSpinner: false,
    prova: {},
    texts: [],
    error: false
  };

  clearProofInterval() {
    if (!this.timer) return;
    try {
      workerTimers.clearInterval(this.timer);
    } catch (err) {
      console.log('O timer já foi desligado. ', err);
    }
  }

  componentDidMount = async () => {
    try {
      window.scrollTo(0, 0);
      let prova = await this.carregarProva();

      // getting texts
      const resTexts = await api.get('/v3/prova-template-textos', {
        params: {
          templateId: prova.template._id,
          proofId: sessionStorage.getItem('atividade')
        }
      });
      const texts = resTexts.data;
      this.setState({ texts });

      await this.recebendoInfo(prova);
      this.setState({ prova });
      this.subscribePush(); // se inscreve para receber as mensagens de socket

      if (!sessionStorage.getItem('firstLogged')) {
        await api.put(`/api/prova/${sessionStorage.getItem('atividade')}/change-participant-status/${sessionStorage.getItem('user')}`, {
          projectId: sessionStorage.getItem('projeto'),
          status: startedProofStatusCode
        });

        sessionStorage.setItem('firstLogged', true);
      }

    } catch (error) {
      console.error(error);
      this.setState({ error: true });
    }
  };

  /**
   * Carrega a prova e seu respectivo template no state.
   */
  async carregarProva() {
    let resultProva = await apiCalls.provas.getProvas({
      provasIds: [sessionStorage.getItem('atividade')]
    });
    const { provas } = resultProva.data; 
    let prova = provas[0];

    let resTemplate = await apiCalls.templateProva.getTemplateById(prova.template);
    prova.template = resTemplate.data;

    return prova;
  }

  componentWillUnmount = () => {

    Constantes.io.off('Projeto:Stop');
    Constantes.io.off('Projeto:Pause');
    Constantes.io.off('Users:Prova:TempoExtra');
    Constantes.io.off('Users:Prova:QuestaoExcluida');
    Constantes.io.off('Projeto:ProvaPause');
    Constantes.io.off('Auth:LogoutInstrumento');
    Constantes.io.off('Auth:Desconectar');
    Constantes.io.off('Projeto:ProvaStop');

    this.clearProofInterval();
    // workerTimers.clearInterval(this.timer);
  };

  /**
   *
   * Calcula o tempo restante da prova
   * Busca o template da prova e define as variáveis de tempo com base nele
   * Consulta a próxima questão da prova, com base nas questões já respondidas pelo participante
   * Verifica se a prova já terminou
   */
  recebendoInfo = async (prova) => {
    try {
      if (this.state.errorCount >= this.state.errorRetry) {
        return this.setState({ haveError: true, loading: false });
      }
      const projetoId = sessionStorage.getItem('projeto');
      const modulo = sessionStorage.getItem('modulo');
      const atividadeId = sessionStorage.getItem('atividade');
      const user = sessionStorage.getItem('user');
  
      // Atualizando perguntas do template
      const { template, pause } = prova;
  
      this.tempoProvaQuestao = template.tempoQuestao * 60 * 1000;
      this.tempoProvaProva = template.tempoProva * 60 * 1000;
      this.quantidadeQuestoes = template.qtdeQuestoes;
      this.respostas.tempos.tempoFimProva =
        this.tempoOnMount + this.tempoProvaProva;
  
      // this.respostas.perguntasRestantes = template.niveis;
  
      // Inicializando tempos de pause acumulado da prova e tempo restante
      let tempoPauseAcumuladoProva =
        pause.tempoAcumulado +
        (pause.pausesConsultor.length &&
        pause.pausesConsultor[pause.pausesConsultor.length - 1]
          .tempoFinalizado === 0
          ? this.tempoOnMount -
            pause.pausesConsultor[pause.pausesConsultor.length - 1].tempoIniciado
          : 0);
      let tempoProvaRestante =
        this.respostas.tempos.tempoFimProva - this.tempoOnMount;
      if (pause.ativo) {
        tempoProvaRestante +=
          srvTime() -
          pause.pausesConsultor[pause.pausesConsultor.length - 1].tempoIniciado;
        if (tempoProvaRestante > this.tempoProvaProva) {
          tempoProvaRestante = this.tempoProvaProva;
        }
      }
  
      let tempoQuestaoRestante = this.tempoProvaQuestao;
  
      // Verificando se pause foi dado
      if (pause.ativo) {
        this.togglePause();
      }
  
      const res = await verificarRespostas(
        user,
        Constantes.instrumentoProvas,
        Constantes.moduloProvas,
        atividadeId
      );
      let respostaPrevia = res;
  
      if (respostaPrevia) {
        // Atualizando respostas
        this.respostas = respostaPrevia.resposta;
  
        // Recebendo enunciado antigo
        let questao = this.respostas.escolhas[
          this.respostas.escolhas.length - 1
        ].questao;
  
        // Verificando por tempo extra
        let tempoAdicionalProva = 0;
        if (this.respostas.tempos.tempoAdicional.length)
          tempoAdicionalProva = this.respostas.tempos.tempoAdicional.reduce(
            (acc, val) => {
              return acc + val.tempo;
            },
            0
          );
        let tempoAdicionalQuestao = this.respostas.tempos.tempoAdicional.find(
          (t) => t.questao === this.respostas.questaoAtual
        );
        if (tempoAdicionalQuestao) {
          tempoAdicionalQuestao = tempoAdicionalQuestao.tempo;
        } else {
          tempoAdicionalQuestao = 0;
        }
  
        // Atualizando tempo de prova
        tempoProvaRestante =
          this.respostas.tempos.tempoFimProva - this.tempoOnMount;
        if (pause.ativo) {
          tempoProvaRestante +=
            srvTime() -
            pause.pausesConsultor[pause.pausesConsultor.length - 1]
              .tempoIniciado;
          if (tempoProvaRestante > this.tempoProvaProva) {
            tempoProvaRestante = this.tempoProvaProva;
          }
        }
        // Verificando quantidade de questões perdiddas
        // let questoesPerdidas = Math.floor(
        //   (this.tempoOnMount -
        //     (this.respostas.tempos.tempoAtualProva +
        //       tempoPauseAcumuladoProva +
        //       tempoAdicionalProva)) /
        //     this.tempoProvaQuestao
        // );
        // if (questoesPerdidas < 0) questoesPerdidas = 0;
  
        // // Checando se já passou da quantidade de questões
        // if (
        //   this.respostas.questaoAtual + questoesPerdidas >
        //   this.respostas.totalQuestoes
        // )
        //   questoesPerdidas =
        //     this.respostas.totalQuestoes - this.respostas.questaoAtual;
  
        // // Verificando questões a serem perdidas
        // for (let i = 0; i < questoesPerdidas; i++) {
        //   this.respostas.tempos.tempoAtualProva = Date.now();
        //   this.respostas.errors++;
        //   this.respostas.escolhas[this.respostas.escolhas.length - 1] = {
        //     ...this.respostas.escolhas[this.respostas.escolhas.length - 1],
        //     resposta: {
        //       texto: ''
        //     },
        //     tempoQuestaoDuracao: this.tempoProvaQuestao,
        //     nivel: this.respostas.nivel
        //   };
        //   let resp = {
        //     respostas: this.respostas,
        //     questaoAtual: this.state.questao.enunciado,
        //     modulo,
        //     atividadeId
        //   };
        //   let questaoRecebida = await api.put(
        //     `api/instruments/prova/novaQuestao/${sessionStorage.getItem(
        //       'user'
        //     )}`,
        //     {
        //       ...resp
        //     }
        //   );
        //   questao = questaoRecebida.data.questao;
        //   this.respostas = questaoRecebida.data.respostas;
        // }
  
        // Verificando se prova já acabou
        if (
          tempoProvaRestante <= 0 ||
          this.respostas.questaoAtual > this.quantidadeQuestoes
        ) {
          this.alternativaEscolhida = undefined;
          this.tempoQuestaoDuracao = 0;
          await this.fimDeProva({ performCalc: true });
        } else {
          // Calculando tempo restante da questao
          //let tempoPauseAcumuladoQuestao = pause.pausesConsultor.filter(p => p.iniciado )
          let tempoPauseAcumuladoQuestao = 0;
          const pauses = pause.pausesConsultor.filter(
            (p) =>
              p.tempoIniciado >
              this.respostas.escolhas[this.respostas.escolhas.length - 1]
                .tempoIniciado
          );
          pauses.length
            ? (tempoPauseAcumuladoQuestao = pauses.reduce(
              (acc, value) =>
                value.tempoFinalizado
                  ? acc + (value.tempoFinalizado - value.tempoIniciado)
                  : acc + this.tempoOnMount - value.tempoIniciado,
              0
            ))
            : (tempoPauseAcumuladoQuestao = 0);
  
  
          const tempoQuestaoCalculado =
            this.tempoProvaQuestao -
            (this.tempoOnMount -
              (this.respostas.tempos.tempoAtualProva +
                tempoPauseAcumuladoQuestao +
                tempoAdicionalQuestao));
          tempoQuestaoRestante =
            tempoQuestaoCalculado < this.tempoProvaQuestao
              ? tempoQuestaoCalculado
              : this.tempoProvaQuestao;
  
          this.setState({
            resetar: true,
            questao: questao,
            loading: false,
            tempoProvaRestante,
            tempoQuestaoRestante,
            tempoPauseAcumuladoProva,
            tempoPauseAcumuladoQuestao
          });
        }
      }
      // Setando número de questoes
      else {
        this.respostas.totalQuestoes = this.quantidadeQuestoes;
  
        let questaoRecebida = await api.put(
          `api/instruments/prova/novaQuestao/${sessionStorage.getItem(
            'user'
          )}`,
          {
            questaoAtual: this.state.questao.enunciado, // inicial vazia.
            modulo,
            atividadeId,
            idTemplate: template._id,
            tempos: this.respostas.tempos,
            tempoProvaProva: this.tempoProvaProva,
            totalQuestoes: this.quantidadeQuestoes,
            posicaoQuestaoAtual: 0
          }
        );
  
  
        let questao = questaoRecebida.data.questao;
        this.respostas.questaoAtual = questaoRecebida.data.questaoAtual;
  
        this.setState({
          resetar: true,
          questao: questao,
          loading: false,
          // modalInstrucoes: true,
          tempoProvaRestante,
          tempoQuestaoRestante,
          tempoPauseAcumuladoProva
        });
      }
  
      // this.timer = setInterval(this.tempoAtualizado, 1000);
      this.timer = workerTimers.setInterval(this.tempoAtualizado, 1000);
    } catch (err) {
      console.error(err);
      this.setState({ errorCount: this.state.errorCount + 1 });
      setTimeout(() => this.recebendoInfo(), 1000);
    }
  };

  subscribePush = () => {
    Constantes.io.on('disconnect', () => setTimeout(this.subscribePush, 3000));
    Constantes.io.on('Auth:LogoutInstrumento', (userId) => {
      console.info('Auth:LogoutInstrumento');
      if (sessionStorage.getItem('user') === userId.toString()) {
        this.onLogout = true;
        if (this.state.tempoProvaRestante > 0) {
          this.clearProofInterval();
          // workerTimers.clearInterval(this.timer);
          this.props.logout();
        } else this.proximaAtividade({ performCalc: false });
      }
    });
    Constantes.io.on('Projeto:ProvaStopUsers', (data) => {
      console.info('Projeto:ProvaStopUsers')
      let { usersDeslogados } = data;
      let myUserId = sessionStorage.getItem('user');

      if (usersDeslogados && usersDeslogados.length && myUserId) {
        let mustLogout = usersDeslogados.some(
          (d) => d.toString() === myUserId.toString()
        );
        if (mustLogout) {
          let tempoQuestaoDuracao =
          this.tempoProvaQuestao - this.state.tempoQuestaoRestante;

          this.alternativaEscolhida = undefined;
          this.tempoQuestaoDuracao = tempoQuestaoDuracao;
          this.fimDeProva({ performCalc: false });
        }
      }
    });
    Constantes.io.on('Projeto:ProvaStop', (data) => {
      console.info('Projeto:ProvaStop')
      let stopedProvaId = data.provaId;
      let provaAtual = sessionStorage.getItem('atividade');

      if (stopedProvaId === provaAtual) {
        this.setState({
          modalEnvioResposta: true,
          progressoEnvio: 5
        });
        this.alternativaEscolhida = undefined;
        this.tempoQuestaoDuracao = this.tempoProvaQuestao - this.state.tempoQuestaoRestante;
        this.fimDeProva({ performCalc: false, stop: true });
      }
    });
    Constantes.io.on('Projeto:ProvaPause', (data) => {
      let pausedProvaId = data.provaId;
      let provaAtual = sessionStorage.getItem('atividade');
      if (pausedProvaId === provaAtual) {
        if (!data.ativo) {
          this.setState({ tempoPauseAcumulado: data.tempoAcumulado });
        }
        this.togglePause();
      }
    });
    Constantes.io.on('Projeto:ProvaPlay', (data) => {
      try {
        let playedProvaId = data.provaId;
        let provaAtual = sessionStorage.getItem('atividade');
        // this.setState({
        //   tempoProvaRestante:
        //     this.state.tempoProvaRestante -
        //     this.tempoOnMount -
        //     this.state.prova.pause.pausesConsultor[
        //       this.state.prova.pause.pausesConsultor.length - 1
        //     ].tempoIniciado
        // });

        if (playedProvaId === provaAtual) this.togglePause();
      } catch (error) {
        console.error(error);
      }
    });

    Constantes.io.on('Users:Prova:TempoExtra', (data) => {
      if (data.id === sessionStorage.getItem('user')) {
        let { tempoQuestaoRestante, tempoProvaRestante } = this.state;

        if (tempoQuestaoRestante + data.tempoAdicional < this.tempoProvaQuestao)
          tempoQuestaoRestante += data.tempoAdicional;
        else tempoQuestaoRestante = this.tempoProvaQuestao;

        tempoProvaRestante = data.tempoFim - srvTime();
        if (tempoProvaRestante > this.tempoProvaProva) {
          tempoProvaRestante = this.tempoProvaProva;
        }

        this.setState({ tempoQuestaoRestante, tempoProvaRestante });
      }
    });
    Constantes.io.on('Users:Prova:QuestaoExcluida', (data) => {
      if (data.id === sessionStorage.getItem('user')) {
        this.respostas = data.resposta.resposta;
        this.pontuacao = data.resposta.pontuacao;
        let { tempoProvaRestante } = this.state;
        tempoProvaRestante =
          this.respostas.tempos.tempoFimProva - srvTime();

        if (tempoProvaRestante > this.tempoProvaProva) {
          tempoProvaRestante = this.tempoProvaProva;
        }
        this.setState({ tempoProvaRestante });
      }
    });
  };

  proximaAtividade = async ({ performCalc = false, stop }) => {
    const atividadeId = sessionStorage.getItem('atividade');
    await api
      .put(`api/user/respostas/finalizar`, {
        atividade: Constantes.moduloProvas,
        atividadeId: atividadeId,
        questaoAtual: this.state.questao.enunciado,
        alternativaEscolhida: this.alternativaEscolhida,
        tempoQuestaoDuracao: this.tempoQuestaoDuracao,
        projectId: sessionStorage.getItem("projeto"),
        stop
      })
      .then(() => {

        if (performCalc) {
          apiCalls.provas.calcProofResults({
            idProjeto: sessionStorage.getItem("projeto"),
            provaId: atividadeId
          });
        }   

        this.setState({ loadingSpinner: true });
        this.alternativaEscolhida = undefined;
        this.tempoQuestaoDuracao = undefined;
        this.props.action('fimDeProva');
      })
      .catch(async (err) => {
        console.error(err);
        if (err.response) {
          console.error(err.response.data);
        }
        this.alternativaEscolhida = undefined;
        this.tempoQuestaoDuracao = undefined;
      });
  };
  semAlternativaToggle = () => {
    this.setState({
      modalSelecioneAlterativa: false
    });
  };

  gravarResposta = async (retry = false) => {
    this.clearProofInterval();
    const atividadeId = sessionStorage.getItem('atividade');
    if (retry) var atualizadoEm = retry;
    else var atualizadoEm = srvTime();
    let resp = {
      instrumento: Constantes.instrumentoProvas,
      resposta: this.respostas,
      pontuacao: this.pontuacao,
      atualizadoEm
    };

    this.setState({
      progressoEnvio: 75
    });

    // Verifica se terminou a prova
    if (this.respostas.questaoAtual === this.quantidadeQuestoes) {
      let respostas = this.respostas;
      await this.fimDeProva({ performCalc: true });
    } else {
      this.novaQuestao();
    }
    this.setState({
      progressoEnvio: 100
    });
  };
  novaQuestao = async () => {
    if (this.state.errorCount >= this.state.errorRetry) {
      return this.setState({ haveError: true, loading: false });
    }
    const { template } = this.state.prova;

    // Recebendo nova pergunta
    const atividadeId = sessionStorage.getItem('atividade');
    const modulo = sessionStorage.getItem('modulo');
    await api
      .put(
        `api/instruments/prova/novaQuestao/${sessionStorage.getItem('user')}`,
        {
          questaoAtual: this.state.questao.enunciado,
          modulo,
          atividadeId,
          idTemplate: template._id,
          tempos: this.respostas.tempos,
          alternativaEscolhida: this.alternativaEscolhida,
          tempoQuestaoDuracao: this.tempoQuestaoDuracao,
          posicaoQuestaoAtual: this.respostas.questaoAtual - 1
        }
      )
      .then(async (res) => {
        let questaoRecebida = res;

        if (questaoRecebida.data.finalizado) {
          this.fimDeProva({ performCalc: true });
        }

        this.respostas.questaoAtual = questaoRecebida.data.questaoAtual;
        let questao = questaoRecebida.data.questao;

        // Resetar questao
        this.setState({
          modalEnvioResposta: false
        });
        this.respostas.tempos.tempoAtualProva = srvTime();
        this.setState({
          resetar: true,
          questao,
          loading: false,
          tempoQuestaoRestante: this.tempoProvaQuestao,
          progressoEnvio: 0
        });
        window.scrollTo(0, 0);
        this.timer = workerTimers.setInterval(this.tempoAtualizado, 1000);
      })
      .catch((err) => {
        console.error(err);
        this.setState({ errorCount: this.state.errorCount + 1 });
        setTimeout(() => this.novaQuestao(), 1000);
      })
      .finally(() => {
        this.alternativaEscolhida = undefined;
        this.tempoQuestaoDuracao = undefined;
      });
  };

  respostaEnviada = async (resposta) => {
    this.respostas.tempos.tempoAtualProva = srvTime();
    this.setState({
      progressoEnvio: 30
    });
    // Checando se o timer da questão não acabou
    if (resposta !== -1) {
      // Salvando resposta
      this.respostaEnviadaConfirmada(resposta);
      this.setState({
        respSelecao: ''
      });
    } else {
      // Atualizando tempos
      let tempoQuestaoDuracao = this.tempoProvaQuestao;

      this.alternativaEscolhida = undefined;
      this.tempoQuestaoDuracao = tempoQuestaoDuracao;

      await this.gravarResposta();
    }

    // Notifica user
    //this.notificarRespostaEnviada();
  };

  respostaEnviadaConfirmada = async (resposta) => {
    document.getElementsByName('choice').forEach(function(child) {
      if (child.checked === true) {
        child.checked = false;
      }
    });

    // // Atualizando tempos
    let tempoQuestaoDuracao =
      this.tempoProvaQuestao - this.state.tempoQuestaoRestante;

    this.alternativaEscolhida = resposta;
    this.tempoQuestaoDuracao = tempoQuestaoDuracao;
    await this.gravarResposta();
    //await this.sleep(1000);

    // Notifica user
    //this.notificarRespostaEnviada();
  };

  tempoAtualizado = () => {

    if (!this.state.modalPause) {
      let { tempoProvaRestante, tempoQuestaoRestante } = this.state;

      tempoProvaRestante = atualizarTempo(tempoProvaRestante);
      tempoQuestaoRestante = atualizarTempo(tempoQuestaoRestante);

      // Verifica se tempo da prova terminou
      if (tempoProvaRestante <= 0) {
        this.setState({
          modalEnvioResposta: true,
          progressoEnvio: 5
        });
        this.alternativaEscolhida = undefined;
        this.tempoQuestaoDuracao = this.tempoProvaQuestao - this.state.tempoQuestaoRestante;
        this.fimDeProva({ performCalc: true });
      }

      // Verifica se tempo da questao acabou
      if (tempoQuestaoRestante <= 0) {
        this.setState({
          modalEnvioResposta: true,
          progressoEnvio: 0,
          tempoQuestaoRestante: this.tempoProvaQuestao
        });
        this.sleep(1000).then(() => {
          this.respostaEnviada(-1);
          tempoQuestaoRestante = this.tempoProvaQuestao;
          this.setState({
            tempoProvaRestante,
            tempoQuestaoRestante
          });
        });
      } else {
        this.setState({
          tempoProvaRestante,
          tempoQuestaoRestante
        });
      }
    }
  };

  fimDeProva = async ({ performCalc, stop=false }) => { 
    this.clearProofInterval();
    this.proximaAtividade({ performCalc, stop });
  };

  resetFeedback = () => {
    this.setState({
      resetar: false
    });
  };

  checkEnvio = async (respSelecao) => {
    if (this.state.respSelecao === '') {
      this.setState({
        modalSelecioneAlterativa: true
      });
    } else {
      var b = document.getElementById('botao');
      b.blur();
      // Checando se resposta vazia

      if (respSelecao === '-2') {
        this.setState({ modalSelecioneAlterativa: true });
      } else {
        this.clearProofInterval();
        this.setState({
          modalEnvioResposta: true,
          progressoEnvio: 5
        });
        // await this.sleep(1000);
        this.respostaEnviada(respSelecao);
        var t = document.getElementById('logo');
      }
    }
  };

  sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  sobeTela = () => {
    let it = document.getElementById('top');
    it.focus();
    document.getElementsByTagName('body')[0].scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  RespostaEscolhida = (e) => {
    this.setState({
      respSelecao: e
    });
  };

  atualizarBarraEnvio = (statusEnvio) => {
    this.setState({
      progressoEnvio: statusEnvio
    });
  };

  /* TOGGLES */
  toggleInstrucoes = () => {
    this.setState((previousState) => ({
      modalInstrucoes: !previousState.modalInstrucoes
    }));
  };

  togglePause = () => {
    this.setState((previousState) => ({
      modalPause: !previousState.modalPause
    }));
  };
  // ------- DRIX --------

  /// AUMENTA E DIMINUI FONT///

  aumentaFonte = () => {
    let { font } = this.state;
    if (font + 5 >= 30) return notifyError('Não é possível aumentar mais.');
    this.setState({ font: font + 5 });
  };

  diminuiFonte = () => {
    let { font } = this.state;
    if (font - 5 <= 7) return notifyError('Não é possível diminuir mais.');

    this.setState({ font: font - 5 });
  };

  render() {
    if (this.state.error || this.state.haveError) {
      return <ErrorComponent />
    }

    if (this.state.loading) {
      return <Loading />;
    }
    /*if( !this.state.modalEnvioResposta){}*/
    return (
      this.state.questao.alternativas.length !== 0 && (
        <div className="text-center back" id="logo" tabIndex="0">
          <Modal
            isOpen={this.state.modalEnvioResposta}
            className={this.props.className}
          >
            <ModalHeader>Questão {this.respostas.questaoAtual}:</ModalHeader>
            <ModalBody>
              {/*Você selecionou a alternativa{" "}
              {String.fromCharCode(97 + this.checkResposta).toUpperCase()}. <br />
      Sua resposta está sendo enviada...*/}
              Enviando...
              {!this.state.loadingSpinner ? (
                <Progress color="success" value={this.state.progressoEnvio} />
              ) : (
                <img src={require(`../../Assets/_assets/${spinner}`)} />
              )}
              {
                this.state.errorCount > 0 ?
                <><span style={{ fontSize: 12 }}>Tentativas {this.state.errorCount} / {this.state.errorRetry}.</span></>
              : null
              }
            </ModalBody>
            <ModalFooter />
          </Modal>

          {/* ----- MODAL DE PAUSE ----- */}
          <Modal
            isOpen={this.state.modalPause}
            className={this.props.className}
          >
            <ModalHeader>Atenção</ModalHeader>
            <ModalBody>
              Prova pausada. Aguardando play do consultor...
            </ModalBody>
          </Modal>

          <Modal
            isOpen={this.state.modalSelecioneAlterativa}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggle}>Atenção</ModalHeader>
            <ModalBody>Selecione uma alternativa</ModalBody>
            <ModalFooter>
              <Button color="success" onClick={this.semAlternativaToggle}>
                Ok
              </Button>{' '}
            </ModalFooter>
          </Modal>
          {this.state.modalEnvioResposta ? (
            false
          ) : (
            <Base>
              <Container>
                <br />
                <br />
                <br />
                <Row>
                  <Col className="text-left">
                    <Titulo id="top">Prova</Titulo>
                  </Col>
                  <Col className="text-right">
                    <Icon
                      src={require('../../Assets/_assets/doubt.png')}
                      alt="Instrucoes"
                    />
                    <Inst onClick={this.toggleInstrucoes}>Instruções</Inst>
                  </Col>
                </Row>
                <br />
                <Modal
                  isOpen={this.state.modalInstrucoes}
                  className={this.props.className}
                >
                  <ModalBody>
                    <Instrucoes>
                      <p
                        dangerouslySetInnerHTML={{
                            __html: (this.state.texts && 
                              this.state.texts.length && 
                              this.state.texts[0].textos && 
                              this.state.texts[0].complementar && 
                              this.state.texts[0].textos.inicial && (
                                this.state.texts[0].textos.inicial + this.state.texts[0].complementar.textoInicial
                              )) || ''
                        }}
                      ></p>
                    </Instrucoes>
                  </ModalBody>
                  <ModalFooter>
                    <Container className="text-center">
                      <Button color="success" onClick={this.toggleInstrucoes}>
                        ok
                      </Button>{' '}
                    </Container>
                  </ModalFooter>
                </Modal>

                <ContadorProva
                  counter={formatarTempo(this.state.tempoProvaRestante)}
                  tempo={
                    100 * (this.state.tempoProvaRestante / this.tempoProvaProva)
                  }
                />
                <br />
                <br />
                <Row>
                  {montaPaginas(this.respostas.totalQuestoes, this.respostas.questaoAtual)}
                  <Clear />
                </Row>
              </Container>
              <br className="bigger-screen" />
              <br className="bigger-screen" />
              <Container className="pv">
                <Row>
                  <Col sm="8">
                    <br className="small-screen" />
                    <Col
                      sm="12"
                      id="tempo-questao-wrapper"
                      className="small-screen"
                    >
                      <div id="tempo-questao">
                        <ContadorQst
                          counter={formatarTempo(
                            this.state.tempoQuestaoRestante
                          )}
                          tempo={
                            100 *
                            (this.state.tempoQuestaoRestante /
                              this.tempoProvaQuestao)
                          }
                        />
                      </div>
                    </Col>
                    <br className="small-screen" />
                    <div className="small-screen">
                      <br />
                      <Fonts className="font-button">
                        <button className="a" onClick={this.diminuiFonte}>
                          A-
                        </button>
                      </Fonts>
                      <Fonts className="font-button">
                        <button className="aPlus" onClick={this.aumentaFonte}>
                          A+
                        </button>
                      </Fonts>
                    </div>
                    <br className="small-screen" />
                    <Row id="enunciado">
                      <span>
                        <Enunciado
                          contador={this.respostas.questaoAtual + ' - '}
                          estilo={this.state.font + 'px'}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.state.questao.enunciado
                            }}
                          />
                        </Enunciado>
                      </span>
                      {/*{this.state.questao.enunciado.split("\n").map((item, i) => {
                      return (
                        <p style={{ fontSize: this.state.font + "px" }} key={i}>
                          {i == 0 ? this.respostas.questaoAtual + " - " + item : item}
                        
                      );
                    })}*/}
                    </Row>
                    <br />
                    <br />
                    {this.state.questao.alternativas.map(
                      (alternativa, index) => (
                        <Row
                          key={'Alternativa' + index}
                          onClick={() => this.RespostaEscolhida(index)}
                          name="choice"
                        >
                          <Options
                            id={opts}
                            tempo={
                              100 *
                              (this.state.tempoQuestaoRestante /
                                this.tempoProvaQuestao)
                            }
                            sobe={this.sobeTela}
                            value={index}
                            className="ch"
                            font={this.state.font}
                            contador={
                              String.fromCharCode(97 + index).toUpperCase() +
                              ' : '
                            }
                            texto={alternativa.texto}
                          />
                        </Row>
                      )
                    )}
                  </Col>
                  <Col
                    sm="4"
                    id="tempo-questao-wrapper"
                    className="bigger-screen"
                  >
                    <div id="tempo-questao">
                      <ContadorQst
                        counter={formatarTempo(this.state.tempoQuestaoRestante)}
                        tempo={
                          100 *
                          (this.state.tempoQuestaoRestante /
                            this.tempoProvaQuestao)
                        }
                      />
                      <br />
                      <Fonts>
                        <button className="a" onClick={this.diminuiFonte}>
                          A-
                        </button>
                      </Fonts>
                      <Fonts>
                        <button className="aPlus" onClick={this.aumentaFonte}>
                          A+
                        </button>
                      </Fonts>
                    </div>
                  </Col>
                </Row>
              </Container>
              <Container className="text-center">
                <Button
                  onClick={() => this.checkEnvio(this.state.respSelecao)}
                  id="botao"
                >
                  CONFIRMAR
                </Button>
                <Footer />
              </Container>
            </Base>
          )}
        </div>
      )
    );
  }
}
export default Prova;
