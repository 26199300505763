import React, { Component } from "react";
import Joyride from 'react-joyride';
import JoyrideContext from "../../../contexts/joyride";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import api from "../../../Services/api";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Container
} from "reactstrap";
import { Constantes } from "../../../Services/Constantes";
import { reenviarResposta } from "../../../Services/GerenciarConexao";
import Timer from "../../../Services/Timer";
import { verificarRespostas } from "../Utils/verificarRespostas";
import Loading from "../../../Services/Loading";
import ErrorComponent from '../../../Services/ErrorComponent';

import {
  Base,
  Inst,
  Instrucoes2,
  Button,
  Contador,
  Icon,
  Story,
  Footer,
  Mesa
} from "../../Assets/Assets";
import styled from "styled-components";
import { withRouter } from 'react-router';

import {
  notifyError
} from "../../../Services/Notificacoes";
import { millisToMinutesAndSeconds } from "../Utils/Calculos";
import { srvTime } from "../Utils";
import { TourP } from "../../../utils/TourUtils";

import apiCalls from "../../../config/apiCalls";

// Imagens
const spinner = Constantes.imagemSpinner;

const Item = styled.div`
  margin: 14px;

  padding: 13px;
`;

const P = styled.p`
  font-size: 13px;
`;

const grid = 1;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  padding: grid,
  width: "100%"
});

const droppableIdGrupo = "droppableGrupo";
const droppableIdIndividual = "droppableIndividual";

class StorytellingGrupo extends Component {
  static contextType = JoyrideContext;

  resultadoListaIndividual = []
  resultadoListaGrupo = []
  state = {
    "frasesIndividual": [
      {content:"A. O bosque é perigoso, por isso evitamos ir pelo caminho mais curto, disse Maria.",
      id:"0",
      sequencia:"10",},

      {content:"B. Caminhos mais fáceis não são os mais seguros, evite a trilha! Lembre-se do que lhe disse!",
      id:"1",
      sequencia:"6",},

      {content:"C. Chapeuzinho sempre ouvia sua mãe, mas hoje estava atrasada.",
      id:"2",
      sequencia:"14",},

      {content:"D. Esse caminho é mais rápido, preciso chegar rápido, para voltar antes do anoitecer.   ",
      id:"3",
      sequencia:"1",},

      {content:"E. Esse caminho é diferente, muitas árvores, flores e frutos diferentes.",
      id:"4",
      sequencia:"13",},

      {content:"F. Acho que não é tão inseguro, e economiza muito tempo vindo pela trilha.",
      id:"5",
      sequencia:"5",},

      {content:"G. Olha a casa da vovó. A chaminé já anuncia um belo bolo no forno.     ",
      id:"6",
      sequencia:"4",},

      {content:"H. Toc. Toc. Vovó, Chapeuzinho chegou!",
      id:"7",
      sequencia:"2",},

      {content:"I. Que saudades, parece que não me visita a anos! Um sorriso carinhoso irradiou suas feições.   ",
      id:"8",
      sequencia:"15",},

      {content:"J. Meus ouvidos escutam você chegando ainda ao longe.",
      id:"9",
      sequencia:"7",},

      {content:"K. Por onde você veio! Não a vi pela estrada!",
      id:"10",
      sequencia:"12",},

      {content:"L. Vovó, você está tão diferente!",
      id:"11",
      sequencia:"8",},

      {content:"M. Meus olhos crescem ao vê-la.",
      id:"12",
      sequencia:"3",},

      {content:"N. Minha boca se abre de alegria.",
      id:"13",
      sequencia:"9",},

      {content:"O. Chapeuzinho corre sempre que percebe o perigo.",
      id:"14",
      sequencia:"11",},
    ],
    "frasesGrupo": [
      {content:"A. O bosque é perigoso, por isso evitamos ir pelo caminho mais curto, disse Maria.",
      id:"0",
      sequencia:"10",},

      {content:"B. Caminhos mais fáceis não são os mais seguros, evite a trilha! Lembre-se do que lhe disse!",
      id:"1",
      sequencia:"6",},

      {content:"C. Chapeuzinho sempre ouvia sua mãe, mas hoje estava atrasada.",
      id:"2",
      sequencia:"14",},

      {content:"D. Esse caminho é mais rápido, preciso chegar rápido, para voltar antes do anoitecer.   ",
      id:"3",
      sequencia:"1",},

      {content:"E. Esse caminho é diferente, muitas árvores, flores e frutos diferentes.",
      id:"4",
      sequencia:"13",},

      {content:"F. Acho que não é tão inseguro, e economiza muito tempo vindo pela trilha.",
      id:"5",
      sequencia:"5",},

      {content:"G. Olha a casa da vovó. A chaminé já anuncia um belo bolo no forno.     ",
      id:"6",
      sequencia:"4",},

      {content:"H. Toc. Toc. Vovó, Chapeuzinho chegou!",
      id:"7",
      sequencia:"2",},

      {content:"I. Que saudades, parece que não me visita a anos! Um sorriso carinhoso irradiou suas feições.   ",
      id:"8",
      sequencia:"15",},

      {content:"J. Meus ouvidos escutam você chegando ainda ao longe.",
      id:"9",
      sequencia:"7",},

      {content:"K. Por onde você veio! Não a vi pela estrada!",
      id:"10",
      sequencia:"12",},

      {content:"L. Vovó, você está tão diferente!",
      id:"11",
      sequencia:"8",},

      {content:"M. Meus olhos crescem ao vê-la.",
      id:"12",
      sequencia:"3",},

      {content:"N. Minha boca se abre de alegria.",
      id:"13",
      sequencia:"9",},

      {content:"O. Chapeuzinho corre sempre que percebe o perigo.",
      id:"14",
      sequencia:"11",},
    ],
    "dados": {
      "peso": "50",
      "instrucoesGrupo": "Vocês terão 40 minutos para decidir-se sobre a sequência que melhor define a História apresentada.~Ao final, na coluna esquerda, Minhas respostas, você deverá indicar qual a sequência que você considera adequada, podendo ser aquela que a equipe definiu, a mesma de que definiu originalmente ou uma terceira~Seu resultado é avaliado pela coerência e lógica da sequência final individual e/ou da Equipe.",
      "instrucoesIndividual": "Você terá 15 minutos para analisar e ordenar os fragmentos da História indicando as possíveis sequências da mesma e, a seguir, preparar-se para discutir com a Equipe que lhe será indicada. Para tanto, reflita e defina o que considerou como base para a sequência que propôs.~Registre sua opção de sequência e aguarde a instrução quanto a Equipe que lhe será indicada. Seu resultado dar-se-á pela coerência da sequência proposta.",
      "_id": "5f2c1156d638d16e5ea23d3d",
      "nome": "Storytelling-Teste",
      "sentencas": [
        {enunciado:"O bosque é perigoso, por isso evitamos ir pelo caminho mais curto, disse Maria.",
      sequencia:"10",},

      {enunciado:"Caminhos mais fáceis não são os mais seguros, evite a trilha! Lembre-se do que lhe disse!",
      sequencia:"6",},

      {enunciado:"Chapeuzinho sempre ouvia sua mãe, mas hoje estava atrasada.",
      sequencia:"14",},

      {enunciado:"Esse caminho é mais rápido, preciso chegar rápido, para voltar antes do anoitecer.   ",
      sequencia:"1",},

      {enunciado:"Esse caminho é diferente, muitas árvores, flores e frutos diferentes.",
      sequencia:"13",},

      {enunciado:"Acho que não é tão inseguro, e economiza muito tempo vindo pela trilha.",
      sequencia:"5",},

      {enunciado:"Olha a casa da vovó. A chaminé já anuncia um belo bolo no forno.     ",
      sequencia:"4",},

      {enunciado:"Toc. Toc. Vovó, Chapeuzinho chegou!",
      sequencia:"2",},

      {enunciado:"Que saudades, parece que não me visita a anos! Um sorriso carinhoso irradiou suas feições.   ",
      sequencia:"15",},

      {enunciado:"Meus ouvidos escutam você chegando ainda ao longe.",
      sequencia:"7",},

      {enunciado:"Por onde você veio! Não a vi pela estrada!",
      sequencia:"12",},

      {enunciado:"Vovó, você está tão diferente!",
      sequencia:"8",},

      {enunciado:"Meus olhos crescem ao vê-la.",
      sequencia:"3",},

      {enunciado:"Minha boca se abre de alegria.",
      sequencia:"9",},

      {enunciado:"Chapeuzinho corre sempre que percebe o perigo.",
      sequencia:"11",},
      ],
      "created_at": "2022-04-27T20:25:36.072Z",
      "updated_at": "2022-04-27T20:25:36.072Z"
    },
    "respostas": [],
    "somaGrupo": 0,
    "somaIndividual": 0,
    "enunciado": "Vocês terão 40 minutos para decidir-se sobre a sequência que melhor define a História apresentada.~Ao final, na coluna esquerda, Minhas respostas, você deverá indicar qual a sequência que você considera adequada, podendo ser aquela que a equipe definiu, a mesma de que definiu originalmente ou uma terceira~Seu resultado é avaliado pela coerência e lógica da sequência final individual e/ou da Equipe.",
    "secretario": true,
    "modalConfirmacao": false,
    "modalInstrucoes": false,
    "loading": false,
    "tempos": {
      "tempoInicio": 1651091135000,
      "tempoFim": 1651093535000,
      "tempoRestante": 2391000,
      "finalizado": false
    },
    "pararTimer": false,
    "loadingSpinner": false,
    "modalMesa": true,
    "intervalResetRequest": false,
    "haveError": false,
    "errorCount": 0,
    "errorRetry": 10,
    "mesaLabel": 'Mesa teste',
    steps: []
  }
  // Variaveis
  usuarioAtualId
  secretarioNome = "Você"
  onLogout = false
  onProximo = false
  // Tempos
  tempoOnMount = srvTime()
  tempoTotal = 0

  componentDidMount = async () => {
    //this.subscribePush()
    this.recebendoInfo();

    const { setHaveTour, setRunTour } = this.context;

      const steps= [
        // {
        //   selector: '[data-tour="storytellinggrupo-step-1"]',
        //   content: <TourP>Nesta área você será informado para qual grupo de trabalho deverá se dirigir (presencial
        //     ou online). Ao se juntar ao seu grupo de trabalho, confirme clicando neste botão</TourP>,
        // },
        {
          target: ".storytellinggrupo-step-2",
          content: <TourP>Aqui você acompanha o tempo restante para a conclusão desta atividade</TourP>,
          disableBeacon: true
        },
        {
          target: ".storytellinggrupo-step-3",
          content: <TourP>Nessa área deverá ser ordenada a história conforme decisão do GRUPO. Caso você seja o
          secretário do grupo, é sua responsabilidade ordenar a história e para isso clique e arraste os cartões
          para cima e/ou para baixo e depois solte na área desejada, de forma a ordenar a história</TourP>,
          disableBeacon: true,
          placement: 'auto',
        },
        {
          target: ".storytellinggrupo-step-4",
          content: <TourP>Aqui você deverá ordenar a história da forma como VOCÊ considera mais adequada. Somente
          você visualiza a ordenação nesta área, podendo ser igual a definida na etapa individual, igual a
          decisão do grupo ou uma nova ordenação</TourP>,
          disableBeacon: true,
          placement: 'auto',
        },
        {
          target: ".storytellinggrupo-step-5",
          content: <TourP>Clique neste botão para enviar a atividade. Ao clicar neste botão uma janela irá se abrir. Caso não
          tenha dúvidas quanto as suas escolhas, confirme o envio clicando no botão ‘Prosseguir’</TourP>,
          disableBeacon: true
        },
      ];
      setHaveTour(true);
      setRunTour(false);

      this.setState({ steps });
  }

  componentWillUnmount = () => {
    Constantes.io.off("Projeto:Stop")
    Constantes.io.off("Projeto:RespostaGrupo")
    Constantes.io.off("Projeto:NovosTempos")
    Constantes.io.off("Auth:LogoutInstrumento")
  }

  /**
   * @todo mover requisições para config/apiCalls
   */
  recebendoInfo = async () => {
    if (this.state.errorCount >= this.state.errorRetry) {
      return this.setState({ haveError: true, loading: false });
    }
    try {
      // Instruções de grupo
    // await api
    // .get(
    //   `api/projects/dinamicas/projeto/${sessionStorage.getItem("atividade")}/instrumento/${
    //     Constantes.instrumentoStoryTellingGrupo
    //   }`,
    //   {
    //     params: {
    //       roundIndex: this.props.rodada,
    //       instrumentId: this.props.idInstrumento
    //     }
    //   }
    // )
    // .then(async res => {

    //   const projetoDinamica = res.data
    //   const { template } = res.data;
    //   const dinamicaId = sessionStorage.getItem("atividade")
    //   // Verificando respostas prévias
    //   await verificarRespostas(
    //     sessionStorage.getItem("user"),
    //     Constantes.instrumentoStoryTellingIndividual2,
    //     Constantes.moduloDinamicas,
    //     dinamicaId,
    //     this.props.rodada, // rodada
    //     -1, // grupoIndex
    //     projetoDinamica,
    //     this.props.idInstrumento
    //   )
    //     .then(async res => {
    //       let respostaPreviaIndividual2 = res
    //       await verificarRespostas(
    //         sessionStorage.getItem("user"),
    //         Constantes.instrumentoStoryTellingIndividual,
    //         Constantes.moduloDinamicas,
    //         dinamicaId,
    //         this.props.rodada,
    //         this.props.grupo,
    //         projetoDinamica,
    //         this.props.idInstrumento
    //       )
    //         .then(async res => {
    //           let respostaPreviaGrupo = res;

    //           let dinamica = projetoDinamica.projeto.modulos.dinamicas[0]
    //           // let dinamica = projetoDinamica.data.modulos.dinamicas.find(
    //           // let dinamica = projetoDinamica.projeto.modulos.dinamicas.find(
    //           //   dinamica =>
    //           //     dinamica._id.toString() ===
    //           //     sessionStorage.getItem("atividade")
    //           // );
    //           let instrumento = dinamica.rodadas[this.props.rodada].instrumentos.find(
    //             intrumento => intrumento._id === this.props.idInstrumento
    //           )
              
    //           // Inicializando template
    //           const dados = template;
    //           const enunciado = dados.instrucoesGrupo

    //           // Inicializando tempos
    //           let tempos = instrumento.tempos && instrumento.tempos.grupo || {
    //             tempoFim: 0,
    //             tempoInicio: 0,
    //             tempoRestante: 0,
    //           };

    //           this.tempoTotal = (tempos.tempoFim - tempos.tempoInicio) || 0;
    //           tempos.tempoRestante = (tempos.tempoFim - this.tempoOnMount) || 0;
    //           if (tempos.tempoRestante < 0) tempos.tempoRestante = 0;

    //           // Inicializando e variaveis
    //           let frasesGrupo = []
    //           let frasesIndividual = []
    //           let iniciandoAtividade = false

    //           // Recuperando userId
    //           this.usuarioAtualId = sessionStorage.getItem("user")

    //           // Checa se secretario e define nome
    //           let secretario = false

    //           if (this.props.secretario === this.usuarioAtualId) {
    //             secretario = true
    //           }

    //           this.secretarioNome = this.props.geral.pessoas.find(
    //             p => p.id === this.props.secretario
    //           ).nome
    //           // ].pessoas.find(g => g.id === this.props.secretario).nome;

    //           // Checando respostas individuais
    //           let modalMesa = false
    //           if (respostaPreviaIndividual2) {
    //             if (respostaPreviaIndividual2.resposta.length) {
    //               frasesIndividual = respostaPreviaIndividual2.resposta
    //             } else {
    //               // Atualizando respostas e tempos
    //               dados.sentencas.map((dado, indice) => {
    //                 frasesIndividual.push({
    //                   id: `${indice}`,
    //                   sequencia: dado.sequencia,
    //                   content:
    //                     String.fromCharCode(97 + indice).toUpperCase() + ". " + dado.enunciado
    //                 })
    //               })
    //             }
    //           } else {
    //             var respostaPreviaIndividual = await verificarRespostas(
    //               sessionStorage.getItem("user"),
    //               Constantes.instrumentoStoryTellingIndividual,
    //               Constantes.moduloDinamicas,
    //               dinamicaId,
    //               this.props.rodada,
    //               -1,
    //               projetoDinamica,
    //               this.props.idInstrumento
    //             )

    //             // Inicializando respostas
    //             // Checando por não stop no storytelling individual
    //             if (respostaPreviaIndividual.resposta.length) {
    //               frasesIndividual = respostaPreviaIndividual.resposta
    //             } else {
    //               // Atualizando respostas e tempos
    //               dados.sentencas.map((dado, indice) => {
    //                 frasesIndividual.push({
    //                   id: `${indice}`,
    //                   sequencia: dado.sequencia,
    //                   content:
    //                     String.fromCharCode(97 + indice).toUpperCase() + ". " + dado.enunciado
    //                 })
    //               })
    //             }

    //             // Flagando incio de atividade
    //             iniciandoAtividade = true

    //             // Habilitando modals
    //             modalMesa = true
    //           }

    //           // Checando respostas em grupo
    //           if (respostaPreviaGrupo)
    //             // Atualizando respostas
    //             frasesGrupo = respostaPreviaGrupo.resposta
    //           else {
    //             // Atualizando respostas e tempos
    //             dados.sentencas.map((dado, indice) => {
    //               frasesGrupo.push({
    //                 id: `${indice}`,
    //                 sequencia: dado.sequencia,
    //                 content:
    //                   String.fromCharCode(97 + indice).toUpperCase() + ". " + dado.enunciado
    //               })
    //             })
    //           }

    //           // Inicializando variaveis de controle
    //           this.resultadoListaIndividual = frasesIndividual
    //           this.resultadoListaGrupo = frasesGrupo

    //           // Iniciando atividade
    //           if (iniciandoAtividade) this.gravaRespostas(respostaPreviaIndividual.pontuacao, 0, secretario)

    //           this.setState({
    //             dados,
    //             frasesGrupo,
    //             frasesIndividual,
    //             enunciado,
    //             secretario,
    //             loading: false,
    //             tempos,
    //             modalMesa,
    //             errorCount: 0
    //           })

    //           //this.subscribeToEvents();
    //         })
    //         // .catch(err => {
    //         //   if (err.response) console.error(err.response.data)
    //         //   // this.recebendoInfo();
    //         //   console.error(err)
    //         // })
    //     })
    //     // .catch(err => {
    //     //   if (err.response) console.error(err.response.data)
    //     //   // this.recebendoInfo();
    //     //   console.error(err)
    //     // })
    // })
    // .catch(err => {
    //   if (err.response) console.error(err.response.data)
    //   // this.recebendoInfo();
    //   console.error(err)
    // })
    } catch (err) {
      console.error(err);
      this.setState({ errorCount: this.state.errorCount + 1 });
      setTimeout(() => this.recebendoInfo(), 1000);
    }
    
  }

  reorderIndividual = (list, startIndex, endIndex) => {
    this.resultadoListaIndividual = list
    const [removed] = this.resultadoListaIndividual.splice(startIndex, 1)
    this.resultadoListaIndividual.splice(endIndex, 0, removed)
    return this.resultadoListaIndividual
  }

  reorderGrupo = (list, startIndex, endIndex) => {
    this.resultadoListaGrupo = list
    const [removed] = this.resultadoListaGrupo.splice(startIndex, 1)
    this.resultadoListaGrupo.splice(endIndex, 0, removed)
    return this.resultadoListaGrupo
  }

  subscribePush = () => {
    if (this.props.secretario !== sessionStorage.getItem("user")) {
      Constantes.io.on("Projeto:RespostaGrupo", data => {
        if (data.grupoIndex === this.props.grupo)
          this.setState({
            frasesGrupo: data.resposta
          })
      })
    }
    Constantes.io.on("Projeto:Stop", data => {
      if (
        data.instrumento === Constantes.instrumentoStoryTellingIndividual &&
        data.start.grupo === 0 &&
        data.rodada === this.props.rodada
      ) {
        this.props.history.go(); // refresh the page
      }
    })
    Constantes.io.on("Projeto:NovosTempos", data => {
      if (data.instrumento === Constantes.instrumentoStoryTellingIndividual) {
        let tempos = data.tempos.grupo
        //tempos.tempoRestante = tempos.tempoFim - srvTime()
        this.tempoTotal = tempos.tempoFim - tempos.tempoInicio;
        this.setState({
          tempos,
          intervalResetRequest: true
        });
        this.setState({ tempos })
      }
    })
    Constantes.io.on("Auth:LogoutInstrumento", userId => {
      if (sessionStorage.getItem("user") === userId.toString()) {
        this.onLogout = true
        if (this.state.tempos.tempoRestante > 0) this.setState({ pararTimer: true })
        else this.fimAtividade()
      }
    })
  }

  openInst = () => {
    this.setState({ modalInstrucoes: true })
  }

  cancelarMesa = () => {
    this.setState({ modalMesa: false })
    this.setState({ modalInstrucoes: true })
  }

  cancelarInst = () => {
    this.setState({ modalInstrucoes: false });
    if (!this.state.alreadyOpen) this.context.setRunTour(true);
    this.setState({ alreadyOpen: true })
  }

  enunciado = () => {
    const texto = this.state.enunciado
    var res = texto.split("~")
    if (this.state.secretario) {
      return (
        <div>
          Você foi nomeado para secretário do seu grupo!
          <br />
          <br />
          Aguarde a chegada de todos do Grupo e decidam qual a melhor ordem para essa históra. Após
          todos estarem de acordo, submeta novamente o storytelling.
          <br />
          <br />
          {res[0]}
          <br />
          <br />
          {res[1]}
          <br />
          <br />
          {res[2]}
          <br />
          <br />
        </div>
      )
    } else {
      return (
        <div>
          <br />
          <br />
          {res[0]}
          <br />
          <br />
          {res[1]}
          <br />
          <br />
          {res[2]}
          <br />
          <br />
        </div>
      )
    }
  }
  onDragEnd = async result => {
    if (!result.destination) {
      return
    }

    // Checando se resposta do grupo
    if (result.source.droppableId === droppableIdGrupo) {
      const frasesGrupo = this.reorderGrupo(
        this.state.frasesGrupo,
        result.source.index,
        result.destination.index
      )
      this.verificaResultado()
      this.setState({
        frasesGrupo
      })
    } else {
      const frasesIndividual = this.reorderIndividual(
        this.state.frasesIndividual,
        result.source.index,
        result.destination.index
      )
      this.verificaResultado()

      this.setState({
        frasesIndividual
      })
    }
  }

  verificaResultado = async () => {
    let somaIndividual = 0
    let somaGrupo = 0
    // Verificando por secretário
    if (this.state.secretario) {
      // Avaliando itens sem ser o último
      for (let i = 1; i < this.resultadoListaGrupo.length; i++) {
        if (
          parseInt(this.resultadoListaGrupo[i - 1].sequencia, 10) + 1 ===
          parseInt(this.resultadoListaGrupo[i].sequencia, 10)
        ) {
          somaGrupo++
        }
      }
      // Avaliando o primeiro
      //if (parseInt(this.resultadoListaGrupo[0].sequencia, 10) === 1) somaGrupo++
    }

    // Calculando resultado individual

    // Avaliando itens sem ser o último
    for (let i = 1; i < this.resultadoListaIndividual.length; i++) {
      if (
        parseInt(this.resultadoListaIndividual[i - 1].sequencia, 10) + 1 ===
        parseInt(this.resultadoListaIndividual[i].sequencia, 10)
      ) {
        somaIndividual++
      }
    }
    // Avaliando o primeiro
    //if (parseInt(this.resultadoListaIndividual[0].sequencia, 10) === 1) somaIndividual++

    // Gravando respostas
    this.gravaRespostas(somaIndividual, somaGrupo)
  }

  /**
   * Salva as respostas do secretário.
   */
  gravaRespostasSecretario = async resp => {
    // debugger
    let dinamicaId = sessionStorage.getItem("atividade")

    if (resp) {
      api
        .put(
          `api/projects/editRespostasSecretario/${sessionStorage.getItem("projeto")}/${dinamicaId}`,
          { ...resp }
        )
        .then(res => {
        })
        .catch(async err => {
          if (err) {
            console.error(err)
          }

          let info = {
            status: 4,
            ...resp,
            atividadeId: dinamicaId,
            projetoId: sessionStorage.getItem("projeto")
          }
          // reenviarResposta(info);
        })
    } else {
    }
  }

  gravaRespostas = async (somaIndividual, somaGrupo, secretario = false) => {
    // Atualizando tempos:
    let tempos = {
      tempoAtual: this.state.tempos.tempoRestante
    }
    const dinamicaId = sessionStorage.getItem("atividade")
    const atualizadoEm = srvTime()

    let respSecretario = {
      instrumentId: this.props.idInstrumento,
      instrumento: Constantes.instrumentoStoryTellingIndividual,
      pontuacao: somaGrupo,
      resposta: this.resultadoListaGrupo,
      grupoIndex: this.props.grupo,
      rodada: this.props.rodada
    }

    // salvando resposta do secretário, caso o participante seja o secretario.
    //if (this.state.secretario || secretario) this.gravaRespostasSecretario(respSecretario)

    // salvando resposta individual.
    let resp = {
      instrumentId: this.props.idInstrumento,
      resposta: this.resultadoListaIndividual,
      instrumento: Constantes.instrumentoStoryTellingIndividual2,
      rodada: this.props.rodada,
      tempos,
      atualizadoEm,
      pontuacao: somaIndividual,
      finalizado: false
    }

    // api
    //   .put(`api/user/respostas/modulo/${Constantes.moduloDinamicas}`, {
    //     resp,
    //     atividadeId: dinamicaId
    //   })
    //   .then(resPut => {
    //   })
    //   .catch(async err => {
    //     if (err) {
    //       console.error(err)
    //     }

    //     let info = {
    //       status: 1,
    //       resp,
    //       atividadeId: dinamicaId,
    //       modulo: Constantes.moduloDinamicas
    //     }
    //     reenviarResposta(info)
    //   })

    this.setState({ somaIndividual, somaGrupo })
  }

  sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  proximaAtividade = async () => {
    if (this.state.errorCount >= this.state.errorRetry) {
      return this.setState({ haveError: true, loading: false, modalConfirmacao: false });
    }
    try {
      this.setState({ loadingSpinner: true });
      await this.sleep(1500);
      const dinamicaId = sessionStorage.getItem("atividade")
      // await apiCalls.dinamica.saveInstrumentAnswer(
      //   Constantes.instrumentoStoryTellingIndividual2,
      //   this.props.idInstrumento,
      //   Constantes.moduloDinamicas,
      //   dinamicaId,
      //   this.props.rodada,
      //   sessionStorage.getItem("projeto")
      // );
      this.props.action();
    } catch (err) {
      console.error(err);
      this.setState({ errorCount: this.state.errorCount + 1 });
      setTimeout(() => this.proximaAtividade(), 1000);
    }
  }

  tempoAtualizado = tempo => {
    this.setState(previousState => ({
      tempos: {
        ...previousState.tempos,
        tempoRestante: tempo
      }
    }))
  }

  toggleConfirmacao = () => {
    this.context.setRunTour(false);
    if (!this.state.loadingSpinner) {
      this.setState(previousState => ({
        modalConfirmacao: !previousState.modalConfirmacao
      }))
    }
  }

  fimAtividade = () => {
    if (this.onLogout) this.props.logout()
    else {
      if (!this.onProximo) {
        this.onProximo = true
        if (!this.state.loadingSpinner) this.setState({ loadingSpinner: true })
        this.proximaAtividade()
      }
    }
  }

  callback = (data) => {
    if (data.status === 'finished' || data.action === 'close') {
        // This explicitly stops the tour (otherwise it displays a "beacon" to resume the tour)
        this.context.setRunTour(false);
    }
  }
  
  render() {
    if (this.state.haveError) return <ErrorComponent />;
    if (this.state.loading) {
      return <Loading />
    }
    return (
      <div className="text-left back" id="logo" tabIndex="0">
        <Joyride
          steps={this.state.steps}
          showProgress={true}
          run={this.context.runTour}
          continuous={true}
          callback={this.callback}
          disableOverlayClose={true}
          spotlightClicks={true}
          disableBeacon={true}
          locale={{
            back: 'Voltar',
            close: 'Fechar',
            last: 'Fim',
            next: 'Próximo',
            skip: 'Pular'
          }}
          styles={{
            options: {
              primaryColor: '#012a4a'
            },
            tooltipContainer: {
              textAlign: 'left',
            },
          }}
        />
        <Modal
          isOpen={this.state.modalMesa}
          className={this.props.className}>
          <ModalBody>
            <Instrucoes2>
              <div className="text-center">
                <h2>
                  No dia da dinâmica oficial, aqui aparecerá o nome do seu grupo. <br />
                </h2>
                <img src={require('../../Assets/_assets/mesa.gif')} style={{ width: '50%' }} />
              </div>
            </Instrucoes2>
          </ModalBody>
          <ModalFooter>
            <Container className="text-center">
              <Button color="success" onClick={this.cancelarMesa}>
                Clique aqui para continuar
              </Button>{" "}
            </Container>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modalInstrucoes}
          toggle={this.toggleInstrucoes}
          className={this.props.className}>
          <ModalBody>
            <Instrucoes2>
              <div>
              <P>Car@ participante, a seguir algumas instruções para que você possa realizar a entrega da atividade storytelling grupo. Lembrando que essas atividades são ilustrativas e não terão seus resultados considerados ou salvos no IAPP.</P>
              <P>As instruções a seguir são relacionadas exclusivamente ao teste atual. Na aplicação oficial, as instruções poderão ser diferentes.</P>
              <P><strong>Enunciado</strong></P>
              <P>Nesta etapa voc&ecirc; e seu grupo ter&atilde;o um tempo para analisar e decidir sobre a sequ&ecirc;ncia que melhor define a hist&oacute;ria apresentada. Contudo, para esse teste, apenas simularemos a entrega da etapa em grupo, sem a necessidade de se fazer a reuni&atilde;o e consenso sobre a entrega. Diferentemente da din&acirc;mica oficial, a qual ser&aacute; necess&aacute;rio que o grupo se reúna e em consenso defina a sequ&ecirc;ncia da hist&oacute;ria.</P>
              <P>As altera&ccedil;&otilde;es discutidas em grupo s&atilde;o registradas pelo participante definido como secret&aacute;rio da mesa na coluna ao lado esquerdo da tela. Nesta coluna apenas o secret&aacute;rio consegue alterar, contudo, todos os participantes acompanhar&atilde;o as atualiza&ccedil;&otilde;es feitas em tempo real nas suas pr&oacute;prias telas. Para esse teste, voc&ecirc; ser&aacute; definido como secret&aacute;rio e poder&aacute; fazer as altera&ccedil;&otilde;es para simula&ccedil;&atilde;o. Mas, na din&acirc;mica oficial, o secret&aacute;rio ser&aacute; definido por sorteio.</P>
              <P>Na coluna &agrave; direita da tela, aparece a hist&oacute;ria na sequ&ecirc;ncia definida por voc&ecirc; na etapa individual. A organiza&ccedil;&atilde;o dessa coluna s&oacute; voc&ecirc; v&ecirc;. Ficar&aacute; a seu crit&eacute;rio alterar sua sequ&ecirc;ncia individual da maneira que considerar mais adequada: (a) igual &agrave;quela que voc&ecirc;s definiram em equipe; (b) a mesma de que voc&ecirc; definiu originalmente; ou, ainda, (c) outra ordena&ccedil;&atilde;o que preferir.</P>
              <P><strong>Como enviar a hist&oacute;ria</strong></P>
              <P><strong>Entrega do grupo</strong></P>
              <P>O secret&aacute;rio ser&aacute; o respons&aacute;vel por registrar e enviar tanto a atividade do grupo quanto sua pr&oacute;pria atividade. Ao longo da discuss&atilde;o, o secret&aacute;rio pode alterar ambas as colunas &ndash; da esquerda (em consenso com o grupo) e da direita (escolhas individuais) (observar a manuten&ccedil;&atilde;o da denomina&ccedil;&atilde;o) Conclu&iacute;da a discuss&atilde;o em grupo, &eacute; importante que, ANTES DE ENVIAR, (i) o secret&aacute;rio consulte todo o grupo assegurando que a discuss&atilde;o se esgotou e o consenso foi atingido; e (ii) confira tamb&eacute;m se sua coluna individual contempla suas escolhas finais. Ao enviar a atividade, n&atilde;o poder&atilde;o ser feitas novas altera&ccedil;&otilde;es. </P>
              <P>Para esse teste, n&atilde;o teremos um grupo para discuss&atilde;o, dessa forma voc&ecirc; poder&aacute; enviar ordenando as duas colunas (em grupo e individual) da forma que lhe conv&eacute;m.</P>
              </div>
            </Instrucoes2>
          </ModalBody>
          <ModalFooter>
            <Container className="text-center">
              <Button color="success" onClick={this.cancelarInst}>
                ok
              </Button>{" "}
            </Container>
          </ModalFooter>
        </Modal>
        <Base>
          <Container>
            <br />
            <br />
            <br />
            <Row>
              <Col>
                <h1>Storytelling</h1>
              </Col>
              <Col className="text-right">
                <Icon src={require("../../Assets/_assets/doubt.png")} alt="Instrucoes" />
                <Inst onClick={this.openInst}>Instruções</Inst>
              </Col>
            </Row>
            <br />
            <br />
            <Modal
              isOpen={this.state.modalConfirmacao}
              toggle={this.toggleConfirmacao}
              className={this.props.className}
              onClosed={() => (this.onProximo = false)}>
              <ModalHeader toggle={this.toggleConfirmacao}>Storytelling</ModalHeader>
              <ModalBody>
                Se você estiver satisfeito com suas escolhas clique em prosseguir, se não clique em
                cancelar
              </ModalBody>
              <ModalFooter>
                {!this.state.loadingSpinner ? (
                  <div>
                    <Button
                      color="success"
                      onClick={() => {
                        this.state.tempos.tempoRestante > 0
                          ? this.setState({
                              loadingSpinner: true,
                              pararTimer: true
                            })
                          : this.fimAtividade()
                      }}
                      disabled={this.state.loadingSpinner}>
                      Prosseguir
                    </Button>{" "}
                    <Button
                      color="danger"
                      onClick={this.toggleConfirmacao}
                      disabled={this.state.loadingSpinner}>
                      Cancelar
                    </Button>
                  </div>
                ) : (
                  <img src={require(`../../Assets/_assets/${spinner}`)} />
                )}
              </ModalFooter>
            </Modal>

            <Row>
              <Col className="storytellinggrupo-step-2" sm="12">
                <Contador
                  counter={
                    <Timer
                      intervalResetRequest={this.state.intervalResetRequest}
                      intervalResetFeedback={() => this.setState({ intervalResetRequest: false })}
                      crescente={false}
                      tempo={2401201}
                      resetAutomatico={false}
                      tempoAtualizado={this.tempoAtualizado}
                      pararTimer={this.state.pararTimer}
                      resetar={false}
                      tempoParado={this.fimAtividade}
                      id="tempo"
                    />
                  }
                  tempo={2401201}
                />
              </Col>
            </Row>
            <br />
            <br />
            <p>Reorganizar ou manter após discussão do Grupo</p>
            <br />
            <br />
            <h2>Mesa: {this.state.mesaLabel}</h2>
            <br />
            <h2>Secretário: {this.state.secretario ? "Você" : this.secretarioNome} </h2>
            <Row>
              <Col className="storytellinggrupo-step-3" sm="6">
                {/* Secretário */}
                {this.state.secretario && (
                  <div>
                    <h3>
                      <b>Grupo</b>
                    </h3>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <Droppable droppableId={droppableIdGrupo}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            style={(getListStyle(snapshot.isDraggingOver), { scroll: "auto" })}>
                            {this.state.frasesGrupo.map((item, index) => (
                              <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}>
                                    <Story story={item.content} />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                      <br />
                    </DragDropContext>
                  </div>
                )}

                {/* Participante */}
                {!this.state.secretario && (
                  <div>
                    <h3>
                      <b>Grupo</b>
                    </h3>
                    {this.state.frasesGrupo.map((item, index) => (
                      <div key={index}>
                        <Story tipo="null" story={item.content} />
                      </div>
                    ))}
                  </div>
                )}
              </Col>
              <Col className="storytellinggrupo-step-4" sm="6">
                <h3>
                  <b>Individual</b>
                </h3>
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId={droppableIdIndividual}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                        {this.state.frasesIndividual.map((item, index) => (
                          <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}>
                                <Story story={item.content} />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  <br />
                </DragDropContext>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col className="text-center">
                <Button className="storytellinggrupo-step-5" onClick={this.toggleConfirmacao}>ENVIAR</Button>
                <Footer />
              </Col>
            </Row>
          </Container>
        </Base>
      </div>
    )
  }
}
export default withRouter(StorytellingGrupo);
