import { GlobalDebug } from "./utils/remove-console"
import React, { Component } from "react";
import Bowser from "bowser";
import { JoyrideContextProvider } from "./contexts/joyride";
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import CookieConsent from "react-cookie-consent";
import { HashRouter, Route, Switch } from "react-router-dom";
import AuthRoute from "./Services/authRoute";
import api from './Services/api';
// Containers
import Intro from "./Pages/Views/Intro";
import Login from "./Pages/Views/Login";
import Iniciar from "./Pages/Views/Iniciar";
import Assets from "./Pages/Assets/Assets";
import Fimprova from "./Pages/Instruments/Prova/Fimprova";
import Fim from "./Pages/Views/Fim";
import InvalidBrowser from "./components/InvalidBrowser";
import CookieConsentModal from "./components/CookieConsentModal";
import { Constantes } from "./Services/Constantes";
import { testarConexao } from "./Services/GerenciarConexao";


import Fimteste from "./Pages/TestsInstruments/Fimteste/Fimteste";

import 'react-tippy/dist/tippy.css';
import 'react-image-crop/dist/ReactCrop.css';

if (process.env.NODE_ENV === 'production') {
  GlobalDebug(false)
}

const steps = [];

class App extends Component {
  // Variavel para testar conexao do timer
  conexaoTimer;

  state = {
    isValidBrowser: true,
    cookieModal: false,
    blockTourNextBtn: false,
    verifyiedPolicy: false,
    consentVisible: false,
    blockTourPrevBtn: false,
  }

  componentDidMount = async () => {
    this.subscribePush();

    this.verifyToken();

    this.verifyPolicy();

    const browser = Bowser.getParser(window.navigator.userAgent);
    const isValidBrowser = browser.satisfies({
      chrome: ">=81.0.4000",
      firefox: ">=69",
      opera: ">=65",
      edge: ">=81.0",
      safari: ">=12"
    });

    const validBrowser = !browser.getBrowser().name.toLowerCase().includes('internet explorer') && isValidBrowser !== false
    this.setState({ isValidBrowser: validBrowser });

    window.addEventListener('hashchange', async() => {await this.verifyPolicy()});

    // Criando intervalo para check da conexão a cada 10 segundos
    this.conexaoTimer = setInterval(testarConexao, 10000);
  };
  componentWillUnmount = () => {

    clearInterval(this.conexaoTimer);
    window.removeEventListener('hashchange', async() => {await this.verifyPolicy()});
  };

  logout = () => {
    api
      .post("api/auth/logout", { userId: sessionStorage.getItem("user") })
      .then(() => {
        let projetoId = sessionStorage.getItem("projeto");
        sessionStorage.clear();
        this.props.history.push(`/projeto/${projetoId}`);
      })
      .catch(err => {
        if (err.response) console.log(err.response.data);
        this.logout();
      });
  };

  verifyToken = () => {
    if (window.location.href.includes('login') || window.location.href.includes('test')) return;

    if (!sessionStorage.getItem('token')) this.logout();
  }

  verifyPolicy = async() => {
    let splited = window.location.href.split('#');
    splited = splited[1].split('/');
    if (splited[1].includes('login')) {
      this.setState({ verifyiedPolicy: false, consentVisible: false });
    } else {
      if (!this.state.verifyiedPolicy) {
        const res = await api.get(`/v3/users/${sessionStorage.getItem('user')}/get-policy`);

        const { policy_agreement } = res.data;

        this.setState({ consentVisible: !policy_agreement, verifyiedPolicy: true });
      }
    }

  }

  subscribePush = () => {
    Constantes.io.on(`Auth:${Constantes.mensagemDeslogarTodos}`, userId => {
      console.log("Deslogando...", userId);
      if (sessionStorage.user === userId)
        if (!sessionStorage.getItem("userLogando")) {
          console.log("Aqui");
          sessionStorage.clear();
          window.location.reload();
        } else sessionStorage.setItem("userLogando", "");
    });
  };

  toggleCookieConsentModal = () => {
    this.setState({ cookieModal: !this.state.cookieModal });
  }

  onAcceptPolicy = async() => {
    const res = await api.put(`/v3/users/${sessionStorage.getItem('user')}/accept-policy`);

    const { success } = res.data;

    if (success) this.setState({ consentVisible: false });
  }

  render() {
    return (
      <>
      <CookieConsentModal isOpen={this.state.cookieModal} toggle={this.toggleCookieConsentModal} />
      <CookieConsent
        location="bottom"
        buttonText="Ok"
        onAccept={this.onAcceptPolicy}
        visible={this.state.consentVisible ? 'show' : 'hidden'}
        overlay={true}
        cookieName={'CookieConsentIappParticipant'}
        style={{ background: "#2B373B", justifyContent: 'center', zIndex: 1050 }}
        contentStyle={{ flex: 'none' }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        overlayStyle={{ zIndex: 1049 }}
      >
        Para prosseguir, precisamos que leia e aceite a nossa <a onClick={this.toggleCookieConsentModal} href="javascript:void(0)">Política de Privacidade</a>. Ao clicar em "Ok", você consente que está de acordo.
      </CookieConsent>
      {!this.state.isValidBrowser ? (
        <InvalidBrowser />
      ) : null}
      {/* <TourProvider
        disableFocusLock={true}
        disableInteraction={false}
        className='remove-btn-focus-outline'
        nextButton={({ Button, ...props }) => {
          return <span onClick={() => {
            if ((props.currentStep >= props.stepsLength - 1) || this.state.blockTourNextBtn) return;
            props.setCurrentStep(props.currentStep + 1);
          }}><FaArrowRight style={{ cursor: 'pointer', opacity: props.currentStep >= props.stepsLength - 1 ? '40%' : '100%' }} size={14} /></span>

        }}
        prevButton={({ Button, ...props }) => {
          return <span onClick={() => {
            if ((props.currentStep <= 0) || this.state.blockTourPrevBtn) return;
            props.setCurrentStep(props.currentStep - 1);
          }}><FaArrowLeft style={{ cursor: 'pointer', opacity: props.currentStep <= 0 ? '40%' : '100%' }} size={14} /></span>
        }}
        position='right'
        setBlockTourNextBtn={(bool) => {this.setState({ blockTourNextBtn: bool })}}
        blockTourNextBtn={this.state.blockTourNextBtn}
        setBlockTourPrevBtn={(bool) => {this.setState({ blockTourPrevBtn: bool })}}
        blockTourPrevBtn={this.state.blockTourPrevBtn}
        beforeClose={() => {
          this.setState({ blockTourNextBtn: false, blockTourPrevBtn: false});

          document.body.style.overflow = 'visible'
        }}
        afterOpen={() => document.body.style.overflow = 'hidden'}
        steps={steps}> */}
      <JoyrideContextProvider>
      <HashRouter>
        <Switch>
          <Route path={`/login`} name="Login no Projeto " component={Login} />
          <Route
            exact
            path="/fimdeprova"
            name="Fim Prova"
            component={Fimprova}
          />
          <Route
            exact
            path="/fimteste"
            name="Fim Teste"
            component={Fimteste}
          />
          <Route
            exact
            path="/concluido"
            name="Fim de atividade"
            component={Fim}
          />
          <Route exact path="/assets" name="Login Page" component={Assets} />
          <AuthRoute exact path="/iniciar" name="Iniciar" component={Iniciar} />
          <AuthRoute path="/" name="Home" component={Intro} />
        </Switch>
      </HashRouter>
      </JoyrideContextProvider>
      {/* </TourProvider> */}
      </>
    );
  }
}

export default App;
