import apiCalls from '../../../../config/apiCalls';

export default async (groupName, idInstrumento,idProjeto) => {
    const progresso = await apiCalls.case.getEscolhasGrupo(
        idInstrumento,
        {
            queryParams: {
                group: groupName,
                idInstrumento: idInstrumento,
                idProjeto: idProjeto
            }
        });
    return progresso;
}
