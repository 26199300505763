import React, { createContext, useState } from 'react';

const JoyrideContext = createContext({
    runTour: false,
    haveTour: false,
    setRunTour: () => {},
    setHaveTour: () => {}
});

const JoyrideContextProvider = function(props) {
    const [haveTour, setHaveTour] = useState(false);
    const [runTour, setRunTour] = useState(false);

    return (
      <JoyrideContext.Provider value={{
        haveTour,
        runTour,
        setRunTour,
        setHaveTour
      }}>
        {props.children}
      </JoyrideContext.Provider>
    );
}

export { JoyrideContextProvider };

export default JoyrideContext;