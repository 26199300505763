import { toast } from "react-toastify";

export function notifyError(mensagem) {
  toast.error(mensagem, {
    position: toast.POSITION.TOP_RIGHT
  });
}
export function notifySuccess(mensagem) {
  toast.success(mensagem, {
    position: toast.POSITION.TOP_RIGHT
  });
}
