import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';

import { HeaderLogged, HeaderLogged2, HeaderLoggedMobile } from '../Assets/Assets';

import capitalizeFirstLetterEachWord from '../../utils/capitalizeFirstLetterEachWord';

class Header extends Component {
  state = {
    isOpen: false,
    modalConfirmação: false
  };
  toggle = () => {
    this.setState((previousState) => ({
      modalConfirmação: !previousState.modalConfirmação
    }));
  };

  logout = () => {
    this.setState({
      modalConfirmação: true
    });
  };

  logoutConfirmado = () => {
    this.props.onLogout();
    this.setState({
      modalConfirmação: false
    });
  };

  cancelar = () => {
    this.setState({
      modalConfirmação: false
    });
  };

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.modalConfirmação}
          toggle={this.toggle}
          className={this.props.className}
          style={{ zIndex: '99999999' }}
        >
          <ModalHeader toggle={this.toggle}>Logout</ModalHeader>
          <ModalBody>Você tem certeza que deseja fazer o logout?</ModalBody>
          <ModalFooter>
            <Button color="success" onClick={this.logoutConfirmado}>
              Logout
            </Button>{' '}
            <Button color="danger" onClick={this.cancelar}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
        <HeaderLogged2
          name={capitalizeFirstLetterEachWord(sessionStorage.getItem('username').toLowerCase())}
          action={this.logout}
        />
        <br />
        <br />
        {/* <Navbar color="light" light expand="md">
          <NavbarBrand href="/">Iapp</NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink>
                  <i className="fa fa-trophy" /> Ranking: 20pts -{" "}
                </NavLink>
              </NavItem>
              <NavItem>
                <img
                  src={sessionStorage.getItem("foto")}
                  className="img-avatar"
                  alt="admin@bootstrapmaster.com"
                />
              </NavItem>

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {sessionStorage.getItem("username")}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    Meus Dados <Badge color="danger">1</Badge>
                  </DropdownItem>

                  <DropdownItem onClick={this.logout}>Logout</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar> */}
      </div>
    );
  }
}
export default Header;
