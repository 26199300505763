import styled from "styled-components";

export const Enunciado = styled.div`
  p{
    font-size: ${props => props.estilo};
  }
 
  p:first-child{
        ::before{
            content: "${props => props.contador}";
            font-weight:bold;
            
        }
    }
`;

/// FORMATACAO INSTRUCOES
export const Instrucoes = styled.div`
  font-size: 14px;
  padding: 20px;
  div {
    text-align: justify;
  }
  b {
    font-size: 16px;
  }
`;
export const Instrucoes2 = styled.div`
  font-size: 14px;
  padding: 1rem;
  div {
    text-align: left;
  }
  b {
    font-size: 16px;
  }
`;
export const Inst = styled.button`
  background: transparent;
  border: 0px;
`;
