export default class Criterio {
    _id = undefined;
    views = 0;
    score = 0;
    value = "";
    group = undefined;
    author = undefined;
    instrumentId = undefined;
    authorName = undefined;

    constructor(id, authorId, instrumentId, tableName, authorName) {
        this._id = id;
        this.author = authorId;
        this.instrumentId = instrumentId;
        this.group = tableName;
        this.authorName = authorName;
    }

    setId(id) {
        try {
            this._id = id;   
        } catch (error) {
            console.error(error);
        }
    }
}