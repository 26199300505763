import React, { Component } from "react";
import Joyride from 'react-joyride';
import JoyrideContext from "../../../contexts/joyride";
import Draggable from "react-draggable";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Container,
  Row,
  Col
} from "reactstrap";
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import {
  Base,
  Inst,
  Instrucoes2,
  Button,
  Contador,
  Icon,
  Titulo,
  Footer
} from "../../Assets/Assets";
import Loading from "../../../Services/Loading";
import styled from "styled-components";
import Timer from "../../../Services/Timer";
import { millisToMinutesAndSeconds } from "../../../Pages/Instruments/Utils/Calculos";
import { srvTime } from '../Utils';
import './AvaliacaoBinaria.module.css';
import styles from './AvaliacaoBinaria.module.css';
import ErrorComponent from '../../../Services/ErrorComponent';
import ErrorBoundary from '../../../components/error-boundary';

import { Constantes } from "../../../Services/Constantes";
import fetchInstrumentoCase from './utils/fetch-instrumento-case';
import { converterMsParaTempo } from "../../../Pages/Instruments/Utils/Calculos";
import { notifyError } from "../../../Services/Notificacoes";
import Messages from '../../../static/messages';
import { TourP } from "../../../utils/TourUtils";

const Handle = styled.div`
  background-color: #00294c;
  padding: 30px 15px;
  border-radius: 7px;
  box-shadow: 0px 0px 10px 1px rgba(0,41,76,1);
  word-wrap: break-word;
  p {
    color: #fafafa;
    margin: 0;
    font-weight: bolder;
    font-size: 1.2rem;
    text-align: justify;
  }
`;

const Switch = styled.div`
  width: 60%;
  position: relative;
  @media (max-width: 660px) {
    width: 60%;
  }
`;

const P = styled.p`
  font-size: 13px;
`;
// Imagens
const spinner = Constantes.imagemSpinner;

function Duel(props) {

  return (
    <>
    <Row>
      <Col sm="12" className="text-center">
        <Switch style={{ cursor: 'pointer' }}>
          <div className={props.styles.choosenOption}>Opção selecionada</div>
          <Draggable
            axis="x"
            handle=".handle"
            defaultPosition={{ x: 0, y: 0 }}
            position={{ x: 0, y: 0 }}
            scale={1}
            bounds={{ left: 0, top: 0, right: 300, bottom: 0 }}
            onStop={(e, ui) =>
              props.handleStop(
                e,
                ui,
                props.dueloAtual.resultadoEmbates[0]._id)}
          >
            <Handle className="handle">
              <p>{props.dueloAtual.resultadoEmbates[0].value}</p>
            </Handle>
          </Draggable>
        </Switch>
      </Col>
    </Row>
    <br />
    <br />
    <Row>
      <Col sm="12" className="text-center">
        <Switch style={{ cursor: 'pointer' }}>
          <div className={props.styles.choosenOption}>Opção selecionada</div>
          <Draggable
            axis="x"
            handle=".handle"
            defaultPosition={{ x: 0, y: 0 }}
            position={{ x: 0, y: 0 }}
            scale={1}
            bounds={{ left: 0, top: 0, right: 275, bottom: 0 }}
            onStop={(e, ui) =>
              props.handleStop(
                e,
                ui,
                props.dueloAtual.resultadoEmbates[1]._id)
            }>
            <Handle className="handle">
              <p>{props.dueloAtual.resultadoEmbates[1].value}</p>
            </Handle>
          </Draggable>
        </Switch>
      </Col>
    </Row>
    </>
  );
}

Duel.propTypes = {
  
  styles: PropTypes.object,
  dueloAtual: PropTypes.array,

  handleStop: PropTypes.func,
}

class AvaliacaoBinaria extends Component {
  static contextType = JoyrideContext;

  state = {
    loading: true,
    currentEmbateIndex: 0,
    tempos: {
      tempoRestante: 0
    },
    pararTimer: false,
    modalInstrucoes: true,
    modalConfirmacao: false,
    dueloAtual: {},
    hasError: false,
    votos: 0,
    loadingSpinner: false,
    intervalResetRequest: false,
    haveError: false,
    errorCount: 0,
    errorRetry: 10,
    embatesToUse: [{
      resultadoEmbates: [
        {
            "views": 0,
            "score": 0,
            "value": "Agilidade na entrega do pedido",
            "_id": "6268338887d04974773e70b5",
            "group": "Azul",
            "author": "6074983b7ef518352e709063",
            "pendingAnswer": false
        },
        {
            "views": 1,
            "score": 0,
            "value": "Opções para escolha do queijo",
            "_id": "6268338887d04974773e70b6",
            "group": "Azul",
            "author": "6074983b7ef518352e709063",
            "pendingAnswer": false,
            "d": 0
        }
      ],
      finalizado: false,
      refresh: false,
      clashId: '1_2'
    },
    {
      resultadoEmbates: [
        {
            "views": 0,
            "score": 0,
            "value": "Batata frita caseira",
            "_id": "6268338887d04974773e70b5",
            "group": "Azul",
            "author": "6074983b7ef518352e709063",
            "pendingAnswer": false
        },
        {
            "views": 1,
            "score": 0,
            "value": "Pão fresco e macio selado internamente",
            "_id": "6268338887d04974773e70b6",
            "group": "Azul",
            "author": "6074983b7ef518352e709063",
            "pendingAnswer": false,
            "d": 0
        }
      ],
      finalizado: false,
      refresh: false,
      clashId: '3_4'
    },
    {
      resultadoEmbates: [
        {
            "views": 0,
            "score": 0,
            "value": "Opções de escolha para o ponto do cocção da carne",
            "_id": "6268338887d04974773e70b5",
            "group": "Azul",
            "author": "6074983b7ef518352e709063",
            "pendingAnswer": false
        },
        {
            "views": 1,
            "score": 0,
            "value": "Ingredientes frescos de produtores locais",
            "_id": "6268338887d04974773e70b6",
            "group": "Azul",
            "author": "6074983b7ef518352e709063",
            "pendingAnswer": false,
            "d": 0
        }
      ],
      finalizado: false,
      refresh: false,
      clashId: '5_6'
    }, {
      resultadoEmbates: [
        {
            "views": 0,
            "score": 0,
            "value": "Tamanho ideal do lanche",
            "_id": "6268338887d04974773e70b5",
            "group": "Azul",
            "author": "6074983b7ef518352e709063",
            "pendingAnswer": false
        },
        {
            "views": 1,
            "score": 0,
            "value": "Hamburguer suculento",
            "_id": "6268338887d04974773e70b6",
            "group": "Azul",
            "author": "6074983b7ef518352e709063",
            "pendingAnswer": false,
            "d": 0
        }
      ],
      finalizado: true,
      refresh: false,
      clashId: '7_8'
    }],
    steps: []
  }

  // Variaveis
  numeroVotosUsuario = 0
  proCriterio = []
  conCriterio = []
  duelos = []
  onLogout = false
  // Tempos
  tempoOnMount = srvTime()
  tempoTotal = 0

  static propTypes = {
    tableName: PropTypes.string.isRequired,
    idInstrumento: PropTypes.string.isRequired,
    rodada: PropTypes.number.isRequired,
    idUser: PropTypes.string.isRequired,
    idDinamica: PropTypes.string.isRequired,
    idProjeto: PropTypes.string.isRequired,
  }

  // Funções de estado
  async componentDidMount() {
    try {
      this.setState({ loading: true });
      //this.subscribePush()
      //await this.recebendoInfo()
      await this.configurarEmbate();

      const { setHaveTour, setRunTour } = this.context;

      const steps= [
        {
          target: '.binario-step-1',
          content: <TourP> Aqui você acompanha o tempo restante para a conclusão desta atividade</TourP>,
          disableBeacon: true
        },
        {
          target: '.binario-step-2',
          content: <TourP>Serão apresentados 2 critérios e você deve escolher o mais relevante para você. Para isso, clique e
          arraste para a direita o critério mais importante para você. Arraste até que a frase “Opção
          Selecionada” fique totalmente visível. Continue até que todos os critérios sejam definidos</TourP>,
          disableBeacon: true
        },
      ];
      setHaveTour(true);
      setRunTour(false);

      this.setState({ steps });
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ loading: false });

    }
  }

  componentWillUnmount = () => {
    Constantes.io.off("Projeto:Stop")
    Constantes.io.off("Projeto:NovosTempos")
    Constantes.io.off("Auth:LogoutInstrumento")
  }

  /**
   * verifica se o binário terminou com base na resposta enviada pelo servidor
   * ao consultar um novo embate.
   * 
   * @param {Obj} serverResponse 
   */
  checkBinarioFinished(serverResponse) {
    if (
      serverResponse.hasOwnProperty("finalizado") &&
      serverResponse.finalizado === true) {
        return true;
    } else {
      return false;
    }
  }

  /**
   * Finaliza o binário.
   */
  endBinario = async () => {
    try {
      const idUser = sessionStorage.getItem("user") || this.props.idUser;
      const idInstrumento = this.props.idInstrumento;
      const idProjeto = this.props.idProjeto;
      const idDinamica = this.props.idDinamica;
      const indexRodadaAtual = this.props.rodada;
      
      // await apiCalls.case.calculaBinario(idInstrumento);
      // await apiCalls.case.finalizarBinario({
      //   queryParams: {
      //     idUser,
      //     idInstrumento,
      //     idProjeto,
      //     indexRodadaAtual,
      //     idDinamica
      //   }
      // });
      
      this.props.action(); // DEVE CHAMAR ESSA FUNÇÃO
    } catch (error) {
      console.error(error); 
      this.setState({ errorCount: this.state.errorCount + 1 });
      setTimeout(() => this.endBinario(), 1000);
    }
  }

  configurarEmbate = async () => {
    try {
      const group = this.props.tableName;
      const idUser = sessionStorage.getItem("user") || this.props.idUser;
      const idInstrumento = this.props.idInstrumento;
      // const embate = await apiCalls.binary.getNovoDuelo({
      //   queryParams: {
      //     group,
      //     idUser,
      //     idInstrumento,
      //     projectId: sessionStorage.getItem("projeto"), 
      //     dynamicId: sessionStorage.getItem("atividade"), 
      //     roundIndex: this.props.rodada
      //   }
      // });
      const embate = this.state.embatesToUse[0];
      const isFinalizado = this.checkBinarioFinished(embate);
      if (isFinalizado) {
        this.setState({
          loading: true,
          errorCount: 0
        })
        await this.endBinario();
      } else {
        this.setState({
          dueloAtual: embate,
          modalInstrucoes: true,
          embate,
          loading: false,
          errorCount: 0
        });
      }

    } catch (err) {
      console.error(err);
      notifyError("Não foi possível consultar o próximo embate!");
    }
  }

  recebendoInfo = async () => {
    if (this.state.errorCount >= this.state.errorRetry) {
      return this.setState({ haveError: true, loading: false });
    }
    try {
      const instrumento = await fetchInstrumentoCase(this.props);
      
      let tempos = instrumento.tempos.binario;
      this.tempoTotal      = tempos.tempoFim - tempos.tempoInicio;
      tempos.tempoRestante = tempos.tempoFim - this.tempoOnMount;
      
      if (tempos.tempoRestante < 0) tempos.tempoRestante = 0

        this.setState({
          tempos,
          errorCount: 0
        });
    } catch (err) {
      console.error(err);
      this.setState({ errorCount: this.state.errorCount + 1 });
      setTimeout(() => this.recebendoInfo(), 1000);
    }




   
    // const usuarioId = sessionStorage.getItem("user");
    // const progressoBinario = await apiCalls.binary.getProgressoBinario(
    //   usuarioId
    // );
    // if (progressoBinario.binarioFinalizado) {
    // } else {
    //     const dueloBinario = await apiCalls.binary.getNovoDuelo();
    //     this.numeroVotosUsuario = this.duelos.length
    //     const instrumento = await fetchInstrumentoCase(this.props);
    //     // Inicializando tempo
    //     let tempos = instrumento.tempos.binario
    //     this.tempoTotal = tempos.tempoFim - tempos.tempoInicio
    //     tempos.tempoRestante = tempos.tempoFim - this.tempoOnMount
    //     if (tempos.tempoRestante < 0) tempos.tempoRestante = 0
    //     if (!progressoBinario.duelosVisualizados || 
    //         (progressoBinario.duelosVisualizados && 
    //           !progressoBinario.duelosVisualizados.length)) {
    //       this.setState({
    //         modalInstrucoes: true
    //       });
    //     }
    //     this.setState({
    //       dueloAtual: dueloBinario
    //     });
    // }
    // this.setState({ loading: false });
  }

  subscribePush = () => {
    Constantes.io.on("Projeto:Stop", data => {
      if (
        data.instrumento === Constantes.instrumentoCaseIndividual &&
        data.start.binario === 0 &&
        data.rodada === this.props.rodada
      ) {
        this.props.history.go();
      }
    })
    Constantes.io.on("Projeto:NovosTempos", data => {
      if (data.instrumento === Constantes.instrumentoCaseIndividual) {
        let tempos = data.tempos.binario;
        //tempos.tempoRestante = tempos.tempoFim - srvTime();
        this.tempoTotal = tempos.tempoFim - tempos.tempoInicio;
        this.setState({
          tempos,
          intervalResetRequest: true
        });
      }
    });
    Constantes.io.on("Auth:LogoutInstrumento", userId => {
      if (sessionStorage.getItem("user") === userId.toString()) {
        this.onLogout = true
        if (this.state.tempos.tempoRestante > 0) this.setState({ pararTimer: true })
        else {
          console.log("Auth:LogoutInstrumento");
        }
      }
    })
  }

  handleStop = async (e, ui, idCriterioAvaliado) => {
    const maxX = window.screen.width <= 450 ? 175 : 275;
    if (ui.x >= maxX) {
      await this.handleCriterioEscolhido(idCriterioAvaliado);
    }
  }

  sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  // Funções de interação
  handleCriterioEscolhido = async idCriterioAvaliado => {
      this.setState({
        modalConfirmacao: true
      });
      await this.sleep(1000);

      await this.gravarResposta(idCriterioAvaliado);
  }

  saveUserResponse = async (selectedCriterionId) => {
    const idUser = sessionStorage.getItem("user") || this.props.idUser;
    const idProjeto = sessionStorage.getItem("projeto") || this.props.idProjeto;
    const idDinamica = sessionStorage.getItem("atividade") || this.props.idDinamica;
    const idInstrumento = this.props.idInstrumento;

    // await apiCalls.binary.saveUserResponse({
    //   reqBody: {
    //     selectedCriterionId
    //   },
    //   queryParams: {
    //     idUser,
    //     idProjeto,
    //     idDinamica,
    //     idInstrumento
    //   }
      
    // });
    // await apiCalls.case.calculaBinario(idInstrumento);
  }

  gravarResposta = async (selectedCriterionId) => {
    if (this.state.errorCount >= this.state.errorRetry) {
      notifyError("Erro: não foi possível salvar a resposta na base");
      return this.setState({ modalConfirmacao: false, errorCount: 0 });
    }
    try {
      //await this.saveUserResponse(selectedCriterionId);
      await this.avaliarProxima();
      this.setState({ errorCount: 0 });
    } catch (error) {
      console.error(error);
      this.setState({ errorCount: this.state.errorCount + 1 });
      setTimeout(() => this.gravarResposta(selectedCriterionId), 1000);
    } finally {
      this.setState({
        modalConfirmacao: false
      })
    }
  }

  /**
   * carrega um novo embate.
   * termina a atividade caso o backend retorne finalizado == true no payload.
   */
  avaliarProxima = async () => {
    this.context.setRunTour(false);
    const group = this.props.tableName;
    const idUser = sessionStorage.getItem("user") || this.props.idUser;
    const idInstrumento = this.props.idInstrumento;
    try {
      // const novoEmbate = await apiCalls.binary.getNovoDuelo({
      //   queryParams: {
      //     group,
      //     idUser,
      //     idInstrumento,
      //     projectId: sessionStorage.getItem("projeto"), 
      //     dynamicId: sessionStorage.getItem("atividade"), 
      //     roundIndex: this.props.rodada
      //   }
      // });
      const novoEmbate = this.state.embatesToUse[this.state.currentEmbateIndex + 1];
      const isFinalizado = this.checkBinarioFinished(novoEmbate);
      if (isFinalizado) {
        this.setState({
          loading: true
        });
        await this.endBinario();
      } else {
        this.setState({
          dueloAtual: novoEmbate,
          currentEmbateIndex: this.state.currentEmbateIndex + 1
        });
      }
    } catch (error) {
      console.error(error);
      notifyError("Não foi possível consultar o próximo embate!");
    }
  }

  tempoAtualizado = tempo => {
    this.setState(previousState => ({
      tempos: {
        ...previousState.tempos,
        tempoRestante: tempo
      }
    }))
  }

  toggleInstrucoes = () => {
    if (this.state.modalInstrucoes && !this.state.alreadyOpened) {
      this.context.setRunTour(true);
      this.setState({ alreadyOpened: true });
    } 
    this.setState(previousState => ({
      modalInstrucoes: !previousState.modalInstrucoes
    }))
  }

  toggleConfirmacao = () => {
    this.setState(previousState => ({
      modalConfirmacao: !previousState.modalConfirmacao
    }))
  }

  // deverá ser refatorado no futuro
  scrollTop = () => {
    setTimeout(() => {
      window.scrollTo(0,0);
    }, 25);
  }

  callback = (data) => {
    if (data.status === 'finished' || data.action === 'close') {
        // This explicitly stops the tour (otherwise it displays a "beacon" to resume the tour)
        this.context.setRunTour(false);
    }
  }

  render() {
    if (this.state.haveError) return <ErrorComponent />;
    if (this.state.loading) return <Loading />;
    if (this.state.hasError) return <h1>Erro ao carregar atividade</h1>;

    return (
      <div className="text-left back" id="logo" tabIndex="0">
        <Joyride
          showProgress={true}
          steps={this.state.steps}
          run={this.context.runTour}
          continuous={true}
          callback={this.callback}
          disableOverlayClose={true}
          spotlightClicks={true}
          disableBeacon={true}
          locale={{
            back: 'Voltar',
            close: 'Fechar',
            last: 'Fim',
            next: 'Próximo',
            skip: 'Pular'
          }}
          styles={{
            options: {
              primaryColor: '#012a4a'
            },
            tooltipContainer: {
              textAlign: 'left',
            },
          }}
        />
        <Modal
          isOpen={this.state.modalConfirmacao}
          toggle={this.toggleConfirmacao}
          className={this.props.className}
          returnFocusAfterClose={false}
          >
          <ModalHeader>Critérios</ModalHeader>
          <ModalBody>Enviando escolha...</ModalBody>
          <ModalFooter>
            <img src={require(`../../Assets/_assets/${spinner}`)} />
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalInstrucoes}
          className={this.props.className}>
          <ModalBody>
            <Instrucoes2>
              <div>
                <P>Car@ participante, a seguir algumas instruções para que você possa realizar a entrega da atividade case binário. Lembrando que essas atividades são ilustrativas e não terão seus resultados considerados ou salvos no IAPP.</P>
                <P>As instruções a seguir são relacionadas exclusivamente ao teste atual. Na aplicação oficial, as instruções poderão ser diferentes.</P>
                <P><strong>Enunciado</strong></P>
                <P>Lembrando que essa atividade &eacute; ilustrativa e n&atilde;o ter&atilde;o seus resultados considerados ou salvos no IAPP.</P>
                <P>Para esta atividade, ser&atilde;o apresentados pares de crit&eacute;rios fake. Em cada compara&ccedil;&atilde;o voc&ecirc; dever&aacute; indicar qual crit&eacute;rio considera mais relevante. Ap&oacute;s sua escolha, o sistema selecionar&aacute; mais dois crit&eacute;rios para voc&ecirc; avaliar, e assim sucessivamente.</P>
                <P>** Na din&acirc;mica oficial os crit&eacute;rios ser&atilde;o selecionados dentre os crit&eacute;rios criados por participantes de todos os grupos, n&atilde;o apenas de seu grupo.</P>
                <P><strong>Como escolher o crit&eacute;rio mais relevante</strong></P>
                <P>Acesso com mouse</P>
                <P>Posicione o mouse sobre o crit&eacute;rio escolhido, clique e segure clicado, arraste o crit&eacute;rio para a direita o m&aacute;ximo que puder e solte o bot&atilde;o do mouse.</P>
                <P>Acesso com touch</P>
                <P>Encoste o dedo na tela do seu aparelho sobre o crit&eacute;rio, arraste o crit&eacute;rio para a direita o m&aacute;ximo que puder e tire o dedo da tela.</P>
              </div>
            </Instrucoes2>
          </ModalBody>
          <ModalFooter>
            <Container className="text-center">
              <Button color="success" onClick={this.toggleInstrucoes}>
                ok
              </Button>{" "}
            </Container>
          </ModalFooter>
        </Modal>
        <Base>
          <Container>
            <br />
            <br />
            <br />
            <Row>
              <Col>
                <h1>Definições de Critérios</h1>
              </Col>
              <Col className="text-right">
                <Icon src={require("../../Assets/_assets/doubt.png")} alt="Instrucoes" />
                <Inst onClick={this.toggleInstrucoes}>Instruções</Inst>
              </Col>
            </Row>
            <br />
            <Row>
              <Col className="binario-step-1" sm="6">
                <Contador
                  counter={
                    <Timer
                      intervalResetRequest={this.state.intervalResetRequest}
                      intervalResetFeedback={() => this.setState({ intervalResetRequest: false })}
                      crescente={false}
                      tempo={601200}
                      resetAutomatico={false}
                      tempoAtualizado={this.tempoAtualizado}
                      pararTimer={this.state.pararTimer}
                      resetar={false}
                      tempoParado={() => console.log("timer finalizado")}
                      id="tempo"
                    />
                  }
                  tempo={601200}
                />
              </Col>
            </Row>
            <br />
            <br />

            <div className="binario-step-2">
              <Titulo>
                Defina os critérios mais importantes para você:
              </Titulo>
              <p>
                <span className={styles.bold}>*Arraste para direita o critério que acha mais importante para você</span>
              </p>
              <br />
              <ErrorBoundary errorComponent={() => (
                <div>
                  <small>{Messages.msgErrorLoadActivity}</small> <br />
                  <Button onClick={() => window.location.reload(false)}>
                      Recarregar página
                  </Button>
                </div>
              )}>
                <Duel styles={styles}
                  dueloAtual={this.state.dueloAtual}
                  handleStop={this.handleStop} />
              </ErrorBoundary>
            </div>
            <Footer />
          </Container>
        </Base>
      </div>
    )
  }
}
export default withRouter(AvaliacaoBinaria);
