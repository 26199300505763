import apiCalls from '../../../../config/apiCalls';

export default async (idUser, idInstrumento, group) => {
    const progresso = await apiCalls.case.getEscolhasUser({
        queryParams: {
          idUser: idUser,
          idInstrumento: idInstrumento,
          group
        }
      });
      return progresso.progressoFaseGrupo;
}
