import React, { Component } from "react";
import Joyride from 'react-joyride';
import JoyrideContext from "../../../contexts/joyride";
import { DragDropContext } from "react-beautiful-dnd";
import styled from "styled-components";
import Column from "./Drop";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Container,
  Row,
  Col
} from "reactstrap";
import { Constantes } from "../../../Services/Constantes";
import { reenviarResposta } from "../../../Services/GerenciarConexao";
import Timer from "../../../Services/Timer";
import { verificarRespostas } from "../Utils/verificarRespostas";
import Loading from "../../../Services/Loading";
import { millisToMinutesAndSeconds } from "../Utils/Calculos";
import { srvTime } from '../Utils';
import { montaPaginas } from "../Utils";
import ErrorComponent from '../../../Services/ErrorComponent';

import {
  Base,
  Inst,
  Instrucoes2,
  Button,
  Contador,
  Icon,
  Story,
  Clear,
  Qst,
  Footer
} from "../../Assets/Assets";
import { notifyError } from "../../../Services/Notificacoes";

import { withRouter } from 'react-router';
import { TourP } from "../../../utils/TourUtils";


// Imagens
const spinner = Constantes.imagemSpinner;

var json = {};
var current = json;
var columnJson = {};
var col = columnJson;
var ordem = [];
var total = [];
var resposta;
var valor = {};
var contDesenvolvedor = 0;

var initialData = {
  tasks: {
    e: { id: "e", content: "" }
  },
  columns: {
    participantes: {
      id: "participantes",
      title: "Participantes",
      estilo: "Participantes",
      taskIds: ["e"]
    }
  },
  // Facilitate reordering of the columns
  columnOrder: ["participantes"]
};

var autoritarioResp = [];
var diretivoResp = [];
var modeladorResp = [];
var participativoResp = [];
var afetivoResp = [];
var desenvolvedorResp = [];

/// MEDE A MATURIDADE ANTERIOR PELO CASE
///

let estilos = {
  autoritario: [],
  diretivo: [],
  modelador: [],
  participativo: [],
  afetivo: [],
  desenvolvedor: [],
  laissezFaire: []

};

var sumAut = 0;
var sumDir = 0;
var sumMod = 0;
var sumPart = 0;
var sumAfe = 0;
var sumDev = 0;

var maturidade = 0;
var resultadoLista;
var maturidadeAuto = [3, 2, 1, -1, -2, -3];
var maturidadeDir = [2, 2, 1, -1, -2, -3];
var maturidadeMod = [2, 2, 1, -1, -2, -2];
var maturidadePart = [-1, -1, 1, 2, 2, 3];
var maturidadeAfe = [-2, -1, 1, 1, 2, 2];
var maturidadeDev = [1, 1, 2, 2, 3, 3];
var maturidadeLaissez = [-3, -3, -3, -3, -3, -3];

const Box = styled.div`
  border: 1px solid lightgray;
`;

const Boxed = styled.div`
  border: 1px solid lightgray;

  @media (max-width: 575px) {
    width: 90% !important;
    margin: 0 auto !important;
}
`;

const P = styled.p`
  font-size: 13px;
`;

const StyledCol = styled(Col) `
  text-align: right !important;

  @media (max-width: 575px) {
    text-align: left !important;
  }
`

const StyledRow = styled(Row) `
  @media (max-width: 575px) {
    flex-direction: column-reverse;
  }
`

 class TiposDeLideranca extends Component {
  static contextType = JoyrideContext;
  state = {
    "initialData": {
        "tasks": {
            "621793409c2eab226abe0724": {
                "id": "621793409c2eab226abe0724",
                "content": "Homem de Ferro",
                "picture": "HOMEM-FERRO.png"
            },
            "62322b0705955d59f2693b57": {
                "id": "62322b0705955d59f2693b57",
                "content": "Homem Aranha",
                "picture": "HOMEM-ARANHA.png"
            },
            "6074983b7ef518352e70905f": {
                "id": "6074983b7ef518352e70905f",
                "content": "Mulher Maravilha",
                "picture": "MULHER-MARAVILHA.png"
            },
            "622918a5a8d2ea982c4c1dbb": {
                "id": "622918a5a8d2ea982c4c1dbb",
                "content": "Viúva Negra",
                "picture": "VIUVA-NEGRA.png"
            },
            "6074983b7ef518352e70905d": {
                "id": "6074983b7ef518352e70905d",
                "content": "Hulk",
                "picture": "HULK.png"
            },
            "6074983b7ef518352e70905x": {
              "id": "6074983b7ef518352e70905x",
              "content": "Thor",
              "picture": "THOR.png"
          },
        },
        "columns": {
            "participantes": {
                "id": "participantes",
                "title": "Participantes",
                "estilo": "Participantes",
                "taskIds": [
                    "621793409c2eab226abe0724",
                    "62322b0705955d59f2693b57",
                    "6074983b7ef518352e70905f",
                    "622918a5a8d2ea982c4c1dbb",
                    "6074983b7ef518352e70905d",
                    "6074983b7ef518352e70905x"
                ]
            },
            "Diretivo": {
                "id": "Diretivo",
                "title": "Busca garantir que os crimes sejam inibidos, evitando danos na cidade e garantindo a segurança das pessoas.",
                "estilo": "Diretivo",
                "taskIds": []
            },
            "LaissezFaire": {
                "id": "LaissezFaire",
                "title": "Age imediatamente e de maneira pragmática, prendendo os suspeitos custe o que custar.",
                "estilo": "LaissezFaire",
                "taskIds": []
            },
            "Autoritário": {
                "id": "Autoritário",
                "title": "Chama a polícia e a apoia a fazer o  trabalho policial.",
                "estilo": "Autoritário",
                "taskIds": []
            },
            "Afetivo": {
                "id": "Afetivo",
                "title": "Parte para cima dos membros da gangue, buscando que amedrontados, fujam e temam sua presença futura.",
                "estilo": "Afetivo",
                "taskIds": []
            },
            "Desenvolvedor": {
                "id": "Desenvolvedor",
                "title": "Avalia a melhor forma de evitar o confronto direto, buscando momentos oportunos para os ir capturando em pequenos embates.",
                "estilo": "Desenvolvedor",
                "taskIds": []
            },
            "Participativo": {
                "id": "Participativo",
                "title": "Aciona e reúne outros heróis para poder garantir e conter o alvoroço, eliminando a ameaça.",
                "estilo": "Participativo",
                "taskIds": []
            },
            "Modelador": {
                "id": "Modelador",
                "title": "Observa e procura não intervir imediatamente, pois algumas situações irão se resolver, podendo concentra-se mais tarde no que for preciso.",
                "estilo": "Modelador",
                "taskIds": []
            }
        },
        "columnOrder": [
            "participantes",
            "Diretivo",
            "LaissezFaire",
            "Autoritário",
            "Afetivo",
            "Desenvolvedor",
            "Participativo",
            "Modelador"
        ]
    },
    "dados": [
        {
            "enunciado": "<p><strong>Aten&ccedil;&atilde;o</strong></p>\n\n<p>Para responder considere o que seus/suas superiores diriam sobre voc&ecirc;, decidindo se discorda totalmente at&eacute; se concorda totalmente.</p>\n\n<p>Observe que n&atilde;o h&aacute; resposta certa ou errada, mas a mais apropriada para seu autoconhecimento e futuro plano de desenvolvimento e a&ccedil;&atilde;o. Desse modo, fa&ccedil;a uso da maior sinceridade nas respostas.</p>\n\n<p>Na d&uacute;vida, escolha a op&ccedil;&atilde;o que primeiro lhe veio a mente.</p>\n",
            "alternativas": [
                {
                    "pergunta": "A cidade está sob o ataque de gangues sob o comando de um conhecido criminoso. A pequena comunidade se vê a mercê deste bando, que está causando alvoroço entre a população. Nessa situação qual o comportamento mais provável destes heróis:",
                    "resposta": [
                        {
                            "resposta": "Busca garantir que os crimes sejam inibidos, evitando danos na cidade e garantindo a segurança das pessoas.",
                            "estilo": "Diretivo"
                        },
                        {
                            "resposta": "Age imediatamente e de maneira pragmática, prendendo os suspeitos custe o que custar.",
                            "estilo": "LaissezFaire"
                        },
                        {
                            "resposta": "Chama a polícia e a apoia a fazer o  trabalho policial.",
                            "estilo": "Autoritário"
                        },
                        {
                            "resposta": "Parte para cima dos membros da gangue, buscando que amedrontados, fujam e temam sua presença futura.",
                            "estilo": "Afetivo"
                        },
                        {
                            "resposta": "Avalia a melhor forma de evitar o confronto direto, buscando momentos oportunos para os ir capturando em pequenos embates.",
                            "estilo": "Desenvolvedor"
                        },
                        {
                            "resposta": "Aciona e reúne outros heróis para poder garantir e conter o alvoroço, eliminando a ameaça.",
                            "estilo": "Participativo"
                        },
                        {
                            "resposta": "Observa e procura não intervir imediatamente, pois algumas situações irão se resolver, podendo concentra-se mais tarde no que for preciso.",
                            "estilo": "Modelador"
                        }
                    ]
                },
                {
                    "pergunta": "A melhor descrição de cada herói seria:",
                    "resposta": [
                        {
                            "resposta": "Investigativo e furtivo",
                            "estilo": "Afetivo"
                        },
                        {
                            "resposta": "Reflexivo e prudente",
                            "estilo": "Diretivo"
                        },
                        {
                            "resposta": "Inteligente e perspicaz",
                            "estilo": "Autoritário"
                        },
                        {
                            "resposta": "Forte e pouco paciente",
                            "estilo": "Modelador"
                        },
                        {
                            "resposta": "Mediador e afeito ao diálogo e negociação",
                            "estilo": "Participativo"
                        },
                        {
                            "resposta": "Seu diferencial é a habilidade de persuadir e manipular",
                            "estilo": "Desenvolvedor"
                        },
                        {
                            "resposta": "Apresenta habilidades físicas indiscutíveis",
                            "estilo": "LaissezFaire"
                        }
                    ]
                },
            ]
        }
    ],
    "participantes": [
        {
            "_id": "621793409c2eab226abe0724",
            "id": "621793409c2eab226abe0724",
            "nome": "Homem de Ferro",
            "foto": "HOMEM-FERRO.png"
        },
        {
            "_id": "62322b0705955d59f2693b57",
            "id": "62322b0705955d59f2693b57",
            "nome": "Homem Aranha",
            "foto": "HOMEM-ARANHA.png"
        },
        {
            "_id": "6074983b7ef518352e70905f",
            "id": "6074983b7ef518352e70905f",
            "nome": "Mulher Maravilha",
            "foto": "MULHER-MARAVILHA.png"
        },
        {
            "_id": "622918a5a8d2ea982c4c1dbb",
            "id": "622918a5a8d2ea982c4c1dbb",
            "nome": "Viúva Negra",
            "foto": "VIUVA-NEGRA.png"
        },
        {
            "_id": "6074983b7ef518352e70905d",
            "id": "6074983b7ef518352e70905d",
            "nome": "Hulk",
            "foto": "HULK.png"
        },
        {
          "_id": "6074983b7ef518352e70905x",
          "id": "6074983b7ef518352e70905x",
          "nome": "Thor",
          "foto": "THOR.png"
      },
    ],
    "finais": {},
    "items": [],
    "enunciado": "<p><strong>Aten&ccedil;&atilde;o</strong></p>\n\n<p>Para responder considere o que seus/suas superiores diriam sobre voc&ecirc;, decidindo se discorda totalmente at&eacute; se concorda totalmente.</p>\n\n<p>Observe que n&atilde;o h&aacute; resposta certa ou errada, mas a mais apropriada para seu autoconhecimento e futuro plano de desenvolvimento e a&ccedil;&atilde;o. Desse modo, fa&ccedil;a uso da maior sinceridade nas respostas.</p>\n\n<p>Na d&uacute;vida, escolha a op&ccedil;&atilde;o que primeiro lhe veio a mente.</p>\n",
    "selected": [],
    "perguntaAtual": 0,
    "respostas": [],
    "modalConfirmacao": false,
    "modalErro": false,
    "modalInstrucoes": true,
    "loading": false,
    "tempos": {
        "tempoInicio": 1652189954000,
        "tempoFim": 1652190854000,
        "tempoRestante": 632000,
        "finalizado": false
    },
    "pararTimer": false,
    "loadingSpinner": false,
    "barra": false,
    "intervalResetRequest": false,
    "haveError": false,
    "errorCount": 0,
    "errorRetry": 10,
    alreadyOpened: false,
    steps: []
  };
  respostas = [];
  onLogout = false;
  onProximo = false;
  // Tempos
  tempoOnMount = srvTime();
  tempoTotal = 0;

  componentDidMount = async () => {
    // document.getElementById("barra").width = 0;
    
    //this.subscribePush();
    await this.recebendoInfo();

    const { setHaveTour, setRunTour } = this.context;

    const msg4 = this.detectMob() ? 'abaixo' : 'a esquerda';

      const steps= [
        {
          target: '.estilos-step-1',
          content: <TourP>Aqui você acompanha o tempo restante para a conclusão desta atividade</TourP>,
          disableBeacon: true
        },
        {
          target: '.estilos-step-2',
          content: <TourP>Aqui são exibidas a quantidade de rodadas a serem respondidas. Conforme você avança nas
          respostas, o número correspondente a rodada atual fica evidenciado pela cor amarela. Não é
          possível retroceder nem avançar para a questão seguinte.</TourP>,
          disableBeacon: true
        },
        {
          target: '.estilos-step-3',
          content: <TourP>Você deverá arrastar os participantes para os quadros de opções nesta área, de acordo com suas
          escolhas. É obrigatório você inserir todos os participantes (incluindo você) em alguma das
          assertivas.</TourP>,
          disableBeacon: true,
          placement: 'auto',
        },
        {
          target: '.estilos-step-4',
          content: <TourP>Nesta área estão os participantes que você deverá mover para o quadro {msg4}. Clique e
          segure (aponte com o dedo e segure se estiver em dispositivo touch) sobre o participante e arraste
          para o quadro ao lado na opção que desejar.</TourP>,
          disableBeacon: true,
          placement: 'auto',
        },
        {
          target: '.estilos-step-5',
          content: <TourP>Clique neste botão para enviar a sua escolha. Ao clicar neste botão uma janela irá se abrir. Caso
          não tenha dúvidas quanto a sua escolha, confirme o envio clicando no botão ‘Prosseguir’. A seguir, uma nova rodada será apresentada, e assim sucessivamente até que se encerrem as
          rodadas.</TourP>,
          disableBeacon: true
        },
      ];
      setHaveTour(true);
      setRunTour(false);

      this.setState({ steps });

  };

  // componentWillUnmount = () => {
  //   Constantes.io.off("Projeto:Stop");
  //   Constantes.io.off("Projeto:NovosTempos");
  //   Constantes.io.off("Auth:LogoutInstrumento");
  // };

  detectMob = () => {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];
    
    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}

  recebendoInfo = async () => {
    if (this.state.errorCount >= this.state.errorRetry) {
      return this.setState({ haveError: true, loading: false });
    }
    try {
      // Pegando info de estilos
    let inicio = srvTime()

    //let resGet = await api.get(`api/projects/dinamicas/projeto/${sessionStorage.getItem("atividade")}/instrumento/${Constantes.instrumentoEstilos}`)
    // let projetoDinamica = resGet.data

    // await api
    //   .get("api/instruments/5c1a84452c87cd05d02bd1d8")
    //   .then(async res => {
    //     let fim = srvTime()
    //     let segundos = (fim - inicio) / 1000

    //     const response = res;
    //     const dinamicaId = sessionStorage.getItem("atividade");
    //     // Verificando respostas prévias
    //     await verificarRespostas(
    //       sessionStorage.getItem("user"),
    //       Constantes.instrumentoEstilos,
    //       Constantes.moduloDinamicas,
    //       dinamicaId,
    //       this.props.rodada,
    //       -1,
    //       projetoDinamica,
    //       this.props.idInstrumento
    //     )
    //       .then(async res => {
    //         let respostaPrevia = res;

    //         const dados = response.data.data;
    //         let enunciado = dados[0].enunciado;
    //         let perguntaAtual = 0;

    //         // Verificando grupo e salvando informações dos participantes
    //         inicio = srvTime()
    //         await api
    //           .get(
    //             `api/projects/dinamicas/${dinamicaId}/grupos/${
    //               this.props.rodada
    //             }/${this.props.grupo}`
    //           )
    //           .then(async res => {
    //             fim = srvTime()
    //             segundos = (fim - inicio) / 1000

    //             let participantes = res.data;

    //             // Verifica info do instrumento
    //             inicio = srvTime()
    //             // await api
    //               // .get(`api/projects/dinamicas/projeto/${sessionStorage.getItem("atividade")}/instrumento/${Constantes.instrumentoEstilos}`)
    //               // .get(`api/projects/dinamicas/projeto/${sessionStorage.getItem("atividade")}`)
    //               // .get(`api/projects/${sessionStorage.getItem("projeto")}/info`)
    //               // .then(async res => {
    //                 fim = srvTime()
    //                 segundos = (fim - inicio) / 1000


    //                 // const responseGrupo = res;
    //                 let dinamica = projetoDinamica.projeto.modulos.dinamicas[0]
    //                 // let dinamica = responseGrupo.data.projeto.modulos.dinamicas.find(
    //                 // // let dinamica = responseGrupo.data.modulos.dinamicas.find(
    //                 //   dinamica =>
    //                 //     dinamica._id.toString() ===
    //                 //     sessionStorage.getItem("atividade")
    //                 // );
    //                 let instrumento = dinamica.rodadas[
    //                   this.props.rodada
    //                 ].instrumentos.find(
    //                   intrumento =>
    //                     intrumento.content === Constantes.instrumentoEstilos
    //                 );

    //                 // Inicializando tempo
    //                 let tempos = instrumento.tempos && instrumento.tempos.individual || {};
    //                 if (tempos) {
    //                   this.tempoTotal = tempos.tempoFim - tempos.tempoInicio;
    //                   tempos.tempoRestante = tempos.tempoFim - this.tempoOnMount;
    //                   if (tempos.tempoRestante < 0) tempos.tempoRestante = 0;
    //                 }

    //                 let modalInstrucoes = false;
                    
    //                 // CASO ENCONTRE RESPOSTA PRÉVIA, CARREGAR E SALVAR NA BASE
    //                 if (respostaPrevia) {
    //                   // Atualizando respostas
    //                   estilos = respostaPrevia.resposta.estilos;
    //                   perguntaAtual = respostaPrevia.resposta.perguntaAtual;
    //                   this.monta(
    //                     participantes,
    //                     dados[0].alternativas[perguntaAtual]
    //                   );

    //                   this.clickCount = perguntaAtual;
    //                   this.setState({
    //                     dados,
    //                     enunciado,
    //                     participantes,
    //                     perguntaAtual,
    //                     tempos,
    //                     modalInstrucoes,
    //                     loading: false
    //                   });
    //                 } else {
    //                   modalInstrucoes = true;
    //                   // Atualizando tempos:
    //                   let tempoAtual = tempos.tempoRestante;
    //                   const atualizadoEm = srvTime();
    //                   let resp = {
    //                     instrumentId: this.props.idInstrumento,
    //                     instrumento: Constantes.instrumentoEstilos,
    //                     resposta: {
    //                       estilos,
    //                       perguntaAtual: 0
    //                     },
    //                     atualizadoEm,
    //                     rodada: this.props.rodada,
    //                     tempos: { tempoAtual },
    //                     finalizado: false
    //                   };

    //                   inicio = srvTime()
    //                   await api
    //                     .put(
    //                       `api/user/respostas/modulo/${
    //                         Constantes.moduloDinamicas
    //                       }`,
    //                       {
    //                         resp,
    //                         atividadeId: dinamicaId
    //                       }
    //                     )
    //                     .then(() => {
    //                       fim = srvTime()
    //                       segundos = (fim - inicio) / 1000

    //                       this.monta(
    //                         participantes,
    //                         dados[0].alternativas[perguntaAtual]
    //                       );
    //                       this.setState({
    //                         dados,
    //                         enunciado,
    //                         participantes,
    //                         perguntaAtual,
    //                         tempos,
    //                         modalInstrucoes,
    //                         loading: false,
    //                         errorCount: 0
    //                       });
    //                     })
    //                     // .catch(async err => {
    //                     //   console.error(err)
    //                     //   if (err.response) {
    //                     //     console.error(err.response.data);
    //                     //   }
    //                     //   let info = {
    //                     //     status: 1,
    //                     //     resp,
    //                     //     atividadeId: dinamicaId,
    //                     //     modulo: Constantes.moduloDinamicas
    //                     //   };
    //                     //   await reenviarResposta(info);
    //                     // });
    //                 }
    //               // })
    //               // .catch(err => {
    //               //   console.log(err)
    //               //   if (err.response) console.log(err.response.data);
    //               //   //this.recebendoInfo();
    //               // });
    //           })
    //           // .catch(err => {
    //           //   console.error(err)
    //           //   if (err.response) console.error(err.response.data);
    //           //   //this.recebendoInfo();
    //           // });
    //       })
    //       // .catch(err => {
    //       //   console.error(err)
    //       //   if (err.response) console.error(err.response.data);
    //       //   //this.recebendoInfo();
    //       // });
    //   })
      // .catch(err => {
      //   console.error(err)
      //   if (err.response) console.error(err.response.data);
      //   //this.recebendoInfo();
      // });
    } catch (err) {
      console.error(err);
      this.setState({ errorCount: this.state.errorCount + 1 });
      setTimeout(() => this.recebendoInfo(), 1000);
    }
  };

  subscribePush = () => {
    Constantes.io.on("Projeto:Stop", data => {
      if (
        data.instrumento === Constantes.instrumentoEstilos &&
        data.start.individual === 0 &&
        data.rodada === this.props.rodada
      ) {
        this.props.history.go() // refresh the page
      }
    });
    Constantes.io.on("Projeto:NovosTempos", data => {
      let tempos = data.tempos.individual;
      //tempos.tempoRestante = tempos.tempoFim - srvTime();
      this.tempoTotal = tempos.tempoFim - tempos.tempoInicio;
      this.setState({
        tempos,
        intervalResetRequest: true
      });
    });
    Constantes.io.on("Auth:LogoutInstrumento", userId => {
      if (sessionStorage.getItem("user") === userId.toString()) {
        this.onLogout = true;
        if (this.state.tempos.tempoRestante > 0)
          this.setState({ pararTimer: true });
        else this.fimAtividade();
      }
    });
  };

  transforma = ids => {
    var novo = [];
    for (var i = 0; i < ids.length; i++) {
      novo[i] = [];
      for (var i2 = 0; i2 < ids[i].length; i2++) {
        for (var i3 = 0; i3 < this.props.geral.length; i3++) {
          for (var i4 = 0; i4 < this.props.geral[i3].pessoas.length; i4++) {
            if (ids[i][i2] === this.props.geral[i3].pessoas[i4].id) {
              novo[i].push(this.props.geral[i3].pessoas[i4].nome);
            }
          }
        }
      }
      return novo;
    }
  };
  monta = async (participantes, pergunta) => {
    json = {};
    current = json;
    columnJson = {};
    col = columnJson;
    ordem = [];
    await participantes.map((part, indice) => {
      var valordeId = part.id;

      current[valordeId] = {
        id: valordeId,
        content: part.nome,
        picture: part.foto
      };
      col["participantes"] = {
        id: "participantes",
        title: "Participantes",
        estilo: "Participantes",
        taskIds: []
      };
      ordem.push(part.id);
    });
    await pergunta.resposta.map((valor, indice) => {
      col[valor.estilo] = {
        id: valor.estilo,
        title: valor.resposta,
        estilo: valor.estilo,
        taskIds: []
      };

      initialData.columnOrder[0] = "participantes";
      initialData.columnOrder[indice + 1] = valor.estilo;
    });
    initialData.columns = columnJson;
    initialData.tasks = json;
    initialData.columns.participantes.taskIds = ordem;
    this.setState({ initialData: initialData });

  };
  enunciado = () => {
    const texto = this.state.enunciado;
    var res = texto.split("~");

    return (
      <div>
        {res[0]}
        <br />
        <br />
        {res[1]}
        <br />
        <br />
        {res[2]}
        <br />
        <br />
        {res[3]}
        <br />
        <br />
      </div>
    );
  };
  montaAlternativas = () => {
    this.state.respostas.map((test, indice) => {
      return indice;
    });
  };
  verifica = () => {
    this.context.setRunTour(false);
    let b = document.getElementById("button");

    if (this.state.initialData.columns.participantes.taskIds.length === 0) {
      b.blur();
      this.setState({ modalConfirmacao: true });
    } else {
      this.setState({ modalErro: true });
    }
  };

  onDragEnd = result => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = this.state.initialData.columns[source.droppableId];
    const finish = this.state.initialData.columns[destination.droppableId];

    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        taskIds: newTaskIds
      };

      const newState = {
        ...this.state.initialData,
        columns: {
          ...this.state.initialData.columns,
          [newColumn.id]: newColumn
        }
      };

      this.setState(newState);
      return;
    }

    // Moving from one list to another
    const startTaskIds = Array.from(start.taskIds);

    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);

    const newFinish = {
      ...finish,
      taskIds: finishTaskIds
    };

    const newState = {
      ...this.state.initialData,
      columns: {
        ...this.state.initialData.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish
      }
    };

    this.verificaResultado(newState);
  };

  /**
   * salva os votos no state
   */
  verificaResultado = async (initialData, proxima = false) => {
    var obj = initialData.columns;
    this.respostas.push(obj);

    for (var i = 0; i < this.respostas.length; i++) {
      if (this.respostas[i]["Autoritário"]) {
        estilos.autoritario[this.state.perguntaAtual] = this.respostas[i][
          "Autoritário"
        ].taskIds;
      }
      if (this.respostas[i]["Diretivo"]) {
        estilos.diretivo[this.state.perguntaAtual] = this.respostas[i][
          "Diretivo"
        ].taskIds;
      }
      if (this.respostas[i]["Modelador"]) {
        estilos.modelador[this.state.perguntaAtual] = this.respostas[i][
          "Modelador"
        ].taskIds;
      }
      if (this.respostas[i]["Participativo"]) {
        estilos.participativo[this.state.perguntaAtual] = this.respostas[i][
          "Participativo"
        ].taskIds;
      }
      if (this.respostas[i]["Afetivo"]) {
        estilos.afetivo[this.state.perguntaAtual] = this.respostas[i][
          "Afetivo"
        ].taskIds;
      }
      if (this.respostas[i]["Desenvolvedor"]) {
        estilos.desenvolvedor[this.state.perguntaAtual] = this.respostas[i][
          "Desenvolvedor"
        ].taskIds;
      }
      if (this.respostas[i]["LaissezFaire"]) {
        estilos.laissezFaire[this.state.perguntaAtual] = this.respostas[i][
          "LaissezFaire"
        ].taskIds;
      }
    }
    ///// CALCULANDO RESPOSTAS

    // console.log(resposta)

    ///// CALCULANDO RESPOSTAS

    /*console.log("Verificando estilos", estilos.autoritario);
    this.transforma(estilos.autoritario);
    this.transforma(estilos.diretivo);
    this.transforma(estilos.modelador);
    this.transforma(estilos.participativo);
    this.transforma(estilos.afetivo);
    this.transforma(estilos.desenvolvedor);*/

    //this.gravarResposta(proxima);
    this.setState({ initialData });
  };

  /**
   * salva a resposta do participante
   * 
   * @param {boolean} proxima indica se deve configurar a proxima questão no state depois de salvar
   */
  gravarResposta = async (proxima, retry = false) => {
    if (this.state.errorCount >= this.state.errorRetry) {
      notifyError("Erro: não foi possível salvar a resposta na base");
      return this.setState({ loading: false, modalConfirmacao: false, errorCount: 0, loadingSpinner: false });
    }
    let { perguntaAtual } = this.state;
    if (proxima) perguntaAtual++;
    resposta = {
      estilos,
      perguntaAtual
    };

    // Atualizando tempos:
    let tempos = {
      tempoAtual: this.state.tempos.tempoRestante
    };

    if (retry) var atualizadoEm = retry;
    else var atualizadoEm = srvTime();

    let resp = {
      instrumentId: this.props.idInstrumento,
      resposta,
      instrumento: Constantes.instrumentoEstilos,
      rodada: this.props.rodada,
      atualizadoEm,
      tempos
    };
    if (proxima) {
      this.monta(
        this.state.participantes,
        this.state.dados[0].alternativas[perguntaAtual]
      );
      this.setState(previousState => ({
        modalConfirmacao: false,
        loadingSpinner: false,
        perguntaAtual: previousState.perguntaAtual + 1,
      }));
    }
    // await api
    //   .put(`api/user/respostas/modulo/${Constantes.moduloDinamicas}`, {
    //     resp,
    //     atividadeId: dinamicaId
    //   })
    //   .then(() => {

    //     if (proxima) {
    //       this.monta(
    //         this.state.participantes,
    //         this.state.dados[0].alternativas[perguntaAtual]
    //       );
    //       this.setState(previousState => ({
    //         modalConfirmacao: false,
    //         loadingSpinner: false,
    //         perguntaAtual: previousState.perguntaAtual + 1,
    //       }));
    //     }
    //   })
    //   .catch(err => {
    //     console.error(err);
    //     this.setState({ errorCount: this.state.errorCount + 1 });
    //     setTimeout(() => this.gravarResposta(proxima, retry), 1000);
    //   });
  };

  /**
   * finaliza a atividade e chama a próxima tela a ser renderizada
   */
   proximaAtividade = async () => {
     try {
      //  await apiCalls.dinamica.saveInstrumentAnswer(
      //    Constantes.instrumentoEstilos,
      //    this.props.idInstrumento,
      //    Constantes.moduloDinamicas,
      //    sessionStorage.getItem("atividade"),
      //    this.props.rodada,
      //    sessionStorage.getItem("projeto")
      //  );
       this.props.action();
     } catch (error) {
       console.error(error);
       notifyError("ocorreu um erro ao enviar sua solicitação");
     }
   };

  tempoAtualizado = tempo => {
    this.setState(previousState => ({
      tempos: {
        ...previousState.tempos,
        tempoRestante: tempo
      }
    }));
  };

  fimAtividade = () => {
    if (this.onLogout) this.props.logout();
    else {
      this.proximaAtividade();
    }
  };

  /**
   * callback do botão "prosseguir"
   */
  avaliarProxima = async () => {
    try {
      if (!this.onProximo) {
        this.onProximo = true;
        this.setState({ loadingSpinner: true });
        setTimeout(async() => {
          if (
            this.state.perguntaAtual <
            this.state.dados[0].alternativas.length - 1
          ) {
            this.verificaResultado(this.state.initialData, true);
            this.gravarResposta(true);
          } else if (
            this.state.perguntaAtual ===
            this.state.dados[0].alternativas.length - 1
          ) {
            this.verificaResultado(this.state.initialData, true);
            await this.gravarResposta(false);
            this.fimAtividade();
          }
        }, 1500)
      }
      this.setState({ errorCount: 0 });
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    } catch (error) {
      console.error(error);
    } finally {
      document.getElementsByTagName("body")[0].scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  };

  toggleConfirmacao = () => {
    if (!this.state.loadingSpinner) {
      this.setState(previousState => ({
        modalConfirmacao: !previousState.modalConfirmacao
      }));
    }
  };

  toggleInstrucoes = () => {
    if (this.state.modalInstrucoes && !this.state.alreadyOpened) {
      this.context.setRunTour(true);
      this.setState({ alreadyOpened: true });
    } 
    this.setState(previousState => ({
      modalInstrucoes: !previousState.modalInstrucoes
    }));
  };

  colapsa = () => {
    this.setState(previousState => ({
      barra: !previousState.barra
    }));
  };

  toggleErro = () => {
    this.setState(previousState => ({
      modalErro: !previousState.modalErro
    }));
  };

  callback = (data) => {
    if (data.status === 'finished' || data.action === 'close') {
        // This explicitly stops the tour (otherwise it displays a "beacon" to resume the tour)
        this.context.setRunTour(false);
    }
  }

  render() {
    if (this.state.haveError) return <ErrorComponent />;
    if (this.state.loading) return <Loading />;

    return (
      <div className="text-left back" id="logo" tabIndex="0">
        <Joyride
          showProgress={true}
          steps={this.state.steps}
          run={this.context.runTour}
          continuous={true}
          callback={this.callback}
          disableOverlayClose={true}
          spotlightClicks={true}
          disableBeacon={true}
          locale={{
            back: 'Voltar',
            close: 'Fechar',
            last: 'Fim',
            next: 'Próximo',
            skip: 'Pular'
          }}
          styles={{
            options: {
              primaryColor: '#012a4a'
            },
            tooltipContainer: {
              textAlign: 'left',
            },
          }}
        />
        <Modal
          isOpen={this.state.modalInstrucoes}
          className={this.props.className}
        >
          <ModalBody>
            <Instrucoes2>
              <div>
                <P>Car@ participante, a seguir algumas instruções para que você possa realizar a entrega da atividade estilos de liderança. Lembrando que essas atividades são ilustrativas e não terão seus resultados considerados ou salvos no IAPP.</P>
                <P>As instruções a seguir são relacionadas exclusivamente ao teste atual. Na aplicação oficial, as instruções poderão ser diferentes.</P>
                <P><strong>Enunciado</strong></P>
                <P>Nesta atividade voc&ecirc; dever&aacute; indicar qual das frases apresentadas &agrave; esquerda da tela melhor representa voc&ecirc; e cada um dos participantes do seu grupo (fake), listados &agrave; direita da tela. Para esse teste, usaremos participantes fake, mas na din&acirc;mica oficial, ser&atilde;o listados os participantes do seu grupo. Ser&atilde;o apresentadas duas rodadas com diferentes possibilidades de respostas.</P>
                <P>Lembrando que essa atividade &eacute; ilustrativa e n&atilde;o ter&atilde;o seus resultados considerados ou salvos no IAPP.</P>
                <P><strong>Como indicar os participantes nas frases</strong></P>
                <P><strong>Acesso com mouse</strong></P>
                <P>Posicione o mouse at&eacute; o nome/foto do participante &agrave; direita da tela, clicar e segurar clicado, mover o participante para o espa&ccedil;o abaixo da frase com a qual voc&ecirc; o identifica e soltar o bot&atilde;o do mouse.</P>
                <P><strong>Acesso com touch (tablets/celulares)</strong></P>
                <P>Encoste o dedo na tela do seu aparelho sobre o nome/foto, arraste o participante para o espa&ccedil;o abaixo da frase com a qual voc&ecirc; o identifica e tire o dedo da tela.</P>
                <P><strong>Regras</strong></P>
                <P>&Eacute; obrigat&oacute;rio voc&ecirc; inserir todos os participantes (incluindo voc&ecirc;) em alguma das assertivas.</P>
                <P>Voc&ecirc; pode incluir quantos participantes quiser na mesma assertiva.</P>
                <P>N&atilde;o &eacute; necess&aacute;rio que todas as assertivas tenham algum participante vinculado.</P>
                <P>&Eacute; obrigat&oacute;rio enviar as 2 rodadas.</P>
                <P>OBSERVA&Ccedil;&Atilde;O: Caso desejar, voc&ecirc; poder&aacute; clicar no &iacute;cone&nbsp;<strong>☰</strong>&nbsp;no campo dos nomes para redimensionar sua tela, deixando apenas as fotos dos participantes, sem o nome. Caso deseje desfazer, basta clicar novamente no &iacute;cone e o nome voltar&aacute; a aparecer.</P>
              </div>
            </Instrucoes2>
          </ModalBody>
          <ModalFooter>
            <Container className="text-center">
              <Button color="success" onClick={this.toggleInstrucoes}>
                ok
              </Button>{" "}
            </Container>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modalErro}
          toggle={this.toggleErro}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleErro}>
            Estilos de Liderança
          </ModalHeader>
          <ModalBody>
            Você só pode prosseguir após arrastar todos os participantes de seu
            grupo para aresposta desejada.
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={this.toggleErro}>
              ok
            </Button>{" "}
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modalConfirmacao}
          toggle={this.toggleConfirmacao}
          className={this.props.className}
          onClosed={() => (this.onProximo = false)}
        >
          <ModalHeader toggle={this.toggleConfirmacao}>
            Estilos de Liderança
          </ModalHeader>
          <ModalBody>
            Se você estiver satisfeito com suas escolhas clique em prosseguir,
            se não clique em cancelar
          </ModalBody>
          <ModalFooter>
            {!this.state.loadingSpinner ? (
              <div>
                <Button
                  color="success"
                  onClick={() => this.avaliarProxima()}
                  disabled={this.state.loadingSpinner}
                >
                  Prosseguir
                </Button>{" "}
                <Button
                  color="danger"
                  onClick={this.toggleConfirmacao}
                  disabled={this.state.loadingSpinner}
                >
                  Cancelar
                </Button>
              </div>
            ) : (
              <img src={require(`../../Assets/_assets/${spinner}`)} />
            )}
          </ModalFooter>
        </Modal>
        <Base>
          <Container>
            <br />
            <br />
            <br />
            <Row>
              <Col>
                <h1>Estilos de Liderança</h1>
              </Col>
              <Col className="text-right">
                <Icon
                  src={require("../../Assets/_assets/doubt.png")}
                  alt="Instrucoes"
                />
                <Inst onClick={this.toggleInstrucoes}>Instruções</Inst>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col className='estilos-step-1' sm="6">
                <Contador
                  counter={
                    <Timer
                      intervalResetRequest={this.state.intervalResetRequest}
                      intervalResetFeedback={() => this.setState({ intervalResetRequest: false })}
                      crescente={false}
                      tempo={901200}
                      resetAutomatico={false}
                      tempoAtualizado={this.tempoAtualizado}
                      pararTimer={this.state.pararTimer}
                      resetar={false}
                      tempoParado={this.fimAtividade}
                      id="tempo"
                    />
                  }
                  tempo={
                    901200
                  }
                />
              </Col>
            </Row>

            <Row style={{marginTop: 20}}>
              {
                montaPaginas(2, this.state.perguntaAtual + 1, 'estilos-step-2')
              }
              <Clear />
            </Row>
            <br />
            <br />

            <Row>
              <StyledCol sm="1">
                <h2>{this.state.perguntaAtual + 1}.</h2>
              </StyledCol>
              <Col>
                <p>
                  {
                    this.state.dados[0].alternativas[this.state.perguntaAtual]
                      .pergunta
                  }
                </p>
              </Col>
            </Row>

            <DragDropContext onDragEnd={this.onDragEnd}>
              <Container>
                <StyledRow>
                  <Col className='estilos-step-3' style={{ marginTop: 10 }}>
                    {this.state.initialData.columnOrder.map(columnId => {
                      const column = this.state.initialData.columns[columnId];
                      const tasks = column.taskIds.map(
                        taskId => this.state.initialData.tasks[taskId]
                      );
                      return (
                        <Col sm="12" key={column.id}>
                          <Box>
                            {column.title !== "Participantes" ? (
                              <Col>
                                <Column column={column} tasks={tasks} />
                              </Col>
                            ) : (
                              ""
                            )}
                          </Box>
                        </Col>
                      );
                    })}
                  </Col>
                  <Col className='estilos-step-4' style={{ marginTop: 10 }} sm={this.state.barra ? "2" : "4"}>
                    <Boxed className="posicao" style={{ padding: 5 }}>
                      <span onClick={this.colapsa}>
                        <b style={{ fontSize: 15, cursor: "pointer" }}>
                          {" "}
                          &#9776; Participantes
                        </b>
                      </span>

                      {this.state.initialData.columnOrder.map(columnId => {
                        const column = this.state.initialData.columns[columnId];
                        const tasks = column.taskIds.map(
                          taskId => this.state.initialData.tasks[taskId]
                        );
                        return (
                          <div key={column.id}>
                            {column.title === "Participantes" ? (
                              <Col>
                                <Column
                                  barra={this.state.barra}
                                  column={column}
                                  tasks={tasks}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })}
                    </Boxed>
                  </Col>
                </StyledRow>
              </Container>
            </DragDropContext>
            <Row>
              <Col className="text-center">
                <Button className='estilos-step-5' id="button" onClick={this.verifica}>
                  Enviar
                </Button>
                <Footer />
              </Col>
            </Row>
          </Container>
        </Base>
      </div>
    );
  }
}

export default withRouter(TiposDeLideranca)