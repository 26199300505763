import axios from "axios";
// TODO: remover esta dependencia
import axiosTiming from 'axios-timing'
import { Constantes } from "./Constantes";
const api = axios.create({
 baseURL: Constantes.baseURL,
  headers: {
    common: {
      Authorization: "Bearer " + sessionStorage.getItem("token")
    }
  }
});
export default api;
