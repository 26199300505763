import api from "../../../../Services/api";
import { Constantes } from "../../../../Services/Constantes";

const fetchInstrumentoCase = async (props) => {
    const res = await api
        .get(
            `api/projects/dinamicas/projeto/${
            sessionStorage.getItem("atividade") || props.idAtividade
            }/instrumento/${
            Constantes.instrumentoCaseIndividual
            }`, {
                params: {
                    roundIndex: props.rodada,
                    instrumentId: props.idInstrumento
                }
            });
    const projetoDinamica = res.data;
    let dinamica = projetoDinamica.projeto.modulos.dinamicas[0]
    let instrumento = dinamica.rodadas[props.rodada].instrumentos.find(
        intrumento => intrumento._id === props.idInstrumento
    );
    return instrumento;
}

export default fetchInstrumentoCase;