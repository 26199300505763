import React, { Component } from 'react';
import Joyride from 'react-joyride';
import JoyrideContext from "../../../contexts/joyride";
import api from '../../../Services/api';
import { Constantes, startedProofStatusCode } from '../../../Services/Constantes';
import * as workerTimers from 'worker-timers';

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Container,
  Progress,
  Col
} from 'reactstrap';
import Loading from '../../../Services/Loading';
// ------- DRIX --------
import {
  Base,
  HeaderLogged,
  Button,
  Titulo,
  Icon,
  ContadorProva,
  Qst,
  Options,
  ContadorQst,
  Fonts,
  Footer,
  Clear
} from '../../Assets/Assets';
import { Inst, Instrucoes2, Enunciado } from './Assets';
// ------- DRIX --------
import { verificarRespostas } from '../Utils/verificarRespostas';
import { montaPaginas } from '../Utils';
import { atualizarTempo, formatarTempo } from './Timer';
import { notifyError } from '../../..//Services/Notificacoes';
import apiCalls from '../../../config/apiCalls';
import './css/Prova.css';
import { srvTime } from '../Utils/index';
import { TourP } from "../../../utils/TourUtils";

let opts = React.createRef();
// Imagens
const spinner = Constantes.imagemSpinner;

class Prova extends Component {
  static contextType = JoyrideContext;

  constructor(props) {
    super(props);
    this.logo = React.createRef();
  }

  // Constantes
  quantidadeQuestoes = 0;
  tempoProvaQuestao = 0;
  tempoProvaProva = 0;
  tempoOnMount = srvTime();

  tempoInicial = {
    tempoQuestaoRestante: 0,
    tempoProvaRestante: 0,
    tempoPauseAcumuladoProva: 0,
    tempoPauseAcumuladoQuestao: 0
  };

  // Variaveis Globais
  checkFimTimer = 0;
  onLogout = false;
  timer;

  // Variaveis de resposta
  respostas = {
    acertos: 0,
    errors: 0,
    escolhas: [],
    nivel: 0,
    totalQuestoes: 0,
    questaoAtual: 0,
    perguntasRestantes: [],
    tempos: {
      tempoInicioProva: this.tempoOnMount,
      tempoAtualProva: this.tempoOnMount,
      tempoFimProva: this.tempoOnMount + this.tempoProvaProva,
      tempoAdicional: []
    }
  };
  pontuacao = 0;

  //Estado
  state = {
    currentQuestion: 0,
    questionsToUse: [
      {
        "enunciado": "<p>No Brasil, a Gest&atilde;o de Talentos ocupa o topo da lista de tend&ecirc;ncias em pol&iacute;ticas e pr&aacute;ticas de Gest&atilde;o de Pessoas no pa&iacute;s. Foi realizado um estudo com o objetivo de tra&ccedil;ar uma an&aacute;lise comparativa entre tr&ecirc;s organiza&ccedil;&otilde;es de grande porte, localizadas no munic&iacute;pio de Porto Alegre/RS, buscando conhecer as pr&aacute;ticas que constituem aquilo que cada uma denomina Gest&atilde;o de Talentos.</p>\n<p>Do texto se infere que</p>",
        "alternativas": [{
            "texto": "<p>É necess&aacute;rio fazer estudo sobre Gest&atilde;o de Pessoas.</p>",
            "correta": false,
            "escolhidas": 0
        }, {
            "texto": "<p>Pouco se sabe sobre Gest&atilde;o de Talentos em Porto Alegre.</p>",
            "correta": false,
            "escolhidas": 0
        }, {
            "texto": "<p>Todo estudo tem um objetivo.</p>",
            "correta": false,
            "escolhidas": 0
        }, {
            "texto": "<p>Se desconhecem detalhes sobre a Gest&atilde;o de Talentos em Porto Alegre.</p>",
            "correta": false,
            "escolhidas": 0
        }, {
            "texto": "<p>A Gest&atilde;o de Talentos pode assumir diferentes configura&ccedil;&otilde;es nas empresas.</p>",
            "correta": true,
            "escolhidas": 0
        }],
        "utilizacoes": 0,
        "acertos": 0,
        "feedback": "<p>A alternativa correta &eacute; a &uacute;nica que se pauta em elementos do texto. Mais especificamente, pauta-se na passagem &ldquo;buscando conhecer as pr&aacute;ticas que constituem aquilo que cada uma denomina Gest&atilde;o de Talentos&rdquo;. Oras, se se busca conhecer essas pr&aacute;ticas e se assume que cada uma denomina algo particular de Gest&atilde;o de Talentos, ent&atilde;o essa gest&atilde;o pode, sim, assumir diferentes configura&ccedil;&otilde;es nas empresas.<br />A) alternativa incorreta porque a exist&ecirc;ncia de um estudo, como o mencionado, n&atilde;o justifica a necessidade de se estudar um assunto.<br />B) alternativa incorreta porque a exist&ecirc;ncia de um estudo sobre empresas em Porto Alegre, como o mencionado, n&atilde;o necessariamente implica que pouco se saiba sobre a Gest&atilde;o de Talentos em Porto Alegre.<br />C) alternativa incorreta porque consiste em uma generaliza&ccedil;&atilde;o pautada em um &uacute;nico texto. Lembre-se aqui que as quest&otilde;es abordam racioc&iacute;nios inerentes ao texto, dispensando conhecimento de mundo.<br />D) alternativa incorreta porque a exist&ecirc;ncia de um estudo sobre empresas em Porto Alegre, como o mencionado, n&atilde;o necessariamente implica que se desconhecem detalhes sobre a Gest&atilde;o de Talentos em Porto Alegre.</p>",
        "_id": "5eb568a2e7ebd8016cdae7d4",
        "nivel": 0,
        "finalizado": false
      },
      {
        "enunciado": "<p>As informa&ccedil;&otilde;es armazenadas nos sistemas corporativos s&atilde;o, geralmente, desconhecidas ou ignoradas pela maioria dos membros de uma organiza&ccedil;&atilde;o quando das tomadas de decis&otilde;es.</p>\n<p>A senten&ccedil;a acima implica que a informa&ccedil;&atilde;o necess&aacute;ria est&aacute dispon&iacute;vel, mas as pessoas,</p>",
        "alternativas": [{
            "texto": "<p>V&aacute;rias vezes, n&atilde;o sabem como acess&aacute;-la nem da sua exist&ecirc;ncia.</p>",
            "correta": false,
            "escolhidas": 0
        }, {
            "texto": "<p>Diversas vezes, n&atilde;o sabem como acess&aacute;-la ou da sua exist&ecirc;ncia.</p>",
            "correta": false,
            "escolhidas": 0
        }, {
            "texto": "<p>Por vezes, n&atilde;o sabem da sua exist&ecirc;ncia e lhe s&atilde;o indiferentes.</p>",
            "correta": false,
            "escolhidas": 0
        }, {
            "texto": "<p>Muitas vezes, n&atilde;o sabem da sua exist&ecirc;ncia ou lhe s&atilde;o indiferentes.</p>",
            "correta": true,
            "escolhidas": 0
        }, {
            "texto": "<p>Às vezes, a desconhecem ou as ignoram.</p>",
            "correta": false,
            "escolhidas": 0
        }],
        "utilizacoes": 0,
        "acertos": 0,
        "feedback": "<p>A alternativa D &eacute; incorreta porque sua afirma&ccedil;&atilde;o &eacute; uma conclus&atilde;o para o fato de que as informa&ccedil;&otilde;es s&atilde;o &ldquo;desconhecidas&rdquo; (ou seja, as pessoas &ldquo;n&atilde;o sabem da sua exist&ecirc;ncia&rdquo;) ou &ldquo;ignoradas&rdquo; (ou seja, as pessoas &ldquo;lhe[s] s&atilde;o indiferentes&rdquo;). A alternativa A &eacute; incorreta porque o texto n&atilde;o permite conclus&otilde;es sobre saber ou n&atilde;o acessar a informa&ccedil;&atilde;o (vide D). A alternativa B &eacute; incorreta porque o texto n&atilde;o permite conclus&otilde;es sobre saber ou n&atilde;o acessar a informa&ccedil;&atilde;o (vide D). A alternativa C &eacute; incorreta porque o texto aponta que se trata de um comportamento ou outro, mas n&atilde;o os dois simultaneamente para a mesma pessoa (vide D). A alternativa E &eacute; incorreta porque o texto diz que as pessoas geralmente desconhecem ou ignoram a informa&ccedil;&atilde;o, sendo &ldquo;&agrave;s vezes&rdquo; uma frequ&ecirc;ncia muito baixa.</p>",
        "_id": "5eb568a2e7ebd8016cdae7d5",
        "nivel": 0,
        "finalizado": false
      },
      {
        "enunciado": "<p>Frequentemente, as pessoas de nossas amizades compartilham conosco suas reclama&ccedil;&otilde;es sobre algu&eacute;m ou algo. Por um lado, isso &eacute; natural; elas est&atilde;o buscando uma maneira de aliviar a tens&atilde;o. Por outro lado, se convivemos a todo momento com pessoas que apenas reclamam, nossas energias acabam se esgotando. (O Segredo)</p><p>Na segunda frase, &ldquo;isso&rdquo; e &ldquo;elas&rdquo; se referem, respectivamente, a</p>",
        "alternativas": [{
            "texto": "<p>&ldquo;Algo&rdquo; e &ldquo;pessoas&rdquo;.</p>",
            "correta": false,
            "escolhidas": 0
        }, {
            "texto": "<p>&ldquo;Reclama&ccedil;&otilde;es&rdquo; e &ldquo;pessoas&rdquo;.</p>",
            "correta": false,
            "escolhidas": 0
        }, {
            "texto": "<p>&ldquo;Reclama&ccedil;&otilde;es&rdquo; e &ldquo;nossas amizades&rdquo;.</p>",
            "correta": false,
            "escolhidas": 0
        }, {
            "texto": "<p>&ldquo;Compartilhamento de reclama&ccedil;&otilde;es&rdquo; e &ldquo;amizades&rdquo;.</p>",
            "correta": false,
            "escolhidas": 0
        }, {
            "texto": "<p>&ldquo;Compartilhamento de reclama&ccedil;&otilde;es&rdquo; e &ldquo;pessoas de nossas amizades&rdquo;.</p>",
            "correta": true,
            "escolhidas": 0
        }],
        "utilizacoes": 0,
        "acertos": 0,
        "_id":"5eb568a2e7ebd8016cdae7eb",
        "nivel": 0,
        "finalizado": false,
        "feedback": "<p>A &uacute;nica interpreta&ccedil;&atilde;o poss&iacute;vel para as palavras solicitadas &eacute; a de que se referem, respectivamente, ao compartilhamento de reclama&ccedil;&otilde;es e a pessoas da nossa amizade, elementos da primeira frase. Logo, &eacute; natural que pessoas de nossas amizades compartilham conosco suas reclama&ccedil;&otilde;es sobre algu&eacute;m ou algo; e essas pessoas da nossa amizade est&atilde;o buscando, assim, uma maneira de aliviar a tens&atilde;o. A alternativa A &eacute; incorreta porque &ldquo;isso&rdquo; n&atilde;o se refere ao elemento mais pr&oacute;ximo &ldquo;algo&rdquo; e &ldquo;elas&rdquo; n&atilde;o se refere a pessoas em geral. A alternativa B &eacute; incorreta porque n&atilde;o se trata de reclama&ccedil;&otilde;es em geral nem de pessoas em geral. A alternativa C &eacute; incorreta porque n&atilde;o se trata de reclama&ccedil;&otilde;es em geral nem das nossas amizades, mas sim das pessoas da nossa amizade. A alternativa D &eacute; incorreta porque se trata de compartilhamento de reclama&ccedil;&otilde;es, mas n&atilde;o de amizades.</p>"
      },
      {
        "enunciado": "<p>Leia o texto a seguir, em que [X] consiste em um argumento necess&aacute;rio &agrave; sua coer&ecirc;ncia/unicidade:</p>\n<p>Ao comprar um ve&iacute;culo usado em uma loja independente ou concession&aacute;ria autorizada, o consumidor tem 90 dias de garantia do produto prevista por lei. [X] . Se o ve&iacute;culo for reparado e o defeito voltar em 30 dias, o consumidor pode exigir o dinheiro de volta ou a troca por outro modelo.</p>\n<p>A senten&ccedil;a que melhor substitui [X] &eacute;:</p>",
        "alternativas": [{
            "texto": "<p>&Eacute; obriga&ccedil;&atilde;o da loja recolher o autom&oacute;vel e resolver o problema em caso de defeito.</p>",
            "correta": true,
            "escolhidas": 0
        }, {
            "texto": "<p>A garantia cobre todos os componentes mec&acirc;nicos e el&eacute;tricos do ve&iacute;culo.</p>",
            "correta": false,
            "escolhidas": 0
        }, {
            "texto": "<p>O consumidor deve provar que o defeito &eacute; anterior &agrave; compra.</p>",
            "correta": false,
            "escolhidas": 0
        }, {
            "texto": "<p>Se o ve&iacute;culo tiver reparo, cabe ao consumidor escolher o local do conserto.</p>",
            "correta": false,
            "escolhidas": 0
        }, {
            "texto": "<p>No caso de avarias posteriores &agrave; compra, h&aacute; legisla&ccedil;&atilde;o protegendo o vendedor.</p>",
            "correta": false,
            "escolhidas": 0
        }],
        "utilizacoes": 0,
        "acertos": 0,
        "feedback": "<p>a alternativa correta &eacute; a &uacute;nica que apresenta elementos (premissas) que concatenam a primeira e a &uacute;ltima senten&ccedil;a do texto. Mas especificamente, a alternativa correta menciona uma obriga&ccedil;&atilde;o, que a conecta com a men&ccedil;&atilde;o da lei na primeira senten&ccedil;a, e um defeito a ser resolvido/consertado/reparado, que a conecta com a &uacute;ltima senten&ccedil;a. Al&eacute;m disso, observe-se que a &uacute;ltima frase d&aacute; a entender que j&aacute; foi mencionado algo sobre um poss&iacute;vel defeito e necessidade de conserto do autom&oacute;vel, o que deve constar em [X] para que o texto tenha unicidade.<br />B) alternativa incorreta porque apenas se conecta com a primeira senten&ccedil;a do texto.<br />C) alternativa incorreta porque apenas se conecta com a &uacute;ltima senten&ccedil;a, mas nada menciona sobre o poss&iacute;vel reparo.<br />D) alternativa incorreta porque apenas se conecta com a &uacute;ltima senten&ccedil;a, mas nada menciona sobre a efetividade de o ve&iacute;culo ser reparado.<br />E) alternativa incorreta porque apenas reitera o conte&uacute;do da primeira senten&ccedil;a e n&atilde;o apresenta liga&ccedil;&atilde;o direta com a &uacute;ltima senten&ccedil;a.</p>",
        "_id": "5eb568a2e7ebd8016cdae7f0",
        "nivel": 1,
        "finalizado": false
      },
      {
        "enunciado": "<p>&ldquo;Muitas pesquisas indicam que, at&eacute; 2030, uma parcela relevante das profiss&otilde;es que conhecemos deixar&aacute; de existir e, de outro lado, o mercado vem migrando suas exig&ecirc;ncias, deixando de lado apenas diplomas, para exigir habilidades&rdquo;, afirma Abdul Nasser, professor da FGV In Company e do Ibmec no Rio. &ldquo;As fam&iacute;lias perceberam que investir em educa&ccedil;&atilde;o no exterior amplia os horizontes e possibilita a forma&ccedil;&atilde;o de um networking globalizado.&rdquo; Para Daniela Ronchetti Perkins, diretora operacional da FPP Edu-Media no Brasil &ndash; empresa organizadora das feiras EduExpo e EduCanada &ndash;, os estudantes cada vez mais consideram que uma experi&ecirc;ncia no exterior vai ajud&aacute;-los em tempos de crise. &ldquo;Muitos tamb&eacute;m t&ecirc;m a inten&ccedil;&atilde;o de permanecer no exterior ap&oacute;s a gradua&ccedil;&atilde;o. Por exemplo, o Canad&aacute; d&aacute; a oportunidade de o estudante ficar no pa&iacute;s legalmente para trabalhar na &aacute;rea ap&oacute;s a conclus&atilde;o do curso.&rdquo; (Estad&atilde;o)</p><p>No texto, identifica-se que</p>",
        "alternativas": [{
            "texto": "<p>A segunda frase corresponde &agrave; fala de um professor, enquanto a &uacute;ltima se refere &agrave; fala de uma diretora.</p>",
            "correta": true,
            "escolhidas": 0
        }, {
            "texto": "<p>O cen&aacute;rio &eacute; pessimista para os pr&oacute;ximos anos, haja vista que muitas profiss&otilde;es deixar&atilde;o de existir.</p>",
            "correta": false,
            "escolhidas": 0
        }, {
            "texto": "<p>Um networking globalizado &eacute; condi&ccedil;&atilde;o essencial para se enfrentar a atual crise econ&ocirc;mica do pa&iacute;s.</p>",
            "correta": false,
            "escolhidas": 0
        }, {
            "texto": "<p>A perman&ecirc;ncia no exterior ap&oacute;s a gradua&ccedil;&atilde;o d&aacute; a oportunidade de se conseguir a nacionalidade do pa&iacute;s onde se estudou.</p>",
            "correta": false,
            "escolhidas": 0
        }, {
            "texto": "<p>O uso de it&aacute;lico em &ldquo;networking&rdquo; &eacute; inconsistente com os casos de &ldquo;In Company&rdquo; e &ldquo;Edu-Media&rdquo;.</p>",
            "correta": false,
            "escolhidas": 0
        }],
        "utilizacoes": 0,
        "acertos": 0,
        "_id": "5eb568a2e7ebd8016cdae802",
        "nivel": 1,
        "finalizado": false,
        "feedback": "<p>A alternativa correta &eacute; a &uacute;nica que encontra respaldo no texto &ndash; no caso, a primeira cita&ccedil;&atilde;o &eacute; imediatamente atribu&iacute;da no texto como do professor Abdul Nasser, enquanto a &uacute;ltima cita&ccedil;&atilde;o somente pode ser compreendida como atribu&iacute;vel &agrave; diretora operacional da Daniela R. Perkins. A alternativa B &eacute; incorreta porque o texto n&atilde;o relaciona a extin&ccedil;&atilde;o de algumas profiss&otilde;es a um contexto pessimista. A alternativa C &eacute; incorreta porque o networking globalizado pode ajudar a enfrentar tempos de crise, mas n&atilde;o necessariamente &eacute; uma condi&ccedil;&atilde;o essencial para a atual crise econ&ocirc;mica do pa&iacute;s. A alternativa D &eacute; incorreta porque n&atilde;o encontra lastro no texto. A alternativa E &eacute; incorreta porque n&atilde;o necessariamente se italicizam nomes pr&oacute;prios.</p>"
      },
      {
        "enunciado": "<p>Os avan&ccedil;os de tecnologias que permitem ao trabalhador ser pontual e produtivo onde quer que esteja j&aacute; eram uma realidade antes da pandemia da Covid-19. As mudan&ccedil;as de comportamento exigidas pelo isolamento social s&oacute; aceleraram a concretiza&ccedil;&atilde;o de uma tend&ecirc;ncia no dia a dia das empresas e de seus colaboradores: o trabalho remoto. Seja pela surpresa com o aumento da produtividade, pela economia ou pela praticidade, o fato &eacute; que muitas organiza&ccedil;&otilde;es j&aacute; preparam a implanta&ccedil;&atilde;o desse modelo a partir de 2021.</p> <p>VEROTTI, Angelo. O futuro do trabalho remoto. Isto&Eacute; Dinheiro, p. 44, 15 jul. 2020. Excerto adaptado.</p> <p>E express&atilde;o &ldquo;esse modelo&rdquo; se refere:</p>",
        "alternativas": [{
            "texto": "<p>Ao trabalho pontual e produtivo.</p>",
            "correta": false,
            "escolhidas": 0
        }, {
            "texto": "<p>Ao aumento da produtividade.</p>",
            "correta": false,
            "escolhidas": 0
        }, {
            "texto": "<p>Ao trabalho remoto.</p>",
            "correta": true,
            "escolhidas": 0
        }, {
            "texto": "<p>Ao isolamento social.</p>",
            "correta": false,
            "escolhidas": 0
        }, {
            "texto": "<p>Ao avan&ccedil;o tecnol&oacute;gico.</p>",
            "correta": false,
            "escolhidas": 0
        }],
        "utilizacoes": 0,
        "acertos": 0,
        "nivel": 0,
        "feedback": "<p>As rela&ccedil;&otilde;es coesivas do texto permitem concluir que o modelo a que o texto se refere &eacute; ao modelo de trabalho remoto.</p>",
        "_id": "612657b2a3271fa9e6fb6f56",
        "finalizado": true,
      }
    ],
    questao: {
      enunciado: '',
      alternativas: []
    },
    ...this.tempoInicial,
    resetar: false,
    loading: true,
    modalEnvioResposta: false,
    modalInstrucoes: true,
    font: 14,
    respSelecao: '-2',
    progressoEnvio: 0,
    modalSelecioneAlterativa: false,
    loadingSpinner: false,
    prova: {
      _id:"62712af70ad3d51f2341de78",
      _idProjeto:"607496a37ef518352e709009",
      allowShowResults:false,
      arquivado:false,
      ativo:true,
      codigoAcesso:"mld7h3",
      comentarios:[],
      datas:{dataFim: "04/05/2022", dataInicio: "03/05/2022"},
      facilitadores:[],
      horarios:{horarioFim: "10:15", horarioInicio: "10:15"},
      lastModified:"2022-05-03T13:12:53.874Z",
      nome:"Prova: test",
      participantes:[sessionStorage.getItem('user')],
      pause:{
        ativo:false,
        pausesConsultor:[],
        tempoAcumulado:0,
      },
      peso:100,
      realizationDate:null,
      start:1,
      template:{
        _id:"61265faac86419278554af5e",
        niveis:[
          {questoes: [
            {_id: "5eb568a2e7ebd8016cdae7d4"},
            {_id: "5eb568a2e7ebd8016cdae7d5"},
            {_id: "5eb568a2e7ebd8016cdae7d9"},
            {_id: "5eb568a2e7ebd8016cdae7da"},
            {_id: "5eb568a2e7ebd8016cdae7db"},
            {_id: "5eb568a2e7ebd8016cdae7dd"},
            {_id: "5eb568a2e7ebd8016cdae7de"},
            {_id: "5eb568a2e7ebd8016cdae7df"},
            {_id: "5eb568a2e7ebd8016cdae7e0"},
            {_id: "5eb568a2e7ebd8016cdae7e1"},
            {_id: "5eb568a2e7ebd8016cdae7e2"},
            {_id: "5eb568a2e7ebd8016cdae7e4"},
            {_id: "5eb568a2e7ebd8016cdae7e5"},
            {_id: "5eb568a2e7ebd8016cdae7e6"},
            {_id: "5eb568a2e7ebd8016cdae7e7"},
            {_id: "5eb568a2e7ebd8016cdae7e9"},
            {_id: "5eb568a2e7ebd8016cdae7eb"},
            {_id: "5eb568a2e7ebd8016cdae7ec"},
            {_id: "5eb568a2e7ebd8016cdae7ed"},
            {_id: "5eb568a2e7ebd8016cdae7ee"},
            {_id: "6126579fa3271fa9e6fb6f50"},
            {_id: "612657a1a3271fa9e6fb6f51"},
            {_id: "612657a5a3271fa9e6fb6f52"},
            {_id: "612657a9a3271fa9e6fb6f53"},
            {_id: "612657aca3271fa9e6fb6f54"},
            {_id: "612657b0a3271fa9e6fb6f55"},
            {_id: "612657b2a3271fa9e6fb6f56"},
            {_id: "612657b6a3271fa9e6fb6f57"},
          ]},
          {questoes: [
            {_id: "5eb568a2e7ebd8016cdae7f0"},
            {_id: "5eb568a2e7ebd8016cdae7f6"},
            {_id: "5eb568a2e7ebd8016cdae801"},
            {_id: "5eb568a2e7ebd8016cdae802"},
            {_id: "61265d38a3271fa9e6fb6f5d"},
            {_id: "61265d31a3271fa9e6fb6f5c"},
            {_id: "61265d2ca3271fa9e6fb6f5b"},
            {_id: "61265d26a3271fa9e6fb6f5a"},
            {_id: "61265d21a3271fa9e6fb6f59"},
            {_id: "61265d1ca3271fa9e6fb6f58"},
          ]},
          {questoes: [
            {_id: "5eb568a2e7ebd8016cdae805"},
            {_id: "5eb568a2e7ebd8016cdae80e"}
          ]},
          {questoes: [
          
          ]},
          {questoes: [
            {_id: "5eb568a2e7ebd8016cdae819"}
          ]},
        ],
        nome:"Teste",
        qtdeQuestoes:5,
        tempoProva:"10",
        tempoQuestao:"3",
        tipo:"Teste",
      },
      textos:{textoFinal: "", textoInicial: ""},
      tipo:"sincrono"
    },
    texts: [],
    error: false,
    steps: []
  };

  clearProofInterval() {
    if (!this.timer) return;
    try {
      workerTimers.clearInterval(this.timer);
    } catch (err) {
      console.log('O timer já foi desligado. ', err);
    }
  }

  componentDidMount = async () => {
    try {
      window.scrollTo(0, 0);
      //let prova = await this.carregarProva();

      // getting texts
      // const resTexts = await api.get('/v3/prova-template-textos', {
      //   params: {
      //     templateId: prova.template._id,
      //     proofId: sessionStorage.getItem('atividade')
      //   }
      // });
      // const texts = resTexts.data;
      // this.setState({ texts });

      await this.recebendoInfo(this.state.prova);
      // this.setState({ prova });
      // //this.subscribePush(); // se inscreve para receber as mensagens de socket

      // if (!sessionStorage.getItem('firstLogged')) {
      //   await api.put(`/api/prova/${sessionStorage.getItem('atividade')}/change-participant-status/${sessionStorage.getItem('user')}`, {
      //     projectId: sessionStorage.getItem('projeto'),
      //     status: startedProofStatusCode
      //   });

      //   sessionStorage.setItem('firstLogged', true);
      // }

      const { setHaveTour, setRunTour } = this.context;

      const steps= [
        {
          target: '.prova-step-1',
          content: <TourP>Acompanhe aqui o tempo restante para realização da prova. Ao finalizar esse tempo, sua prova
          será encerrada independentemente se você já finalizou todas as questões.</TourP>,
          disableBeacon: true
        },
        {
          target: '.prova-step-2',
          content: <TourP>Aqui são exibidas a quantidade de questões na prova. Conforme você avança nas respostas, o
          número correspondente a questão atual fica evidenciado pela cor amarela. Não é possível retroceder
          nem avançar para a questão seguinte.</TourP>,
          disableBeacon: true
        },
        {
          target: '.prova-step-3',
          content: <TourP>Nesta área você poderá acompanhar o tempo restante para responder a questão atual. Caso o
          tempo seja finalizado antes de você enviar a questão, ela será considerada como errada e o sistema
          prosseguirá para a questão seguinte.</TourP>,
          disableBeacon: true,
        },
        {
          target: '.prova-step-4',
          content: <TourP>Clique no botão A- para diminuir o tamanho do texto e clique no botão A+ para aumentar o tamanho do texto.</TourP>,
          disableBeacon: true
        },
        {
          target: '.prova-step-5',
          content: <TourP>Nesta área será exibido o enunciado da questão. Observe atentamente o que se pede e em seguida escolha a resposta que julgar correta.</TourP>,
          disableBeacon: true
        },
        {
          target: '.prova-step-6',
          content: <TourP>Nesta área você deve escolher a alternativa que julgar correta. Clique sobre ela para selecionar. A
          escolha é obrigatória pois respostas em branco não são aceitas.</TourP>,
          disableBeacon: true
        },
        {
          target: '.prova-step-7',
          content: <TourP>Clique neste botão para confirmar sua escolha e passar para a questão seguinte. Ao responder a
          última questão uma página de ‘atividade encerrada’será exibida.</TourP>,
          disableBeacon: true
        },
      ];
      setHaveTour(true);
      setRunTour(false);

      this.setState({ steps });
    } catch (error) {
      console.error(error);
      this.setState({ error: true });
    }
  };

  /**
   * Carrega a prova e seu respectivo template no state.
   */
  async carregarProva() {
    let resultProva = await apiCalls.provas.getProvas({
      provasIds: [sessionStorage.getItem('atividade')]
    });
    const { provas } = resultProva.data; 
    let prova = provas[0];

    let resTemplate = await apiCalls.templateProva.getTemplateById(prova.template);
    prova.template = resTemplate.data;

    return prova;
  }

  // componentWillUnmount = () => {

  //   Constantes.io.off('Projeto:Stop');
  //   Constantes.io.off('Projeto:Pause');
  //   Constantes.io.off('Users:Prova:TempoExtra');
  //   Constantes.io.off('Users:Prova:QuestaoExcluida');
  //   Constantes.io.off('Projeto:ProvaPause');
  //   Constantes.io.off('Auth:LogoutInstrumento');
  //   Constantes.io.off('Auth:Desconectar');
  //   Constantes.io.off('Projeto:ProvaStop');

  //   this.clearProofInterval();
  //   // workerTimers.clearInterval(this.timer);
  // };

  /**
   *
   * Calcula o tempo restante da prova
   * Busca o template da prova e define as variáveis de tempo com base nele
   * Consulta a próxima questão da prova, com base nas questões já respondidas pelo participante
   * Verifica se a prova já terminou
   */
  recebendoInfo = async (prova) => {
    const projetoId = sessionStorage.getItem('projeto');
    const modulo = sessionStorage.getItem('modulo');
    const atividadeId = sessionStorage.getItem('atividade');
    const user = sessionStorage.getItem('user');

    // Atualizando perguntas do template
    const { template, pause } = prova;

    this.tempoProvaQuestao = template.tempoQuestao * 60 * 1000;
    this.tempoProvaProva = template.tempoProva * 60 * 1000;
    this.quantidadeQuestoes = template.qtdeQuestoes;
    this.respostas.tempos.tempoFimProva =
      this.tempoOnMount + this.tempoProvaProva;

    // this.respostas.perguntasRestantes = template.niveis;

    // Inicializando tempos de pause acumulado da prova e tempo restante
    let tempoPauseAcumuladoProva =
      pause.tempoAcumulado +
      (pause.pausesConsultor.length &&
      pause.pausesConsultor[pause.pausesConsultor.length - 1]
        .tempoFinalizado === 0
        ? this.tempoOnMount -
          pause.pausesConsultor[pause.pausesConsultor.length - 1].tempoIniciado
        : 0);
    let tempoProvaRestante =
      this.respostas.tempos.tempoFimProva - this.tempoOnMount;
    if (pause.ativo) {
      tempoProvaRestante +=
        srvTime() -
        pause.pausesConsultor[pause.pausesConsultor.length - 1].tempoIniciado;
      if (tempoProvaRestante > this.tempoProvaProva) {
        tempoProvaRestante = this.tempoProvaProva;
      }
    }

    let tempoQuestaoRestante = this.tempoProvaQuestao;

    // Verificando se pause foi dado
    if (pause.ativo) {
      this.togglePause();
    }

    // const res = await verificarRespostas(
    //   user,
    //   Constantes.instrumentoProvas,
    //   Constantes.moduloProvas,
    //   atividadeId
    // );

    const res = false;

    let respostaPrevia = res;

    if (respostaPrevia) {
      // Atualizando respostas
      this.respostas = respostaPrevia.resposta;

      // Recebendo enunciado antigo
      let questao = this.respostas.escolhas[
        this.respostas.escolhas.length - 1
      ].questao;

      // Verificando por tempo extra
      let tempoAdicionalProva = 0;
      if (this.respostas.tempos.tempoAdicional.length)
        tempoAdicionalProva = this.respostas.tempos.tempoAdicional.reduce(
          (acc, val) => {
            return acc + val.tempo;
          },
          0
        );
      let tempoAdicionalQuestao = this.respostas.tempos.tempoAdicional.find(
        (t) => t.questao === this.respostas.questaoAtual
      );
      if (tempoAdicionalQuestao) {
        tempoAdicionalQuestao = tempoAdicionalQuestao.tempo;
      } else {
        tempoAdicionalQuestao = 0;
      }

      // Atualizando tempo de prova
      tempoProvaRestante =
        this.respostas.tempos.tempoFimProva - this.tempoOnMount;
      if (pause.ativo) {
        tempoProvaRestante +=
          srvTime() -
          pause.pausesConsultor[pause.pausesConsultor.length - 1]
            .tempoIniciado;
        if (tempoProvaRestante > this.tempoProvaProva) {
          tempoProvaRestante = this.tempoProvaProva;
        }
      }
      // Verificando quantidade de questões perdiddas
      // let questoesPerdidas = Math.floor(
      //   (this.tempoOnMount -
      //     (this.respostas.tempos.tempoAtualProva +
      //       tempoPauseAcumuladoProva +
      //       tempoAdicionalProva)) /
      //     this.tempoProvaQuestao
      // );
      // if (questoesPerdidas < 0) questoesPerdidas = 0;

      // // Checando se já passou da quantidade de questões
      // // if (
      // //   this.respostas.questaoAtual + questoesPerdidas >
      // //   this.respostas.totalQuestoes
      // // )
      //   questoesPerdidas =
      //     this.respostas.totalQuestoes - this.respostas.questaoAtual;

      // // Verificando questões a serem perdidas
      // for (let i = 0; i < questoesPerdidas; i++) {
      //   this.respostas.tempos.tempoAtualProva = Date.now();
      //   this.respostas.errors++;
      //   this.respostas.escolhas[this.respostas.escolhas.length - 1] = {
      //     ...this.respostas.escolhas[this.respostas.escolhas.length - 1],
      //     resposta: {
      //       texto: ''
      //     },
      //     tempoQuestaoDuracao: this.tempoProvaQuestao,
      //     nivel: this.respostas.nivel
      //   };
      //   let resp = {
      //     respostas: this.respostas,
      //     questaoAtual: this.state.questao.enunciado,
      //     modulo,
      //     atividadeId
      //   };
      //   let questaoRecebida = this.state.questionsToUse[0];
      //   questao = questaoRecebida.data.questao;
      //   this.respostas = questaoRecebida.data.respostas;
      // }

      
      // Verificando se prova já acabou
      const finishedProof = tempoProvaRestante <= 0 || this.respostas.questaoAtual > this.quantidadeQuestoes;
      if (
        finishedProof
      ) {
        this.alternativaEscolhida = undefined;
        this.tempoQuestaoDuracao = 0;
        await this.fimDeProva({ performCalc: true });
      } else {
        // Calculando tempo restante da questao
        //let tempoPauseAcumuladoQuestao = pause.pausesConsultor.filter(p => p.iniciado )
        let tempoPauseAcumuladoQuestao = 0;
        const pauses = pause.pausesConsultor.filter(
          (p) =>
            p.tempoIniciado >
            this.respostas.escolhas[this.respostas.escolhas.length - 1]
              .tempoIniciado
        );
        pauses.length
          ? (tempoPauseAcumuladoQuestao = pauses.reduce(
            (acc, value) =>
              value.tempoFinalizado
                ? acc + (value.tempoFinalizado - value.tempoIniciado)
                : acc + this.tempoOnMount - value.tempoIniciado,
            0
          ))
          : (tempoPauseAcumuladoQuestao = 0);


        const tempoQuestaoCalculado =
          this.tempoProvaQuestao -
          (this.tempoOnMount -
            (this.respostas.tempos.tempoAtualProva +
              tempoPauseAcumuladoQuestao +
              tempoAdicionalQuestao));
        tempoQuestaoRestante =
          tempoQuestaoCalculado < this.tempoProvaQuestao
            ? tempoQuestaoCalculado
            : this.tempoProvaQuestao;

        this.setState({
          resetar: true,
          questao: questao,
          loading: false,
          tempoProvaRestante,
          tempoQuestaoRestante,
          tempoPauseAcumuladoProva,
          tempoPauseAcumuladoQuestao
        });
      }
    }
    // Setando número de questoes
    else {
      this.respostas.totalQuestoes = this.quantidadeQuestoes;

      let questaoRecebida = null;
      let actualQuestion = 1;
      if (!this.state.questao.enunciado) questaoRecebida = this.state.questionsToUse[0];
      else {
        actualQuestion = this.state.currentQuestion + 1;
        questaoRecebida = this.state.questionsToUse[actualQuestion + 1]
      }

      // let questaoRecebida = await api.put(
      //   `api/instruments/prova/novaQuestao/${sessionStorage.getItem(
      //     'user'
      //   )}`,
      //   {
      //     questaoAtual: this.state.questao.enunciado, // inicial vazia.
      //     modulo,
      //     atividadeId,
      //     idTemplate: template._id,
      //     tempos: this.respostas.tempos,
      //     tempoProvaProva: this.tempoProvaProva,
      //     totalQuestoes: this.quantidadeQuestoes,
      //     posicaoQuestaoAtual: 0
      //   }
      // );


      let questao = questaoRecebida;
      this.respostas.questaoAtual = actualQuestion;

      this.setState({
        resetar: true,
        currentQuestion: actualQuestion,
        questao: questao,
        loading: false,
        // modalInstrucoes: true,
        tempoProvaRestante,
        tempoQuestaoRestante,
        tempoPauseAcumuladoProva
      });
    }

    // this.timer = setInterval(this.tempoAtualizado, 1000);
    this.timer = workerTimers.setInterval(this.tempoAtualizado, 1000);
  };

  subscribePush = () => {
    Constantes.io.on('disconnect', () => setTimeout(this.subscribePush, 3000));
    Constantes.io.on('Auth:LogoutInstrumento', (userId) => {
      console.info('Auth:LogoutInstrumento');
      if (sessionStorage.getItem('user') === userId.toString()) {
        this.onLogout = true;
        if (this.state.tempoProvaRestante > 0) {
          this.clearProofInterval();
          // workerTimers.clearInterval(this.timer);
          this.props.logout();
        } else this.proximaAtividade({ performCalc: false });
      }
    });
    Constantes.io.on('Projeto:ProvaStopUsers', (data) => {
      console.info('Projeto:ProvaStopUsers')
      let { usersDeslogados } = data;
      let myUserId = sessionStorage.getItem('user');

      if (usersDeslogados && usersDeslogados.length && myUserId) {
        let mustLogout = usersDeslogados.some(
          (d) => d.toString() === myUserId.toString()
        );
        if (mustLogout) {
          let tempoQuestaoDuracao =
          this.tempoProvaQuestao - this.state.tempoQuestaoRestante;

          this.alternativaEscolhida = undefined;
          this.tempoQuestaoDuracao = tempoQuestaoDuracao;
          this.fimDeProva({ performCalc: false });
        }
      }
    });
    Constantes.io.on('Projeto:ProvaStop', (data) => {
      console.info('Projeto:ProvaStop')
      let stopedProvaId = data.provaId;
      let provaAtual = sessionStorage.getItem('atividade');

      if (stopedProvaId === provaAtual) {
        this.setState({
          modalEnvioResposta: true,
          progressoEnvio: 5
        });
        this.alternativaEscolhida = undefined;
        this.tempoQuestaoDuracao = this.tempoProvaQuestao - this.state.tempoQuestaoRestante;
        this.fimDeProva({ performCalc: false, stop: true });
      }
    });
    Constantes.io.on('Projeto:ProvaPause', (data) => {
      let pausedProvaId = data.provaId;
      let provaAtual = sessionStorage.getItem('atividade');
      if (pausedProvaId === provaAtual) {
        if (!data.ativo) {
          this.setState({ tempoPauseAcumulado: data.tempoAcumulado });
        }
        this.togglePause();
      }
    });
    Constantes.io.on('Projeto:ProvaPlay', (data) => {
      try {
        let playedProvaId = data.provaId;
        let provaAtual = sessionStorage.getItem('atividade');
        // this.setState({
        //   tempoProvaRestante:
        //     this.state.tempoProvaRestante -
        //     this.tempoOnMount -
        //     this.state.prova.pause.pausesConsultor[
        //       this.state.prova.pause.pausesConsultor.length - 1
        //     ].tempoIniciado
        // });

        if (playedProvaId === provaAtual) this.togglePause();
      } catch (error) {
        console.error(error);
      }
    });

    Constantes.io.on('Users:Prova:TempoExtra', (data) => {
      if (data.id === sessionStorage.getItem('user')) {
        let { tempoQuestaoRestante, tempoProvaRestante } = this.state;

        if (tempoQuestaoRestante + data.tempoAdicional < this.tempoProvaQuestao)
          tempoQuestaoRestante += data.tempoAdicional;
        else tempoQuestaoRestante = this.tempoProvaQuestao;

        tempoProvaRestante = data.tempoFim - srvTime();
        if (tempoProvaRestante > this.tempoProvaProva) {
          tempoProvaRestante = this.tempoProvaProva;
        }

        this.setState({ tempoQuestaoRestante, tempoProvaRestante });
      }
    });
    Constantes.io.on('Users:Prova:QuestaoExcluida', (data) => {
      if (data.id === sessionStorage.getItem('user')) {
        this.respostas = data.resposta.resposta;
        this.pontuacao = data.resposta.pontuacao;
        let { tempoProvaRestante } = this.state;
        tempoProvaRestante =
          this.respostas.tempos.tempoFimProva - srvTime();

        if (tempoProvaRestante > this.tempoProvaProva) {
          tempoProvaRestante = this.tempoProvaProva;
        }
        this.setState({ tempoProvaRestante });
      }
    });
  };

  proximaAtividade = async ({ performCalc = false, stop }) => {
    this.props.action();
    // await api
    //   .put(`api/user/respostas/finalizar`, {
    //     atividade: Constantes.moduloProvas,
    //     atividadeId: atividadeId,
    //     questaoAtual: this.state.questao.enunciado,
    //     alternativaEscolhida: this.alternativaEscolhida,
    //     tempoQuestaoDuracao: this.tempoQuestaoDuracao,
    //     projectId: sessionStorage.getItem("projeto"),
    //     stop
    //   })
    //   .then(() => {

    //     if (performCalc) {
    //       apiCalls.provas.calcProofResults({
    //         idProjeto: sessionStorage.getItem("projeto"),
    //         provaId: atividadeId
    //       });
    //     }   

    //     this.setState({ loadingSpinner: true });
    //     this.alternativaEscolhida = undefined;
    //     this.tempoQuestaoDuracao = undefined;
    //     this.props.action('fimDeProva');
    //   })
    //   .catch(async (err) => {
    //     console.error(err);
    //     if (err.response) {
    //       console.error(err.response.data);
    //     }
    //     this.alternativaEscolhida = undefined;
    //     this.tempoQuestaoDuracao = undefined;
    //   });
  };
  semAlternativaToggle = () => {
    this.setState({
      modalSelecioneAlterativa: false
    });
  };

  gravarResposta = async (retry = false) => {
    this.clearProofInterval();
    const atividadeId = sessionStorage.getItem('atividade');
    if (retry) var atualizadoEm = retry;
    else var atualizadoEm = srvTime();
    let resp = {
      instrumento: Constantes.instrumentoProvas,
      resposta: this.respostas,
      pontuacao: this.pontuacao,
      atualizadoEm
    };

    this.setState({
      progressoEnvio: 75
    });
    await this.sleep(500);

    // Verifica se terminou a prova
    if (this.respostas.questaoAtual === this.quantidadeQuestoes) {
      let respostas = this.respostas;
      await this.fimDeProva({ performCalc: false });
    } else {
      this.novaQuestao();
    }
    this.setState({
      progressoEnvio: 100
    });
  };
  novaQuestao = async () => {
    const { template } = this.state.prova;

    // Recebendo nova pergunta
    const atividadeId = sessionStorage.getItem('atividade');
    const modulo = sessionStorage.getItem('modulo');

    let actualQuestion = this.state.currentQuestion + 1;
    let questaoRecebida = this.state.questionsToUse[actualQuestion];

    if (actualQuestion > 5) {
      this.fimDeProva({ performCalc: false });
    }

    this.respostas.questaoAtual = actualQuestion;
    let questao = questaoRecebida;

    // Resetar questao
    this.setState({
      modalEnvioResposta: false
    });
    this.respostas.tempos.tempoAtualProva = srvTime();
    this.setState({
      resetar: true,
      currentQuestion: actualQuestion,
      questao,
      loading: false,
      tempoQuestaoRestante: this.tempoProvaQuestao,
      progressoEnvio: 0
    });
    window.scrollTo(0, 0);
    this.timer = workerTimers.setInterval(this.tempoAtualizado, 1000);

    this.alternativaEscolhida = undefined;
    this.tempoQuestaoDuracao = undefined;

    // await api
    //   .put(
    //     `api/instruments/prova/novaQuestao/${sessionStorage.getItem('user')}`,
    //     {
    //       questaoAtual: this.state.questao.enunciado,
    //       modulo,
    //       atividadeId,
    //       idTemplate: template._id,
    //       tempos: this.respostas.tempos,
    //       alternativaEscolhida: this.alternativaEscolhida,
    //       tempoQuestaoDuracao: this.tempoQuestaoDuracao,
    //       posicaoQuestaoAtual: this.respostas.questaoAtual - 1
    //     }
    //   )
    //   .then(async (res) => {
        
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   })
    //   .finally(() => {
    //     this.alternativaEscolhida = undefined;
    //     this.tempoQuestaoDuracao = undefined;
    //   });
  };

  respostaEnviada = async (resposta) => {
    this.respostas.tempos.tempoAtualProva = srvTime();
    this.setState({
      progressoEnvio: 30
    });
    await this.sleep(500);
    // Checando se o timer da questão não acabou
    if (resposta !== -1) {
      // Salvando resposta
      this.respostaEnviadaConfirmada(resposta);
      this.setState({
        respSelecao: ''
      });
    } else {
      // Atualizando tempos
      let tempoQuestaoDuracao = this.tempoProvaQuestao;

      this.alternativaEscolhida = undefined;
      this.tempoQuestaoDuracao = tempoQuestaoDuracao;

      await this.gravarResposta();
    }

    // Notifica user
    //this.notificarRespostaEnviada();
  };

  respostaEnviadaConfirmada = async (resposta) => {
    document.getElementsByName('choice').forEach(function(child) {
      if (child.checked === true) {
        child.checked = false;
      }
    });

    // // Atualizando tempos
    let tempoQuestaoDuracao =
      this.tempoProvaQuestao - this.state.tempoQuestaoRestante;

    this.alternativaEscolhida = resposta;
    this.tempoQuestaoDuracao = tempoQuestaoDuracao;
    await this.gravarResposta();
    //await this.sleep(1000);

    // Notifica user
    //this.notificarRespostaEnviada();
  };

  tempoAtualizado = () => {

    if (!this.state.modalPause) {
      let { tempoProvaRestante, tempoQuestaoRestante } = this.state;

      tempoProvaRestante = atualizarTempo(tempoProvaRestante);
      tempoQuestaoRestante = atualizarTempo(tempoQuestaoRestante);

      // Verifica se tempo da prova terminou
      if (tempoProvaRestante <= 0) {
        this.setState({
          modalEnvioResposta: true,
          progressoEnvio: 5
        });
        this.alternativaEscolhida = undefined;
        this.tempoQuestaoDuracao = this.tempoProvaQuestao - this.state.tempoQuestaoRestante;
        this.fimDeProva({ performCalc: true });
      }

      // Verifica se tempo da questao acabou
      if (tempoQuestaoRestante <= 0) {
        this.setState({
          modalEnvioResposta: true,
          progressoEnvio: 0,
          tempoQuestaoRestante: this.tempoProvaQuestao
        });
        this.sleep(1000).then(() => {
          this.respostaEnviada(-1);
          tempoQuestaoRestante = this.tempoProvaQuestao;
          this.setState({
            tempoProvaRestante,
            tempoQuestaoRestante
          });
        });
      } else {
        this.setState({
          tempoProvaRestante,
          tempoQuestaoRestante
        });
      }
    }
  };

  fimDeProva = async ({ performCalc, stop=false }) => { 
    console.log("fim")
    this.clearProofInterval();
    this.proximaAtividade({ performCalc, stop });
  };

  resetFeedback = () => {
    this.setState({
      resetar: false
    });
  };

  checkEnvio = async (respSelecao) => {
    this.context.setRunTour(false)
    if (this.state.respSelecao === '') {
      this.setState({
        modalSelecioneAlterativa: true
      });
    } else {
      var b = document.getElementById('botao');
      b.blur();
      // Checando se resposta vazia

      if (respSelecao === '-2') {
        this.setState({ modalSelecioneAlterativa: true });
      } else {
        this.clearProofInterval();
        this.setState({
          modalEnvioResposta: true,
          progressoEnvio: 5
        });
        await this.sleep(500);
        this.respostaEnviada(respSelecao);
        var t = document.getElementById('logo');
      }
    }
  };

  sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  sobeTela = () => {
    let it = document.getElementById('top');
    it.focus();
    document.getElementsByTagName('body')[0].scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  RespostaEscolhida = (e) => {
    var list = document.getElementsByClassName('ch');
    for (var item of list) {
    }

    this.setState({
      respSelecao: e
    });
  };

  atualizarBarraEnvio = (statusEnvio) => {
    this.setState({
      progressoEnvio: statusEnvio
    });
  };

  /* TOGGLES */
  toggleInstrucoes = () => {
    if (this.state.modalInstrucoes && !this.state.alreadyOpened) {
      this.context.setRunTour(true);
      this.setState({ alreadyOpened: true });
    } 
    this.setState((previousState) => ({
      modalInstrucoes: !previousState.modalInstrucoes
    }));
  };

  togglePause = () => {
    this.setState((previousState) => ({
      modalPause: !previousState.modalPause
    }));
  };
  // ------- DRIX --------

  /// AUMENTA E DIMINUI FONT///

  aumentaFonte = () => {
    let { font } = this.state;
    if (font + 5 >= 30) return notifyError('Não é possível aumentar mais.');
    this.setState({ font: font + 5 });
  };

  diminuiFonte = () => {
    let { font } = this.state;
    if (font - 5 <= 7) return notifyError('Não é possível diminuir mais.');

    this.setState({ font: font - 5 });
  };

  callback = (data) => {
    if (data.status === 'finished' || data.action === 'close') {
        // This explicitly stops the tour (otherwise it displays a "beacon" to resume the tour)
        this.context.setRunTour(false);
    }
  }

  detectMob = () => {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];
    
    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}

  render() {
    if (this.state.error) {
      return <h1>Não foi possível carregar a atividade. Por favor, recarregue a página</h1>;
    }

    if (this.state.loading) {
      return <Loading />;
    }
    /*if( !this.state.modalEnvioResposta){}*/
    return (
      this.state.questao.alternativas.length !== 0 && (
        <div className="text-center back" id="logo" tabIndex="0">
        <Joyride
          showProgress={true}
          steps={this.state.steps}
          run={this.context.runTour}
          continuous={true}
          callback={this.callback}
          disableOverlayClose={true}
          spotlightClicks={true}
          disableBeacon={true}
          locale={{
            back: 'Voltar',
            close: 'Fechar',
            last: 'Fim',
            next: 'Próximo',
            skip: 'Pular'
          }}
          styles={{
            options: {
              primaryColor: '#012a4a'
            },
            tooltipContainer: {
              textAlign: 'left',
            },
          }}
        />
          <Modal
            isOpen={this.state.modalEnvioResposta}
            className={this.props.className}
          >
            <ModalHeader>Questão {this.respostas.questaoAtual}:</ModalHeader>
            <ModalBody>
              {/*Você selecionou a alternativa{" "}
              {String.fromCharCode(97 + this.checkResposta).toUpperCase()}. <br />
      Sua resposta está sendo enviada...*/}
              Enviando...
              {!this.state.loadingSpinner ? (
                <Progress color="success" value={this.state.progressoEnvio} />
              ) : (
                <img src={require(`../../Assets/_assets/${spinner}`)} />
              )}
            </ModalBody>
            <ModalFooter />
          </Modal>

          {/* ----- MODAL DE PAUSE ----- */}
          <Modal
            isOpen={this.state.modalPause}
            className={this.props.className}
          >
            <ModalHeader>Atenção</ModalHeader>
            <ModalBody>
              Prova pausada. Aguardando play do consultor...
            </ModalBody>
          </Modal>

          <Modal
            isOpen={this.state.modalSelecioneAlterativa}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggle}>Atenção</ModalHeader>
            <ModalBody>Selecione uma alternativa</ModalBody>
            <ModalFooter>
              <Button color="success" onClick={this.semAlternativaToggle}>
                Ok
              </Button>{' '}
            </ModalFooter>
          </Modal>
          {this.state.modalEnvioResposta ? (
            false
          ) : (
            <Base>
              <Container>
                <br />
                <br />
                <br />
                <Row>
                  <Col className="text-left">
                    <Titulo id="top">Prova</Titulo>
                  </Col>
                  <Col className="text-right">
                    <Icon
                      src={require('../../Assets/_assets/doubt.png')}
                      alt="Instrucoes"
                    />
                    <Inst onClick={this.toggleInstrucoes}>Instruções</Inst>
                  </Col>
                </Row>
                <br />
                <Modal
                  isOpen={this.state.modalInstrucoes}
                  className={this.props.className}
                >
                  <ModalBody>
                    <Instrucoes2>
                      <div>
                      <p
                        dangerouslySetInnerHTML={{
                            __html: `<p>Car@ participante, a seguir algumas instru&ccedil;&otilde;es para que voc&ecirc; possa realizar a entrega das quest&otilde;es da prova. Lembrando que essas quest&otilde;es s&atilde;o ilustrativas e n&atilde;o ter&atilde;o seus resultados considerados ou salvos no IAPP.</p>
                            <p>As instru&ccedil;&otilde;es a seguir s&atilde;o relacionadas exclusivamente ao teste atual. Na aplica&ccedil;&atilde;o oficial, as instru&ccedil;&otilde;es poder&atilde;o ser diferentes. </p>
                            <p><strong>INSTRU&Ccedil;&Otilde;ES PARA O TESTE</strong></p>
                            <ul>
                            <li>
                            <p>Qualquer problema t&eacute;cnico encontrado &eacute; importante que seja notificado, por meio de imagens e textos para o consultor respons&aacute;vel</p>
                            </li>
                            <li>
                            <p>Voc&ecirc; ter&aacute; 10 minutos para finalizar a prova teste, composta por&nbsp;quest&otilde;es&nbsp;de m&uacute;ltiplas alternativas (A a E)</p>
                            </li>
                            <li>
                            <p>Cada quest&atilde;o lhe oferece at&eacute;&nbsp;<strong>3 minutos</strong>&nbsp;para responder. Observe que se tomar todo o tempo por quest&atilde;o&nbsp;<strong>N&Atilde;O TER&Aacute; TEMPO</strong>&nbsp;para finalizar todas as quest&otilde;es previstas para a prova teste.</p>
                            </li>
                            <li>
                            <p>Voc&ecirc;&nbsp;<strong>DEVE</strong>&nbsp;escolher uma alternativa para avan&ccedil;ar para a pr&oacute;xima quest&atilde;o. N&atilde;o ser&atilde;o aceitas respostas em branco, salvo quando o tempo acaba.</p>
                            </li>
                            <li>
                            <p>Voc&ecirc;&nbsp;<strong>DEVE</strong>&nbsp;clicar no bot&atilde;o Enviar para registrar sua resposta.</p>
                            </li>
                            </ul>`
                        }}
                      ></p></div>
                    </Instrucoes2>
                  </ModalBody>
                  <ModalFooter>
                    <Container className="text-center">
                      <Button color="success" onClick={this.toggleInstrucoes}>
                        ok
                      </Button>{' '}
                    </Container>
                  </ModalFooter>
                </Modal>

                <ContadorProva
                  counter={formatarTempo(this.state.tempoProvaRestante)}
                  tempo={
                    100 * (this.state.tempoProvaRestante / this.tempoProvaProva)
                  }
                />
                <br />
                <br />

                <Row>
                  {montaPaginas(this.respostas.totalQuestoes, this.respostas.questaoAtual)}
                  <Clear />
                </Row>
              </Container>
              <br className="bigger-screen" />
              <br className="bigger-screen" />
              <Container className="pv">
                <Row>
                  <Col sm="8">
                    <br className="small-screen" />
                    <div className={!this.detectMob() ? "small-screen" : "small-screen prova-step-4"}>
                      <br />
                      <Fonts className="font-button">
                        <button className="a" onClick={this.diminuiFonte}>
                          A-
                        </button>
                      </Fonts>
                      <Fonts className="font-button">
                        <button className="aPlus" onClick={this.aumentaFonte}>
                          A+
                        </button>
                      </Fonts>
                    </div>
                    <br className="small-screen" />
                    <Row id="enunciado">
                      <span>
                        <Enunciado
                          contador={this.respostas.questaoAtual + ' - '}
                          estilo={this.state.font + 'px'}
                        >
                          <div
                            className="prova-step-5"
                            dangerouslySetInnerHTML={{
                              __html: this.state.questao.enunciado
                            }}
                          />
                        </Enunciado>
                      </span>
                      {/*{this.state.questao.enunciado.split("\n").map((item, i) => {
                      return (
                        <p style={{ fontSize: this.state.font + "px" }} key={i}>
                          {i == 0 ? this.respostas.questaoAtual + " - " + item : item}
                        
                      );
                    })}*/}
                    </Row>
                    <br />
                    <br />
                    <div className="prova-step-6">
                    {this.state.questao.alternativas.map(
                      (alternativa, index) => (
                        <Row
                          key={'Alternativa' + index}
                          onFocus={() => this.RespostaEscolhida(index)}
                          name="choice"
                        >
                          <Options
                            id={opts}
                            tempo={
                              100 *
                              (this.state.tempoQuestaoRestante /
                                this.tempoProvaQuestao)
                            }
                            sobe={this.sobeTela}
                            value={index}
                            className="ch"
                            font={this.state.font}
                            contador={
                              String.fromCharCode(97 + index).toUpperCase() +
                              ' : '
                            }
                            texto={alternativa.texto}
                          />
                        </Row>
                      )
                    )}
                    </div>
                  </Col>
                  <Col
                    sm="4"
                    id="tempo-questao-wrapper"
                    className={this.detectMob() ? "bigger-screen" : "bigger-screen prova-step-3"}
                  >
                    <div id="tempo-questao">
                    <ContadorQst
                          counter={formatarTempo(
                            this.state.tempoQuestaoRestante
                          )}
                          tempo={
                            100 *
                            (this.state.tempoQuestaoRestante /
                              this.tempoProvaQuestao)
                          }
                      />
                      <br />
                      <div className={this.detectMob() ? "" : "prova-step-4"}>
                      <Fonts>
                        <button className="a" onClick={this.diminuiFonte}>
                          A-
                        </button>
                      </Fonts>
                      <Fonts>
                        <button className="aPlus" onClick={this.aumentaFonte}>
                          A+
                        </button>
                      </Fonts>
                      </div>
                    </div>
                  </Col> 
                </Row>
              </Container>
              <Container className="text-center">
                <Button
                  className="prova-step-7"
                  onClick={() => this.checkEnvio(this.state.respSelecao)}
                  id="botao"
                >
                  CONFIRMAR
                </Button>
                <Footer />
              </Container>
            </Base>
          )}
        </div>
      )
    );
  }
}
export default Prova;
