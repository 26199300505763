import React, { Component } from "react";
import Draggable from "react-draggable";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Container,
  Row,
  Col
} from "reactstrap";
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import api from "../../../Services/api";

import {
  Base,
  Inst,
  Instrucoes,
  Button,
  Contador,
  Icon,
  Titulo,
  Footer
} from "../../Assets/Assets";
import Loading from "../../../Services/Loading";
import styled from "styled-components";
import Timer from "../../../Services/Timer";
import { millisToMinutesAndSeconds } from "../../../Pages/Instruments/Utils/Calculos";
import { srvTime } from '../Utils';
import apiCalls from "../../../config/apiCalls";
import './AvaliacaoBinaria.module.css';
import styles from './AvaliacaoBinaria.module.css';
import ErrorComponent from '../../../Services/ErrorComponent';
import ErrorBoundary from '../../../components/error-boundary';

import { Constantes } from "../../../Services/Constantes";
import fetchInstrumentoCase from './utils/fetch-instrumento-case';
import { converterMsParaTempo } from "../../../Pages/Instruments/Utils/Calculos";
import { notifyError } from "../../../Services/Notificacoes";
import Messages from '../../../static/messages';

const Handle = styled.div`
  background-color: #00294c;
  padding: 30px 15px;
  border-radius: 7px;
  box-shadow: 0px 0px 10px 1px rgba(0,41,76,1);
  word-wrap: break-word;
  p {
    color: #fafafa;
    margin: 0;
    font-weight: bolder;
    font-size: 1.2rem;
    text-align: justify;
  }
`;

const Switch = styled.div`
  width: 60%;
  position: relative;
  @media (max-width: 660px) {
    width: 60%;
  }
`;

const P = styled.p`
  font-size: 13px;
`;
// Imagens
const spinner = Constantes.imagemSpinner;

function Duel(props) {

  return (
    <>
    <Row>
      <Col sm="12" className="text-center">
        <Switch style={{ cursor: 'pointer' }}>
          <div className={props.styles.choosenOption}>Opção selecionada</div>
          <Draggable
            axis="x"
            handle=".handle"
            defaultPosition={{ x: 0, y: 0 }}
            position={{ x: 0, y: 0 }}
            scale={1}
            bounds={{ left: 0, top: 0, right: 300, bottom: 0 }}
            onStop={(e, ui) =>
              props.handleStop(
                e,
                ui,
                props.dueloAtual.resultadoEmbates[0]._id)}
          >
            <Handle className="handle">
              <p>{props.dueloAtual.resultadoEmbates[0].value}</p>
            </Handle>
          </Draggable>
        </Switch>
      </Col>
    </Row>
    <br />
    <br />
    <Row>
      <Col sm="12" className="text-center">
        <Switch style={{ cursor: 'pointer' }}>
          <div className={props.styles.choosenOption}>Opção selecionada</div>
          <Draggable
            axis="x"
            handle=".handle"
            defaultPosition={{ x: 0, y: 0 }}
            position={{ x: 0, y: 0 }}
            scale={1}
            bounds={{ left: 0, top: 0, right: 275, bottom: 0 }}
            onStop={(e, ui) =>
              props.handleStop(
                e,
                ui,
                props.dueloAtual.resultadoEmbates[1]._id)
            }>
            <Handle className="handle">
              <p>{props.dueloAtual.resultadoEmbates[1].value}</p>
            </Handle>
          </Draggable>
        </Switch>
      </Col>
    </Row>
    </>
  );
}

Duel.propTypes = {
  
  styles: PropTypes.object,
  dueloAtual: PropTypes.array,

  handleStop: PropTypes.func,
}

class AvaliacaoBinaria extends Component {
  state = {
    loading: true,
    tempos: {
      tempoRestante: 0
    },
    pararTimer: false,
    modalInstrucoes: false,
    modalConfirmacao: false,
    dueloAtual: {},
    hasError: false,
    votos: 0,
    loadingSpinner: false,
    intervalResetRequest: false,
    haveError: false,
    errorCount: 0,
    errorRetry: 10
  }

  // Variaveis
  numeroVotosUsuario = 0
  proCriterio = []
  conCriterio = []
  duelos = []
  onLogout = false
  // Tempos
  tempoOnMount = srvTime()
  tempoTotal = 0

  static propTypes = {
    tableName: PropTypes.string.isRequired,
    idInstrumento: PropTypes.string.isRequired,
    rodada: PropTypes.number.isRequired,
    idUser: PropTypes.string.isRequired,
    idDinamica: PropTypes.string.isRequired,
    idProjeto: PropTypes.string.isRequired,
  }

  // Funções de estado
  async componentDidMount() {
    try {
      this.setState({ loading: true });
      this.subscribePush()
      await this.recebendoInfo()
      api
      .get(
        `api/projects/rodadas/atualizar/${sessionStorage.getItem(
          "projeto"
        )}/${localStorage.getItem("dinamica")}/get-tempos`,
        {
          params: {
            userId: sessionStorage.getItem('user'),
            rodadaAtual: this.props.rodada,
            instrumento: Constantes.instrumentoCaseIndividual,
          }
        }
      )
      await this.configurarEmbate();

    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ loading: false });

    }
  }

  componentWillUnmount = () => {
    Constantes.io.off("Projeto:Stop")
    Constantes.io.off("Projeto:NovosTempos")
    Constantes.io.off("Auth:LogoutInstrumento")
  }

  /**
   * verifica se o binário terminou com base na resposta enviada pelo servidor
   * ao consultar um novo embate.
   * 
   * @param {Obj} serverResponse 
   */
  checkBinarioFinished(serverResponse) {
    if (
      serverResponse.hasOwnProperty("finalizado") &&
      serverResponse.finalizado === true) {
        return true;
    } else {
      return false;
    }
  }

  /**
   * Finaliza o binário.
   */
  endBinario = async () => {
    try {
      const idUser = sessionStorage.getItem("user") || this.props.idUser;
      const idInstrumento = this.props.idInstrumento;
      const idProjeto = this.props.idProjeto;
      const idDinamica = this.props.idDinamica;
      const indexRodadaAtual = this.props.rodada;
      
      await apiCalls.case.calculaBinario(idInstrumento);
      await apiCalls.case.finalizarBinario({
        queryParams: {
          idUser,
          idInstrumento,
          idProjeto,
          indexRodadaAtual,
          idDinamica
        }
      });
      
      this.props.action(); // DEVE CHAMAR ESSA FUNÇÃO
    } catch (error) {
      console.error(error); 
      this.setState({ errorCount: this.state.errorCount + 1 });
      setTimeout(() => this.endBinario(), 1000);
    }
  }

  configurarEmbate = async () => {
    try {
      const group = this.props.tableName;
      const idUser = sessionStorage.getItem("user") || this.props.idUser;
      const idInstrumento = this.props.idInstrumento;
      const embate = await apiCalls.binary.getNovoDuelo({
        queryParams: {
          group,
          idUser,
          idInstrumento,
          projectId: sessionStorage.getItem("projeto"), 
          dynamicId: sessionStorage.getItem("atividade"), 
          roundIndex: this.props.rodada
        }
      });
      const isFinalizado = this.checkBinarioFinished(embate);
      if (isFinalizado) {
        this.setState({
          loading: true,
          errorCount: 0
        })
        await this.endBinario();
      } else {
        this.setState({
          dueloAtual: embate,
          modalInstrucoes: true,
          embate,
          loading: false,
          errorCount: 0
        });
      }

    } catch (err) {
      console.error(err);
      notifyError("Não foi possível consultar o próximo embate! Por favor, recarregue a página");
    }
  }

  recebendoInfo = async () => {
    if (this.state.errorCount >= this.state.errorRetry) {
      return this.setState({ haveError: true, loading: false });
    }
    try {
      const instrumento = await fetchInstrumentoCase(this.props);
      
      let tempos = instrumento.tempos.binario;
      this.tempoTotal      = tempos.tempoFim - tempos.tempoInicio;
      tempos.tempoRestante = tempos.tempoFim - this.tempoOnMount;
      
      if (tempos.tempoRestante < 0) tempos.tempoRestante = 0

        this.setState({
          tempos,
          errorCount: 0
        });
    } catch (err) {
      console.error(err);
      this.setState({ errorCount: this.state.errorCount + 1 });
      setTimeout(() => this.recebendoInfo(), 1000);
    }




   
    // const usuarioId = sessionStorage.getItem("user");
    // const progressoBinario = await apiCalls.binary.getProgressoBinario(
    //   usuarioId
    // );
    // if (progressoBinario.binarioFinalizado) {
    // } else {
    //     const dueloBinario = await apiCalls.binary.getNovoDuelo();
    //     this.numeroVotosUsuario = this.duelos.length
    //     const instrumento = await fetchInstrumentoCase(this.props);
    //     // Inicializando tempo
    //     let tempos = instrumento.tempos.binario
    //     this.tempoTotal = tempos.tempoFim - tempos.tempoInicio
    //     tempos.tempoRestante = tempos.tempoFim - this.tempoOnMount
    //     if (tempos.tempoRestante < 0) tempos.tempoRestante = 0
    //     if (!progressoBinario.duelosVisualizados || 
    //         (progressoBinario.duelosVisualizados && 
    //           !progressoBinario.duelosVisualizados.length)) {
    //       this.setState({
    //         modalInstrucoes: true
    //       });
    //     }
    //     this.setState({
    //       dueloAtual: dueloBinario
    //     });
    // }
    // this.setState({ loading: false });
  }

  subscribePush = () => {
    Constantes.io.on("Project:SyncConsultantTime", data => {
      if (Object.keys(data.tempos).length && data.instrumento === Constantes.instrumentoCaseIndividual && data.userId === sessionStorage.getItem('user')) {

        let tempos = data.tempos.binario
        //tempos.tempoRestante = tempos.tempoFim - srvTime()
        this.tempoTotal = tempos.tempoFim - tempos.tempoInicio;
        this.setState({
          tempos,
          intervalResetRequest: true
        });
        this.setState({ tempos })
      }
    })

    Constantes.io.on("Projeto:Stop", data => {
      if (
        data.instrumento === Constantes.instrumentoCaseIndividual &&
        data.start.binario === 0 &&
        data.rodada === this.props.rodada
      ) {
        this.props.history.go();
      }
    })
    Constantes.io.on("Projeto:NovosTempos", data => {
      if (data.instrumento === Constantes.instrumentoCaseIndividual) {
        let tempos = data.tempos.binario;
        //tempos.tempoRestante = tempos.tempoFim - srvTime();
        this.tempoTotal = tempos.tempoFim - tempos.tempoInicio;
        this.setState({
          tempos,
          intervalResetRequest: true
        });
      }
    });
    Constantes.io.on("Auth:LogoutInstrumento", userId => {
      if (sessionStorage.getItem("user") === userId.toString()) {
        this.onLogout = true
        if (this.state.tempos.tempoRestante > 0) this.setState({ pararTimer: true })
        else {
          console.log("Auth:LogoutInstrumento");
        }
      }
    })
  }

  handleStop = async (e, ui, idCriterioAvaliado) => {
    const maxX = window.screen.width <= 450 ? 175 : 275;
    if (ui.x >= maxX) {
      await this.handleCriterioEscolhido(idCriterioAvaliado);
    }
  }

  // Funções de interação
  handleCriterioEscolhido = async idCriterioAvaliado => {
      this.setState({
        modalConfirmacao: true
      })
      await this.gravarResposta(idCriterioAvaliado);
  }

  saveUserResponse = async (selectedCriterionId) => {
    const idUser = sessionStorage.getItem("user") || this.props.idUser;
    const idProjeto = sessionStorage.getItem("projeto") || this.props.idProjeto;
    const idDinamica = sessionStorage.getItem("atividade") || this.props.idDinamica;
    const idInstrumento = this.props.idInstrumento;

    await apiCalls.binary.saveUserResponse({
      reqBody: {
        selectedCriterionId
      },
      queryParams: {
        idUser,
        idProjeto,
        idDinamica,
        idInstrumento
      }
      
    });
    // await apiCalls.case.calculaBinario(idInstrumento);
  }

  gravarResposta = async (selectedCriterionId) => {
    if (this.state.errorCount >= this.state.errorRetry) {
      notifyError("Não foi possível salvar a resposta! Por favor, recarregue a página e tente novamente");
      return this.setState({ modalConfirmacao: false, errorCount: 0 });
    }
    try {
      await this.saveUserResponse(selectedCriterionId);
      await this.avaliarProxima();
      this.setState({ errorCount: 0 });
    } catch (error) {
      console.error(error);
      this.setState({ errorCount: this.state.errorCount + 1 });
      setTimeout(() => this.gravarResposta(selectedCriterionId), 1000);
    } finally {
      this.setState({
        modalConfirmacao: false
      })
    }
  }

  /**
   * carrega um novo embate.
   * termina a atividade caso o backend retorne finalizado == true no payload.
   */
  avaliarProxima = async () => {
    const group = this.props.tableName;
    const idUser = sessionStorage.getItem("user") || this.props.idUser;
    const idInstrumento = this.props.idInstrumento;
    try {
      const novoEmbate = await apiCalls.binary.getNovoDuelo({
        queryParams: {
          group,
          idUser,
          idInstrumento,
          projectId: sessionStorage.getItem("projeto"), 
          dynamicId: sessionStorage.getItem("atividade"), 
          roundIndex: this.props.rodada
        }
      });
      const isFinalizado = this.checkBinarioFinished(novoEmbate);
      if (isFinalizado) {
        this.setState({
          loading: true
        });
        await this.endBinario();
      } else {
        this.setState({
          dueloAtual: novoEmbate
        });
      }
    } catch (error) {
      console.error(error);
      notifyError("Não foi possível consultar o próximo embate! Por favor, recarregue a página e tente novamente");
    }
  }

  tempoAtualizado = tempo => {
    this.setState(previousState => ({
      tempos: {
        ...previousState.tempos,
        tempoRestante: tempo
      }
    }))
  }

  toggleInstrucoes = () => {
    this.setState(previousState => ({
      modalInstrucoes: !previousState.modalInstrucoes
    }))
  }

  toggleConfirmacao = () => {
    this.setState(previousState => ({
      modalConfirmacao: !previousState.modalConfirmacao
    }))
  }

  // deverá ser refatorado no futuro
  scrollTop = () => {
    setTimeout(() => {
      window.scrollTo(0,0);
    }, 25);
  }

  render() {
    if (this.state.haveError) return <ErrorComponent />;
    if (this.state.loading) return <Loading />;
    if (this.state.hasError) return <h1>Não foi possível carregar atividade. Por favor, recarregue a página e tente novamente</h1>;

    return (
      <div className="text-left back" id="logo" tabIndex="0">
        <Modal
          isOpen={this.state.modalConfirmacao}
          toggle={this.toggleConfirmacao}
          className={this.props.className}
          returnFocusAfterClose={false}
          >
          <ModalHeader>Critérios</ModalHeader>
          <ModalBody>
            Enviando escolha...
          </ModalBody>
          <ModalFooter>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>Enviando. Por favor não recarregue a tela. <img style={{ width: 20 }} src={require(`../../Assets/_assets/${spinner}`)} /></span>
              {
                this.state.errorCount > 0 ?
                  <><span style={{ fontSize: 12 }}>Tentativas {this.state.errorCount} / {this.state.errorRetry}.</span></>
                : null
              }
            </div>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalInstrucoes}
          className={this.props.className}>
          <ModalBody>
            <Instrucoes>
              <div>
                <b>INSTRUÇÕES</b>
                <br />
                <P>Você terá um total de {`${millisToMinutesAndSeconds(this.tempoTotal)}`} minutos para esta atividade. Serão apresentados pares de critérios, selecionados dentre os critérios criados por participantes de todos os grupos, não apenas de seu grupo. Em cada comparação você deverá indicar qual critério considera mais relevante. Após sua escolha, o sistema selecionará mais dois critérios para você avaliar, e assim sucessivamente.</P>
                <b>Como escolher o critério mais relevante</b> <br />
                <strong>Acesso com mouse</strong>
                <P>Posicione o mouse sobre o critério escolhido, clique e segure clicado, arraste o critério para a direita o máximo que puder e solte o botão do mouse.</P>
                <strong>Acesso com touch</strong>
                <P>Encoste o dedo na tela do seu aparelho sobre o critério, arraste o critério para a direita o máximo que puder e tire o dedo da tela.</P>
                <P>Quando você tiver feito a escolha do último critério apresentado, o sistema o levará para a próxima atividade.</P>
              </div>
            </Instrucoes>
          </ModalBody>
          <ModalFooter>
            <Container className="text-center">
              <Button color="success" onClick={this.toggleInstrucoes}>
                ok
              </Button>{" "}
            </Container>
          </ModalFooter>
        </Modal>
        <Base>
          <Container>
            <br />
            <br />
            <br />
            <Row>
              <Col>
                <h1>Definições de Critérios</h1>
              </Col>
              <Col className="text-right">
                <Icon src={require("../../Assets/_assets/doubt.png")} alt="Instrucoes" />
                <Inst onClick={this.toggleInstrucoes}>Instruções</Inst>
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm="6">
                <Contador
                  counter={
                    <Timer
                      intervalResetRequest={this.state.intervalResetRequest}
                      intervalResetFeedback={() => this.setState({ intervalResetRequest: false })}
                      crescente={false}
                      tempo={this.state.tempos.tempoRestante}
                      resetAutomatico={false}
                      tempoAtualizado={this.tempoAtualizado}
                      pararTimer={this.state.pararTimer}
                      resetar={false}
                      tempoParado={() => console.log("timer finalizado")}
                      id="tempo"
                    />
                  }
                  tempo={100 * (this.state.tempos.tempoRestante / this.tempoTotal)}
                />
              </Col>
            </Row>
            <br />
            <br />

            <div>
              <Titulo>
                Defina os critérios mais importantes para você:
              </Titulo>
              <p>
                <span className={styles.bold}>*Arraste para direita o critério que acha mais importante para você</span>
              </p>
              <br />
              <ErrorBoundary errorComponent={() => (
                <div>
                  <small>{Messages.msgErrorLoadActivity}</small> <br />
                  <Button onClick={() => window.location.reload(false)}>
                      Recarregar página
                  </Button>
                </div>
              )}>
                <Duel styles={styles}
                  dueloAtual={this.state.dueloAtual}
                  handleStop={this.handleStop} />
              </ErrorBoundary>
            </div>
            <Footer />
          </Container>
        </Base>
      </div>
    )
  }
}
export default withRouter(AvaliacaoBinaria);
