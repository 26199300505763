import React, { Component, Fragment } from "react";
import Joyride from 'react-joyride';
import JoyrideContext from "../../../contexts/joyride";
import * as draftToHtml from "draftjs-to-html";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container
} from "reactstrap";
import styled from "styled-components";
import PropTypes from 'prop-types'

import {
  Base,
  Icon,
  Inst,
  Instrucoes2,
  Contador,
  Button,
  Footer,
  Mesa
} from "../../Assets/Assets";
import Loading from "../../../Services/Loading";

import Timer from "../../../Services/Timer";
import { Constantes } from "../../../Services/Constantes";
import { millisToMinutesAndSeconds } from "../../Instruments/Utils/Calculos";
import { srvTime } from '../Utils';
import fetchInstrumentoCase from "./utils/fetch-instrumento-case";
import { notifyError } from "../../../Services/Notificacoes";
import Criterio from "../../../models/Criterio";
import fetchEscolhasParticipante from "./utils/fetch-escolhas-participante";
import fetchProgressoGrupo from "./utils/fetch-progresso-grupo";
import { withRouter } from 'react-router'
import ErrorComponent from '../../../Services/ErrorComponent';
import { TourP } from "../../../utils/TourUtils";

import './styles.css'

const ObjectID = require("bson-objectid");

// Imagens
const spinner = Constantes.imagemSpinner;

// Estilos
const Criterios = styled.div`
  padding: 10px;
  background: #ffd800;
  color: #fff;
  font-size: 14px;
  margin: 10px;
  border-radius: 20px;
`;

const P = styled.p`
  font-size: 13px;
`;

const verificarRespostas = () => null;

class CaseGrupo extends Component {
  static contextType = JoyrideContext;

  state = {
    criterios: [
      {
        _id: '1',
        author: "",
        authorName: 'Homem Aranha',
        group:"Teste 2",
        instrumentId:"",
        score:0,
        value:"Boa qualidade da carne vermelha",
        views: 0

      },
      {
        _id: '2',
        author: "",
        authorName: 'Mulher Maravilha',
        group:"Teste 2",
        instrumentId:"",
        score:0,
        value:"Boa quantidade de recheio no lanche",
        views: 0

      },
      {
        _id: '3',
        author: "",
        authorName: 'Homem de Ferro',
        group:"Teste 2",
        instrumentId:"",
        score:0,
        value:"Hamburguer artesanal e no ponto certo",
        views: 0

      },
      {
        _id: '4',
        author: "",
        authorName: 'Viúva Negra',
        group:"Teste 2",
        instrumentId:"",
        score:0,
        value:"Pão fresco e macio selado internamente",
        views: 0

      },
      {
        _id: '5',
        author: "",
        authorName: 'Hulk',
        group:"Teste 2",
        instrumentId:"",
        score:0,
        value:"Agilidade na preparação do pedido",
        views: 0

      },
      {
        _id: '6',
        author: "",
        authorName: 'Thor',
        group:"Teste 2",
        instrumentId:"",
        score:0,
        value:"Higiene e organização para consumo local",
        views: 0

      }
    ],
    textoGrupo: "",
    template: {},
    SelectId: [],

    /**
     * @type(bool) indica se o User é o secretário
     */
    secretario: true,
    criteriosGrupo: [],
    criteriosIndividual: [],
    escolhaIndividual: [],
    qtdCriterio:0,
    escolhaGrupo: [],
    modalErroCriterio: false,
    modalErroEscolha: false,
    modalConfirmacao: false,
    modalInstrucoes: false,
    loading: true,
    tempos: {},
    pararTimer: false,
    loadingSpinner: false,
    modalMesa: true,
    loadingEscolha: false,
    intervalResetRequest: false,
    haveError: false,
    errorCount: 0,
    errorRetry: 10,
    steps: []
  };

  // Variaveis
  nomeSecretario = 'Você';
  opcoesResposta = [];
  onLogout = false;
  onProximo = false;
  // Tempos
  tempoOnMount = srvTime();
  tempoTotal = 0;

  static defaultProps = {
    /**
     * @type(Obj) todos os grupos da rodada
     */
    geral:
    {
      mesa: 'mesa 1'
    }
  }

  constructor(props) {
    super(props);
    const userId = sessionStorage.getItem("user") || this.props.idUser;
    const idInstrumento = props.idInstrumento;
    const tableName = props.tableName;
    this.inicializarCriterios(
      userId,
      idInstrumento,
      tableName
    );
  }

  inicializarEscolhas() {
    const qtdeEscolhas = this.state.template.tipo.quantidadeEscolhas
    let escolhaGrupo = new Array(qtdeEscolhas).fill(0);
    let escolhaIndividual = new Array(qtdeEscolhas).fill(0);
    for ( let i = 0; i < qtdeEscolhas; i++) {
      escolhaGrupo[i] = {
        id: null,
        value: ""
      };
      escolhaIndividual[i] = {
        id: null,
        value: ""
      };
    }
    this.state.escolhaGrupo = escolhaGrupo;
    this.state.escolhaIndividual = escolhaIndividual;
  }

  /**
   * 
   * @param {string} loadingVar 
   * @param {*} fn será executada entre um loading
   * @param {string} errorMsg 
   */
  async whileLoading (loadingVar, fn, errorMsg = "não foi possível realizar a operação") {
    try {
      this.setState({ [loadingVar]: true });
      await fn(); 
    } catch (error) {
      console.error(error);
      notifyError(errorMsg);
    } finally {
      this.setState({ [loadingVar]: false });
    }
  }

  async salvarResposta(fn, errorMsg) {
    await this.whileLoading("loadingEscolha", fn, errorMsg);
  }

  inicializarCriterios(userId, idInstrumento, tableName) {
    let criteriosGrupo = new Array(5).fill(0);
    let criteriosIndividual = new Array(5).fill(0);
    let criterios = new Array(9).fill(0);
    const qtdeCriterios = 5;
    for (let i = 0; i < qtdeCriterios; i++) {
      criteriosGrupo[i] =
        new Criterio(null, userId, idInstrumento, tableName);
      criteriosIndividual[i] =
        new Criterio(null, userId, idInstrumento, tableName);
      criterios[i] =
        new Criterio(null, userId, idInstrumento, tableName);
      criterios[i].value = "Critério disponível";
    }

    this.state.criteriosGrupo = criteriosGrupo;
    this.state.criteriosIndividual = criteriosIndividual;
    this.state.criterios = criterios;
  
  }

  hasRespostaSalva(escolha) {
    if (escolha == null || escolha && escolha.length <= 0) return false;
    else return true;
  }

  getNomeSecretarioFromProps(
    props
  ) {
    try {
      const meuGrupo = props.geral;
      const pessoasGrupo = meuGrupo.pessoas;
      const dadosSecretario = pessoasGrupo.find(
        (g) => g.id === props.secretario);
      return dadosSecretario.nome;
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * indica se o este user é o secretário.
   * retorna um booleano.
   */
  isSecretario(
    idUser,
    idSecretario
  ) {
    return (idSecretario === idUser);
  }

  /**
   * busca e carrega as escolhas do user no state, num formato especifico.
   */
  async configEscolhasIndividual() {
    try {

      const escolhas = await fetchEscolhasParticipante(
        this.idUser,
        this.idInstrumento,
        this.props.tableName
      );
        if(escolhas.opcoesEscolhidas.length === 0){
          //this.inicializarEscolhas()
          
        }else{
          this.setState({
            escolhaIndividual : escolhas.opcoesEscolhidas
          })
        }
        
      const hasRespostaSalva = this.hasRespostaSalva(escolhas.criteriosSelecionados);
      if (hasRespostaSalva == true) {
        const escolhasAnteriores = escolhas.criteriosSelecionados;
        this.setState(prev => {
          escolhasAnteriores.map((escolha, indexEscolha) => {
            if(prev["criteriosIndividual"][indexEscolha]){
              prev["criteriosIndividual"][indexEscolha]._id =
                escolha._id ? escolha._id.toString() : null;
              prev["criteriosIndividual"][indexEscolha].value = escolha.valor;
            }
          });
          return {
            criteriosIndividual: prev["criteriosIndividual"]
          }
        });
      } else {
        this.setState({ modalMesa: true });
      }
    } catch (error) {
      console.error(error);
      notifyError("Erro: não foi possível carregar as escolhas do usuário");
    }
  }

  mountFunction = async() => {
    if (this.state.errorCount >= this.state.errorRetry) {
      return this.setState({ haveError: true, loading: false });
    }
    try {
      this.idUser = sessionStorage.getItem("user") || this.props.idUser;;
        this.idProjeto = sessionStorage.getItem("projeto") || this.props.idProjeto;
        this.idDinamica = sessionStorage.getItem("atividade") || this.props.idAtividade;
        this.idInstrumento = this.props.idInstrumento;;
        this.tableName = this.props.tableName;
        const criterios = [
          {
            _id: '1',
            author: "",
            authorName: 'Homem Aranha',
            group:"Teste 2",
            instrumentId:"",
            score:0,
            value:"Boa qualidade da carne vermelha",
            views: 0
    
          },
          {
            _id: '2',
            author: "",
            authorName: 'Mulher Maravilha',
            group:"Teste 2",
            instrumentId:"",
            score:0,
            value:"Boa quantidade de recheio no lanche",
            views: 0
    
          },
          {
            _id: '3',
            author: "",
            authorName: 'Homem de Ferro',
            group:"Teste 2",
            instrumentId:"",
            score:0,
            value:"Hamburguer artesanal e no ponto certo",
            views: 0
    
          },
          {
            _id: '4',
            author: "",
            authorName: 'Viúva Negra',
            group:"Teste 2",
            instrumentId:"",
            score:0,
            value:"Pão fresco e macio selado internamente",
            views: 0
    
          },
          {
            _id: '5',
            author: "",
            authorName: 'Hulk',
            group:"Teste 2",
            instrumentId:"",
            score:0,
            value:"Agilidade na preparação do pedido",
            views: 0
    
          },
          {
            _id: '6',
            author: "",
            authorName: 'Thor',
            group:"Teste 2",
            instrumentId:"",
            score:0,
            value:"Higiene e organização para consumo local",
            views: 0
    
          }
        ];

        if (criterios.length < 5) {
          this.state.criteriosGrupo.splice(0, 5 - criterios.length)
          this.state.criteriosIndividual.splice(0, 5 - criterios.length)
        }
  
        var i = 1
        for (let criterio of criterios) {
          if (criterio.value.length > 0) {
            this.state.qtdCriterio = i++
          }
        }
        if (this.state.qtdCriterio > 5) {
          this.state.qtdCriterio = 5
        }
  
        await this.configurarTemplate();
        const isSecretario = true;
        // await this.configCriteriosGrupo();
        // await this.configEscolhasIndividual();
        //this.subscribePush();
        this.setState({
          criterios,
          secretario: true,
          errorCount: 0
        });
    } catch(err) {
      console.error(err);
      this.setState({ errorCount: this.state.errorCount + 1 });
      setTimeout(() => this.mountFunction(), 1000);
    }
    
  }

  componentDidMount = async () => {
    await this.mountFunction();

    const { setHaveTour, setRunTour } = this.context;

      const steps= [
        // {
        //   selector: '[data-tour="casegrupo-step-1"]',
        //   content: <TourP> Nesta área você será informado para qual grupo de trabalho deverá se dirigir ( presencial
        //     ou online). Ao se juntar ao seu grupo de trabalho, confirme clicando neste botão</TourP>,
        // },
        {
          target: '.casegrupo-step-2',
          content: <TourP>Aqui você acompanha o tempo restante para a conclusão desta atividade</TourP>,
          disableBeacon: true
        },
        {
          target: '.casegrupo-step-3',
          content: <TourP>Nesta área serão exibidas as instruções para o preenchimento da atividade. Observe atentamente o que se pede.</TourP>,
          disableBeacon: true
        },
        {
          target: '.casegrupo-step-4',
          content: <TourP>No dia da dinâmica oficial, aqui aparecerá o nome do seu grupo.</TourP>,
          disableBeacon: true
        },
        {
          target: '.casegrupo-step-5',
          content: <TourP>Nesta simulação você é o secretário. O secretário, além de enviar a SUA própria definição de critério e decisão, também será o responsável por enviar a atividade do grupo.</TourP>,
          disableBeacon: true
        },
        {
          target: '.casegrupo-step-6',
          content: <TourP> Nessa área deverão ser selecionados os critérios que o GRUPO escolheu. Caso você seja o
          secretário do grupo, é sua responsabilidade selecionar os critérios e o restante do grupo apenas
          poderá visualizar as escolhas feitas pelo secretário.</TourP>,
          disableBeacon: true
        },
        {
          target: '.casegrupo-step-7',
          content: <TourP>Aqui você deverá selecionar os critérios que para você sejam os mais relevantes. Somente você
          visualiza os critérios escolhidos, podendo ser iguais ou não aos critérios que o grupo escolheu.</TourP>,
          disableBeacon: true

        },
        {
          target: '.casegrupo-step-8',
          content: <TourP>Nesta simulação como secretário, você deverá escolher duas decisões: a decisão definida pelo seu grupo e a sua decisão individual. Ao clicar no campo de seleção, será aberto a lista com todos as decisões disponíveis para a escolha. Selecione a opção que desejar. </TourP>,
          disableBeacon: true
        },
        {
          target: '.casegrupo-step-9',
          content: <TourP>Clique neste botão para enviar a sua atividade. Ao clicar no botão, uma janela irá se abrir. Caso
          não tenha dúvidas quanto as suas escolhas, confirme o envio clicando no botão ‘Prosseguir’</TourP>,
          disableBeacon: true
        },
      ];
      setHaveTour(true);
      setRunTour(false);

      this.setState({ steps });
  };

  /**
   * busca e carrega as escolhas do grupo no state, num formato especifico.
   */
  async configCriteriosGrupo() {
    try {
      const progressoGrupo = await fetchProgressoGrupo(
        this.tableName,
        this.idInstrumento,
        this.idProjeto
      );
      if (!progressoGrupo) return; // secretary answer not found.

      // if(this.props.secretario === this.idUser){
        if(progressoGrupo.opcoesEscolhidas.length === 0){
          if(this.props.secretario === this.idUser){
            this.inicializarEscolhas();
          }
        }else{
          this.setState({
            escolhaGrupo : progressoGrupo.opcoesEscolhidas
          })
        }

      if (progressoGrupo) {
        const hasRespostaGrupoSalva = this.hasRespostaSalva(
          progressoGrupo.criteriosSelecionados
        );
        if (hasRespostaGrupoSalva === true) {
          const escolhasAnteriores = progressoGrupo.criteriosSelecionados;
          this.setState(prev => {
            escolhasAnteriores.map((escolha, indexEscolha) => {
              prev["criteriosGrupo"][indexEscolha]._id =
                escolha._id ? escolha._id.toString() : null;
              prev["criteriosGrupo"][indexEscolha].value = escolha.valor;
              prev["criteriosGrupo"][indexEscolha].authorName = escolha.authorName;
            });
            return {
              criteriosGrupo: prev["criteriosGrupo"]
              
            }
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async loadEscolhasUSer(escolhasAnteriores) {
    try {
      this.setState(prev => {
        escolhasAnteriores.map((escolha, indexEscolha) => {
          prev["criteriosIndividual"][indexEscolha]._id =
            escolha._id ? escolha._id.toString() : null;
          prev["criteriosIndividual"][indexEscolha].value = escolha.valor;
        });
        return {
          criteriosIndividual: prev["criteriosIndividual"]
        }
      });
    } catch (error) {
      console.error(error);
      notifyError("Erro: não foi possível carregar as escolhas do usuário");
    }
  }

  async saveEmptyEscolhas() {
    // TODO
  }

  componentWillUnmount = () => {
    Constantes.io.off("Projeto:Stop");
    Constantes.io.off("Projeto:RespostaGrupo");
    Constantes.io.off("Projeto:NovosTempos");
    Constantes.io.off("Auth:LogoutInstrumento");
  };

  fetchCriteriosDisponiveis = async () => {
    // Recuperando lista de criterios
    const { idInstrumento, tableName, indexGrupo } = this.props;
    const group = tableName;
    // const res = await apiCalls.case.getCriteriaByUserGroupAndInstrument(
    //   idInstrumento,
    //   group,
    //   this.idDinamica,
    //   this.idProjeto,
    //   indexGrupo,
    // );
    const res = null;
    const valoresCriterios = res.data.criterios;
    let criterios = [];
    valoresCriterios.forEach((criterioSalvo, i) => {
      let criterioObj = new Criterio(
        criterioSalvo._id,
        this.idUser,
        this.idInstrumento,
        this.tableName,
        criterioSalvo.authorName);
      criterioObj.value = criterioSalvo.value;
      criterios[i] = criterioObj;
    });

    return criterios;
  }

  async configurarTemplate() {
    const opcoes = ["Burger King", "Mc Donald's", "Bob's"];

    const template = {
      nomeCase: 'Case - Template teste',
      textos: {grupo: "<p>Selecione os 5 principais critérios para a escolha da melhor hamburgueria fast food e eleja dentre as opções a vencedora:</p>", 
      individual: "<p>Defina os 3 principais critérios para a escolha da melhor hamburgueria fast food:</p>"},
      tipo: {
        opcoes: ["Burger King", "Mc Donald's", "Bob's"],
        participantes: false,
        quantidadeEscolhas: 1
      }
    };

    this.caseEscolhido = template;
    
    this.opcoesResposta = opcoes.map((o, index) => ({
      id: (index + 1),
      value: o,
      selected: false
    }));
    
    if(template.tipo.participantes === true){
      let index = (this.opcoesResposta.length) || 1;

      let grupoPessoas = this.props.geral;
      for (let pessoa of grupoPessoas.pessoas){
        index++;
        this.opcoesResposta.push({
          id: index,
          value: pessoa.nome,
          selected: false
        });
      }
    }


    this.setState({
      template,
      textoGrupo: "<p>Selecione os 5 principais critérios para a escolha da melhor hamburgueria fast food e eleja dentre as opções a vencedora:</p>",
      loading: false,
      opcoesResposta: this.opcoesResposta,
      choicesToMake: template.tipo.quantidadeEscolhas
    });
    this.inicializarEscolhas()

  }

  /**
   * cadastra o front as mensagens de socket do backend
   */
  subscribePush = () => {
    if (this.isSecretario(
      this.idUser,
      this.props.secretario
    ) == false) {
      Constantes.io.on("dinamica:casegrupo:sync-secretario-answer", async () => {
        await this.configCriteriosGrupo();
      });
    }
    Constantes.io.on("Projeto:Stop", data => {
      if (
        data.instrumento === Constantes.instrumentoCaseIndividual &&
        data.start.grupo === 0 &&
        data.rodada === this.props.rodada
      ) {
        this.props.history.go();
      }
    });
    Constantes.io.on("Projeto:NovosTempos", data => {
      if (data.instrumento === Constantes.instrumentoCaseIndividual) {
        let tempos = data.tempos.grupo;
        //tempos.tempoRestante = tempos.tempoFim - srvTime();
        this.tempoTotal = tempos.tempoFim - tempos.tempoInicio;
        this.setState({
          tempos,
          intervalResetRequest: true
        });
      } 
    });

    Constantes.io.on("Auth:LogoutInstrumento", userId => {
      if (sessionStorage.getItem("user") === userId.toString()) {
        this.onLogout = true;
        if (this.state.tempos.tempoRestante > 0)
          this.setState({ pararTimer: true });
        else this.fimAtividade();
      }
    });
  };

  /**
   * exibe o modal modalErroCriterio caso encontre erros de validação
   */
  verifica = () => {
    this.context.setRunTour(false);
    let verificacao = true;
    let countCriterioGrupo = 0;
    let countCriterioIndividual = 0;
  
    if (this.state.secretario) {
      if (this.state.qtdCriterio < this.state.criteriosGrupo.length) {
        this.state.criteriosGrupo.forEach(criterio => {
          if (criterio._id) countCriterioGrupo++;
        });

        if (countCriterioGrupo !== this.state.qtdCriterio) verificacao = false;
      } else {
        this.state.criteriosGrupo.forEach(criterio => {
          if (!criterio._id)
            verificacao = false;
        });
      }
    }

    // Checando se mandou todos os individuais
    if (this.state.qtdCriterio < this.state.criteriosIndividual.length) {
      this.state.criteriosIndividual.forEach(criterio => {
        if (criterio._id) countCriterioIndividual++;
      });

      if (countCriterioIndividual !== this.state.qtdCriterio) verificacao = false;
    } else {
      this.state.criteriosIndividual.forEach(criterio => {
        if (!criterio._id)
          verificacao = false;
      });
    }


    if (!verificacao) this.setState({ modalErroCriterio: true });
    else {
      let verificarEscolha = true;

      if (this.state.secretario) {
        this.state.escolhaGrupo.forEach(escolha => {

          if (
            escolha.value === Constantes.mensagemAguardandoSecretario ||
            escolha.value === Constantes.mensagemSelecionar ||
            escolha.value === ''
          )
            verificarEscolha = false;
        });
      }

      this.state.escolhaIndividual.forEach(escolha => {
        if (escolha.value === "" || escolha.value === Constantes.mensagemSelecionar)
          verificarEscolha = false;
      });

      if (!verificarEscolha) this.setState({ modalErroEscolha: true });
      else this.setState({ modalConfirmacao: true });
    }


  };

  gravaRespostas = async (retry = false) => {
    if (this.state.secretario) {
      let resp = {
        instrumento: Constantes.instrumentoCaseIndividual,
        resposta: {
          criterios: this.state.criteriosGrupo,
          escolha: this.state.escolhaGrupo
        },
        grupoIndex: this.props.grupo,
        rodada: this.props.rodada
      };
      const dinamicaId = sessionStorage.getItem("atividade");
      const projetoId = sessionStorage.getItem("projeto");

        await this.saveOpcaoGrupo();
        await this.saveOpcaoUser();


      // // debugger
      // await api
      //   .put(
      //     `api/projects/editRespostasSecretario/${projetoId}/${dinamicaId}`,
      //     { ...resp }
      //   )
      //   .catch(error => {
      //     if (error.response) {
      //       // The request was made and the server responded with a status code
      //       // that falls out of the range of 2xx
      //     } else if (error.request) {
      //       // The request was made but no response was received
      //       // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      //       // http.ClientRequest in node.js
      //     } else {
      //       // Something happened in setting up the request that triggered an Error
      //     }

      //     // this.gravaRespostas();
      //   });
    }
    // Atualizando tempos:
    let tempos = {
      tempoAtual: this.state.tempos.tempoRestante
    };
    if (retry) var atualizadoEm = retry;
    else var atualizadoEm = srvTime();

    let resp = {
      instrumentId: this.props.idInstrumento,
      instrumento: Constantes.instrumentoCaseIndividual2,
      resposta: {
        criterios: this.state.criteriosIndividual,
        escolha: this.state.escolhaIndividual
      },
      atualizadoEm,
      rodada: this.props.rodada,
      tempos
    };
    const dinamicaId = sessionStorage.getItem("atividade");
    await this.saveOpcaoUser();
    // debugger
    // await api
    //   .put(`api/user/respostas/modulo/${Constantes.moduloDinamicas}`, {
    //     resp,
    //     atividadeId: dinamicaId
    //   })
    //   .then((res) => {
    //   })
    //   .catch(error => {
    //     if (error.response) {
    //       // The request was made and the server responded with a status code
    //       // that falls out of the range of 2xx
    //       console.error(error.response.data);
    //       console.error(error.response.status);
    //       console.error(error.response.headers);
    //     } else if (error.request) {
    //       // The request was made but no response was received
    //       // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    //       // http.ClientRequest in node.js
    //       console.error(error.request);
    //     } else {
    //       // Something happened in setting up the request that triggered an Error
    //       console.error('Error', error.message);
    //     }
    //     console.error(error.config);

    //     // this.gravaRespostas(atualizadoEm);
    //   });
  };


    /**
   * salva as escolhas individuais do participante
   */
  async saveOpcaoGrupo() {
    try {
      const opcoesEscolhidas = this.state.escolhaGrupo
      // await apiCalls.case.saveOpcaoGrupo({
      //   queryParams: {
      //     idUser: this.idUser,
      //     group: this.props.tableName,
      //     idInstrumento: this.idInstrumento
      //   },
      //   data: {
      //     opcoesEscolhidas
      //   }
      // });
    } catch (error) {
      console.error(error);
      notifyError("Erro: não foi possível salvar a resposta");
    }
  }


  async saveOpcaoUser() {
    try {
      const opcoesEscolhidas = this.state.escolhaIndividual
      // await apiCalls.case.saveOpcaoUser({
      //   queryParams: {
      //     idUser: this.idUser,
      //     idInstrumento: this.idInstrumento
      //   },
      //   data: {
      //     opcoesEscolhidas
      //   }
      // });
    } catch (error) {
      console.error(error);
      notifyError("Erro: não foi possível salvar a resposta");
    }
  }

  /**
   * acionado no clique do botão Finalizar.
   * deve encerrar esta fase da atividade.
   */
  proximaAtividade = async () => {
    if (this.state.errorCount >= this.state.errorRetry) {
      return this.setState({ haveError: true, loading: false, modalConfirmacao: false });
    }
    try {
      const indexRodadaAtual = this.props.rodada;

      // await apiCalls.case.finalizarCaseGrupo(
      //   {
      //     queryParams: {
      //       idUser: this.idUser,
      //       idInstrumento: this.idInstrumento,
      //       idDinamica: this.idDinamica,
      //       idProjeto: this.props.idProjeto,
      //       indexRodadaAtual
      //     }
      //   }
      // );
      this.props.action();
    } catch (err) {
      console.error(err);
      this.setState({ errorCount: this.state.errorCount + 1 });
      setTimeout(() => this.proximaAtividade(), 1000);
    }
  };

  tempoAtualizado = tempo => {
    this.setState(previousState => ({
      tempos: {
        ...previousState.tempos,
        tempoRestante: tempo
      }
    }));
  };

  getCriterioById(
    id
  ) {
    return this.state.criterios.find(
      (c) => c._id.toString() === id);
  }

  /**
   * envia para o backend os critérios selecionados pelo grupo,
   * em um formato especifico.
   */
  async saveEscolhasGrupo() {
    const { criteriosGrupo } = this.state;
    const criteriosSelecionados = criteriosGrupo.map((criterio) => ({
      _id: criterio._id,
      valor: criterio.value
    }));
    // await apiCalls.case.saveEscolhasGrupo({
    //   queryParams: {
    //     group: this.props.tableName,
    //     idInstrumento: this.idInstrumento,
    //     idDinamica: this.idDinamica
    //   },
    //   data: {
    //     criteriosSelecionados
    //   }
    // });
  }

  /**
   * callback chamado ao selecionar um criterio no select
   * de escolhas do grupo
   */
  atualizarListaGrupo = async (e, indice) => {
    this.salvarResposta(async () => {
      let criteriosGrupo = this.state.criteriosGrupo;
      const idCriterioSelecionado = e.target.value;
      const criterioSelecionado = this.getCriterioById(
        idCriterioSelecionado
      );
      criteriosGrupo[indice] = criterioSelecionado;
      await this.saveEscolhasGrupo();
    }, "Não foi possível salvar sua resposta.")
  };

  /**
   * salva as escolhas individuais do participante
   */
  async saveEscolhasCriterios() {
    const criteriosSelecionados = this.state.criteriosIndividual.map((criterio) => {
      return {
        _id: criterio._id,
        valor: criterio.value
      }
    });
    // let progressoSalvo = await apiCalls.case.saveEscolhasUser({
    //   queryParams: {
    //     idUser: this.idUser,
    //     idInstrumento: this.idInstrumento,
    //     group: this.props.tableName
    //   },
    //   data: {
    //     criteriosSelecionados
    //   }
    // });
  }

  /**
   * salva os critérios selecionados na coluna individual
   */
  atualizarListaIndividual = async (e, indice) => {
    this.salvarResposta(async () => {
      let criteriosIndividual = this.state.criteriosIndividual;
      const idCriterioSelecionado = e.target.value;
      const criterioSelecionado = this.getCriterioById(
        idCriterioSelecionado
      );
      criteriosIndividual[indice] = criterioSelecionado;
      await this.saveEscolhasCriterios();
    }, "Não foi possível salvar sua resposta.");
};

  checarCriteriosDisponiveis = (criterio, select, grupo = false) => {
    let check = true;
    if (grupo) {
      this.state.criteriosGrupo.forEach((cri, index) => {
        if (cri === criterio && select !== index) {
          check = false;
        }
      });
    } else {
      this.state.criteriosIndividual.forEach((cri, index) => {
        if (cri === criterio && select !== index) {
          check = false;
        }
      });
    }
    return check;
  };

  cancelarMesa = () => {
    this.setState({ modalMesa: false });
    this.setState({ modalInstrucoes: true });
  };
  cancelar = () => {
    this.setState({
      modalErroCriterio: false,
      modalConfirmacao: false,
      modalErroEscolha: false
    });
  };

  onChangeEscolha = (e, choiceIndex) => {
    this.salvarResposta(async () => {
      let escolha = this.state[e.target.name];
      let { opcoesResposta } = this.state;
      
      const choiceId = e.target.value;
      if (!choiceId) return;
      
      let choice = opcoesResposta.find((o) => Number(o.id) === Number(choiceId));

      escolha[choiceIndex] = {
        id: choiceId,
        value: choice.value
      };
      await this.gravaRespostas();
    }, "Não foi possível salvar sua resposta.")
  };

  fimAtividade = () => {
    if (this.onLogout) this.props.logout();
    else {
      if (!this.onProximo) {
        this.onProximo = true;
        if (!this.state.loadingSpinner) this.setState({ loadingSpinner: true });
        this.proximaAtividade();
      }
    }
  };

  toggleInstrucoes = () => {
    if (this.state.modalInstrucoes && !this.state.alreadyOpened) {
      this.context.setRunTour(true);
      this.setState({ alreadyOpened: true });
    } 
    this.setState(previousState => ({
      modalInstrucoes: !previousState.modalInstrucoes
    }));
  };

  toggleErroCriterio = () => {
    this.setState(previousState => ({
      modalErroCriterio: !previousState.modalErroCriterio
    }));
  };

  toggleErroEscolha = () => {
    this.setState(previousState => ({
      modalErroEscolha: !previousState.modalErroEscolha
    }));
  };

  toggleConfirmacao = () => {
    if (!this.state.loadingSpinner) {
      this.setState(previousState => ({
        modalConfirmacao: !previousState.modalConfirmacao
      }));
    }
  };

  callback = (data) => {
    if (data.status === 'finished' || data.action === 'close') {
        // This explicitly stops the tour (otherwise it displays a "beacon" to resume the tour)
        this.context.setRunTour(false);
    }
  }

  render() {
    const {
      choicesToMake,
      loadingEscolha,
      secretario,
      escolhaGrupo,
      escolhaIndividual,
      opcoesResposta
    } = this.state;
    if (this.state.haveError) return <ErrorComponent />;
    if (this.state.loading) {
      if (this.state.proximaAtividade) this.proximaAtividade();
      return <Loading />;
    }
    return (
      <div className="text-left back" tabIndex="0">
        <Joyride
          showProgress={true}
          steps={this.state.steps}
          run={this.context.runTour}
          continuous={true}
          callback={this.callback}
          disableOverlayClose={true}
          spotlightClicks={true}
          disableBeacon={true}
          locale={{
            back: 'Voltar',
            close: 'Fechar',
            last: 'Fim',
            next: 'Próximo',
            skip: 'Pular'
          }}
          styles={{
            options: {
              primaryColor: '#012a4a'
            },
            tooltipContainer: {
              textAlign: 'left',
            },
          }}
        />
        <Base>
          {/*--------------------------------- MODALS ------------------------------*/}
          {/*Modal instruções*/}
          <Modal
            isOpen={this.state.modalMesa}
            className={this.props.className}
          >
            <ModalBody>
              <Instrucoes2>
                <div className="text-center">
                  <h2>
                    No dia da dinâmica oficial, aqui aparecerá o nome do seu grupo. <br />
                  </h2>
                  <img src={require('../../Assets/_assets/mesa.gif')} style={{ width: '50%' }} />
                </div>
              </Instrucoes2>
            </ModalBody>
            <ModalFooter>
              <Container className="text-center">
                <Button color="success" onClick={this.cancelarMesa}>
                  Clique aqui para continuar
                </Button>{" "}
              </Container>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={this.state.modalInstrucoes}
            className={this.props.className}
          >
            <ModalBody>
              <Instrucoes2>
                <div>
                  <P>Car@ participante, a seguir algumas instruções para que você possa realizar a entrega da atividade case grupo. Lembrando que essas atividades são ilustrativas e não terão seus resultados considerados ou salvos no IAPP.</P>
                  <P>As instruções a seguir são relacionadas exclusivamente ao teste atual. Na aplicação oficial, as instruções poderão ser diferentes.</P>
                  <P><strong>Enunciado</strong></P>
                  <P>Chegamos na etapa (ii) do case. Neste teste simularemos como seria a sua entrega quando estiver com o grupo. Para esse teste, n&atilde;o teremos colegas reais entregando a atividade, somente voc&ecirc; poder&aacute; ver o que est&aacute; entregando. </P>
                  <P>Lembrando que essa atividade &eacute; ilustrativa e n&atilde;o ter&atilde;o seus resultados considerados ou salvos no IAPP.</P>
                  <P>Para essa simula&ccedil;&atilde;o escolha de 5 crit&eacute;rios dentre os definidos pelos participantes na etapa individual (neste teste ser&atilde;o visualizados crit&eacute;rios fakes) e indique os 5 mais relevantes para grupo (coluna esquerda) e os 5 crit&eacute;rios mais relevantes para voc&ecirc; (coluna &agrave; direita). Ao final, escolha uma decis&atilde;o para o grupo e uma individual.</P>
                  <P>Na din&acirc;mica oficial, nesta etapa, voc&ecirc; e seu grupo devem discutir para entrar em consenso dos 5 crit&eacute;rios que o grupo ir&aacute; escolher. Mas, para esse teste, simularemos apenas voc&ecirc; escolhendo tanto para o grupo como individualmente. </P>
                  <P><strong>Como escolher os crit&eacute;rios e a decis&atilde;o</strong></P>
                  <P>Ao clicar no campo de sele&ccedil;&atilde;o, ser&aacute; aberto a lista com todos os crit&eacute;rios/decis&atilde;o dispon&iacute;veis para a escolha. Selecione a op&ccedil;&atilde;o que desejar. Para trocar a op&ccedil;&atilde;o, clique novamente no campo e escolha outra op&ccedil;&atilde;o. Voc&ecirc; precisar&aacute; fazer isso em todos os campos.</P>
                  <P><strong>Regras</strong></P>
                  <P>S&oacute; &eacute; permitido escolher um crit&eacute;rio em cada campo</P>
                  <P>O crit&eacute;rio escolhido em um campo n&atilde;o poder&aacute; mais ser selecionado em outro campo</P>
                  <P>N&atilde;o &eacute; permitido edi&ccedil;&otilde;es dos crit&eacute;rios j&aacute; criados</P>
                  <P>Ao lado de cada crit&eacute;rio, voc&ecirc; poder&aacute; visualizar o nome (fake) de quem o criou</P>
                  <P><strong>Secret&aacute;rio</strong></P>
                  <P>O secret&aacute;rio, al&eacute;m de enviar a SUA pr&oacute;pria defini&ccedil;&atilde;o de crit&eacute;rio e decis&atilde;o, tamb&eacute;m ser&aacute; o respons&aacute;vel por enviar a atividade do grupo. Para essa simula&ccedil;&atilde;o voc&ecirc; ser&aacute; o secret&aacute;rio, mas na din&acirc;mica oficial, ser&aacute; sorteado um membro do seu grupo para fazer essa entrega.</P>
                  <P><strong>Entregas individuais e em grupo</strong></P>
                  <P>Todos os participantes ter&atilde;o duas colunas:</P>
                  <P><strong>Direita</strong>: Representa a atividade individual. S&oacute; voc&ecirc; mesmo pode enxergar e mudar a escolha dos crit&eacute;rios/decis&atilde;o</P>
                  <P><strong>Esquerda</strong>: Representa a atividade em grupo. S&oacute; o secret&aacute;rio pode fazer altera&ccedil;&otilde;es. Todas as altera&ccedil;&otilde;es feitas por ele, s&atilde;o visualizadas por todos nessa coluna. Lembrando que para este teste, somente voc&ecirc; poder&aacute; visualizar a entrega do grupo.</P>
                  <P>Quando tiver conclu&iacute;do a escolha dos crit&eacute;rios e a decis&atilde;o (individual e em grupo), voc&ecirc; dever&aacute; clicar em &ldquo;enviar&rdquo; e confirmar o envio da atividade.</P>
                  <P>IMPORTANTE: O&nbsp;<strong>secret&aacute;rio</strong>&nbsp;&eacute; o respons&aacute;vel pelo envio da atividade em&nbsp;<strong>grupo</strong>, desta forma, s&oacute; dever&aacute; enviar quando tiver finalizado a atividade em grupo e a pr&oacute;pria individual (colunas da esquerda e da direita).</P>
                  <P>Qualquer participante que n&atilde;o seja o secret&aacute;rio, poder&aacute; enviar a atividade pr&oacute;pria a qualquer momento, contudo, caso seja enviado antes do secret&aacute;rio finalizar a parte em grupo, o participante n&atilde;o poder&aacute; mais visualizar as altera&ccedil;&otilde;es feitas na coluna do grupo (esquerda)</P>
                  <P>Ap&oacute;s enviar a atividade voc&ecirc; n&atilde;o poder&aacute; voltar para ajustar, nem visualizar altera&ccedil;&otilde;es do grupo.</P>
                </div>
              </Instrucoes2>
            </ModalBody>
            <ModalFooter>
              <Container className="text-center">
                <Button color="success" onClick={this.toggleInstrucoes}>
                  ok
                </Button>{" "}
              </Container>
            </ModalFooter>
          </Modal>
          {/* Modal de falta de criterio */}
          <Modal
            isOpen={this.state.modalErroCriterio}
            toggle={this.toggleErroCriterio}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleErroCriterio}>Case</ModalHeader>
            <ModalBody>Escolha todos os Critérios</ModalBody>
            <ModalFooter>
              <Button color="success" onClick={this.toggleErroCriterio}>
                OK
              </Button>{" "}
            </ModalFooter>
          </Modal>
          {/* Modal de falta de escolha */}
          <Modal
            isOpen={this.state.modalErroEscolha}
            toggle={this.toggleErroEscolha}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleErroEscolha}>Case</ModalHeader>
            <ModalBody>Escolha a resposta</ModalBody>
            <ModalFooter>
              <Button color="success" onClick={this.toggleErroEscolha}>
                OK
              </Button>{" "}
            </ModalFooter>
          </Modal>
          {/* Modal de confirmacao */}
          <Modal
            isOpen={this.state.modalConfirmacao}
            toggle={this.toggleConfirmacao}
            className={this.props.className}
            onClosed={() => (this.onProximo = false)}
          >
            <ModalHeader toggle={this.toggleConfirmacao}>Case</ModalHeader>
            <ModalBody>
              Se você estiver satisfeito com suas escolhas clique em prosseguir,
              se não clique em cancelar
            </ModalBody>
            <ModalFooter>
              {!this.state.loadingSpinner ? (
                <div>
                  <Button
                    color="success"
                    onClick={() => {
                      this.state.tempos.tempoRestante > 0
                        ? this.setState({
                            loadingSpinner: true,
                            pararTimer: true
                          })
                        : this.fimAtividade();
                    }}
                    disabled={this.state.loadingSpinner}
                  >
                    Prosseguir
                  </Button>{" "}
                  <Button
                    color="danger"
                    onClick={this.toggleConfirmacao}
                    disabled={this.state.loadingSpinner}
                  >
                    Cancelar
                  </Button>
                </div>
              ) : (
                <img src={require(`../../Assets/_assets/${spinner}`)} />
              )}
            </ModalFooter>
          </Modal>
          {/*--------------------------------- MODALS ------------------------------*/}
          <Container>
            <br />
            <br />
            <br />
            <Row>
              <Col>
                <h1>Case</h1>
              </Col>
              <Col className="text-right">
                <Icon
                  src={require("../../Assets/_assets/doubt.png")}
                  alt="Instrucoes"
                />
                <Inst onClick={this.toggleInstrucoes}>Instruções</Inst>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col className="casegrupo-step-2" sm="6">
                <Contador
                  counter={
                    <Timer
                      intervalResetRequest={this.state.intervalResetRequest}
                      intervalResetFeedback={() => this.setState({ intervalResetRequest: false })}
                      crescente={false}
                      tempo={2401200}
                      resetAutomatico={false}
                      tempoAtualizado={this.tempoAtualizado}
                      pararTimer={this.state.pararTimer}
                      resetar={false}
                      tempoParado={this.fimAtividade}
                      id="tempo"
                    />
                  }
                  tempo={
                    2401200
                  }
                />
              </Col>
            </Row>
            <br />
            <br />
            <div className="casegrupo-step-3">
              <h1>{this.state.template.nomeCase}</h1>

              <div
                className='responsive-image'
                dangerouslySetInnerHTML={{
                  __html: (this.state.textoGrupo)
                }}
              />

            </div>
            {/* <div
              dangerouslySetInnerHTML={{
                __html: (this.state.textoGrupo)
              }}
            /> */}

            <br />
            <br />
            <h2 className="casegrupo-step-4">Mesa: Mesa teste</h2>
            <br />
            <h2 className="casegrupo-step-5">
              Secretário: {this.state.secretario ? "Você" : this.nomeSecretario}
            </h2>
            <br />
            <br />
            <Row>
              <Col className="casegrupo-step-6" sm="6">
                {" "}
                {/* Secretário */}
                {this.state.secretario && (
                  <div>
                    <h4>
                      Defina os {this.state.criterios ? this.state.qtdCriterio : Constantes.quantidadeCriteriosGrupo } Critérios
                      para o Grupo
                    </h4>
                    {this.state.criteriosGrupo.map((criterio, index) => (
                      <div key={index}>
                        <select
                          className="form-control"
                          onChange={e => this.atualizarListaGrupo(e, index)}
                          value={criterio._id ? criterio._id : ""}
                          disabled={this.state.loadingEscolha}
                          id={"select-sec-"+index}
                        >
                          <option
                            key={Constantes.mensagemSelecionar}
                            value=""
                            disabled
                          >
                            {" "}
                            {Constantes.mensagemSelecionar}{" "}
                          </option>
                          {
                        this.state.criterios.map((criterioDisponivel, indice) => {
                          if(criterioDisponivel.value !== ""){
                            if(this.state.criteriosGrupo.some((criterioGrupo) => {
                              return criterioDisponivel._id == criterioGrupo._id
                            })) {
                              return <option title={`${criterioDisponivel.value} - ${criterioDisponivel.authorName}`} key={indice} disabled value={criterioDisponivel._id}>
                              {criterioDisponivel.value} - {criterioDisponivel.authorName}
                              </option>
                            }
                            return <option title={`${criterioDisponivel.value} - ${criterioDisponivel.authorName}`} key={indice} value={criterioDisponivel._id}>
                              {criterioDisponivel.value} - {criterioDisponivel.authorName}
                            </option>
                          }
                        })
                      }
                        </select>
                        <br />
                        <br />
                      </div>
                    ))}
                  </div>
                )}
                {/* Participante */}
                {!this.state.secretario && (
                  <div>
                    <h4>Critérios do Grupo</h4>
                    {this.state.criteriosGrupo.map((criterio, indice) => (
                      <div key={indice}>
                        <select className="form-control" disabled id={"select-sec-"+indice}>
                          <option title={`${criterio.value} - ${criterio.authorName}`}>{criterio.value} - {criterio.authorName} </option>
                        </select>
                        <br />
                        <br />
                      </div>
                    ))}
                  </div>
                )}
              </Col>
              <Col className="casegrupo-step-7" sm="6">
                {" "}
                <h4>Defina os Critérios Individuais:</h4>
                {this.state.criteriosIndividual.map((criterio, index) => (
                  <div key={index}>
                    <select
                      key={index}
                      className="form-control"
                      onChange={e => {
                        this.atualizarListaIndividual(e, index)
                      }
                    }
                      disabled={this.state.loadingEscolha}
                      value={criterio._id ? criterio._id : ""}
                      id={"select-par-"+index}
                    >
                      <option
                        key={Constantes.mensagemSelecionar}
                        value=""
                        disabled
                      >
                        {" "}
                        {Constantes.mensagemSelecionar}{" "}
                      </option>
                      {
                        this.state.criterios.map((criterioDisponivel, indice) => {
                          if(criterioDisponivel.value !== ""){
                            if(this.state.criteriosIndividual.some((criterioIndividual) => {
                              return criterioDisponivel._id == criterioIndividual._id
                            })) {
                              return <option title={`${criterioDisponivel.value} - ${criterioDisponivel.authorName}`} key={indice} disabled value={criterioDisponivel._id}>
                              {criterioDisponivel.value} - {criterioDisponivel.authorName}
                              </option>
                            }
                            return <option title={`${criterioDisponivel.value} - ${criterioDisponivel.authorName}`} key={indice} value={criterioDisponivel._id}>
                              {criterioDisponivel.value} - {criterioDisponivel.authorName}
                            </option>
                          }
                        })
                      }
                    </select>
                    <br />
                    <br />
                  </div>
                ))}
                <br />
                <br />
              </Col>
              <div style={{ display: 'flex', width: '100%' }} className="casegrupo-step-8">
              <Col sm="6" className="text-center">
                <h4>Escolha em grupo</h4>
                {secretario
                  ? new Array(choicesToMake).fill(0).map((_, selectIndex) => {
                    const related = escolhaGrupo[selectIndex] || {};

                    return (
                      <div key={selectIndex}>
                        <select
                          className="form-control"
                          name="escolhaGrupo"
                          key={selectIndex}
                          onChange={e => this.onChangeEscolha(e, selectIndex)}
                          value={related.id || ""}
                          disabled={loadingEscolha}
                          id={"select-grupo-" + selectIndex}
                        >
                          <option
                            key={Constantes.mensagemSelecionar}
                            value=""
                            disabled
                          >
                            {" "}
                            {Constantes.mensagemSelecionar}{" "}
                          </option>

                          {opcoesResposta.map((choice, choiceIndex) => {

                            const alreadySelected = escolhaGrupo
                              .find((a) => Number(a.id) === Number(choice.id));

                            return (
                              <option title={choice.value} disabled={alreadySelected}
                                key={choiceIndex}
                                value={choice.id}>

                                {choice.value}
                              </option>
                            )
                          })
                          }
                        </select>
                        <br />
                        <br />
                      </div>
                    )
                  })
                  : this.state.escolhaGrupo.map((escolhasGrupo, index) => (
                      <div key={index}>
                        <select
                          className="form-control"
                          name="escolhaGrupo"
                          key={index}
                          onChange={e => this.onChangeEscolha(e, index)}
                          value={this.state.escolhaGrupo[index]}
                          disabled={!this.state.secretario || this.state.loadingEscolha}
                          id={"select-grupo-"+index}
                        >
                          <option title={escolhasGrupo.value}> {escolhasGrupo.value} </option>
                        </select>
                        <br />
                        <br />
                      </div>
                    ))}
              </Col>
              <br />
              <br />
              <Col sm="6" className="text-center">
                <h4>Escolha individual</h4>
                {

                new Array(choicesToMake).fill(0).map((_, choiceIndex) => {
                  const related = escolhaIndividual[choiceIndex] || {};

                  return (
                    <div key={choiceIndex}>
                      <select
                        className="form-control"
                        key={choiceIndex}
                        name="escolhaIndividual"
                        value={related.id || ""}
                        onChange={e => this.onChangeEscolha(e, choiceIndex)}
                        disabled={this.state.loadingEscolha}
                        id={"select-individual-" + choiceIndex}
                      >
                        <option
                          key={Constantes.mensagemSelecionar}
                          value=""
                          disabled
                        >
                          {" "}
                          {Constantes.mensagemSelecionar}{" "}
                        </option>
                        {
                          opcoesResposta.map((choice, indice) => {
                            const alreadySelected = escolhaIndividual
                              .find((a) => Number(a.id) === Number(choice.id));

                            return (
                              <option title={choice.value} disabled={alreadySelected}
                                key={indice}
                                value={choice.id}>

                                {choice.value}
                              </option>
                            )
                          })
                        }
                      </select>
                      <br />
                      <br />
                    </div>
                  )
                })}
              </Col>
              </div>
              <br />
              <br />
              <Col sm="12" className="text-center">
                <Button className="casegrupo-step-9" onClick={this.verifica} color="success" disabled={this.state.loadingEscolha}>
                  Enviar
                </Button>
                <Footer />
              </Col>
            </Row>
          </Container>
        </Base>
      </div>
    );
  }
}

export default withRouter(CaseGrupo);
