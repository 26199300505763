import React, { Component } from 'react';
import styled from 'styled-components';
import {
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { Logo, BaseLogin, Button, Icon } from '../Assets/Assets';
import api from '../../Services/api';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { notifyError } from '../../Services/Notificacoes';
import { validarCPF } from '../../Services/Validador';
import { Constantes, errorCode } from '../../Services/Constantes';
import Politics from '../../components/Politics';

// Imagens
const spinner = Constantes.imagemSpinner;

const Wrapper = styled.div`
  height: 100% !important;
  width: 100% !important;
  background: #002a4b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 485px;

  input {
    font-size: 16px !important;
    background: #001b2f;
    color: #fff;
    height: 40px;
    border: 0px;
  }

  span {
    background: #f0c219;
    border: 0px;
    border-radius: 5px;
    padding: 5px 10px;
  }

  @media (max-width: 485px) {
    width: 100%;
  }
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  background: #002a4b;

  color: #fff;
  text-align: center;
`

class LoginForm extends Component {
  state = {
    cpf: '',
    codigoAcesso: '',
    modal: false,
    loadingSpinner: false
  };

  componentDidMount() {
    clearInterval(Constantes.pingServerInterval);
    sessionStorage.clear();
  }

  cpfMask = value => {
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }

  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.name.toLowerCase().includes('cpf')) value = this.cpfMask(e.target.value);

    this.setState({ [e.target.name]: value });
  };
  isAuthenticated() {
    const token = sessionStorage.getItem('token');
    return token && token.length > 10;
  }
  
  loginNovo = async () => {
    const cpf = this.state.cpf.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '');
    const codigoAcesso = this.state.codigoAcesso;
    const response = await api
      .post('api/auth/invalidateaccess', {
        cpf,
        connId: Constantes.io.id
      })
      .then((response) => {
        // outras sessões deste usuário foram derrubadas pelo servidor.
      })
      .catch((err) => {
        if (err.response && err.response.data.error)
          notifyError('Não foi possível realizar login. Verifique os dados inseridos e tente novamente.');
        else notifyError('Problema técnico. Contate o consultor.');
        this.setState({ loadingSpinner: false });
      });

    await api
      .post('api/auth/atividade', {
        cpf,
        codigoAcesso,
        connId: Constantes.io.id
      })
      .then((response) => {
        if (response.data.token) {
          sessionStorage.setItem('token', response.data.token);
          sessionStorage.setItem('user', response.data.user._id);
          sessionStorage.setItem('projeto', response.data.projetoId);
          sessionStorage.setItem('atividade', response.data.idAtividade);
          sessionStorage.setItem('modulo', response.data.modulo);
          sessionStorage.setItem(
            'foto',
            response.data.user.projetos.find(
              (projeto) =>
                projeto._idProjeto.toString() ===
                response.data.projetoId.toString()
            ).foto
          );
          sessionStorage.setItem(
            'username',
            response.data.user.projetos.find(
              (projeto) =>
                projeto._idProjeto.toString() ===
                sessionStorage.getItem('projeto')
            ).nome
          );

          /*
          let projetosUser = response.data.user.projetos;
          sessionStorage.setItem(
            "foto",
            projetosUser[projetosUser.length - 1].foto
          );*/
          api.defaults.headers.common['Authorization'] =
            'Bearer ' + sessionStorage.getItem('token');
          this.props.history.push('/');
        } else if (response.data.logado) {
          sessionStorage.setItem('userLogando', 'true');
          this.setState({
            modal: true
          });
        }
      })
      .catch((err) => {
        if (err.response && err.response.data.error)
          notifyError('Não foi possível realizar login. Verifique os dados inseridos e tente novamente.');
        else notifyError('Problema técnico. Contate o consultor.');
        this.setState({ loadingSpinner: false });
      });
  };
  
  handleForm = async (e) => {
    e.preventDefault();
    this.setState({ loadingSpinner: true });
    const cpf = this.state.cpf.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '');

    if (validarCPF(cpf)) {
      const codigoAcesso = this.state.codigoAcesso;

      try {
        await api.post('api/auth/invalidateaccess', {
          cpf,
          connId: Constantes.io.id
        });

        const response = await api.post('api/auth/atividade', {
          cpf,
          codigoAcesso,
          connId: Constantes.io.id
        });
  
        if (response.data.token) {
          const project = response.data.projetoId;
          
          function getUserName() {
            try {
              if (project && !project.includes('undefined')) {
                return response.data.user.projetos.find(
                  (projeto) =>
                    projeto._idProjeto.toString() ===
                    sessionStorage.getItem('projeto')
                ).nome;
              } else {
                return response.data.user.nome;
              }
            } catch (error) {
              console.error(error);
              return "";
            }
          }

          sessionStorage.setItem('token', response.data.token);
          sessionStorage.setItem('user', response.data.user._id);
          sessionStorage.setItem('projeto', response.data.projetoId);
          sessionStorage.setItem('atividade', response.data.idAtividade);
          sessionStorage.setItem('modulo', response.data.modulo);
          sessionStorage.setItem(
            'username',
            getUserName()
          );

          let projetosUser = response.data.user.projetos;
          let userFoto = '';

          if (project && !project.includes('undefined')) {
            userFoto =  projetosUser.find(
              (pj) =>
                pj._idProjeto.toString() ===
                response.data.projetoId.toString()
            ).foto;
          }
          sessionStorage.setItem('foto', userFoto);
          api.defaults.headers.common['Authorization'] =
            'Bearer ' + sessionStorage.getItem('token');
          this.props.history.push('/');
        } else if (response.data.logado) {
          sessionStorage.setItem('userLogando', true);
          this.setState({
            modal: true
          });
        }

      } catch (err) {
        console.error(err);
        if (err.response && err.response.data.notAuthorized) 
          notifyError('Prova ainda não liberada. Aguarde alguns instantes ou entre em contato com o consultor.');
        else if (err.response && err.response.data.error && !err.response.data.notAuthorized)
          notifyError('Não foi possível realizar login. Verifique os dados inseridos e tente novamente.');
        else if (err.response && err.response.data && err.response.data.code && err.response.data.code === errorCode.NO_CREDITS)
          notifyError(`Não foi possível conectar na atividade. Entre em contato com o seu consultor e informe o código ${errorCode.NO_CREDITS}`)
        else notifyError('Problema técnico. Contate o consultor.');
        this.setState({ loadingSpinner: false });
      }
    } else {
      this.setState({ loadingSpinner: false });
      notifyError(Constantes.mensagemCPFinvalido);
    }
  };
  toogle = () => {
    this.setState({
      modal: false,
      loadingSpinner: false
    });
  };
  render() {
    return (
      <>
        <ToastContainer />
        <Modal
        isOpen={this.state.modal}
        toggle={this.toogle}
        className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>Atenção</ModalHeader>
          <ModalBody>Usuário já logado. Deseja logar aqui?</ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.toogle}>
              Não
            </Button>{' '}
            <Button color="success" onClick={this.loginNovo}>
              Sim
            </Button>{' '}
          </ModalFooter>
        </Modal>
        <Wrapper>
          <div>
            <Logo
              alt="logo"
              src={require('../Assets/_assets/logo.png')}
            />
          </div>

          <FormWrapper>
            <Form className='login-placeholder' onSubmit={this.handleForm}>
              <InputGroup>
                <InputGroupAddon
                addonType="prepend"
                className="before"
              >
                👤
              </InputGroupAddon>
              <Input
                type="text"
                autoComplete="cpf"
                name="cpf"
                onChange={this.handleChange}
                value={this.state.cpf}
                required
                placeholder="CPF"
                className="inputLogin login-placeholder"
              />
            </InputGroup>
            <br />
            <InputGroup>
              <InputGroupAddon
                addonType="prepend"
                className="before"
              >
                🔒
              </InputGroupAddon>
              <Input
                type="password"
                autoComplete="current-password"
                name="codigoAcesso"
                onChange={this.handleChange}
                required
                placeholder="Código de Acesso"
                className="inputLogin login-placeholder"
              />
              </InputGroup>
              <br />
              <Button
                style={{ fontSize: 16 }}
                yellow
                className="botao"
                disabled={this.state.loadingSpinner}
                type='submit'
              >
                ACESSAR
              </Button>
              {this.state.loadingSpinner && (
                <img
                  src={require(`../Assets/_assets/${spinner}`)}
                />
              )}
            </Form>
          </FormWrapper>
          
          <Footer>
            <Logo
              alt="logo"
              src={require('../Assets/_assets/formare_tech_horizontal_branco.png')}
            />
            <small>
              <b>© 2024 Formare Tech.</b> &nbsp; - &nbsp; Todos os Direitos Reservados &nbsp; | &nbsp; <Politics />
            </small>
          </Footer>
        </Wrapper>
      </>
    );
  }
}

export default LoginForm;
