import React from 'react';
import { Col } from 'reactstrap';

import { Qst } from '../../Assets/Assets';


export const montaPaginas = (total, atual) => {
    let lista = [];
    for (var i = 0; i < total; i++) {
      if (i + 1 === atual) {
        lista.push(
          <Qst key={i} active>
            {i + 1}
          </Qst>
        );
      } else if (i + 1 > atual) {
        lista.push(
          <Qst className="next" key={i}>
            {i + 1}
          </Qst>
        );
      } else {
        lista.push(<Qst key={i}>{i + 1}</Qst>);
      }
    }

    return <Col>{lista}</Col>;
  };


 export const srvTime = () => {
    try {
      const date = new Date();
      const UTCTime =  Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
      date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

      return UTCTime;
    } catch (err) {
      console.error('Error while trying to get the server date, returning the local date', err);
      return Date.now();
    }
  }