// essa função serve para abreviar nomes no formato Teste C. Sato
export default function abridgedControl(fullName, limit) {
    if (fullName.length > limit) {
        return abridgeFirstNameLastName(fullName);
    }
    return fullName;
}


export function abridgeFirstNameLastName(fullName='') {
    const nameSplited = fullName.split(' ').filter(n => n);

    const lastName = nameSplited.pop();

    const firstName = nameSplited[0]

    return `${firstName} ${lastName}`
}

function toAbridged(fullName) { 
    const token = '.';
    const separator = ' ';
    const names = removePrepositions(fullName).split(separator);
    const firstName = names[0];
    const lastName = names[names.length - 1];
    let surnames = '';
    names
        .filter((name, index) => index && index !== names.length - 1)
        .map(name => surnames += `${separator}${name.charAt()}${token}`);
    return `${firstName}${surnames.toUpperCase()} ${lastName}`;
}

function removePrepositions(fullName) {
    return fullName.replace(/\ dos|\ das|\ dos|\ das|\ de|\ d\'/gi, '');
}