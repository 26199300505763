import React, { Component } from "react";
import { Button } from "reactstrap";
import Minesweeper from "react-minesweeper";
import "react-minesweeper/lib/minesweeper.css";
import { Card, Container, CardBody } from "reactstrap";
import { Constantes } from "../../Services/Constantes";

class Aguarde extends Component {
  state = {
    disabledButton: false,
    status: "",
    msg: "GAME IN PROGRESS",
    minesweeperKey: 0,
    bombChance: 15,
    width: 15,
    height: 15
  };

  componentDidMount = () => {
    this.subscribePush();
    /// verifica se o grupo tem maturidade
  };
  componentWillUnmount = () => {
    Constantes.io.off("Projeto:Play");
    Constantes.io.off("Projeto:ProvaPlay");
    Constantes.io.off("Auth:LogoutInstrumento");
    Constantes.io.off("Projeto:Stop");
  };
  restartMinesweeper = () => {
    this.setState(prevState => ({
      minesweeperKey: prevState.minesweeperKey + 1,
      msg: "GAME IN PROGRESS"
    }));
    this.setState({
      status: ""
    });
  };

  subscribePush = () => {
    const io = Constantes.io;
    Constantes.io.on("Projeto:Stop", data => {
        window.location.reload();
    })
    io.on("Projeto:Play", async () => {
      console.log('Aguarde, Projeto:Play')
      window.location.reload(false);
    });
    io.on('Projeto:ProvaPlay', (data) => {
      
      let playedProvaId = data.provaId;
      let provaAtual = sessionStorage.getItem('atividade');

      if (playedProvaId === provaAtual) {
        this.props.renderProvaComponent(
          data
        );
      }
    });
    io.on("Projeto:Play-ack", ({ status }) => {
      console.info("Received Projeto:Play-ack with status " + status);
      if (!status) { // unconfirmed
        io.emit("Projeto:Play-ack-confirm")
      }
    })
    io.on("Auth:LogoutInstrumento", userId => {
      if (sessionStorage.getItem("user") === userId.toString()) {
        this.props.logout();
      }
    });
  };

  restart = () => {
    return (
      <>
        <br />
        <Minesweeper
          key={this.state.minesweeperKey}
          onWin={this.ganhou}
          onLose={this.reinicia}
          bombChance={this.state.bombChance / 100}
          width={this.state.width}
          height={this.state.height}
          style={{ backgroundColor: "white !important", width: "50% !important", maxWidth: "50% !important", minWidth: "50% !important" }}
        />
      </>
    );
  };

  ganhou = () => {
    this.setState({
      status: (
        <div>
          <h1>Você Venceu!</h1>
          <button
            className="minesweeper__restart"
            onClick={this.restartMinesweeper}
          >
            😂 - Reiniciar
          </button>
        </div>
      )
    });
  };
  reinicia = () => {
    this.setState({
      status: (
        <div>
          <h1>Você Perdeu!</h1>
          <button
            className="minesweeper__restart"
            onClick={this.restartMinesweeper}
          >
            😂 - Reiniciar
          </button>
        </div>
      )
    });
  };
  render() {
    if (this.props.info === "aguarde") {
      return (
        <Container className="text-center">
          <h3>
            <b>
              Aguarde o início da
              <br /> próxima atividade:
            </b>
          </h3>

          {this.restart()}
          <br />
          <br />
          {this.state.status}
        </Container>
      );
    }
    if (this.props.info === "mesa") {
      return (
        <Container>
          <Card>
            <CardBody>
              <h1>Vá para a Mesa {this.props.mesa}</h1>
              <p>
                Vá para a mesa {this.props.mesa} e aguarde que a atividade já
                vai começar
              </p>
              <Button
                color="success"
                onClick={this.mesa}
                disabled={this.state.disabledButton}
              >
                {" "}
                Já estou na mesa {this.props.mesa}{" "}
              </Button>
            </CardBody>
          </Card>
        </Container>
      );
    }
  }
}

export default Aguarde;
