import api from "../../../Services/api";
import { Constantes } from "../../../Services/Constantes";

export async function verificarRespostas(
  userId,
  instrumento,
  atividade,
  atividadeId,
  rodada = -1,
  grupoIndex = -1,
  projetoDinamica,
  instrumentId = null
) {
  let projectId = sessionStorage.getItem('projeto')
  // Resposta individual
  if (grupoIndex === -1) {
    let respostas
    if (atividade === Constantes.moduloProvas) {
      // respostas prova
      respostas = await api.get(`api/user/${userId}/projeto/${projectId}/modulos/provas/${atividadeId}/respostas`)
    } else {
      // respostas dinâmica
      respostas = await api.get(`api/user/${userId}/projeto/${projectId}/modulos/dinamicas/${atividadeId}/respostas/instrumento/${instrumento}`)
    }
    respostas = respostas.data

    // Checando se existe resposta previa
    let respostaPrevia;

    if (respostas && respostas.length) {
      // Checa se dinâmica
      if (atividade === Constantes.moduloDinamicas) {
        respostaPrevia = respostas.find(resposta => (
          resposta.instrumentId === instrumentId &&
          resposta.rodada === rodada
        ));
      }

      // Checa se prova
      if (atividade === Constantes.moduloProvas) {
        respostaPrevia = respostas[0]
      }
    } else {
      respostaPrevia = undefined // sem resposta prévia.
    }
    
    return respostaPrevia;
  } else {
    if (!projetoDinamica) {
      projetoDinamica = await api.get(`api/projects/${atividade}/projeto/${atividadeId}/instrumento/${instrumento}`);
    }

    let resGet = await api.get(`api/projects/dinamicas/${atividadeId}/rodadas/respostas`)
    let dinamica = resGet.data.modulos.dinamicas[0]
    
    const respostas = dinamica.rodadas[rodada].grupos.grupo[grupoIndex].respostas;
    const respostaPrevia = respostas.find(resposta => resposta.instrumentId === instrumentId);

    return respostaPrevia;
  }
}
