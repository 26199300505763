import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import api from "../../../Services/api";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Container,
  Row,
  Col
} from "reactstrap";
import { Constantes } from "../../../Services/Constantes";
import Timer from "../../../Services/Timer";
import { verificarRespostas } from "../Utils/verificarRespostas";
import ErrorComponent from '../../../Services/ErrorComponent';
import Loading from "../../../Services/Loading";
import {
  Base,
  Inst,
  Instrucoes,
  Button,
  Contador,
  Icon,
  Story,
  Footer
} from "../../Assets/Assets";
import { withRouter } from 'react-router';

import styled from "styled-components";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAlt } from '@fortawesome/free-solid-svg-icons'

import {
  notifyError
} from "../../../Services/Notificacoes";
import apiCalls from "../../../config/apiCalls";
import { millisToMinutesAndSeconds } from "../Utils/Calculos";
import { srvTime } from '../Utils';

const P = styled.p`
  font-size: 13px;
`;

// Imagens
const spinner = Constantes.imagemSpinner;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  padding: grid,
  width: "100%"
});

const grid = 1;

class Storytelling extends Component {
  state = {
    frases: [],
    dados: {},
    respostas: [],
    soma: 0,
    enunciado: "",
    modalConfirmacao: false,
    modalInstrucoes: false,
    loading: true,
    tempos: {},
    pararTimer: false,
    loadingSpinner: false,
    intervalResetRequest: false,
    haveError: false,
    errorCount: 0,
    errorRetry: 10
  };

  resultadoLista = []; // armazena as respostas do usuário, em forma de frases ordenadas.
  onLogout = false;
  onProximo = false;
  // Tempos
  tempoOnMount = srvTime();
  tempoTotal = 0;

  componentDidMount = async () => {
    this.subscribePush();
    await this.recebendoInfo();

    api
    .get(
      `api/projects/rodadas/atualizar/${sessionStorage.getItem(
        "projeto"
      )}/${localStorage.getItem("dinamica")}/get-tempos`,
      {
        params: {
          userId: sessionStorage.getItem('user'),
          rodadaAtual: this.props.rodada,
          instrumento: Constantes.instrumentoStoryTellingIndividual,
        }
      }
    )
  };

  componentWillUnmount = () => {
    Constantes.io.off("Projeto:Stop");
    Constantes.io.off("Projeto:NovosTempos");
    Constantes.io.off("Auth:LogoutInstrumento");
  };

  recebendoInfo = async () => {
    if (this.state.errorCount >= this.state.errorRetry) {
      return this.setState({ haveError: true, loading: false });
    }
    try {
      // Recuperando o template
    const dinamicaId = sessionStorage.getItem("atividade");

    await api
      .get(`api/projects/dinamicas/projeto/${sessionStorage.getItem("atividade")}/instrumento/${Constantes.instrumentoStoryTellingIndividual}`, {
        params: {
          roundIndex: this.props.rodada,
          instrumentId: this.props.idInstrumento
        }
      })
      .then(async res => {
        const projetoDinamica = res.data;
        const { template } = res.data;
        // const response = res;
        // Verificando respostas prévias
        await verificarRespostas(
          sessionStorage.getItem("user"),
          Constantes.instrumentoStoryTellingIndividual,
          Constantes.moduloDinamicas,
          dinamicaId,
          this.props.rodada,
          -1,
          projetoDinamica,
          this.props.idInstrumento
        )
          .then(async res => {
            let respostaPrevia = res;
            let dinamica = projetoDinamica.projeto.modulos.dinamicas[0]
            // let dinamica = projetoDinamica.projeto.modulos.dinamicas.find(
            //   dinamica =>
            //     dinamica._id.toString() === sessionStorage.getItem("atividade")
            // );

            // BUSCANDO INFO DO INSTRUMENTO

            let instrumento = dinamica.rodadas[
              this.props.rodada
            ].instrumentos.find(
              intrumento =>
                intrumento._id ===
                this.props.idInstrumento
            );

            // Inicializando template
            const dados = template;
            const enunciado = dados.instrucoesIndividual;
            let frases = [];

            // Inicializando tempos
            let tempos = instrumento.tempos && instrumento.tempos.individual || {
              tempoFim: 0,
              tempoInicio: 0,
              tempoRestante: 0,
            };

            this.tempoTotal = (tempos.tempoFim - tempos.tempoInicio) || 0;
            tempos.tempoRestante = (tempos.tempoFim - this.tempoOnMount) || 0;
            if (tempos.tempoRestante < 0) tempos.tempoRestante = 0;

            if (respostaPrevia) {
              // Atualizando respostas e tempos
              var modalInstrucoes = false;
              frases = respostaPrevia.resposta;
            } else {
              // não foi encontrada resposta prévia.

              var modalInstrucoes = true;

              // inicia as frases
              dados.sentencas.map((dado, indice) => {
                frases.push({
                  id: `${indice}`,
                  sequencia: dado.sequencia,
                  content:
                    String.fromCharCode(97 + indice).toUpperCase() +
                    ". " +
                    dado.enunciado
                });
              });

              // Iniciando atividade
              this.resultadoLista = frases;
              this.gravaRespostas(0); // grava a resposta inicial do usuário (ainda inalterada), com soma 0.
            }

            this.setState({
              dados,
              frases,
              enunciado,
              loading: false,
              tempos,
              modalInstrucoes,
              errorCount: 0
            });
          })
          // .catch(err => {
          //   if (err.response) console.error(err.response.data);
          //   console.error(err)
          // });
      })
      // .catch(err => {
      //   if (err.response) console.error(err.response.data);
      //   console.error(err)
      // });
    } catch (err) {
      console.error(err);
      this.setState({ errorCount: this.state.errorCount + 1 });
      setTimeout(() => this.recebendoInfo(), 1000);
    }
    
  };

  reorder = (list, startIndex, endIndex) => {
    this.resultadoLista = list;
    const [removed] = this.resultadoLista.splice(startIndex, 1);
    this.resultadoLista.splice(endIndex, 0, removed);
    return this.resultadoLista;
  };

  subscribePush = () => {
    Constantes.io.on("Project:SyncConsultantTime", data => {
      if (Object.keys(data.tempos).length && data.instrumento === Constantes.instrumentoStoryTellingIndividual && data.userId === sessionStorage.getItem('user')) {

        let tempos = data.tempos.individual
        //tempos.tempoRestante = tempos.tempoFim - srvTime()
        this.tempoTotal = tempos.tempoFim - tempos.tempoInicio;
        this.setState({
          tempos,
          intervalResetRequest: true
        });
        this.setState({ tempos })
      }
    })

    Constantes.io.on("Projeto:Stop", data => {
      if (
        data.instrumento === Constantes.instrumentoStoryTellingIndividual &&
        data.start.individual === 0 &&
        data.rodada === this.props.rodada
      ) {
        this.props.history.go()
      }
    });
    Constantes.io.on("Projeto:NovosTempos", data => {
      if (
        data.instrumento === Constantes.instrumentoStoryTellingIndividual
      ) {
        let tempos = data.tempos.individual;
        //tempos.tempoRestante = tempos.tempoFim - srvTime();
        this.tempoTotal = tempos.tempoFim - tempos.tempoInicio;
        this.setState({
          tempos,
          intervalResetRequest: true
        });
    }

    });
    Constantes.io.on("Auth:LogoutInstrumento", userId => {
      if (sessionStorage.getItem("user") === userId.toString()) {
        this.onLogout = true;
        if (this.state.tempos.tempoRestante > 0)
          this.setState({ pararTimer: true });
        else this.fimAtividade();
      }
    });
  };

  openInst = () => {
    this.setState({ modalInstrucoes: true });
  };

  cancelarInst = () => {
    this.setState({ modalInstrucoes: false });
  };

  enunciado = () => {
    const texto = this.state.enunciado;
    var res = texto.split("~");

    return (
      <div>
        <br />
        <br />
        {res[0]}
        <br />
        <br />
        {res[1]}
        <br />
        <br />
        {res[2]}
        <br />
        <br />
      </div>
    );
  };
  onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const frases = this.reorder(
      this.state.frases,
      result.source.index,
      result.destination.index
    );

    this.verificaResultado();

    this.setState({
      frases
    });
  };

  verificaResultado = async () => {
    let soma = 0;
    // Avaliando o primeiro
    if (parseInt(this.resultadoLista[0].sequencia, 10) === 1) soma++;

    // Avaliando itens sem ser o último
    for (let i = 1; i < this.resultadoLista.length; i++) {
      if (
        parseInt(this.resultadoLista[i - 1].sequencia, 10) + 1 ===
        parseInt(this.resultadoLista[i].sequencia, 10)
      ) {
        soma++;
      }
    }

    // Gravando respostas
    this.gravaRespostas(soma);
  };

  gravaRespostas = async soma => {
    const dinamicaId = sessionStorage.getItem("atividade");
    const atualizadoEm = srvTime();

    // Atualizando tempos:
    let tempos = {
      tempoAtual: this.state.tempos.tempoRestante
    };
    
    let resp = {
      instrumentId: this.props.idInstrumento,
      resposta: this.resultadoLista,
      instrumento: Constantes.instrumentoStoryTellingIndividual,
      rodada: this.props.rodada,
      tempos,
      atualizadoEm,
      pontuacao: soma
    };
    
    await api
      .put(`api/user/respostas/modulo/${Constantes.moduloDinamicas}`, {
        resp,
        atividadeId: dinamicaId,
      })
      .then(resPut => {
        // atualizado
      })
      .catch(async err => {
        console.error(err);
        let info = {
          status: 1,
          resp,
          atividadeId: dinamicaId,
          modulo: Constantes.moduloDinamicas
        };
      });

    this.setState({ soma });
  };

  proximaAtividade = async () => {
    if (this.state.errorCount >= this.state.errorRetry) {
      return this.setState({ haveError: true, loading: false, modalConfirmacao: false });
    }
    try {
      const dinamicaId = sessionStorage.getItem("atividade");
      await apiCalls.dinamica.saveInstrumentAnswer(
        Constantes.instrumentoStoryTellingIndividual,
        this.props.idInstrumento,
        Constantes.moduloDinamicas,
        dinamicaId,
        this.props.rodada,
        sessionStorage.getItem("projeto")
      );
      this.props.action();
    } catch (err) {
      console.error(err);
      this.setState({ errorCount: this.state.errorCount + 1 });
      setTimeout(() => this.proximaAtividade(), 1000);
    }
  };

  toggleConfirmacao = () => {
    if (!this.state.loadingSpinner) {
      this.setState(previousState => ({
        modalConfirmacao: !previousState.modalConfirmacao
      }));
    }
  };

  toggleInstrucoes = () => {
    this.setState(previousState => ({
      modalInstrucoes: !previousState.modalInstrucoes
    }));
  };

  tempoAtualizado = tempo => {
    this.setState(previousState => ({
      tempos: {
        ...previousState.tempos,
        tempoRestante: tempo
      }
    }));
  };

  fimAtividade = () => {
    if (this.onLogout) this.props.logout();
    else {
      if (!this.onProximo) {
        this.onProximo = true;
        if (!this.state.loadingSpinner) this.setState({ loadingSpinner: true });
        this.proximaAtividade();
      }
    }
  };

  render() {
    if (this.state.haveError) return <ErrorComponent />;
    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <div className="text-left back" id="logo" tabIndex="0">
        <Modal
          isOpen={this.state.modalInstrucoes}
          className={this.props.className}
        >
          <ModalBody>
            <Instrucoes>
              <div>
                <b>INSTRUÇÕES</b>
                <br />
                <strong>Enunciado</strong> 
                <P>Nessa atividade, será apresentado a você trechos que compõem uma história. Contudo, você perceberá que os trechos se apresentam fora de ordem. Você terá um total de { `${millisToMinutesAndSeconds(this.tempoTotal)}` } minutos para analisar e ordenar os fragmentos da história indicando as possíveis sequências da mesma.
                Após os { `${millisToMinutesAndSeconds(this.tempoTotal)}` } minutos, aparecerá para você a indicação do grupo com o qual irá discutir sobre a organização ideal da história. Para tanto, é importante que você tenha clareza e saiba explicar aos demais qual foi a lógica que considerou para a sequência que propôs.</P>
                <br />
                <strong>Como ordenar a história</strong>
                <P>Se você está utilizando um mouse físico
                Leve o mouse até o card (fragmentos de frase) que quer movimentar, clique e segure sobre o símbolo <FontAwesomeIcon icon={faArrowsAlt} /> Mantendo esse comando, arraste o card até a posição escolhida e solte o botão do mouse.
                Se você está utilizando touch
                Encoste o dedo na tela do seu notebook sobre o card (fragmentos de frase), arraste-o para a posição escolhida e tire o dedo da tela.
                Ao concluir a ordenação dos trechos, clique em ENVIAR e aguarde as instruções da próxima etapa desta atividade. 
                IMPORTANTE: Após clicar em ENVIAR, você não poderá mais fazer modificações na sequência de sua história nesta etapa individual.</P>
                <br />
                <strong>Como é definido meu resultado nesta etapa</strong>
                <P>Seu resultado final será definido pela coerência da sequência proposta.</P>
              </div>
            </Instrucoes>
          </ModalBody>
          <ModalFooter>
            <Container className="text-center">
              <Button color="success" onClick={this.cancelarInst}>
                ok
              </Button>{" "}
            </Container>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalConfirmacao}
          toggle={this.toggleConfirmacao}
          className={this.props.className}
          onClosed={() => (this.onProximo = false)}
        >
          <ModalHeader toggle={this.toggleConfirmacao}>
            Storytelling
          </ModalHeader>
          <ModalBody>
            Se você estiver satisfeito com suas escolhas clique em prosseguir,
            se não clique em cancelar
          </ModalBody>
          <ModalFooter>
            {!this.state.loadingSpinner ? (
              <div>
                <Button
                  color="success"
                  onClick={() => {
                    this.state.tempos.tempoRestante > 0
                      ? this.setState({
                          loadingSpinner: true,
                          pararTimer: true
                        })
                      : this.fimAtividade();
                  }}
                  disabled={this.state.loadingSpinner}
                >
                  Prosseguir
                </Button>{" "}
                <Button
                  color="danger"
                  onClick={this.toggleConfirmacao}
                  disabled={this.state.loadingSpinner}
                >
                  Cancelar
                </Button>
              </div>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>Enviando. Por favor não recarregue a tela. <img style={{ width: 20 }} src={require(`../../Assets/_assets/${spinner}`)} /></span>
                {
                  this.state.errorCount > 0 ?
                    <><span style={{ fontSize: 12 }}>Tentativas {this.state.errorCount} / {this.state.errorRetry}.</span></>
                  : null
                }
              </div>
            )}
          </ModalFooter>
        </Modal>
        <Base>
          <Container>
            <br />
            <br />
            <br />
            <Row>
              <Col>
                <h1>Storytelling</h1>
              </Col>
              <Col className="text-right">
                <Icon
                  src={require("../../Assets/_assets/doubt.png")}
                  alt="Instrucoes"
                />
                <Inst onClick={this.openInst}>Instruções</Inst>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col sm="6">
                <Contador
                  counter={
                    <Timer
                      intervalResetRequest={this.state.intervalResetRequest}
                      intervalResetFeedback={() => this.setState({ intervalResetRequest: false })}
                      crescente={false}
                      tempo={this.state.tempos.tempoRestante}
                      resetAutomatico={false}
                      tempoAtualizado={this.tempoAtualizado}
                      pararTimer={this.state.pararTimer}
                      resetar={false}
                      tempoParado={this.fimAtividade}
                      id="tempo"
                    />
                  }
                  tempo={
                    100 * (this.state.tempos.tempoRestante / this.tempoTotal)
                  }
                />
              </Col>
            </Row>
            <br />
            <br />

            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={(getListStyle(snapshot.isDraggingOver), {})}
                  >
                    {this.state.frases.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <Story story={item.content} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>

              <br />
              <Row>
                <Col className="text-center">
                  <Button onClick={this.toggleConfirmacao}>ENVIAR</Button>
                </Col>
              </Row>
              <Footer />
            </DragDropContext>
          </Container>
        </Base>
      </div>
    );
  }
}
export default withRouter(Storytelling);
