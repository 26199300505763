import api from "../../../../Services/api";

const fetchRespostaPrevia = async (props) => {
    const userId = sessionStorage.getItem("user");
    const idInstrumento = props.idInstrumento;
    const res = await api.get(
        `/case/get-criteria-by-instrument-and-user/${idInstrumento}/${userId}`
    );
    return res.data;
}

export default fetchRespostaPrevia;