import React from "react";
import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import { UserFotoSmall } from "../../Assets/Assets";
import { Row, Col } from "reactstrap";

import capitalizeFirstLetterEachWord from '../../../utils/capitalizeFirstLetterEachWord';

const Container = styled.div`
  display: flex;
  border-radius: 2px;
  padding-left: ${props => (props.barra ? "-20px" : "8px")};
  padding-bottom: ${props => (props.barra ? "10px" : "8px")};
  font-size: 12px;
  transition: background-color 0.2s ease;
  background-color: ${props =>
    props.isDragDisabled
      ? "lightgrey"
      : props.isDragging
      ? "lightgreen"
      : "white"};

  p {
    font-size: 12px;
    font-weight: bold;
    padding: 10px;
    display: ${props => (props.barra ? "none" : "block")};
  }
`;

export default class Task extends React.Component {
  render() {
    //console.log(this.props.barra);
    return (
      <Draggable draggableId={this.props.task.id} index={this.props.index}>
        {(provided, snapshot) => (
          <Container
            barra={this.props.barra}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
          >
            <UserFotoSmall foto={this.props.task.picture} />
            <p>{capitalizeFirstLetterEachWord(this.props.task.content.toLowerCase())}</p>
          </Container>
        )}
      </Draggable>
    );
  }
}
