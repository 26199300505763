import api from './api';
import { notifySuccess, notifyError } from './Notificacoes';
import { Constantes } from './Constantes';

const retry = 20;
const bancoTotal = 2;
const internetTotal = 2;

let reconectandoBanco = false;
let reconectandoInternet = false;
let bancoRetry = 0;
let internetRetry = 0;
let serverOK = true;
export function testarConexao() {
  if (!window.navigator.onLine) {
    if (internetRetry === internetTotal) {
      notifyError(`Problema na conexão. Contate o consultor`);
      reconectandoInternet = true;
      internetRetry = 0;
    } else internetRetry++;
  } else if (reconectandoInternet) {
    notifySuccess('Conexão reestabelecida');
    reconectandoInternet = false;
  }
}

export function testarServidor() {

  // escuta ping do server.
  Constantes.io.on('Server:ping', () => {
    serverOK = true;
  });
  let createNewNotify = true;

  // verificar status do server a cada 10s
  setInterval(() => {
    if (!serverOK) {
      // notifyError("Problema técnico. Contate o consultor.");
      // if (createNewNotify) {
      //   notifyError(`Você está offline.`);
      //   createNewNotify = false;
      // }
      // setTimeout(() => {
      //   if (!Constantes.io.connected) {
      //     this.logoutProcess();
      //   } else {
      //     notifySuccess('Você está online novamente');
      //     createNewNotify = true;
      //   }
      // }, 10000);
    } else {
      serverOK = false;
    }
  }, 1000);

  // api
  //   .get("api/auth/status")
  //   .then(() => {
  //     if (reconectandoBanco) {
  //       notifySuccess("Conexão reestabelecida");
  //       reconectandoBanco = false;
  //     }
  //   })
  //   .catch(() => {
  //     if (bancoRetry == bancoTotal) {
  //       notifyError("Problema técnico. Contate o consultor.");
  //       reconectandoBanco = true;
  //       bancoRetry = 0;
  //     } else bancoRetry++;
  //   });
}

export async function reenviarResposta(info, count = 0) {

  if (count >= retry && count % retry === 0 && window.navigator.onLine)
    notifyError('Problema técnico. Contate o consultor.');
  let { status, atividadeId, modulo } = info;
  let resp = { ...info };
  delete resp.status;
  delete resp.atividadeId;
  delete resp.modulo;
  switch (status) {
    case 1:
      // Salvando resultado do usuário
      await api
        .put(`api/user/respostas/modulo/${modulo}`, {
          atividadeId,
          resp: resp.resp
        })
        .then(() => {
          if (count > retry) notifySuccess('Conexão reestabelecida');
          count = 0;
        })
        .catch(() => reenviarResposta(info, count + 1));
      break;
    case 2:
      // Recebendo nova pergunta
      let questaoRecebida = await api
        .put(
          `api/instruments/prova/novaQuestao/${sessionStorage.getItem('user')}`,
          {
            ...resp
          }
        )
        .then(() => {
          if (count > retry) notifySuccess('Conexão reestabelecida');
          count = 0;
        })
        .catch(() => reenviarResposta(info, count + 1));
      return questaoRecebida;
    /*case 3:
      // Recebendo nova pergunta
      api
        .put(`api/user/respostas/finalizar`, {
          instrumento: info.instrumento,
          atividade: Constantes.moduloDinamicas,
        atividadeId: dinamicaId,
          rodada: info.rodada
        })
        .then(() => count > retry && notifySuccess("Conexão reestabelecida"))
        .catch(() => reenviarResposta(info, count + 1));
      break;*/
    case 4:
      api
        .put(
          `api/projects/editRespostasSecretario/${info.projetoId}/${atividadeId}`,
          {
            ...resp
          }
        )
        .then(() => {
          if (count > retry) notifySuccess('Conexão reestabelecida');
          count = 0;
        })
        .catch(() => reenviarResposta(info, count + 1));
  }
}
