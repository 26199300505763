import React, { Component } from "react";
import api from "../../../Services/api";

import * as draftToHtml from "draftjs-to-html";
import {
  Col,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Container
} from "reactstrap";
import {
  Base,
  Icon,
  Inst,
  Instrucoes,
  Contador,
  Textarea,
  Button,
  Footer
} from "../../Assets/Assets";
import { Constantes } from "../../../Services/Constantes";
import Timer from "../../../Services/Timer";
import Loading from "../../../Services/Loading";
import { millisToMinutesAndSeconds } from "../Utils/Calculos";
import { srvTime } from '../Utils';
import { notifyError } from "../../../Services/Notificacoes";
import ErrorComponent from '../../../Services/ErrorComponent';
import PropTypes from 'prop-types';

import styled from "styled-components";

import fetchInstrumentoCase from './utils/fetch-instrumento-case';
import fetchRespostaPrevia from './utils/fetch-resposta-previa';
import apiCalls from "../../../config/apiCalls";
import Criterio from "../../../models/Criterio";
import { withRouter } from 'react-router';

import './styles.css';

const ObjectID = require("bson-objectid");

// Imagens
const spinner = Constantes.imagemSpinner;

const P = styled.p`
  font-size: 13px;
`;

String.prototype.cleanup = function() {
  return this.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");
};

class Case extends Component {
  state = {
    template: {
      nomeCase: ''
    },
    textoIndividual: "",
    criterios: [],
    modalConfirmacao: false,
    msgErro: "",
    modalErro: false,
    modalInstrucoes: false,
    loading: true,
    tempos: {},
    pararTimer: false,
    loadingSpinner: false,
    intervalResetRequest: false,
    haveError: false,
    errorCount: 0,
    errorRetry: 10,
    patchError: false,
  };

  onLogout = false;
  onProximo = false;
  // Tempos
  tempoOnMount = srvTime();
  tempoTotal = 0;

  static propTypes = {
    idInstrumento: PropTypes.string.isRequired,
    tableName: PropTypes.string.isRequired,
    rodada: PropTypes.number.isRequired,
  }

  mountCriterias = async () => {
    return new Promise((resolve, reject) => {
      const userId = sessionStorage.getItem("user");
      const idInstrumento = this.props.idInstrumento;
      const tableName = this.props.tableName;
      let arrayCriterios = new Array(3).fill(0);
      arrayCriterios.map((_c, i) => {
        arrayCriterios[i] = new Criterio(ObjectID(), userId, idInstrumento, tableName);
      });

      resolve(this.setState({
        criterios: arrayCriterios
      }));
    });
  }

  componentDidMount = async () => {
    await this.mountCriterias()
    
    this.indexRodadaAtual = this.props.rodada;

    this.subscribePush();
    await this.recebendoInfo();

    api
    .get(
      `api/projects/rodadas/atualizar/${sessionStorage.getItem(
        "projeto"
      )}/${localStorage.getItem("dinamica")}/get-tempos`,
      {
        params: {
          userId: sessionStorage.getItem('user'),
          rodadaAtual: this.props.rodada,
          instrumento: Constantes.instrumentoCaseIndividual,
        }
      }
    )
  };

  componentWillUnmount = () => {
    Constantes.io.off("Projeto:Stop");
    Constantes.io.off("Projeto:NovosTempos");
    Constantes.io.off("Auth:LogoutInstrumento");
  };

  /**
   * Salva critérios em branco na base.
   * Usada no primeiro acesso a atividade.
   */
  saveEmptyCriterios = async () => {
    try {
      const criteriosArray = [...this.state.criterios];
      await apiCalls.binary.postCriteriaV2(criteriosArray);

      this.setState({
        criterios: criteriosArray
      })
    } catch (error) {
      console.error(error);
      throw new Error("Erro: não foi possível salvar critérios em branco!");
    }
  }

  updateSavedCriterios = () => {
    const criterios = this.state.criterios;
    this.setState({ patchError: false });
    criterios.map((criterio) => {
      apiCalls.binary.updateCriteria(criterio)
      .then((_) => {

      })
      .catch((err) => {
        console.error(err);
        this.setState({ patchError: true });
      })
    });
  }

  recebendoInfo = async () => {
    if (this.state.errorCount >= this.state.errorRetry) {
      return this.setState({ haveError: true, loading: false });
    }
    try {
      this.setState({ loading: true });
      const instrumento = await fetchInstrumentoCase(this.props);
      const caseTemplateConfigId = instrumento.info;
      const caseConfigResponse = await apiCalls.case.getCaseConfig(caseTemplateConfigId);
      const infoCase = caseConfigResponse.data;
      const { textos } = infoCase;
      let textoIndividual = textos[0].individual;
      
      for(let part of textos){
        for(let par of part.participante){
          if(par.participante === sessionStorage.getItem("user")){
            if(typeof (textos[par.texto].individual) === 'string'){
              textoIndividual = textos[par.texto].individual;
            }else{
              textoIndividual = draftToHtml(textos[par.texto].individual);
            }
          }
        }        
      }
      
      const respostaPrevia = await fetchRespostaPrevia(this.props);

      // Inicializando tempos
      let tempos = instrumento.tempos && instrumento.tempos.individual || {
        tempoFim: 0,
        tempoInicio: 0,
        tempoRestante: 0,
      };

      this.tempoTotal = (tempos.tempoFim - tempos.tempoInicio) || 0;
      tempos.tempoRestante = (tempos.tempoFim - this.tempoOnMount) || 0;
      if (tempos.tempoRestante < 0) tempos.tempoRestante = 0;
 
      if (!respostaPrevia || (respostaPrevia && !respostaPrevia.length)) {
        this.setState({
          modalInstrucoes: true
        });
        await this.saveEmptyCriterios();
      } else {
        this.setState({
          criterios: respostaPrevia
        });
      }
      
      this.setState({
        template: infoCase,
        textoIndividual,
        tempos,
        errorCount: 0
      });

    } catch (err) {
      console.error(err);
      this.setState({ errorCount: this.state.errorCount + 1 });
      setTimeout(() => this.recebendoInfo(), 1000);
    } finally {
      this.setState({
        loading: false
      })
    }
  };

  subscribePush = () => {
    Constantes.io.on("Project:SyncConsultantTime", data => {
      if (Object.keys(data.tempos).length && data.instrumento === Constantes.instrumentoCaseIndividual && data.userId === sessionStorage.getItem('user')) {

        let tempos = data.tempos.individual
        //tempos.tempoRestante = tempos.tempoFim - srvTime()
        this.tempoTotal = tempos.tempoFim - tempos.tempoInicio;
        this.setState({
          tempos,
          intervalResetRequest: true
        });
        this.setState({ tempos })
      }
    })

    Constantes.io.on("Projeto:Stop", data => {
      if (
        data.instrumento === Constantes.instrumentoCaseIndividual &&
        data.start.individual === 0 &&
        data.rodada === this.props.rodada
      ) {
        this.props.history.go();
      }
    });
    Constantes.io.on("Projeto:NovosTempos", data => {
      if (
        data.instrumento === Constantes.instrumentoCaseIndividual
      ) {
        let tempos = data.tempos.individual;
        //tempos.tempoRestante = tempos.tempoFim - srvTime();
        this.tempoTotal = tempos.tempoFim - tempos.tempoInicio;
        this.setState({
          tempos,
          intervalResetRequest: true
        });
      }
    });
    Constantes.io.on("Auth:LogoutInstrumento", userId => {
      if (sessionStorage.getItem("user") === userId.toString()) {
        this.onLogout = true;
        if (this.state.tempos.tempoRestante > 0)
          this.setState({ pararTimer: true });
        else this.fimAtividade();
      }
    });
  };

  proximaAtividade = async () => {
    if (this.state.errorCount >= this.state.errorRetry || this.state.patchError) {
      return this.setState({ haveError: true, loading: false, modalConfirmacao: false });
    }
    try {
      const idProjeto = sessionStorage.getItem("projeto") || this.props.idProjeto;
      const idDinamica = sessionStorage.getItem("atividade") || this.props.idDinamica;
      const idUser = sessionStorage.getItem("user");
      const idInstrumento = this.props.idInstrumento;

      await apiCalls.case.finalizarCaseIndividual({
        queryParams: {
          idProjeto,
          idDinamica,
          idUser,
          idInstrumento,
          indexRodadaAtual: this.indexRodadaAtual,
          group: this.props.tableName
        }
      });
      this.props.action();
    } catch (err) {
      console.error(err);
      this.setState({ errorCount: this.state.errorCount + 1 });
      setTimeout(() => this.proximaAtividade(), 1000);
    }
  };

  verificarRespostasCase = () => {
    let verificar = true;
    loop1: for (let i = 0; i < Constantes.caseIndividualQuantidade; i++) {
      if (this.state.criterios[i].value.trim() == "") {
        this.setState({
          modalErro: true,
          msgErro: "Preencha todos os critérios"
        });
        verificar = false;
        break;
      }
      for (let j = i + 1; j < Constantes.caseIndividualQuantidade; j++) {
        if (
          this.state.criterios[i].value.cleanup() ===
          this.state.criterios[j].value.cleanup()
        ) {
          verificar = false;
          this.setState({
            modalErro: true,
            msgErro: "Os critérios precisam ser diferentes"
          });
          break loop1;
        }
      }
    }
    if (verificar) this.setState({ modalConfirmacao: true });
  };

  tempoAtualizado = tempo => {
    this.setState(previousState => ({
      tempos: {
        ...previousState.tempos,
        tempoRestante: tempo
      }
    }));
  };

  stripEmojis = (str) =>
  str
    .replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, ''
    )
    .replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');

  handleCriterioChange = e => {
    if (e.target.value.length <= Constantes.criteriosTamanho) {
      let criterios = this.state.criterios;
      criterios[
        parseInt(e.target.name, 10) - 1
      ].value = this.stripEmojis(e.target.value.toLowerCase());
      this.setState({ criterios });
    }
  };

  fimAtividade = () => {
    if (this.onLogout) this.props.logout();
    else {
      if (!this.onProximo) {
        this.onProximo = true;
        if (!this.state.loadingSpinner) this.setState({ loadingSpinner: true });
        this.proximaAtividade();
      }
    }
  };

  toggleConfirmacao = () => {
    if (!this.state.loadingSpinner) {
      this.setState(previousState => ({
        modalConfirmacao: !previousState.modalConfirmacao
      }));
    }
  };

  toggleErro = () => {
    this.setState(previousState => ({
      modalErro: !previousState.modalErro
    }));
  };

  toggleInstrucoes = () => {
    this.setState(previousState => ({
      modalInstrucoes: !previousState.modalInstrucoes
    }));
  };

  render() {
    if (this.state.haveError) return <ErrorComponent />;
    if (this.state.loading) {
      if (this.state.proximaAtividade) this.proximaAtividade();

      return <Loading />;
    }
    const criterios = this.state.criterios;
    return (
      <div className="text-left back" tabIndex="0">
        <Base>
          <Modal
            isOpen={this.state.modalInstrucoes}
            className={this.props.className}
          >
            <ModalBody>
              <Instrucoes>
                <div>
                  <b>INSTRUÇÕES</b>
                  <br />
                  <strong>Enunciado</strong>
                  <P>Você terá um total de {`${millisToMinutesAndSeconds(this.tempoTotal)}`} minutos para analisar a situação proposta descrita a seguir, e preparar-se para a reunião que se seguirá com uma equipe a ser informada ao final desta etapa. Após a análise, reflita e defina três critérios que você considerar determinantes para uma tomada de decisão, de acordo com o que será proposto no texto abaixo.
                  </P>
                  <strong>Como criar os critérios</strong>
                  <P>Após a leitura da situação apresentada a seguir, haverá 3 campos para você registrar cada um dos critérios que propor. Clique no campo e insira o texto.
                  </P>
                  <strong>Regras</strong><br />
                      <P><b>•</b> O campo aceita, no máximo, 100 caracteres</P>
                      <P><b>•</b> Atente-se para que o texto de cada campo represente apenas UM critério. Não deverá haver dois ou mais critérios em um único campo </P>
                      <P><b>•</b> Não é possível enviar a atividade sem ter criado os 3 critérios </P>
                  <P>Quando tiver concluído a criação dos 3 critérios, você deverá clicar em ENVIAR para a atividade.</P> 
                  <P>IMPORTANTE: Após enviar a atividade você não poderá voltar para ajustar</P>
                  <P>Lembre-se, o Critério é um argumento que deve ser entendido pelo seu executivo, sem que haja necessidade de você estar presente para explicar.
                  </P>
                  <P><b>Exemplos:</b></P>
                      <P><b>•</b> Certo: "O custo, sendo uma variável estratégica, deverá ser analisada em relação ao retorno do investimento."</P>
                  <P>Errado: "Custo versus benefício."</P>
                </div>
              </Instrucoes>
            </ModalBody>
            <ModalFooter>
              <Container className="text-center">
                <Button color="success" onClick={this.toggleInstrucoes}>
                  ok
                </Button>{" "}
              </Container>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={this.state.modalConfirmacao}
            toggle={this.toggleConfirmacao}
            className={this.props.className}
            onClosed={() => (this.onProximo = false)}
          >
            <ModalHeader toggle={this.toggleConfirmacao}>Case</ModalHeader>
            <ModalBody>
              Se você estiver satisfeito com suas escolhas clique em prosseguir,
              se não clique em cancelar
            </ModalBody>
            <ModalFooter>
              {!this.state.loadingSpinner ? (
                <div>
                  <Button
                    color="success"
                    onClick={() => {
                      this.state.tempos.tempoRestante > 0
                        ? this.setState({
                            loadingSpinner: true,
                            pararTimer: true
                          })
                        : this.fimAtividade();
                    }}
                    disabled={this.state.loadingSpinner}
                  >
                    Prosseguir
                  </Button>{" "}
                  <Button
                    color="danger"
                    onClick={this.toggleConfirmacao}
                    disabled={this.state.loadingSpinner}
                  >
                    Cancelar
                  </Button>
                </div>
              ) : (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>Enviando. Por favor não recarregue a tela. <img style={{ width: 20 }} src={require(`../../Assets/_assets/${spinner}`)} /></span>
                    {
                      this.state.errorCount > 0 ?
                        <><span style={{ fontSize: 12 }}>Tentativas {this.state.errorCount} / {this.state.errorRetry}.</span></>
                      : null
                    }
                  </div>
              )}
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={this.state.modalErro}
            toggle={this.toggleErro}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleErro}>Case</ModalHeader>
            <ModalBody>{this.state.msgErro}</ModalBody>
            <ModalFooter>
              <Button color="success" onClick={this.toggleErro}>
                OK
              </Button>{" "}
            </ModalFooter>
          </Modal>
          <Container>
            <br />
            <br />
            <br />
            <Row>
              <Col>
                <h1>Case</h1>
              </Col>
              <Col className="text-right">
                <Icon
                  src={require("../../Assets/_assets/doubt.png")}
                  alt="Instrucoes"
                />
                <Inst onClick={this.toggleInstrucoes}>Instruções</Inst>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col sm="6">
                <Contador
                  counter={
                    <Timer
                      intervalResetRequest={this.state.intervalResetRequest}
                      intervalResetFeedback={() => this.setState({ intervalResetRequest: false })}
                      crescente={false}
                      tempo={this.state.tempos.tempoRestante}
                      resetAutomatico={false}
                      tempoAtualizado={this.tempoAtualizado}
                      pararTimer={this.state.pararTimer}
                      resetar={false}
                      tempoParado={this.fimAtividade}
                      id="tempo"
                    />
                  }
                  tempo={
                    100 * (this.state.tempos.tempoRestante / this.tempoTotal)
                  }
                />
              </Col>
            </Row>
            <br />
            <br />
            <h1>{this.state.template.nomeCase}</h1>
            {/*<div
              dangerouslySetInnerHTML={{
                __html: this.state.template.template.texto
              }}
            />*/}

            <div
              className='responsive-image'
              dangerouslySetInnerHTML={{
                __html: (this.state.textoIndividual)
              }}
            />

            <Col sm="12" className="text-left">
              {" "}
              <br />
              <br />
              <h2>Defina os Critérios Abaixo:</h2>
              {this.state.criterios.map((criterio, index) => (
                <div key={index}>
                  {`${this.state.criterios[index].value.length}/${
                    Constantes.criteriosTamanho
                  }`}
                  <br />
                  <Textarea
                    placeholder="Insira um critério de decisão"
                    name={index + 1}
                    key={index}
                    onChange={this.handleCriterioChange}
                    onBlur={this.updateSavedCriterios}
                    onFocus={this.updateSavedCriterios}
                    value={criterio.value}
                  />
                  <br />
                </div>
              ))}
            </Col>
            <Container className="text-center">
              <Button color="success" onClick={this.verificarRespostasCase}>
                {" "}
                Enviar{" "}
              </Button>
            </Container>
            <Footer />
            <br />
            <br />
            <br />
            <br />
            <br />
          </Container>
        </Base>
      </div>
    );
  }
}

export default withRouter(Case);
