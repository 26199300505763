import React from "react";
import styled from "styled-components";
import Task from "./Task";
import { Droppable } from "react-beautiful-dnd";
import { Row, Col, Container } from "reactstrap";

const Place = styled.div`
  height: 150px;
  div p {
    display: none;
  }
`;
const Title = styled.p`
  padding: 8px;
  font-size: 12px;
`;
const TaskList = styled.div`
  transition: background-color 0.2s ease;
  background-color: ${props => (props.isDraggingOver ? "skyblue" : "white")};
`;

export default class Column extends React.Component {
  render() {
    return (
      <>
        <Title>
          {this.props.column.title != "Participantes"
            ? this.props.column.title
            : ""}
        </Title>
        <Droppable droppableId={this.props.column.id} type="TASK">
          {(provided, snapshot) => (
            <TaskList
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
            >
              {this.props.tasks.map((task, index) => (
                <Task
                  tests={true}
                  className="text-center"
                  barra={this.props.barra}
                  key={task.id}
                  task={task}
                  index={index}
                />
              ))}
              <Place>{provided.placeholder}</Place>
            </TaskList>
          )}
        </Droppable>
      </>
    );
  }
}
