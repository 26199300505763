import React, { Component } from "react";
import styled from 'styled-components';
import { Col, Container, Button, Card, CardBody } from "reactstrap";

import { Constantes } from '../../Services/Constantes';
import {
  Header,
  Base,
  Nome,
  UserGreeting,
  UserFoto,
  User,
  BaseConclusao,
  Logo
} from "../Assets/Assets";

import api from "../../Services/api";
import apiCalls from '../../config/apiCalls';
import Politics from "../../components/Politics";

const apiProjects = apiCalls.projects;
let logoutTimer;

const StyledDiv = styled.div`
  text-align: center;
  height: 100%;
  background: #f2c800;

  .logo {
    filter: invert(1);
    width: 100px;
  }

  .footer-logo {
    filter: invert(1);
  }

  button {
    background: #002a4b;
    color: #fff;
    border: 0px;
    font-size: 12px;
    width: 200px;
    margin: 60px 0px 20px 0px;
  }
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  background: #f2c800;

  color: #000;
  text-align: center;
`

class Fim extends Component {
  state = {
    textoFinal: '',
  }

  componentDidMount = async () => {

    document.body.style.background = '#f2c800';

    const projectId = sessionStorage.getItem('projeto');
    const atividadeId = sessionStorage.getItem('atividade');
    const modulo = sessionStorage.getItem('modulo');

    try {
      const response = await apiProjects.getProvasText(projectId, modulo, atividadeId);
      const { textos, textosTemplate } = response.data;
  
      if (modulo === Constantes.moduloDinamicas) {
        textos.final = textosTemplate.final + `<p>${textos.final}</p>`;
      }

      this.setState({
        textoFinal: textos.final
      });
    } catch(err) {
      console.log(err);
    }

    if (!sessionStorage.getItem("token")) this.props.history.push("/");
    else logoutTimer = setInterval(this.logout, 60000);
  };

  componentWillUnmount() {
    document.body.style.background = '#fff';
  }

  start = () => {
    this.props.history.push("/iniciar");
  };

  logout = () => {
    api
      .post("api/auth/logout", { userId: sessionStorage.getItem("user") })
      .then(() => {
        clearInterval(logoutTimer);
        let projetoId = sessionStorage.getItem("projeto");
        sessionStorage.clear();
        this.props.history.push(`/projeto/${projetoId}`);
      })
      .catch(err => {
        if (err.response) console.log(err.response.data);
        this.logout();
      });
  };
  render() {
    return (
      <StyledDiv>
        <Logo
          className="logo"
          alt="logo"
          src={require("../Assets/_assets/logo.png")}
        />
          <h2>Final dinâmica</h2>

          <p style={{ marginBottom: 40, background: '#f2c800' }}>As atividades foram finalizadas, parabéns! Siga as orientações do consultor</p>

          <div
            style={{ marginBottom: 40, wordBreak: 'break-word', paddingLeft: 100, paddingRight: 100, background: '#f2c800' }}
            className="text-center"
            dangerouslySetInnerHTML={{
              __html: `${this.state.textoFinal}`
            }}
          />

          <div style={{ width: '100%', background: '#f2c800' }}>
            <Button onClick={this.logout}>Voltar para página Inicial</Button>
          </div>
          <Footer>
            <Logo
              alt="logo"
              className={'footer-logo'}
              src={require('../Assets/_assets/formare_tech_horizontal_branco.png')}
            />
            <small>
              <b>© 2024 Formare Tech.</b> &nbsp; - &nbsp; Todos os Direitos Reservados &nbsp; | &nbsp; <Politics />
            </small>
          </Footer>
      </StyledDiv>
    );
  }
}

export default Fim;
