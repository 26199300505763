import styled from "styled-components";
export const Person = styled.div`
  .foto {
    width: 110px;
    height: 110px;
    border: solid 2px white;
    -webkit-box-shadow: -1px 8px 10px 10px rgba(0, 0, 0, 0.32);
    -moz-box-shadow: -1px 8px 10px 10px rgba(0, 0, 0, 0.32);
    box-shadow: -0px 0px 10px 10px rgba(0, 0, 0, 0.32);
    border-radius: 60px;
    background:url(${props=>props.background});
    background-size:cover;
    background-position:center center;
  }

  .foto:hover {
    box-shadow: -1px 0px 30px 15px rgba(0, 0, 0, 0.50);
  }

  p {
    margin: 20px;
    font-weight: 700;
    color: #012a4a;
    text-align: center;
  }
  padding: 10px;
  text-align:center [type="checkbox"] {
    opacity: 0;
  }

  /* IMAGE STYLES */
  label {
    cursor: pointer;
  }
  [type="checkbox"] + label div {
    opacity: 0.2;
  }
  [type="checkbox"]:checked + label div {
    opacity: 1;
  }
`;

export const Person2 = styled.div`
  .foto {
    width: 110px;
    height: 110px;
    border: solid 2px white;
    -webkit-box-shadow: -1px 8px 10px 10px rgba(0, 0, 0, 0.32);
    -moz-box-shadow: -1px 8px 10px 10px rgba(0, 0, 0, 0.32);
    box-shadow: -0px 0px 10px 10px rgba(0, 0, 0, 0.32);
    border-radius: 60px;
    background:url(${props=>props.background});
    background-size:cover;
    background-position:center center;
  }

  .foto:hover {
    box-shadow: -1px 0px 30px 15px rgba(0, 0, 0, 0.50);
  }

  p {
    margin: 20px;
    font-weight: 700;
    color: #012a4a;
    text-align: center;
  }
  padding: 10px;
  text-align:center [type="checkbox"] {
    opacity: 0;
  }

  /* IMAGE STYLES */
  label {
    cursor: pointer;
  }
  [type="checkbox"] + label div {
    opacity: 0.2;
  }
  [type="checkbox"]:checked + label div {
    opacity: 1;
  }
`;
