import React, { Component } from "react";
import { UncontrolledAlert } from "reactstrap";
import { DragDropContext } from "react-beautiful-dnd";
import styled from "styled-components";
import Column from "./Drop";
import api from "../../../Services/api";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Container,
  Row,
  Col
} from "reactstrap";
import { Constantes } from "../../../Services/Constantes";
import { reenviarResposta } from "../../../Services/GerenciarConexao";
import Timer from "../../../Services/Timer";
import { verificarRespostas } from "../Utils/verificarRespostas";
import Loading from "../../../Services/Loading";
import { millisToMinutesAndSeconds } from "../Utils/Calculos";
import { srvTime } from '../Utils';
import { montaPaginas } from "../Utils";
import ErrorComponent from '../../../Services/ErrorComponent';

import {
  Base,
  Inst,
  Instrucoes,
  Button,
  Contador,
  Icon,
  Story,
  Clear,
  Qst,
  Footer
} from "../../Assets/Assets";
import { notifyError } from "../../../Services/Notificacoes";

import { withRouter } from 'react-router';

import apiCalls from "../../../config/apiCalls";

// Imagens
const spinner = Constantes.imagemSpinner;

var json = {};
var current = json;
var columnJson = {};
var col = columnJson;
var ordem = [];
var total = [];
var resposta;
var valor = {};
var contDesenvolvedor = 0;

var initialData = {
  tasks: {
    e: { id: "e", content: "" }
  },
  columns: {
    participantes: {
      id: "participantes",
      title: "Participantes",
      estilo: "Participantes",
      taskIds: ["e"]
    }
  },
  // Facilitate reordering of the columns
  columnOrder: ["participantes"]
};

var autoritarioResp = [];
var diretivoResp = [];
var modeladorResp = [];
var participativoResp = [];
var afetivoResp = [];
var desenvolvedorResp = [];

/// MEDE A MATURIDADE ANTERIOR PELO CASE
///

let estilos = {
  autoritario: [],
  diretivo: [],
  modelador: [],
  participativo: [],
  afetivo: [],
  desenvolvedor: [],
  laissezFaire: []

};

var sumAut = 0;
var sumDir = 0;
var sumMod = 0;
var sumPart = 0;
var sumAfe = 0;
var sumDev = 0;

var maturidade = 0;
var resultadoLista;
var maturidadeAuto = [3, 2, 1, -1, -2, -3];
var maturidadeDir = [2, 2, 1, -1, -2, -3];
var maturidadeMod = [2, 2, 1, -1, -2, -2];
var maturidadePart = [-1, -1, 1, 2, 2, 3];
var maturidadeAfe = [-2, -1, 1, 1, 2, 2];
var maturidadeDev = [1, 1, 2, 2, 3, 3];
var maturidadeLaissez = [-3, -3, -3, -3, -3, -3];

const Box = styled.div`
  border: 1px solid lightgray;
`;

const Boxed = styled.div`
  border: 1px solid lightgray;

  @media (max-width: 575px) {
    width: 90% !important;
    margin: 0 auto !important;
}
`;

const P = styled.p`
  font-size: 13px;
`;

const StyledCol = styled(Col) `
  text-align: right !important;

  @media (max-width: 575px) {
    text-align: left !important;
  }
`

const StyledRow = styled(Row) `
  @media (max-width: 575px) {
    flex-direction: column-reverse;
  }
`

const AlertRow = styled(Row) `
  display: none !important;

  @media (max-width: 575px) {
    display: flex !important;
  }
`

 class TiposDeLideranca extends Component {
  state = {
    initialData,
    dados: [],
    participantes: [],
    finais: {},
    items: [],
    enunciado: "",
    selected: [],
    perguntaAtual: 0,
    respostas: [],
    modalConfirmacao: false,
    modalErro: false,
    modalInstrucoes: false,
    loading: true,
    tempos: {},
    pararTimer: false,
    loadingSpinner: false,
    barra: false,
    intervalResetRequest: false,
    haveError: false,
    errorCount: 0,
    errorRetry: 10
  };
  respostas = [];
  onLogout = false;
  onProximo = false;
  // Tempos
  tempoOnMount = srvTime();
  tempoTotal = 0;

  componentDidMount = async () => {
    // document.getElementById("barra").width = 0;
    
    this.subscribePush();
    await this.recebendoInfo();

    api
    .get(
      `api/projects/rodadas/atualizar/${sessionStorage.getItem(
        "projeto"
      )}/${localStorage.getItem("dinamica")}/get-tempos`,
      {
        params: {
          userId: sessionStorage.getItem('user'),
          rodadaAtual: this.props.rodada,
          instrumento: Constantes.instrumentoEstilos,
        }
      }
    )
  };

  componentWillUnmount = () => {
    Constantes.io.off("Projeto:Stop");
    Constantes.io.off("Projeto:NovosTempos");
    Constantes.io.off("Auth:LogoutInstrumento");
  };

  recebendoInfo = async () => {
    if (this.state.errorCount >= this.state.errorRetry) {
      return this.setState({ haveError: true, loading: false });
    }
    try {
      // Pegando info de estilos
    let inicio = srvTime()

    let resGet = await api.get(`api/projects/dinamicas/projeto/${sessionStorage.getItem("atividade")}/instrumento/${Constantes.instrumentoEstilos}`)
    let projetoDinamica = resGet.data

    await api
      .get("api/instruments/5c1a84452c87cd05d02bd1d8")
      .then(async res => {
        let fim = srvTime()
        let segundos = (fim - inicio) / 1000

        const response = res;
        const dinamicaId = sessionStorage.getItem("atividade");
        // Verificando respostas prévias
        await verificarRespostas(
          sessionStorage.getItem("user"),
          Constantes.instrumentoEstilos,
          Constantes.moduloDinamicas,
          dinamicaId,
          this.props.rodada,
          -1,
          projetoDinamica,
          this.props.idInstrumento
        )
          .then(async res => {
            let respostaPrevia = res;

            const dados = response.data.data;
            let enunciado = dados[0].enunciado;
            let perguntaAtual = 0;

            // Verificando grupo e salvando informações dos participantes
            inicio = srvTime()
            await api
              .get(
                `api/projects/dinamicas/${dinamicaId}/grupos/${
                  this.props.rodada
                }/${this.props.grupo}`
              )
              .then(async res => {
                fim = srvTime()
                segundos = (fim - inicio) / 1000

                let participantes = res.data;

                // Verifica info do instrumento
                inicio = srvTime()
                // await api
                  // .get(`api/projects/dinamicas/projeto/${sessionStorage.getItem("atividade")}/instrumento/${Constantes.instrumentoEstilos}`)
                  // .get(`api/projects/dinamicas/projeto/${sessionStorage.getItem("atividade")}`)
                  // .get(`api/projects/${sessionStorage.getItem("projeto")}/info`)
                  // .then(async res => {
                    fim = srvTime()
                    segundos = (fim - inicio) / 1000


                    // const responseGrupo = res;
                    let dinamica = projetoDinamica.projeto.modulos.dinamicas[0]
                    // let dinamica = responseGrupo.data.projeto.modulos.dinamicas.find(
                    // // let dinamica = responseGrupo.data.modulos.dinamicas.find(
                    //   dinamica =>
                    //     dinamica._id.toString() ===
                    //     sessionStorage.getItem("atividade")
                    // );
                    let instrumento = dinamica.rodadas[
                      this.props.rodada
                    ].instrumentos.find(
                      intrumento =>
                        intrumento.content === Constantes.instrumentoEstilos
                    );

                    // Inicializando tempo
                    let tempos = instrumento.tempos && instrumento.tempos.individual || {};
                    if (tempos) {
                      this.tempoTotal = tempos.tempoFim - tempos.tempoInicio;
                      tempos.tempoRestante = tempos.tempoFim - this.tempoOnMount;
                      if (tempos.tempoRestante < 0) tempos.tempoRestante = 0;
                    }

                    let modalInstrucoes = false;
                    
                    // CASO ENCONTRE RESPOSTA PRÉVIA, CARREGAR E SALVAR NA BASE
                    if (respostaPrevia) {
                      // Atualizando respostas
                      estilos = respostaPrevia.resposta.estilos;
                      perguntaAtual = respostaPrevia.resposta.perguntaAtual;
                      this.monta(
                        participantes,
                        dados[0].alternativas[perguntaAtual]
                      );

                      this.clickCount = perguntaAtual;
                      this.setState({
                        dados,
                        enunciado,
                        participantes,
                        perguntaAtual,
                        tempos,
                        modalInstrucoes,
                        loading: false
                      });
                    } else {
                      modalInstrucoes = true;
                      // Atualizando tempos:
                      let tempoAtual = tempos.tempoRestante;
                      const atualizadoEm = srvTime();
                      let resp = {
                        instrumentId: this.props.idInstrumento,
                        instrumento: Constantes.instrumentoEstilos,
                        resposta: {
                          estilos,
                          perguntaAtual: 0
                        },
                        atualizadoEm,
                        rodada: this.props.rodada,
                        tempos: { tempoAtual },
                        finalizado: false
                      };

                      inicio = srvTime()
                      await api
                        .put(
                          `api/user/respostas/modulo/${
                            Constantes.moduloDinamicas
                          }`,
                          {
                            resp,
                            atividadeId: dinamicaId
                          }
                        )
                        .then(() => {
                          fim = srvTime()
                          segundos = (fim - inicio) / 1000

                          this.monta(
                            participantes,
                            dados[0].alternativas[perguntaAtual]
                          );
                          this.setState({
                            dados,
                            enunciado,
                            participantes,
                            perguntaAtual,
                            tempos,
                            modalInstrucoes,
                            loading: false,
                            errorCount: 0
                          });
                        })
                        // .catch(async err => {
                        //   console.error(err)
                        //   if (err.response) {
                        //     console.error(err.response.data);
                        //   }
                        //   let info = {
                        //     status: 1,
                        //     resp,
                        //     atividadeId: dinamicaId,
                        //     modulo: Constantes.moduloDinamicas
                        //   };
                        //   await reenviarResposta(info);
                        // });
                    }
                  // })
                  // .catch(err => {
                  //   console.log(err)
                  //   if (err.response) console.log(err.response.data);
                  //   //this.recebendoInfo();
                  // });
              })
              // .catch(err => {
              //   console.error(err)
              //   if (err.response) console.error(err.response.data);
              //   //this.recebendoInfo();
              // });
          })
          // .catch(err => {
          //   console.error(err)
          //   if (err.response) console.error(err.response.data);
          //   //this.recebendoInfo();
          // });
      })
      // .catch(err => {
      //   console.error(err)
      //   if (err.response) console.error(err.response.data);
      //   //this.recebendoInfo();
      // });
    } catch (err) {
      console.error(err);
      this.setState({ errorCount: this.state.errorCount + 1 });
      setTimeout(() => this.recebendoInfo(), 1000);
    }
  };

  subscribePush = () => {
    Constantes.io.on("Project:SyncConsultantTime", data => {
      if (Object.keys(data.tempos).length && data.instrumento === Constantes.instrumentoEstilos && data.userId === sessionStorage.getItem('user')) {

        let tempos = data.tempos.individual
        //tempos.tempoRestante = tempos.tempoFim - srvTime()
        this.tempoTotal = tempos.tempoFim - tempos.tempoInicio;
        this.setState({
          tempos,
          intervalResetRequest: true
        });
        this.setState({ tempos })
      }
    })
    Constantes.io.on("Projeto:Stop", data => {
      if (
        data.instrumento === Constantes.instrumentoEstilos &&
        data.start.individual === 0 &&
        data.rodada === this.props.rodada
      ) {
        this.props.history.go() // refresh the page
      }
    });
    Constantes.io.on("Projeto:NovosTempos", data => {
      let tempos = data.tempos.individual;
      //tempos.tempoRestante = tempos.tempoFim - srvTime();
      this.tempoTotal = tempos.tempoFim - tempos.tempoInicio;
      this.setState({
        tempos,
        intervalResetRequest: true
      });
    });
    Constantes.io.on("Auth:LogoutInstrumento", userId => {
      if (sessionStorage.getItem("user") === userId.toString()) {
        this.onLogout = true;
        if (this.state.tempos.tempoRestante > 0)
          this.setState({ pararTimer: true });
        else this.fimAtividade();
      }
    });
  };

  transforma = ids => {
    var novo = [];
    for (var i = 0; i < ids.length; i++) {
      novo[i] = [];
      for (var i2 = 0; i2 < ids[i].length; i2++) {
        for (var i3 = 0; i3 < this.props.geral.length; i3++) {
          for (var i4 = 0; i4 < this.props.geral[i3].pessoas.length; i4++) {
            if (ids[i][i2] === this.props.geral[i3].pessoas[i4].id) {
              novo[i].push(this.props.geral[i3].pessoas[i4].nome);
            }
          }
        }
      }
      return novo;
    }
  };
  monta = async (participantes, pergunta) => {
    json = {};
    current = json;
    columnJson = {};
    col = columnJson;
    ordem = [];
    await participantes.map((part, indice) => {
      var valordeId = part.id;

      current[valordeId] = {
        id: valordeId,
        content: part.nome,
        picture: part.foto
      };
      col["participantes"] = {
        id: "participantes",
        title: "Participantes",
        estilo: "Participantes",
        taskIds: []
      };
      ordem.push(part.id);
    });
    await pergunta.resposta.map((valor, indice) => {
      col[valor.estilo] = {
        id: valor.estilo,
        title: valor.resposta,
        estilo: valor.estilo,
        taskIds: []
      };

      initialData.columnOrder[0] = "participantes";
      initialData.columnOrder[indice + 1] = valor.estilo;
    });
    initialData.columns = columnJson;
    initialData.tasks = json;
    initialData.columns.participantes.taskIds = ordem;
    this.setState({ initialData: initialData });

  };
  enunciado = () => {
    const texto = this.state.enunciado;
    var res = texto.split("~");

    return (
      <div>
        {res[0]}
        <br />
        <br />
        {res[1]}
        <br />
        <br />
        {res[2]}
        <br />
        <br />
        {res[3]}
        <br />
        <br />
      </div>
    );
  };
  montaAlternativas = () => {
    this.state.respostas.map((test, indice) => {
      return indice;
    });
  };
  verifica = () => {
    let b = document.getElementById("button");

    if (this.state.initialData.columns.participantes.taskIds.length === 0) {
      b.blur();
      this.setState({ modalConfirmacao: true });
    } else {
      this.setState({ modalErro: true });
    }
  };

  onDragEnd = result => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = this.state.initialData.columns[source.droppableId];
    const finish = this.state.initialData.columns[destination.droppableId];

    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        taskIds: newTaskIds
      };

      const newState = {
        ...this.state.initialData,
        columns: {
          ...this.state.initialData.columns,
          [newColumn.id]: newColumn
        }
      };

      this.setState(newState);
      return;
    }

    // Moving from one list to another
    const startTaskIds = Array.from(start.taskIds);

    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);

    const newFinish = {
      ...finish,
      taskIds: finishTaskIds
    };

    const newState = {
      ...this.state.initialData,
      columns: {
        ...this.state.initialData.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish
      }
    };

    this.verificaResultado(newState);
  };

  /**
   * salva os votos no state
   */
  verificaResultado = async (initialData, proxima = false) => {
    var obj = initialData.columns;
    this.respostas.push(obj);

    for (var i = 0; i < this.respostas.length; i++) {
      if (this.respostas[i]["Autoritário"]) {
        estilos.autoritario[this.state.perguntaAtual] = this.respostas[i][
          "Autoritário"
        ].taskIds;
      }
      if (this.respostas[i]["Diretivo"]) {
        estilos.diretivo[this.state.perguntaAtual] = this.respostas[i][
          "Diretivo"
        ].taskIds;
      }
      if (this.respostas[i]["Modelador"]) {
        estilos.modelador[this.state.perguntaAtual] = this.respostas[i][
          "Modelador"
        ].taskIds;
      }
      if (this.respostas[i]["Participativo"]) {
        estilos.participativo[this.state.perguntaAtual] = this.respostas[i][
          "Participativo"
        ].taskIds;
      }
      if (this.respostas[i]["Afetivo"]) {
        estilos.afetivo[this.state.perguntaAtual] = this.respostas[i][
          "Afetivo"
        ].taskIds;
      }
      if (this.respostas[i]["Desenvolvedor"]) {
        estilos.desenvolvedor[this.state.perguntaAtual] = this.respostas[i][
          "Desenvolvedor"
        ].taskIds;
      }
      if (this.respostas[i]["LaissezFaire"]) {
        estilos.laissezFaire[this.state.perguntaAtual] = this.respostas[i][
          "LaissezFaire"
        ].taskIds;
      }
    }
    ///// CALCULANDO RESPOSTAS

    // console.log(resposta)

    ///// CALCULANDO RESPOSTAS

    /*console.log("Verificando estilos", estilos.autoritario);
    this.transforma(estilos.autoritario);
    this.transforma(estilos.diretivo);
    this.transforma(estilos.modelador);
    this.transforma(estilos.participativo);
    this.transforma(estilos.afetivo);
    this.transforma(estilos.desenvolvedor);*/

    //this.gravarResposta(proxima);
    this.setState({ initialData });
  };

  /**
   * salva a resposta do participante
   * 
   * @param {boolean} proxima indica se deve configurar a proxima questão no state depois de salvar
   */
  gravarResposta = async (proxima, retry = false) => {
    if (this.state.errorCount >= this.state.errorRetry) {
      notifyError("Não foi possível salvar a resposta. Por favor, tente novamente");
      return this.setState({ loading: false, modalConfirmacao: false, errorCount: 0, loadingSpinner: false });
    }
    let { perguntaAtual } = this.state;
    if (proxima) perguntaAtual++;
    resposta = {
      estilos,
      perguntaAtual
    };

    // Atualizando tempos:
    let tempos = {
      tempoAtual: this.state.tempos.tempoRestante
    };

    if (retry) var atualizadoEm = retry;
    else var atualizadoEm = srvTime();

    let resp = {
      instrumentId: this.props.idInstrumento,
      resposta,
      instrumento: Constantes.instrumentoEstilos,
      rodada: this.props.rodada,
      atualizadoEm,
      tempos
    };
    const dinamicaId = sessionStorage.getItem("atividade");
    await api
      .put(`api/user/respostas/modulo/${Constantes.moduloDinamicas}`, {
        resp,
        atividadeId: dinamicaId
      })
      .then(() => {

        if (proxima) {
          this.monta(
            this.state.participantes,
            this.state.dados[0].alternativas[perguntaAtual]
          );
          this.setState(previousState => ({
            modalConfirmacao: false,
            loadingSpinner: false,
            perguntaAtual: previousState.perguntaAtual + 1,
          }));
        }
      })
      .catch(err => {
        console.error(err);
        this.setState({ errorCount: this.state.errorCount + 1 });
        setTimeout(() => this.gravarResposta(proxima, retry), 1000);
      });
  };

  /**
   * finaliza a atividade e chama a próxima tela a ser renderizada
   */
   proximaAtividade = async () => {
     try {
       await apiCalls.dinamica.saveInstrumentAnswer(
         Constantes.instrumentoEstilos,
         this.props.idInstrumento,
         Constantes.moduloDinamicas,
         sessionStorage.getItem("atividade"),
         this.props.rodada,
         sessionStorage.getItem("projeto")
       );
       this.props.action();
     } catch (error) {
       console.error(error);
       notifyError("Não foi possível finalizar o instrumento. Por favor, tente novamente");
     }
   };

  tempoAtualizado = tempo => {
    this.setState(previousState => ({
      tempos: {
        ...previousState.tempos,
        tempoRestante: tempo
      }
    }));
  };

  fimAtividade = () => {
    if (this.onLogout) this.props.logout();
    else {
      if (!this.state.loadingSpinner) this.setState({ loadingSpinner: true });
      this.proximaAtividade();
    }
  };

  /**
   * callback do botão "prosseguir"
   */
  avaliarProxima = async () => {
    try {
      if (!this.onProximo) {
        this.onProximo = true;
        this.setState({ loadingSpinner: true });
        if (
          this.state.perguntaAtual <
          this.state.dados[0].alternativas.length - 1
        ) {
          this.verificaResultado(this.state.initialData, true);
          this.gravarResposta(true);
        } else if (
          this.state.perguntaAtual ===
          this.state.dados[0].alternativas.length - 1
        ) {
          this.verificaResultado(this.state.initialData, true);
          await this.gravarResposta(false);
          this.fimAtividade();
        }
      }
      this.setState({ errorCount: 0 });
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    } catch (error) {
      console.error(error);
    } finally {
      document.getElementsByTagName("body")[0].scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  };

  toggleConfirmacao = () => {
    if (!this.state.loadingSpinner) {
      this.setState(previousState => ({
        modalConfirmacao: !previousState.modalConfirmacao
      }));
    }
  };

  toggleInstrucoes = () => {
    this.setState(previousState => ({
      modalInstrucoes: !previousState.modalInstrucoes
    }));
  };

  colapsa = () => {
    this.setState(previousState => ({
      barra: !previousState.barra
    }));
  };

  toggleErro = () => {
    this.setState(previousState => ({
      modalErro: !previousState.modalErro
    }));
  };

  render() {
    if (this.state.haveError) return <ErrorComponent />;
    if (this.state.loading) return <Loading />;

    return (
      <div className="text-left back" id="logo" tabIndex="0">
        <Modal
          isOpen={this.state.modalInstrucoes}
          className={this.props.className}
        >
          <ModalBody>
            <Instrucoes>
              <div>
                <b>INSTRUÇÕES</b>
                <br />
                <b>Enunciado</b><br />
                <P>Nesta atividade você terá {`${millisToMinutesAndSeconds(this.tempoTotal)}`} minutos para indicar qual das frases apresentadas à esquerda da tela melhor representa você e cada um dos participantes do seu grupo, listados à direita da tela. Para tanto, considere sua experiência com os demais participantes nesta atividade. Serão apresentadas seis rodadas com diferentes possibilidades de respostas.</P>
                <b>Como indicar os participantes nas frases</b> <br />
                <strong>Acesso com mouse</strong>
                <P>Posicione o mouse até o nome/foto do participante à direita da tela, clicar e segurar clicado, mover o participante para o espaço abaixo da frase com a qual você o identifica e soltar o botão do mouse.</P>
                <strong>Acesso com touch (tablets/celulares)</strong>
                <P>Encoste o dedo na tela do seu aparelho sobre o nome/foto, arraste o participante para o espaço abaixo da frase com a qual você o identifica e tire o dedo da tela.</P>

                <strong>Regras</strong>
                <P>É obrigatório você inserir todos os participantes (incluindo você) em alguma das assertivas.</P>
                <P>Você pode incluir quantos participantes quiser na mesma assertiva.</P>
                <P>Não é necessário que todas as assertivas tenham algum participante vinculado.</P>
                <P>É obrigatório enviar as 6 rodadas.</P>
                <P>OBSERVAÇÃO: Caso desejar, você poderá clicar no ícone <b>☰</b> no campo dos nomes para redimensionar sua tela, deixando apenas as fotos dos participantes, sem o nome. Caso deseje desfazer, basta clicar novamente no ícone e o nome voltará a aparecer.</P> 
              </div>
            </Instrucoes>
          </ModalBody>
          <ModalFooter>
            <Container className="text-center">
              <Button color="success" onClick={this.toggleInstrucoes}>
                ok
              </Button>{" "}
            </Container>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modalErro}
          toggle={this.toggleErro}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleErro}>
            Estilos de Liderança
          </ModalHeader>
          <ModalBody>
            Você só pode prosseguir após arrastar todos os participantes de seu
            grupo para aresposta desejada.
          </ModalBody>
          <ModalFooter>
            <Button color="success" onClick={this.toggleErro}>
              ok
            </Button>{" "}
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modalConfirmacao}
          toggle={this.toggleConfirmacao}
          className={this.props.className}
          onClosed={() => (this.onProximo = false)}
        >
          <ModalHeader toggle={this.toggleConfirmacao}>
            Estilos de Liderança
          </ModalHeader>
          <ModalBody>
            Se você estiver satisfeito com suas escolhas clique em prosseguir,
            se não clique em cancelar
          </ModalBody>
          <ModalFooter>
            {!this.state.loadingSpinner ? (
              <div>
                <Button
                  color="success"
                  onClick={() => this.avaliarProxima()}
                  disabled={this.state.loadingSpinner}
                >
                  Prosseguir
                </Button>{" "}
                <Button
                  color="danger"
                  onClick={this.toggleConfirmacao}
                  disabled={this.state.loadingSpinner}
                >
                  Cancelar
                </Button>
              </div>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>Enviando. Por favor não recarregue a tela. <img style={{ width: 20 }} src={require(`../../Assets/_assets/${spinner}`)} /></span>
                {
                  this.state.errorCount > 0 ?
                    <><span style={{ fontSize: 12 }}>Tentativas {this.state.errorCount} / {this.state.errorRetry}.</span></>
                  : null
                }
              </div>
            )}
          </ModalFooter>
        </Modal>
        <Base>
          <Container>
            <AlertRow>
              <Col>
                <UncontrolledAlert color={'primary'}>
                Essa atividade requer que você use gestos de arrastar e soltar. Recomendamos que você use seu disposito no modo ‘paisagem’ (posição horizontal).
                </UncontrolledAlert>
              </Col>
            </AlertRow>
            <br />
            <br />
            <br />

            <Row>
              <Col>
                <h1>Estilos de Liderança</h1>
              </Col>
              <Col className="text-right">
                <Icon
                  src={require("../../Assets/_assets/doubt.png")}
                  alt="Instrucoes"
                />
                <Inst onClick={this.toggleInstrucoes}>Instruções</Inst>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col sm="6">
                <Contador
                  counter={
                    <Timer
                      intervalResetRequest={this.state.intervalResetRequest}
                      intervalResetFeedback={() => this.setState({ intervalResetRequest: false })}
                      crescente={false}
                      tempo={this.state.tempos.tempoRestante}
                      resetAutomatico={false}
                      tempoAtualizado={this.tempoAtualizado}
                      pararTimer={this.state.pararTimer}
                      resetar={false}
                      tempoParado={this.fimAtividade}
                      id="tempo"
                    />
                  }
                  tempo={
                    100 * (this.state.tempos.tempoRestante / this.tempoTotal)
                  }
                />
              </Col>
            </Row>

            <Row style={{marginTop: 20}}>
              {
                montaPaginas(Constantes.estilosTotalQuestoes, this.state.perguntaAtual + 1)
              }
              <Clear />
            </Row>
            <br />
            <br />

            <Row>
              <StyledCol sm="1">
                <h2>{this.state.perguntaAtual + 1}.</h2>
              </StyledCol>
              <Col>
                <p>
                  {
                    this.state.dados[0].alternativas[this.state.perguntaAtual]
                      .pergunta
                  }
                </p>
              </Col>
            </Row>

            <DragDropContext onDragEnd={this.onDragEnd}>
              <Container>
                <StyledRow>
                  <Col style={{ marginTop: 10 }}>
                    {this.state.initialData.columnOrder.map(columnId => {
                      const column = this.state.initialData.columns[columnId];
                      const tasks = column.taskIds.map(
                        taskId => this.state.initialData.tasks[taskId]
                      );
                      return (
                        <Col sm="12" key={column.id}>
                          <Box>
                            {column.title !== "Participantes" ? (
                              <Col>
                                <Column column={column} tasks={tasks} />
                              </Col>
                            ) : (
                              ""
                            )}
                          </Box>
                        </Col>
                      );
                    })}
                  </Col>
                  <Col style={{ marginTop: 10 }} sm={this.state.barra ? "2" : "4"}>
                    <Boxed className="posicao" style={{ padding: 5 }}>
                      <span onClick={this.colapsa}>
                        <b style={{ fontSize: 15, cursor: "pointer" }}>
                          {" "}
                          &#9776; Participantes
                        </b>
                      </span>

                      {this.state.initialData.columnOrder.map(columnId => {
                        const column = this.state.initialData.columns[columnId];
                        const tasks = column.taskIds.map(
                          taskId => this.state.initialData.tasks[taskId]
                        );
                        return (
                          <div key={column.id}>
                            {column.title === "Participantes" ? (
                              <Col>
                                <Column
                                  barra={this.state.barra}
                                  column={column}
                                  tasks={tasks}
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })}
                    </Boxed>
                  </Col>
                </StyledRow>
              </Container>
            </DragDropContext>
            <Row>
              <Col className="text-center">
                <Button id="button" onClick={this.verifica}>
                  Enviar
                </Button>
                <Footer />
              </Col>
            </Row>
          </Container>
        </Base>
      </div>
    );
  }
}

export default withRouter(TiposDeLideranca)